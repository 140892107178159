import React, { useEffect, useRef } from "react";
import { FormText } from "react-bootstrap";


const ShowError = ({ addClass, errors, current_key }) => {

  const errorMsg = useRef(null);

  useEffect(() => {
    if (errorMsg && 'focusKey' in errors) {
      errorMsg.current.focus();
    }
  }, [errors])


  if (current_key in errors) {

    return (
      <FormText ref={errorMsg} className={`invalid-feedback-text ${addClass ?? ''}`}>
        {errors[current_key]}
      </FormText>
    );

  }
  return "";
};

export default ShowError;

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useFindNewsLetterStatusQuery, useUnSubscribeEmailMutation } from '../redux-services/apis/newsApi';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Spiner from '../components/Layouts/Spiner';

const UnSubscribe = () => {
    const email = useLocation();
    const [status, setStatus] = useState(false);
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [unSubscribeEmail, unSubscribeEmailResponse] = useUnSubscribeEmailMutation();
    const { isLoading, data: findNewsLetterStatus } = useFindNewsLetterStatusQuery({ email: email?.search?.split('=')[1] });

    useEffect(() => {
        if(findNewsLetterStatus?.response?.status !== undefined) {
            setStatus(findNewsLetterStatus?.response?.status)
        }
    }, [findNewsLetterStatus, show, status])

    useEffect(() => {
        if (unSubscribeEmailResponse?.isSuccess) {
            setShow(true);
        }
        if (unSubscribeEmailResponse?.isError) {
            setShow(true);
        }
    }, [unSubscribeEmailResponse])

    useEffect(() => {
        if (email?.search?.split('=')[1] === undefined || email.search?.split('=')[1] === '') {
            navigate('/');
        }
    }, [])

    const handleClick = () => {
        unSubscribeEmail({ email: email?.search?.split('=')[1] });
    }
    const handleShow = () => {
        setShow(!show);
    }

    return (
        <>
            {isLoading ? <Spiner /> :
                <div>
                    {unSubscribeEmailResponse?.isSuccess && show &&
                        <Col md="12">
                            <div className="productTopBar mb-0">
                                <p>
                                    {unSubscribeEmailResponse?.data?.message}
                                </p>
                                <Button onClick={handleShow} className="btn btn btn-primary">
                                    Close
                                </Button>
                            </div>
                        </Col>
                    }
                    {unSubscribeEmailResponse?.isError && show &&
                        <Col md="12">
                            <div className="productTopBar mb-0">
                                <p>
                                    {unSubscribeEmailResponse?.error?.data?.message}
                                </p>
                                <Button onClick={handleShow} className="btn btn btn-primary">
                                    Close
                                </Button>
                            </div>
                        </Col>
                    }
                    <section className="loginOuter">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md="8">
                                    <div className="thankyou-outer">
                                        <img src="images/unsubscribe.png" alt="#" />
                                        <h3>Would you like to {status ? 'Unsubscribe' : 'Subscribe to'}?</h3>
                                        <button onClick={handleClick} className="btn btn-primary">{status ? 'Unsubscribe' : 'Subscribe'}</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            }
        </>
    )
}

export default UnSubscribe
import React, { useCallback, useEffect, useState, createRef } from 'react';
import { Container, Row, Col, Button, Form, Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showModel } from "../redux-services/slices/siteDataSlice";
import { useDoLoginMutation, useDoSubLoginMutation, useSendOtpMutation } from "../redux-services/apis/authApi";
import { getPreviousLocation, setPreviousLocation } from "../redux-services/slices/authSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { RECAPTCHA_SITE_KEY } from '../config';
import ShowModel from './Notifications/ShowModel';

function Login() {

  const navigate = useNavigate();
  let location = useLocation();
  const recaptchaRef = createRef();
  const previousLocation = useSelector(getPreviousLocation);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordId, setPasswordId] = useState("");
  const [message, setMessage] = useState('');

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const dispatch = useDispatch();

  const [login, loginResponse] = useDoLoginMutation();
  const [subLogin, subLoginResponse] = useDoSubLoginMutation();
  const [sendOtp, sendOtpResponse] = useSendOtpMutation();
  const [showOtpMode, setShowOtpMode] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [selectedOtpOption, setSelectedOtpOption] = useState("email");



  const { email, password } = formData;

  const handleOtpOptionChange = (e) => {
    setSelectedOtpOption(e.target.value);
  }

  const onChangeOtp = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    setOtp(numericValue);
  }

  const onCloseOtpOptions = () => {
    setShowOtpMode(false);
  }

  const onCloseOtpInput = () => {
    setShowOtpInput(false);
  }

  const onChange = (e) => {
    if (e.target.name === 'password') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === 'email') {
      setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    }
  };

  useEffect(() => {
    if (loginResponse.isError) {
      // setMessage(loginResponse.error.data.message);
      dispatch(showModel({ isOpen: true, modelType: "danger", body: loginResponse?.error?.data?.message }))
      if (loginResponse.error.data.errors.otpExprired) {
        setShowOtpInput(false);
        setOtp("");
        setOtpError(true);
        return;
      }
      if (!loginResponse.error.data.errors.otpVerified) {
        setOtpError(true);
        return;
      }
      setShowOtpInput(false);
    } else if (loginResponse.isSuccess) {
      const { productMerge } = loginResponse.data.response.user;
      dispatch(setPreviousLocation(null));
      setOtp("");
      setOtpError(false);
      if (location?.state) {
        navigate(location?.state ?? "/");
      } else {
        if (productMerge?.productMerge) {
          navigate(previousLocation ?? "/cart");
        } else {
          navigate(previousLocation ?? "/");
        }
      }
    }

  }, [loginResponse]);


  useEffect(() => {
    if (subLoginResponse?.isError) {
      // setMessage(subLoginResponse.error.data.message);
      dispatch(showModel({ isOpen: true, modelType: "danger", body: subLoginResponse?.error?.data?.message }))
    } else if (subLoginResponse.isSuccess) {
      if (subLoginResponse.data.response.redirect) {
        const { productMerge } = subLoginResponse.data.response.user;
        dispatch(setPreviousLocation(null));
        if (location?.state) {
          navigate(location?.state ?? "/");
        } else {
          if (productMerge?.productMerge) {
            navigate(previousLocation ?? "/cart");
          } else {
            navigate(previousLocation ?? "/");
          }
        }
      } else if (subLoginResponse.data.response.isDealer) {
        const firstMaskedLength = 2;
        const lastMaskLength = 3;
        const domainSeparator = "@";

        const [username, domain] = email.split(domainSeparator);

        // const maskedPart = "*".repeat(username.length - (firstMaskedLength + lastMaskLength));
        // const maskedEmail = `${username.slice(0, firstMaskedLength)}${maskedPart}${username.substring(username.length - lastMaskLength)}${domainSeparator}${domain}`;

        let maskedEmail = "";
        if (username.length <= (lastMaskLength * 2)) {
          // If the username is too short to mask, keep it as is
          maskedEmail = `${username.charAt(0)}${"*".repeat(username.length - 1)}${domainSeparator}${domain}`;
        } else if (username.length <= firstMaskedLength + (lastMaskLength * 2)) {
          maskedEmail = `${username.substring(0, 2)}${"*".repeat(username.length - 2)}${domainSeparator}${domain}`;
        } else {
          const maskedPart = "*".repeat(username.length - (firstMaskedLength + lastMaskLength));
          maskedEmail = `${username.slice(0, firstMaskedLength)}${maskedPart}${username.substring(username.length - lastMaskLength)}${domainSeparator}${domain}`;
        }

        setInputEmail(maskedEmail);
        if (subLoginResponse.data.response.phone) {
          const originalPhoneNumber = subLoginResponse.data.response.phone;
          const countryCode = originalPhoneNumber.substring(0, 3);
          const lastDigits = originalPhoneNumber.substring(originalPhoneNumber.length - 3);
          const maskedPhoneNumber = `${countryCode}******${lastDigits}`;
          setPhoneNumber(maskedPhoneNumber);
        } else {
          setPhoneNumber(subLoginResponse.data.response.phone);
        }
        setShowOtpMode(true);
      } else if (subLoginResponse.data.response.isAdmin) {
        sendOtp({ email, password, otpMode: selectedOtpOption });
        setShowOtpInput(true);
      }
    }

  }, [subLoginResponse]);

  useEffect(() => {
    setOtpError(false);
    if (sendOtpResponse?.isError) {
      // setMessage(sendOtpResponse.error.data.message);
      dispatch(showModel({ isOpen: true, modelType: "danger", body: sendOtpResponse?.error?.data?.message }))
    } else if (sendOtpResponse?.isSuccess) {
      // setMessage(sendOtpResponse.data.message);
      dispatch(showModel({ isOpen: true, modelType: "success", body: sendOtpResponse?.data?.message }))
      setShowOtpMode(false);
      setShowOtpInput(true);
    }
    setTimeout(() => {
      setMessage("");
    }, 10000);
  }, [sendOtpResponse]);

  const onReCAPTCHAChange = async (captchaCode) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      setMessage("Please Select the  Recaptcha")
      return false;
    } else {
      onSubmit(captchaCode)
    }
  }


  const generateCaptach = async (e) => {
    e.preventDefault();
    const tokenData = recaptchaRef.current.execute();
  }

  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordId(e.currentTarget.getAttribute("data-id"));
    setPasswordShown(!passwordShown);
  };

  const onSubmitSendOtp = () => {
    sendOtp({ email, password, otpMode: selectedOtpOption })
  }

  const onSubmitVerifyOtp = () => {
    login({ email, password, otp })
  };

  const onClickResendOtp = () => {
    sendOtp({ email, password, otpMode: selectedOtpOption })
  }

  const onSubmit = ((token) => {
    if (token !== '') {
      subLogin({ email, password, token });
    } else {
      setMessage("Please Select the Recaptcha")
    }
    recaptchaRef.current.reset();
  });

  return (
    <>
      <section className="loginOuter">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="4">
              <div className="logoOuter">
                <Link to="">
                  <img src="images/logo.svg" />
                </Link>
              </div>
              <Form onSubmit={generateCaptach}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={onReCAPTCHAChange}
                />
                <div className="loginformOuter">
                  <h4>Login</h4>
                  <hr />

                  {/* {(subLoginResponse.isError || message != '') ? <div className="alert alert-danger">{message}</div> : null} */}
                  <ShowModel />
                  <div className="form-group">
                    <input type="text" name="email" onChange={onChange} placeholder="" className="form-control" />
                  </div>
                  <div className="form-group">
                    <input type={(passwordShown && passwordId == "password") ? "text" : "password"} name="password" onChange={onChange} placeholder="" className="form-control" />
                    <span className='viewpass' onClick={togglePassword} data-id="password" >{(passwordShown && passwordId == "password") ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                  </div>
                  <div className="form-group">
                    <Row className="align-items-center">
                      <Col md="6">
                        <div className='checkouter m-0'>
                          <span className="custom_check">Remember me<input type="checkbox" /><span className="check_indicator">&nbsp;</span></span>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className='forgetps text-end'>
                          <Link to="/forgot-password">Forgot Password</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="form-group text-center">
                    <Button type='submit'>Login</Button>
                  </div>
                  <div className="account">
                    <p>Don't have an account? <Link to="/register">Click here to Sign Up.</Link></p>
                    <p>Are you a dealer? <Link to="/dealer-registration">Click here</Link></p>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal className='To-factor' show={showOtpMode} centered>
        <div className="logoOuter">
          <Link to="">
            <img src="images/logo.svg" />
          </Link>
        </div>
        <ModalHeader onHide={onCloseOtpOptions} style={{ fontWeight: 600 }}>
          Two-Factor Authentication
          <hr />
        </ModalHeader>
        <ModalBody>
          {phoneNumber ? <p style={{ fontWeight: 500 }}>Choose a Authentication Method</p> : null}
          <div className='otp-mode-options'>
            <label className='otp-mode-option'>
              <input
                type="radio"
                value="email"
                checked={selectedOtpOption === 'email'}
                onChange={handleOtpOptionChange}

              />
              Email - ({inputEmail})
            </label>
            {phoneNumber ?
              <label>
                <input
                  type="radio"
                  value="phone"
                  checked={selectedOtpOption === 'phone'}
                  onChange={handleOtpOptionChange}

                />
                Phone - ({phoneNumber})
              </label> : null}
          </div>
        </ModalBody>
        <ModalFooter>
          <div>
            <Button type="button" onClick={onSubmitSendOtp} style={{ marginRight: "5px" }}>Send OTP</Button>
            <Button type="button" variant="secondary" onClick={onCloseOtpOptions}>Cancel</Button>
          </div>
        </ModalFooter>
      </Modal>
      <Modal className='To-factor' show={showOtpInput} centered>
        <div className="logoOuter">
          <Link to="">
            <img src="images/logo.svg" />
          </Link>
        </div>
        <ModalHeader closeButton={showOtpInput} onHide={onCloseOtpInput} style={{ fontWeight: 600 }}>
          Two-Factor Authentication
          <hr />
        </ModalHeader>
        <ModalBody>
          <input
            type="text"
            value={otp}
            placeholder='Enter OTP'
            onChange={(e) => onChangeOtp(e)}
            className="form-control"
            style={{ marginBottom: "10px" }}
          />
          {/* {(!otpError && sendOtpResponse.isError && message != '') ? <div className="alert alert-danger">{message}</div> : null}
          {(!otpError && sendOtpResponse.isSuccess && message != '') ? <div className="alert alert-success">{message}</div> : null}
          {(otpError && loginResponse.isError && message != '') ? <div className="alert alert-danger">{message}</div> : null}
          {(otpError && loginResponse.isSuccess && message != '') ? <div className="alert alert-success">{message}</div> : null} */}
          <ShowModel />
        </ModalBody>
        <ModalFooter>
          <div>
            <Button type="submit" onClick={onSubmitVerifyOtp} style={{ marginRight: "5px" }} disabled={otp.length <= 3}>Verify OTP</Button>
            <Button type="button" variant="secondary" onClick={onClickResendOtp}>Resend OTP</Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Login;

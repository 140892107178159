import { useCallback, useEffect, useRef, useState } from "react";
import extractNumber from "../../utills/extractNumber";
import { getCountryShort } from "../../utills/countryCode";
import { useSelector } from 'react-redux';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { Button, Col, Form, Row } from "react-bootstrap";
import ShowError from "../Notifications/ShowError";
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from "../../config";
import 'react-phone-number-input/style.css'
import { useAddAddressMutation, useEditAddressMutation } from "../../redux-services/apis/userApi";
import LookupCountry from "../../components/CountryCode/LookupCountry";
import { getUserDetails } from "../../redux-services/slices/authSlice";

const AddAddress = ({ setAddressId, setAddNew, setNewAdded, onClose, editAddress, addressList, setAddAddressResponse, setEditAddressResponse }) => {


  const [errors, setErrors] = useState({});
  //########################## set the initial form data ##############################

  const initialState = {
    name: '',
    email: '',
    alternative_email_one: '',
    alternative_email_two: '',
    ccode: '+1',
    latitute: 0,
    longitude: 0,
    address_line_1: '',
    address_line_2: '',
    google_map_link: '',
    fitting_studio: false,
    contact_no: '',
    iute164_phone: '',
    address: '',
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: ''
  };
  const [formData, setFormData] = useState(initialState);
  const [countryCode, setCountryCode] = useState();
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const authUser = useSelector(getUserDetails);
  const [defaultCountry, setDefaultCountry] = useState('US');
  const [defaultCountryCode, setDefaultCountryCode] = useState('+1');

  //########################## change event for all inputs ##############################
  const onChange = useCallback((e) => {
    const newFormData = { ...formData, [e.target.name]: e.target.value.trimLeft() };
    newFormData['address'] = `${newFormData.address_line_1}, ${newFormData.address_line_2 ? newFormData.address_line_2 + "," : ""} ${newFormData.city}, ${newFormData.state} ${newFormData.zipcode}, ${newFormData.country}`;
    setFormData(newFormData);
  }, [formData]);

  // const onFittingStudioChange = (e) => {
  //   setFormData({ ...formData, fitting_studio: e.target.checked });
  // };
  useEffect(() => {
    setFormData({ ...formData, email: authUser?.email, name: authUser?.first_name + ' ' + authUser?.last_name })
  }, []);


  useEffect(() => {
    const fetchDefaultCountryCode = async () => {
      try {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        const countryCode = data.country_code || '';

        // Use libphonenumber-js to format the country code
        const phoneNumber = getCountryCallingCode(countryCode);


        if (phoneNumber) {
          setDefaultCountry(countryCode);
          setFormData({ ...formData, ccode: `+${phoneNumber}`, email: authUser?.email, name: authUser?.first_name + ' ' + authUser?.last_name });
          setDefaultCountryCode(`+${phoneNumber}`);

        }
      } catch (error) {
        console.error('Error fetching default country code:', error);
      }
    };
    if (!editAddress) {
      fetchDefaultCountryCode();
    }

  }, [authUser]);



  useEffect(() => {
    if (editAddress && addressList) {
      const editableAddress = addressList.find(address => address._id == editAddress);


      if (editableAddress) {
        const addressData = {
          name: editableAddress.name,
          email: editableAddress.email,
          alternative_email_one: editableAddress?.alternative_email_one ?? "",
          alternative_email_two: editableAddress?.alternative_email_two ?? "",
          ccode: editableAddress.ccode,
          address_line_1: editableAddress.address_line_1,
          address_line_2: editableAddress.address_line_2,
          contact_no: editableAddress.contact_no,
          iute164_phone: editableAddress.ccode + editableAddress.contact_no,
          address: editableAddress.address,
          google_map_link: editableAddress.google_map_link,
          fitting_studio: editableAddress.fitting_studio,
          google_id: editableAddress.google_id,
          google_place_id: editableAddress.google_place_id,
          city: editableAddress.city,
          zipcode: editableAddress.zipcode,
          country: editableAddress.country,
          state: editableAddress.state,
          country_code: editableAddress.country_code
        };
        setFormData(addressData);
      }

    }
  }, [editAddress])

  //########################## change event for phone number ##############################
  const onPhoneChange = number => {

    const contact_no = extractNumber(number, defaultCountryCode);

    setFormData((form) => ({
      ...form,
      "contact_no": contact_no,
      "iute164_phone": number ?? '',
    }));
  }

  const onCountryChange = code => {
    setFormData((form) => ({
      ...form,
      "ccode": "+" + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)),
    }));
    setDefaultCountryCode('+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)));
  }

  const setFocusOn = (fieldId) => {
    const fieldItem = document.querySelector("input[name=" + fieldId + "]");
    fieldItem?.focus();
  }

  const [addAddressSubmit, addAddressResponse] = useAddAddressMutation();

  const [editAddressSubmit, editAddressResponse] = useEditAddressMutation();

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();
    if (editAddress) {
      formData.id = editAddress;
      editAddressSubmit(formData);
      if (editAddressResponse.error?.data?.errors[0]?.msg === "") {
        setFormData(initialState);
      }
    }
    else {
      addAddressSubmit({ ...formData, ccode: defaultCountryCode });
      if (addAddressResponse.error?.data?.errors[0]?.msg === "") {
        setFormData(initialState);
      }
    }
  };

  // const handleNavigator = async (pos) => {
  //   const { latitude, longitude } = pos.coords;

  //   const userCountryCode = await LookupCountry({ latitude, longitude });
  //   setCountryCode(userCountryCode);
  // }

  // // useEffect to run the navigator API on initial render
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(handleNavigator, () => console.warn('permission was rejected'));
  // }, []);

  useEffect(() => {
    if (addAddressResponse.isSuccess && addAddressResponse.error?.data?.status !== false) {
      if (setAddAddressResponse !== undefined) {
        setAddAddressResponse(addAddressResponse)
      }

      if (onClose) {
        onClose();
        return;
      }
      setAddressId(addAddressResponse.data.response._id);
      setFormData(initialState);
      setAddNew(false);
      setNewAdded(true);
    }
    if (addAddressResponse.isError && addAddressResponse.error?.data?.status === false) {
      const errorList = {};
      let firstError = "";
      addAddressResponse?.error?.data?.errors?.map((error, index) => {
        if (index === 0) {
          firstError = error.param;
        }
        errorList[error.param] = error.msg;
      });

      setErrors(errorList);
      setFocusOn(firstError);
    }

  }, [addAddressResponse]);

  useEffect(() => {
    if (editAddressResponse.isSuccess && editAddressResponse.error?.data?.status !== false && editAddress) {
      setEditAddressResponse(editAddressResponse)
      if (onClose) {
        onClose();
        return;
      }
      setAddressId(addAddressResponse.data.response._id);
      setFormData(initialState);
      setAddNew(false);
    }
    if (editAddressResponse.error?.data?.status === false) {
      const errorList = {};
      let firstError = "";
      editAddressResponse.error?.data?.errors?.map((error, index) => {
        if (index === 0) {
          firstError = error.param;
        }
        errorList[error.param] = error.msg;
      });

      setErrors(errorList);
      setFocusOn(firstError);
    }

  }, [editAddressResponse])


  const setGMapPlace = useCallback((location) => {

    const google_id = location.place_id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let latitute = location?.geometry?.location?.lat((e) => e.Scopes[0].e);
    let longitude = location?.geometry?.location?.lng((e) => e.Scopes[0].f);


    let city = '';
    let address_line_1 = '';
    let address_line_2 = '';
    let state = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? " " : "") + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? " " : "") + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case "administrative_area_level_2":
          case 'postal_town':
            if (city == "") {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      })


    setFormData(fData => ({
      ...fData,
      address,
      latitute,
      longitude,
      city,
      address_line_1,
      address_line_2,
      google_id,
      google_place_id,
      state,
      location,
      country,
      country_code,
      zipcode,
    }));
    setAutocompleteValue('');
  }, []);

  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  const { name, email, alternative_email_one, alternative_email_two, iute164_phone, address_line_1, address_line_2, city, state, country, zipcode, google_map_link, fitting_studio, address } = formData;

  return <div className='creditcardform'>
    <Form onSubmit={onSubmit}>
      <Row>
        <Col md="12">
          <div className='addressHeading'>
            <h5 className='mb-2'>Personal information:</h5>
          </div>
        </Col>
        <Col md="12">
          <div className='form-group'>
            <input
              type='text'
              name="name"
              placeholder='Name*'
              className='form-control'
              value={name}
              required
              onChange={onChange}
              invalid={errors.name ? "true" : " false"}
            />
            <ShowError current_key="name" key="name" errors={errors} />

          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <input
              type='text'
              name="email"
              placeholder='Email *'
              className='form-control'
              value={email}
              required
              onChange={onChange}
              invalid={errors.email ? "true" : " false"}
            />
            <ShowError current_key="email" key="email" errors={errors} />

          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <PhoneInput
              className="form-control"
              autoComplete="tel"
              withCountryCallingCode={true}
              countryCallingCodeEditable={true}
              smartCaret={true}
              international={true}
              defaultCountry={defaultCountry}
              value={iute164_phone}
              name="contact_no"
              // addInternationalOption={false}
              onChange={onPhoneChange}
              onCountryChange={onCountryChange}
            />
            <ShowError current_key="contact_no" key="contact_no" errors={errors} />

          </div>
        </Col>
        {authUser?.userType === 3 ? <>
          <Col md="6">
            <div className='form-group'>
              <input
                type='text'
                name="alternative_email_one"
                placeholder='Alternative Email One'
                className='form-control'
                value={alternative_email_one}
                onChange={onChange}
                invalid={errors.alternative_email_one ? "true" : " false"}
              />
              <ShowError current_key="alternative_email_one" key="alternative_email_one" errors={errors} />

            </div>
          </Col>
          <Col md="6">
            <div className='form-group'>
              <input
                type='text'
                name="alternative_email_two"
                placeholder='Alternative Email Two'
                className='form-control'
                value={alternative_email_two}
                onChange={onChange}
                invalid={errors.alternative_email_two ? "true" : " false"}
              />
              <ShowError current_key="alternative_email_two" key="alternative_email_two" errors={errors} />

            </div>
          </Col>
        </> : null}
        <Col md="12">
          <div className='addressHeading mt-3'>
            <h5 className='mb-2'>Address information: </h5>
            <small style={{ color: "#435568" }} className='mb-2'><b>{"Note: Use our location finder to add a new address"}</b></small>
          </div>
        </Col>
        <Col md="12">
          <div className='form-group'>
            <Autocomplete
              apiKey={GOOGLE_MAPS_API_KEY}
              onPlaceSelected={setGMapPlace}
              options={{
                types: ['address'],
              }}
              value={autocompleteValue}
              onChange={handleInputChange}
              placeholder="Location Finder"
              className="form-control"
            />
          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <input
              type="text"
              className="form-control"
              id="address_line_1"
              name="address_line_1"
              placeholder='Address Line 1 *'
              maxLength="100"
              value={address_line_1}
              required
              onChange={onChange}
              invalid={errors.address_line_1 ? "true" : " false"}
            />
            <ShowError current_key="address_line_1" key="address_line_1" errors={errors} />

          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <input
              type="text"
              className="form-control"
              id="address_line_2"
              name="address_line_2"
              placeholder='Address Line 2'
              maxLength="100"
              value={address_line_2}
              onChange={onChange}
              invalid={errors.address_line_2 ? "true" : " false"}
            />
            <ShowError current_key="address_line_2" key="address_line_2" errors={errors} />

          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              placeholder='City *'
              maxLength="100"
              value={city}
              required
              onChange={onChange}
              invalid={errors.city ? "true" : " false"}
            />
            <ShowError current_key="city" key="city" errors={errors} />

          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <input
              type="text"
              className="form-control"
              name="state"
              id="state"
              placeholder='State *'
              value={state}
              required
              onChange={onChange}
              invalid={errors.state ? "true" : "false"}
            />
            <ShowError current_key="state" key="state" errors={errors} />

          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <input
              type="text"
              className="form-control"
              name="country"
              id="country"
              placeholder='Country *'

              value={country}
              onChange={onChange}
              required
              invalid={errors.country ? "true" : "false"}
            />
            <ShowError current_key="country" key="country" errors={errors} />

          </div>
        </Col>
        <Col md="6">
          <div className='form-group'>
            <input
              type="text"
              className="form-control"
              id="zipcode"
              name="zipcode"
              placeholder='Zip Code *'
              maxLength="10"
              // minLength="3"
              value={zipcode}
              onChange={onChange}
              // disabled={true}
              required
              invalid={errors.zipcode ? "true" : "false"}
            />
            <ShowError current_key="zipcode" key="zipcode" errors={errors} />

          </div>
        </Col>
        {authUser && authUser?.userType == 3 &&
          <Col md="6">
            <div className='form-group'>
              <input
                type="text"
                className="form-control"
                id="google_map_link"
                name="google_map_link"
                placeholder='Google Map Link'
                value={google_map_link}
                onChange={onChange}
                // disabled={true}
                invalid={errors.google_map_link ? "true" : "false"}
              />
              <ShowError current_key="google_map_link" key="google_map_link" errors={errors} />

            </div>
          </Col>
        }
        {/* {authUser.userType == 3 &&
          <Col md="12">
            <div className='checkouter m-0'>
              <label className="custom_check">Fitting Studio
                <input
                  type="checkbox"
                  id="fitting_studio"
                  name="fitting_studio"
                  className="mr-2"
                  checked={formData.fitting_studio}
                  onChange={onFittingStudioChange}
                  // disabled={true}
                  invalid={errors.fitting_studio ? "true" : "false"}
                />
                <span className="check_indicator">&nbsp;</span>
              </label>
              <ShowError current_key="fitting_studio" key="fitting_studio" errors={errors} />
            </div>
          </Col>
        } */}
        <Col md="12">
          {authUser && authUser?.userType == 3 && fitting_studio == false ?
            <div className='form-group'>
              <label htmlFor="zipcode" style={{ fontSize: "15px" }}><i>If you have a fitting studio at this location then please write to us at "{process.env.REACT_APP_CONTACT_EMAIL}" </i></label>
            </div>
            : ""}
        </Col>
        <Col md="12">
          <div className='form-group'>
            <label htmlFor="zipcode"><b>Address Preview: </b> <i>{address} </i></label>
          </div>
        </Col>
        <Col md="12">
          <div className='form-group text-end'>
            <Button variant="secondary" className='ms-2' onClick={onClose}>Close</Button>

            <Button type="submit" className='ms-2'>{editAddress ? "Update" : "Add"} Address</Button>
          </div>
        </Col>
      </Row>
    </Form>
  </div>
}

export default AddAddress;;

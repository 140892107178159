import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Button, Image, ProgressBar, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { getSelectedShippingRate, setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { getExploreFitting, getProductSelector, setExploreFitting, setFittingOnSpecs, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { useGetProductSelectorDetailMutation } from "../redux-services/apis/productDetailApi";
import { useAddToCartMutation, useGetCartQuery } from "../redux-services/apis/cartApi";
import Spiner from "../components/Layouts/Spiner";
import { getUserDetails } from "../redux-services/slices/authSlice";
import { BsFillExclamationCircleFill, BsFillInfoCircleFill } from "react-icons/bs";
import { BiRefresh } from 'react-icons/bi';

const DUMMY_IMAGE = '/images/productdummy.png';
const ProductResult = () => {
    let navigate = useNavigate();
    const [params] = useSearchParams();
    let initialStateOfWedges = {
        wedges_1: "",
        wedges_2: "",
        wedges_3: "",
        wedges_4: "",
    };
    const exploreFitting = useSelector(getExploreFitting);

    const [showModal, setShowModal] = useState(false);
    const [variationDetail, setVariationDetail] = useState(null);
    const dispatch = useDispatch();
    const [wedges1, setWedges1] = useState(null);
    const [wedges2, setWedges2] = useState(null);
    const [wedges3, setWedges3] = useState(null);
    const [wedgesData, setWedgesData] = useState([]);
    const [shaftCount, setShaftCount] = useState(0);

    const [moreColorFirst, setMoreColorFirst] = useState(false);
    const [moreColorSecond, setMoreColorSecond] = useState(false);
    const [moreColorThird, setMoreColorThird] = useState(false);

    const [flexOne, setFlexOne] = useState(null);
    const [flexTwo, setFlexTwo] = useState(null);
    const [flexThree, setFlexThree] = useState(null);

    const [firstFlex, setFirstFlex] = useState(null);
    const [secondFlex, setSecondFlex] = useState(null);
    const [thirdFlex, setThirdFlex] = useState(null);

    const [ironOne, setIronOne] = useState(null);
    const [ironTwo, setIronTwo] = useState(null);
    const [ironThree, setIronThree] = useState(null);

    const [tipOne, setTipOne] = useState(null);
    const [tipTwo, setTipTwo] = useState(null);
    const [tipThree, setTipThree] = useState(null);

    const [firstProdPrice, setFirstProdPrice] = useState(null);
    const [secondProdPrice, setSecondProdPrice] = useState(null);
    const [thirdProdPrice, setThirdProdPrice] = useState(null);

    const [productOne, setProductOne] = useState(null);
    const [productTwo, setProductTwo] = useState(null);
    const [productThree, setProductThree] = useState(null);

    const productSelector = useSelector(getProductSelector);
    const [addToCart, addToCartResult] = useAddToCartMutation();
    const [currentAddedProductTitle, setCurrentAddedProductTitle] = useState('');
    //################ Handle add to cart notifications #########
    const [cartUpdate, setCartUpdate] = useState(null);

    const [result, setResult] = useState(null);
    const user = useSelector(getUserDetails);
    const [getProductSelectorDetail, getProductSelectorDetailResponse] = useGetProductSelectorDetailMutation();
    let tipCategory = ['Tip', 'Tip Diameter'];
    let ironCategory = ['Iron', 'Iron #'];
    let IronNumbers = ['4', '5', '6', '7', '8', '9', 'P'];

    const selectedShippingRate = useSelector(getSelectedShippingRate);
    let { data: cartDetail } = useGetCartQuery(
        selectedShippingRate?.totalCharges
    );

    useEffect(() => {
        setResult(getProductSelectorDetailResponse?.data?.response[0]);

        if (getProductSelectorDetailResponse?.data?.response[0] !== null && getProductSelectorDetailResponse?.data?.response[0]?.first_product !== undefined) {
            for (let item of getProductSelectorDetailResponse?.data?.response[0]?.first_product?.variation) {
                if (item.attribute_name === 'Flex') {
                    setFlexOne(item.option_id);
                }
                if (ironCategory.includes(item.attribute_name)) {
                    setIronOne(item.option_id);
                }
                if (tipCategory.includes(item.attribute_name)) {
                    setTipOne(item.option_id);
                }
            }
            for (let item of getProductSelectorDetailResponse?.data?.response[0]?.second_product?.variation) {
                if (item.attribute_name === 'Flex') {
                    setFlexTwo(item.option_id);
                }
                if (ironCategory.includes(item.attribute_name)) {
                    setIronTwo(item.option_id);
                }
                if (tipCategory.includes(item.attribute_name)) {
                    setTipTwo(item.option_id);
                }
            }
            for (let item of getProductSelectorDetailResponse?.data?.response[0]?.third_product?.variation) {
                if (item.attribute_name === 'Flex') {
                    setFlexThree(item.option_id);
                }
                if (ironCategory.includes(item.attribute_name)) {
                    setIronThree(item.option_id);
                }
                if (tipCategory.includes(item.attribute_name)) {
                    setTipThree(item.option_id);
                }
            }
        }
    }, [getProductSelectorDetailResponse]);

    useEffect(() => {
        if (getProductSelectorDetailResponse?.data?.response[0] !== undefined && getProductSelectorDetailResponse?.data?.response[0].first_product !== undefined) {
            const flexData = [];
            const flexAttributes = [];
            const ironData = [];
            const ironAttributes = [];
            const tipData = [];
            const tipAttributes = [];
            const data = getProductSelectorDetailResponse?.data?.response[0]?.first_product.variation?.find(
                (data) => data.attribute_name.toLowerCase() === 'flex'
            );
            if (data && !flexAttributes.includes(data.option_id)) {
                flexAttributes.push(data.option_id);
                flexData.push(data);
            }

            const ironDataNew = getProductSelectorDetailResponse?.data?.response[0]?.first_product.variation?.find(
                (data) => ironCategory.includes(data.attribute_name)
            );
            if (
                ironDataNew &&
                !ironAttributes.includes(ironDataNew.option_id)
            ) {
                ironAttributes.push(ironDataNew.option_id);
                ironData.push(ironDataNew);
            }
            const tipDataNew = getProductSelectorDetailResponse?.data?.response[0]?.first_product.variation?.find(
                (data) =>
                    tipCategory.includes(data.attribute_name)
            );
            if (
                tipDataNew &&
                !tipAttributes.includes(tipDataNew.option_id)
            ) {
                tipAttributes.push(tipDataNew.option_id);
                tipData.push(tipDataNew);
            }
            const uniqueflexData = [
                ...new Set(
                    flexData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];

            const uniqueIronData = [
                ...new Set(
                    ironData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];
            const uniqueTipData = [
                ...new Set(
                    tipData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];

            setProductOne({
                ...getProductSelectorDetailResponse?.data?.response[0]?.first_product,
                categoryName: getProductSelectorDetailResponse?.data?.response[0]?.first_product.category_details?.name,
                meterial: getProductSelectorDetailResponse?.data?.response[0]?.first_product.product_details?.meterial,
                slug: getProductSelectorDetailResponse?.data?.response[0]?.first_product?.product_details?.slug,
                construction_technology: getProductSelectorDetailResponse?.data?.response[0]?.first_product.product_details?.construction_technology,
                thumbnail: 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + getProductSelectorDetailResponse?.data?.response[0]?.first_product?.product_details?.thumbnail,
                product_title: getProductSelectorDetailResponse?.data?.response[0]?.first_product?.product_details?.product_title,
                flexData: uniqueflexData,
                ironData: uniqueIronData,
                tipData: uniqueTipData,
                variations: [getProductSelectorDetailResponse?.data?.response[0]?.first_product]
            });
        }
    }, [getProductSelectorDetailResponse])

    useEffect(() => {
        if (getProductSelectorDetailResponse?.data?.response[0] !== undefined && getProductSelectorDetailResponse?.data?.response[0].first_product !== undefined) {
            const flexData = [];
            const flexAttributes = [];
            const ironData = [];
            const ironAttributes = [];
            const tipData = [];
            const tipAttributes = [];
            const data = getProductSelectorDetailResponse?.data?.response[0]?.second_product.variation?.find(
                (data) => data.attribute_name.toLowerCase() === 'flex'
            );
            if (data && !flexAttributes.includes(data.option_id)) {
                flexAttributes.push(data.option_id);
                flexData.push(data);
            }

            const ironDataNew = getProductSelectorDetailResponse?.data?.response[0]?.second_product.variation?.find(
                (data) => ironCategory.includes(data.attribute_name)
            );
            if (
                ironDataNew &&
                !ironAttributes.includes(ironDataNew.option_id)
            ) {
                ironAttributes.push(ironDataNew.option_id);
                ironData.push(ironDataNew);
            }
            const tipDataNew = getProductSelectorDetailResponse?.data?.response[0]?.second_product.variation?.find(
                (data) =>
                    tipCategory.includes(data.attribute_name)
            );
            if (
                tipDataNew &&
                !tipAttributes.includes(tipDataNew.option_id)
            ) {
                tipAttributes.push(tipDataNew.option_id);
                tipData.push(tipDataNew);
            }
            const uniqueflexData = [
                ...new Set(
                    flexData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];

            const uniqueIronData = [
                ...new Set(
                    ironData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];
            const uniqueTipData = [
                ...new Set(
                    tipData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];
            setProductTwo({
                ...getProductSelectorDetailResponse?.data?.response[0]?.second_product,
                categoryName: getProductSelectorDetailResponse?.data?.response[0]?.second_product.category_details?.name,
                meterial: getProductSelectorDetailResponse?.data?.response[0]?.second_product.product_details?.meterial,
                slug: getProductSelectorDetailResponse?.data?.response[0]?.second_product?.product_details?.slug,
                construction_technology: getProductSelectorDetailResponse?.data?.response[0]?.second_product.product_details?.construction_technology,
                thumbnail: 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + getProductSelectorDetailResponse?.data?.response[0]?.second_product?.product_details?.thumbnail,
                flexData: uniqueflexData,
                product_title: getProductSelectorDetailResponse?.data?.response[0]?.second_product?.product_details?.product_title,
                ironData: uniqueIronData,
                tipData: uniqueTipData,
                variations: [getProductSelectorDetailResponse?.data?.response[0]?.second_product]
            });
        }
    }, [getProductSelectorDetailResponse])

    useEffect(() => {
        if (getProductSelectorDetailResponse?.data?.response[0] !== undefined && getProductSelectorDetailResponse?.data?.response[0].first_product !== undefined) {
            const flexData = [];
            const flexAttributes = [];
            const ironData = [];
            const ironAttributes = [];
            const tipData = [];
            const tipAttributes = [];
            const data = getProductSelectorDetailResponse?.data?.response[0]?.third_product.variation?.find(
                (data) => data.attribute_name.toLowerCase() === 'flex'
            );
            if (data && !flexAttributes.includes(data.option_id)) {
                flexAttributes.push(data.option_id);
                flexData.push(data);
            }

            const ironDataNew = getProductSelectorDetailResponse?.data?.response[0]?.third_product.variation?.find(
                (data) => ironCategory.includes(data.attribute_name)
            );
            if (
                ironDataNew &&
                !ironAttributes.includes(ironDataNew.option_id)
            ) {
                ironAttributes.push(ironDataNew.option_id);
                ironData.push(ironDataNew);
            }
            const tipDataNew = getProductSelectorDetailResponse?.data?.response[0]?.third_product.variation?.find(
                (data) =>
                    tipCategory.includes(data.attribute_name)
            );
            if (
                tipDataNew &&
                !tipAttributes.includes(tipDataNew.option_id)
            ) {
                tipAttributes.push(tipDataNew.option_id);
                tipData.push(tipDataNew);
            }
            const uniqueflexData = [
                ...new Set(
                    flexData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];

            const uniqueIronData = [
                ...new Set(
                    ironData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];
            const uniqueTipData = [
                ...new Set(
                    tipData.map((item) => ({
                        option_id: item.option_id,
                        option_term: item.option_term,
                    }))
                ),
            ];
            setProductThree({
                ...getProductSelectorDetailResponse?.data?.response[0]?.third_product,
                categoryName: getProductSelectorDetailResponse?.data?.response[0]?.third_product.category_details?.name,
                meterial: getProductSelectorDetailResponse?.data?.response[0]?.third_product.product_details?.meterial,
                slug: getProductSelectorDetailResponse?.data?.response[0]?.third_product?.product_details?.slug,
                construction_technology: getProductSelectorDetailResponse?.data?.response[0]?.third_product.product_details?.construction_technology,
                thumbnail: 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + getProductSelectorDetailResponse?.data?.response[0]?.third_product?.product_details?.thumbnail,
                flexData: uniqueflexData,
                ironData: uniqueIronData,
                product_title: getProductSelectorDetailResponse?.data?.response[0]?.third_product?.product_details?.product_title,
                tipData: uniqueTipData,
                variations: [getProductSelectorDetailResponse?.data?.response[0]?.third_product]
            });
        }
    }, [getProductSelectorDetailResponse])

    useEffect(() => {
        const { isSuccess, isError } = addToCartResult;
        if (isSuccess || isError) {
            setCartUpdate(
                isSuccess ? addToCartResult.data : addToCartResult.error.data
            );
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [addToCartResult]);

    useEffect(() => {
        let result = [];
        getProductSelectorDetail(productSelector);
        if (productSelector?.questions?.wedges !== undefined) {
            let value = Object.values(productSelector?.questions?.wedges);
            if (value.length > 0) {
                for (let i = 0; i < Object.values(productSelector?.questions?.wedges).length; i++) {
                    if (value[i] !== "") {
                        result.push(value[i]);
                    }
                }
            }
            result.join(",");
        }
        setWedgesData(result);
    }, [])

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    const handleClick = () => {
        const sVariations = result?.first_product?.variation.reduce(
            (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
            {}
        );
        if (result?.first_product?.category_details?.slug === "iron") {
            let checkAddToCart = false;
            if (cartDetail) {
                const filterData = cartDetail?.response?.products?.find(item => item?.productVariationSku === result?.first_product?.sku);
                const prodVariationMatch = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && IronNumbers.includes(item?.term_option));
                const checkVariationFor123 = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === '1-2-3');
                const checkVariationForWedges = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === "GW/SW/LW");
                if (user?.userType !== 2) {
                    checkAddToCart = true;
                } else {
                    if (prodVariationMatch === undefined) {
                        checkAddToCart = true;
                    } else {
                        checkAddToCart = false;
                    }
                    if (checkVariationFor123 !== undefined && filterData?.quantity === 3) {
                        checkAddToCart = false;
                    }
                    if (checkVariationForWedges !== undefined && filterData?.quantity === 4) {
                        checkAddToCart = false;
                    }
                }
            }

            let productVariationList = [];
            let sVariationsList = [];
            let filteredData = [];
            let skuDetails = [];
            let filterProductVariationList;
            let flexOne;
            let colorOne;
            let tipOne;
            let flexTwo;
            let flexNumber;
            let colorTwo;
            let tipTwo;
            let productCountArr = [];
            let wedges_count = 0;

            if (productSelector?.questions?.wedges !== undefined) {
                let value = Object.values(productSelector?.questions?.wedges);
                if (value.length > 0) {
                    for (let i = 0; i < Object.values(productSelector?.questions?.wedges).length; i++) {
                        if (value[i] !== "") {
                            wedges_count = wedges_count + 1;
                        }
                    }
                }
            }

            for (let item of result?.first_product?.variation) {
                if (item.attribute_name === "Flex") {
                    flexTwo = item.option_id;
                    flexNumber = item.option_term;
                }
                if (item.attribute_name === "Color") {
                    colorTwo = item.option_id;
                }
                if (tipCategory.includes(item.attribute_name)) {
                    tipTwo = item.option_id;
                }
            }
            const number = parseInt(flexNumber.match(/\d+/)[0]);
            if (productSelector?.questions?.one_length_player === true) {
                for (let item of result?.productVariationFirst) {
                    const variant = item?.variation;
                    let ironOne;
                    for (const items of variant) {
                        if (items.attribute_name === "Flex") {
                            flexOne = items.option_term;
                        }
                        if (items.attribute_name === "Color") {
                            colorOne = items.option_id;
                        }
                        if (tipCategory.includes(items.attribute_name)) {
                            tipOne = items.option_id;
                        }
                        if (ironCategory.includes(items.attribute_name)) {
                            ironOne = items.option_term;
                        }
                    }
                    const flexs = [`LIP${number}`, `SIP${number}`, `MIP${number}`];
                    if (tipOne === tipTwo && colorOne === colorTwo && flexs.includes(flexOne)) {
                        productVariationList.push(item);
                    }
                }
                let newFilteredArr = [];
                if (productSelector?.questions?.config === "Utility Irons") {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne.option_term)) {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                } else {
                    // if (wedges_count === 0) {
                    //     for (let item of productVariationList) {
                    //         const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    //         if (itemOne !== undefined) {
                    //             if (!itemOne.option_term.includes('GW/SW/LW')) {
                    //                 newFilteredArr.push(item);
                    //             }
                    //         }
                    //     }
                    // } else {
                    newFilteredArr = productVariationList;
                    // }
                }
                filterProductVariationList = newFilteredArr.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let firstVariant = result?.first_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if (optionTerm >= firstVariant?.option_term || optionTerm === "P") {
                        return arr;
                    }
                }));
                for (let item of filterProductVariationList) {
                    const itemOne = item?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                        switch (productSelector?.questions?.config) {
                            case "Utility Irons":
                                productCountArr.push(1);
                                break;
                            case '3 - P':
                                productCountArr.push(3);
                                break;
                            case '4 - P':
                                productCountArr.push(2);
                                break;
                            case '5 - P':
                                productCountArr.push(1);
                                break;
                        }
                    }
                    if (["6 or 7 or 8", "6,7,8"].includes(itemOne?.option_term)) {
                        productCountArr.push(3);
                    }
                    if (["9 or P or GW/SW/LW", "9,P,GW/SW/LW"].includes(itemOne?.option_term)) {
                        productCountArr.push(2);
                    }
                }
            } else {
                for (let item of result?.productVariationFirst) {
                    const variant = item?.variation;
                    for (const items of variant) {
                        if (items.attribute_name === "Flex") {
                            flexOne = items.option_id;
                        }
                        if (items.attribute_name === "Color") {
                            colorOne = items.option_id;
                        }
                        if (tipCategory.includes(items.attribute_name)) {
                            tipOne = items.option_id;
                        }
                    }
                    if (tipOne === tipTwo && flexOne === flexTwo && colorOne === colorTwo) {
                        productVariationList.push(item);
                    }
                }
                let newFilteredArr = [];
                if (productSelector?.questions?.config === "Utility Irons") {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (itemOne.option_term === '1-2-3') {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                } else {
                    // if (wedges_count === 0) {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (!itemOne.option_term.includes('GW/SW/LW')) {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                    // } else {
                    //     newFilteredArr = productVariationList;
                    // }
                }
                filterProductVariationList = newFilteredArr.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let firstVariant = result?.first_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if (optionTerm >= firstVariant?.option_term || optionTerm === "P") {
                        return arr;
                    }
                }))
            }
            for (let item = 0; item <= filterProductVariationList.length; item++) {
                if (filterProductVariationList[item]?.length === 4) {
                    let productVariation = {
                        variation: filterProductVariationList[item],
                        sku: productVariationList[item].sku,
                        variation_id: productVariationList[item]._id
                    };
                    filteredData.push(productVariation);
                }
            }

            for (let item of filteredData) {
                let sVariationReduceData = item?.variation?.reduce(
                    (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
                    {}
                );
                sVariationsList.push(sVariationReduceData);
                let formatData = {
                    variation_id: item.variation_id,
                    sku: item.sku,
                }
                skuDetails.push(formatData);
            }
            let wedgesData = Object.values(productSelector?.questions?.wedges);
            let count = 0;
            for (let item of wedgesData) {
                if (item !== '') {
                    count += 1;
                }
            }
            const sVariationsWedges = wedges1?.product?.variation.reduce(
                (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
                {}
            );
            if (checkAddToCart) {
                let newProduct = {
                    product_id: result?.first_product?.product_details?._id,
                    product_slug: result?.first_product?.product_details?.slug,
                    product_title: result?.first_product?.product_details?.product_title,
                    product_variation: sVariationsList,
                    one_length_quantity: productCountArr,
                    quantity: 1,
                    fitting: {},
                    product_variation_sku: result?.first_product?.sku,
                    product_variation_id: result?.first_product?._id,
                    skuDetails: skuDetails,
                    wedges: (productSelector?.questions?.wedges === initialStateOfWedges) ? [] : wedges1,
                    wedges_variation: sVariationsWedges,
                    wedges_count: count,
                    acknowledgementChecked: false,
                };
                addToCart({ productDetails: newProduct });
                checkAddToCart = false;
            }
        } else {
            let newProduct = {
                product_id: result?.first_product?.product_details?._id,
                product_slug: result?.first_product?.product_details?.slug,
                product_title: result?.first_product?.product_details?.product_title,
                product_variation: sVariations,
                quantity: 1,
                fitting: {},
                product_variation_sku: result?.first_product?.sku,
                product_variation_id: result?.first_product?._id,
                acknowledgementChecked: false,
            };
            addToCart({ productDetails: newProduct });
        }
        let setProduct = {
            ...productSelector,
            product_selector_result_id: result?.result_id,
        }
        dispatch(setProductSelector(setProduct))
        setCurrentAddedProductTitle(result?.first_product?.product_details?.product_title);
    };
    const handleClickSecondProd = () => {
        const sVariations = result?.second_product?.variation.reduce(
            (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
            {}
        );
        if (result?.second_product?.category_details?.slug === "iron") {
            let checkAddToCart = false;
            if (cartDetail) {
                const filterData = cartDetail?.response?.products?.find(item => item?.productVariationSku === result?.second_product?.sku);
                const prodVariationMatch = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && IronNumbers.includes(item?.term_option));
                const checkVariationFor123 = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === '1-2-3');
                const checkVariationForWedges = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === "GW/SW/LW");
                if (user?.userType !== 2) {
                    checkAddToCart = true;
                } else {
                    if (prodVariationMatch === undefined) {
                        checkAddToCart = true;
                    } else {
                        checkAddToCart = false;
                    }
                    if (checkVariationFor123 !== undefined && filterData?.quantity === 3) {
                        checkAddToCart = false;
                    }
                    if (checkVariationForWedges !== undefined && filterData?.quantity === 4) {
                        checkAddToCart = false;
                    }
                }
            }

            let productVariationList = [];
            let sVariationsList = [];
            let filteredData = [];
            let skuDetails = [];
            let filterProductVariationList;
            let flexOne;
            let colorOne;
            let tipOne;
            let flexTwo;
            let flexNumber;
            let colorTwo;
            let tipTwo;
            let productCountArr = [];
            let wedges_count = 0;

            if (productSelector?.questions?.wedges !== undefined) {
                let value = Object.values(productSelector?.questions?.wedges);
                if (value.length > 0) {
                    for (let i = 0; i < Object.values(productSelector?.questions?.wedges).length; i++) {
                        if (value[i] !== "") {
                            wedges_count = wedges_count + 1;
                        }
                    }
                }
            }

            for (let item of result?.second_product?.variation) {
                if (item.attribute_name === "Flex") {
                    flexTwo = item.option_id;
                    flexNumber = item.option_term;
                }
                if (item.attribute_name === "Color") {
                    colorTwo = item.option_id;
                }
                if (tipCategory.includes(item.attribute_name)) {
                    tipTwo = item.option_id;
                }
            }
            const number = parseInt(flexNumber.match(/\d+/)[0]);
            if (productSelector?.questions?.one_length_player === true) {
                for (let item of result?.productVariationSecond) {
                    const variant = item?.variation;
                    let ironOne;
                    for (const items of variant) {
                        if (items.attribute_name === "Flex") {
                            flexOne = items.option_term;
                        }
                        if (items.attribute_name === "Color") {
                            colorOne = items.option_id;
                        }
                        if (tipCategory.includes(items.attribute_name)) {
                            tipOne = items.option_id;
                        }
                        if (ironCategory.includes(items.attribute_name)) {
                            ironOne = items.option_term;
                        }
                    }
                    const flexs = [`LIP${number}`, `SIP${number}`, `MIP${number}`];
                    if (tipOne === tipTwo && colorOne === colorTwo && flexs.includes(flexOne)) {
                        productVariationList.push(item);
                    }
                }

                let newFilteredArr = [];
                if (productSelector?.questions?.config === "Utility Irons") {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne.option_term)) {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                } else {
                    // if (wedges_count === 0) {
                    //     for (let item of productVariationList) {
                    //         const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    //         if (itemOne !== undefined) {
                    //             if (!itemOne.option_term.includes('GW/SW/LW')) {
                    //                 newFilteredArr.push(item);
                    //             }
                    //         }
                    //     }
                    // } else {
                    newFilteredArr = productVariationList;
                    // }
                }
                filterProductVariationList = newFilteredArr.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let secondVariant = result?.second_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if (optionTerm >= secondVariant?.option_term || optionTerm === "P") {
                        return arr;
                    }
                }));
                for (let item of filterProductVariationList) {
                    const itemOne = item?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                        switch (productSelector?.questions?.config) {
                            case "Utility Irons":
                                productCountArr.push(1);
                                break;
                            case '3 - P':
                                productCountArr.push(3);
                                break;
                            case '4 - P':
                                productCountArr.push(2);
                                break;
                            case '5 - P':
                                productCountArr.push(1);
                                break;
                        }
                    }
                    if (["6 or 7 or 8", "6,7,8"].includes(itemOne?.option_term)) {
                        productCountArr.push(3);
                    }
                    if (["9 or P or GW/SW/LW", "9,P,GW/SW/LW"].includes(itemOne?.option_term)) {
                        productCountArr.push(2);
                    }
                }
            } else {
                for (let item of result?.productVariationSecond) {
                    const variant = item?.variation;
                    for (const items of variant) {
                        if (items.attribute_name === "Flex") {
                            flexOne = items.option_id;
                        }
                        if (items.attribute_name === "Color") {
                            colorOne = items.option_id;
                        }
                        if (tipCategory.includes(items.attribute_name)) {
                            tipOne = items.option_id;
                        }
                    }
                    if (tipOne === tipTwo && flexOne === flexTwo && colorOne === colorTwo) {
                        productVariationList.push(item);
                    }
                }

                let newFilteredArr = [];
                if (productSelector?.questions?.config === "Utility Irons") {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (itemOne.option_term === '1-2-3') {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                } else {
                    // if (wedges_count === 0) {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (!itemOne.option_term.includes('GW/SW/LW')) {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                    // } else {
                    //     newFilteredArr = productVariationList;
                    // }
                }
                filterProductVariationList = newFilteredArr.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let secondVariant = result?.second_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if (optionTerm >= secondVariant?.option_term || optionTerm === "P") {
                        return arr;
                    }
                }))
            }

            for (let item = 0; item <= filterProductVariationList.length; item++) {
                if (filterProductVariationList[item]?.length === 4) {
                    let productVariation = {
                        variation: filterProductVariationList[item],
                        sku: productVariationList[item].sku,
                        variation_id: productVariationList[item]._id
                    };
                    filteredData.push(productVariation);
                }
            }

            for (let item of filteredData) {
                let sVariationReduceData = item?.variation?.reduce(
                    (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
                    {}
                );
                sVariationsList.push(sVariationReduceData);
                let formatData = {
                    variation_id: item.variation_id,
                    sku: item.sku,
                }
                skuDetails.push(formatData);
            }
            let wedgesData = Object.values(productSelector?.questions?.wedges);
            let count = 0;
            for (let item of wedgesData) {
                if (item !== '') {
                    count += 1;
                }
            }
            const sVariationsWedges = wedges2?.product?.variation.reduce(
                (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
                {}
            );

            if (checkAddToCart) {
                let newProduct = {
                    product_id: result?.second_product?.product_details?._id,
                    product_slug: result?.second_product?.product_details?.slug,
                    product_title: result?.second_product?.product_details?.product_title,
                    product_variation: sVariationsList,
                    one_length_quantity: productCountArr,
                    quantity: 1,
                    fitting: {},
                    product_variation_sku: result?.second_product?.sku,
                    product_variation_id: result?.second_product?._id,
                    skuDetails: skuDetails,
                    wedges: (productSelector?.questions?.wedges === initialStateOfWedges) ? [] : wedges2,
                    wedges_variation: sVariationsWedges,
                    wedges_count: count,
                    acknowledgementChecked: false,
                };
                addToCart({ productDetails: newProduct });
                checkAddToCart = false;
            }
        } else {
            let newProduct = {
                product_id: result?.second_product?.product_details?._id,
                product_slug: result?.second_product?.product_details?.slug,
                product_title: result?.second_product?.product_details?.product_title,
                product_variation: sVariations,
                quantity: 1,
                fitting: {},
                product_variation_sku: result?.second_product?.sku,
                product_variation_id: result?.second_product?._id,
                acknowledgementChecked: false,
            };
            addToCart({ productDetails: newProduct });
        }
        let setProduct = {
            ...productSelector,
            product_selector_result_id: result?.result_id,
        }
        dispatch(setProductSelector(setProduct))
        setCurrentAddedProductTitle(result?.second_product?.product_details?.product_title);
    };
    const handleClickThirdProd = () => {
        const sVariations = result?.third_product?.variation.reduce(
            (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
            {}
        );
        if (result?.third_product?.category_details?.slug === "iron") {
            let checkAddToCart = false;
            if (cartDetail) {
                const filterData = cartDetail?.response?.products?.find(item => item?.productVariationSku === result?.third_product?.sku);
                const prodVariationMatch = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && IronNumbers.includes(item?.term_option));
                const checkVariationFor123 = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === '1-2-3');
                const checkVariationForWedges = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === "GW/SW/LW");
                if (user?.userType !== 2) {
                    checkAddToCart = true;
                } else {
                    if (prodVariationMatch === undefined) {
                        checkAddToCart = true;
                    } else {
                        checkAddToCart = false;
                    }
                    if (checkVariationFor123 !== undefined && filterData?.quantity === 3) {
                        checkAddToCart = false;
                    }
                    if (checkVariationForWedges !== undefined && filterData?.quantity === 4) {
                        checkAddToCart = false;
                    }
                }
            }

            let productVariationList = [];
            let sVariationsList = [];
            let filteredData = [];
            let skuDetails = [];
            let filterProductVariationList;
            let flexOne;
            let colorOne;
            let tipOne;
            let flexTwo;
            let flexNumber;
            let colorTwo;
            let tipTwo;
            let productCountArr = [];
            let wedges_count = 0;

            if (productSelector?.questions?.wedges !== undefined) {
                let value = Object.values(productSelector?.questions?.wedges);
                if (value.length > 0) {
                    for (let i = 0; i < Object.values(productSelector?.questions?.wedges).length; i++) {
                        if (value[i] !== "") {
                            wedges_count = wedges_count + 1;
                        }
                    }
                }
            }

            for (let item of result?.third_product?.variation) {
                if (item.attribute_name === "Flex") {
                    flexTwo = item.option_id;
                    flexNumber = item.option_term;
                }
                if (item.attribute_name === "Color") {
                    colorTwo = item.option_id;
                }
                if (tipCategory.includes(item.attribute_name)) {
                    tipTwo = item.option_id;
                }
            }
            const number = parseInt(flexNumber.match(/\d+/)[0]);
            if (productSelector?.questions?.one_length_player === true) {
                for (let item of result?.productVariationThird) {
                    const variant = item?.variation;
                    let ironOne;
                    for (const items of variant) {
                        if (items.attribute_name === "Flex") {
                            flexOne = items.option_term;
                        }
                        if (items.attribute_name === "Color") {
                            colorOne = items.option_id;
                        }
                        if (tipCategory.includes(items.attribute_name)) {
                            tipOne = items.option_id;
                        }
                        if (ironCategory.includes(items.attribute_name)) {
                            ironOne = items.option_term;
                        }
                    }
                    const flexs = [`LIP${number}`, `SIP${number}`, `MIP${number}`];
                    if (tipOne === tipTwo && colorOne === colorTwo && flexs.includes(flexOne)) {
                        productVariationList.push(item);
                    }
                }

                let newFilteredArr = [];
                if (productSelector?.questions?.config === "Utility Irons") {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne.option_term)) {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                } else {
                    // if (wedges_count === 0) {
                    //     for (let item of productVariationList) {
                    //         const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    //         if (itemOne !== undefined) {
                    //             if (!itemOne.option_term.includes('GW/SW/LW')) {
                    //                 newFilteredArr.push(item);
                    //             }
                    //         }
                    //     }
                    // } else {
                    newFilteredArr = productVariationList;
                    // }
                }
                filterProductVariationList = newFilteredArr.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariant = result?.third_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if ((optionTerm >= thirdVariant?.option_term || optionTerm === "P")) {
                        return arr;
                    }
                }));
                for (let item of filterProductVariationList) {
                    const itemOne = item?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                        switch (productSelector?.questions?.config) {
                            case "Utility Irons":
                                productCountArr.push(1);
                                break;
                            case '3 - P':
                                productCountArr.push(3);
                                break;
                            case '4 - P':
                                productCountArr.push(2);
                                break;
                            case '5 - P':
                                productCountArr.push(1);
                                break;
                        }
                    }
                    if (["6 or 7 or 8", "6,7,8"].includes(itemOne?.option_term)) {
                        productCountArr.push(3);
                    }
                    if (["9 or P or GW/SW/LW", "9,P,GW/SW/LW"].includes(itemOne?.option_term)) {
                        productCountArr.push(2);
                    }

                }
            } else {
                for (let item of result?.productVariationThird) {
                    const variant = item?.variation;
                    for (const items of variant) {
                        if (items.attribute_name === "Flex") {
                            flexOne = items.option_id;
                        }
                        if (items.attribute_name === "Color") {
                            colorOne = items.option_id;
                        }
                        if (tipCategory.includes(items.attribute_name)) {
                            tipOne = items.option_id;
                        }
                    }
                    if (tipOne === tipTwo && flexOne === flexTwo && colorOne === colorTwo) {
                        productVariationList.push(item);
                    }
                }

                let newFilteredArr = [];
                if (productSelector?.questions?.config === "Utility Irons") {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (itemOne.option_term === '1-2-3') {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                } else {
                    // if (wedges_count === 0) {
                    for (let item of productVariationList) {
                        const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                        if (itemOne !== undefined) {
                            if (!itemOne.option_term.includes('GW/SW/LW')) {
                                newFilteredArr.push(item);
                            }
                        }
                    }
                    // } else {
                    //     newFilteredArr = productVariationList;
                    // }
                }
                filterProductVariationList = newFilteredArr.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariant = result?.third_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if (optionTerm >= thirdVariant?.option_term || optionTerm === "P") {
                        return arr;
                    }
                }))
            }

            for (let item = 0; item <= filterProductVariationList.length; item++) {
                if (filterProductVariationList[item]?.length === 4) {
                    let productVariation = {
                        variation: filterProductVariationList[item],
                        sku: productVariationList[item].sku,
                        variation_id: productVariationList[item]._id
                    };
                    filteredData.push(productVariation);
                }
            }

            for (let item of filteredData) {
                let sVariationReduceData = item?.variation?.reduce(
                    (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
                    {}
                );
                sVariationsList.push(sVariationReduceData);
                let formatData = {
                    variation_id: item.variation_id,
                    sku: item.sku,
                }
                skuDetails.push(formatData);
            }
            let wedgesData = Object.values(productSelector?.questions?.wedges);
            let count = 0;
            for (let item of wedgesData) {
                if (item !== '') {
                    count += 1;
                }
            }

            const sVariationsWedges = wedges3?.product?.variation.reduce(
                (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
                {}
            );

            if (checkAddToCart) {
                let newProduct = {
                    product_id: result?.third_product?.product_details?._id,
                    product_slug: result?.third_product?.product_details?.slug,
                    product_title: result?.third_product?.product_details?.product_title,
                    product_variation: sVariationsList,
                    one_length_quantity: productCountArr,
                    quantity: 1,
                    fitting: {},
                    product_variation_sku: result?.third_product?.sku,
                    product_variation_id: result?.third_product?._id,
                    skuDetails: skuDetails,
                    wedges: (productSelector?.questions?.wedges === initialStateOfWedges) ? [] : wedges3,
                    wedges_variation: sVariationsWedges,
                    wedges_count: count,
                    acknowledgementChecked: false,
                };

                addToCart({ productDetails: newProduct });
                checkAddToCart = false;
            }
        } else {
            let newProduct = {
                product_id: result?.third_product?.product_details?._id,
                product_slug: result?.third_product?.product_details?.slug,
                product_title: result?.third_product?.product_details?.product_title,
                product_variation: sVariations,
                quantity: 1,
                fitting: {},
                product_variation_sku: result?.third_product?.sku,
                product_variation_id: result?.third_product?._id,
                acknowledgementChecked: false,
            };
            addToCart({ productDetails: newProduct });
        }
        let setProduct = {
            ...productSelector,
            product_selector_result_id: result?.result_id,
        }
        dispatch(setProductSelector(setProduct))
        setCurrentAddedProductTitle(result?.third_product?.product_details?.product_title);
    };

    const handleCompareProduct = useCallback((e) => {
        e.preventDefault();
        const indexData = e.currentTarget.getAttribute("data-id");
        if (indexData === 'second') {
            dispatch(setFittingOnSpecs({
                key: 'productOne',
                product: productOne,
                flex: flexOne,
                iron: ironOne,
                tip: tipOne
            }));
            dispatch(setFittingOnSpecs({
                key: 'productTwo',
                product: productTwo,
                flex: flexTwo,
                iron: ironTwo,
                tip: tipTwo
            }));
            navigate({
                pathname: "/compare",
                search: createSearchParams({
                    productOne: result?.first_product?.product_details?.slug,
                    productTwo: result?.second_product?.product_details?.slug,
                    flexOne,
                    flexTwo,
                    ironOne,
                    ironTwo,
                    tipOne,
                    tipTwo,
                }).toString()
            })
        }
        if (indexData === 'third') {
            dispatch(setFittingOnSpecs({
                key: 'productOne',
                product: productOne,
                flex: flexOne,
                iron: ironOne,
                tip: tipOne
            }));
            dispatch(setFittingOnSpecs({
                key: 'productTwo',
                product: productThree,
                flex: flexThree,
                iron: ironThree,
                tip: tipThree,
            }));
            navigate({
                pathname: "/compare",
                search: createSearchParams({
                    productOne: result?.first_product?.product_details?.slug,
                    productTwo: result?.third_product?.product_details?.slug,
                    flexOne,
                    flexTwo: flexThree,
                    ironOne,
                    ironTwo: ironThree,
                    tipOne,
                    tipTwo: tipThree,
                }).toString()
            })
        }
    }, [result]);

    const handleExplore = useCallback((e) => {
        const data = e.currentTarget.getAttribute('data-id');
        if (data === "explore-first") {
            const sVariations = result?.first_product?.variation?.reduce(
                (obj, item) => ({ ...obj, [item.attribute_id]: item }),
                {}
            );
            setVariationDetail(sVariations);
            dispatch(setExploreFitting({ variation: sVariations, sku: result?.first_product?.sku }))
        }
        if (data === "explore-second") {
            const sVariations = result?.second_product?.variation?.reduce(
                (obj, item) => ({ ...obj, [item.attribute_id]: item }),
                {}
            );
            setVariationDetail(sVariations);
            dispatch(setExploreFitting({ variation: sVariations, sku: result?.second_product?.sku }))
        }
        if (data === "explore-third") {
            const sVariations = result?.third_product?.variation?.reduce(
                (obj, item) => ({ ...obj, [item.attribute_id]: item }),
                {}
            );
            setVariationDetail(sVariations);
            dispatch(setExploreFitting({ variation: sVariations, sku: result?.third_product?.sku }))
        }
        let setProduct = {
            ...productSelector,
            product_selector_result_id: result?.result_id,
        }
        dispatch(setProductSelector(setProduct))
    }, [variationDetail, exploreFitting, result]);

    useEffect(() => {
        let variantOne = [];
        let variantTwo = [];
        let variantThree = [];
        if (result !== undefined) {
            let flexOne = result?.first_product?.variation?.find(item => item.attribute_name.toLowerCase() === "flex" && item.option_term);
            let colorOne = result?.first_product?.variation?.find(item => item.attribute_name.toLowerCase() === "color" && item.option_term);
            let tipOne = result?.first_product?.variation?.find(item => tipCategory.includes(item.attribute_name) && item.option_term);
            let firstTitle = result?.first_product?.product_details?._id;

            let flexTwo = result?.second_product?.variation?.find(item => item.attribute_name.toLowerCase() === "flex" && item.option_term);
            let colorTwo = result?.second_product?.variation?.find(item => item.attribute_name.toLowerCase() === "color" && item.option_term);
            let tipTwo = result?.second_product?.variation?.find(item => tipCategory.includes(item.attribute_name) && item.option_term);
            let secondTitle = result?.second_product?.product_details?._id;

            let flexThree = result?.third_product?.variation?.find(item => item.attribute_name.toLowerCase() === "flex" && item.option_term);
            let colorThree = result?.third_product?.variation?.find(item => item.attribute_name.toLowerCase() === "color" && item.option_term);
            let tipThree = result?.third_product?.variation?.find(item => tipCategory.includes(item.attribute_name) && item.option_term);
            let thirdTitle = result?.third_product?.product_details?._id;

            if (result?.allProducts !== undefined) {
                if (productSelector.questions.one_length_player) {
                    for (let item of result?.allProducts) {
                        let optionId = `SIP${parseInt(flexOne?.option_term?.match(/\d+/)[0])}`;
                        let optionIdTwo = `SIP${parseInt(flexTwo?.option_term?.match(/\d+/)[0])}`;
                        let optionIdThree = `SIP${parseInt(flexThree?.option_term?.match(/\d+/)[0])}`
                        let flexOption;
                        let colorOption;
                        let tipOption;
                        let ironOption;
                        const variant = item?.product?.variation;
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOption = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOption = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOption = items.option_term;
                            }
                            if (ironCategory.includes(items.attribute_name)) {
                                ironOption = items.option_term;
                            }
                        }
                        if (colorOption === colorOne?.option_term && flexOption === optionId && ["9 or P or GW/SW/LW", 'GW/SW/LW', '9,P,GW/SW/LW'].includes(ironOption) && tipOption === tipOne?.option_term) {
                            if (item?.product?.product_details?._id === firstTitle) {
                                variantOne.push(item)
                            }
                        }
                        if (colorOption === colorTwo?.option_term && flexOption === optionIdTwo && ["9 or P or GW/SW/LW", 'GW/SW/LW', '9,P,GW/SW/LW'].includes(ironOption) && tipOption === tipTwo?.option_term) {
                            if (item?.product?.product_details?._id === secondTitle) {
                                variantTwo.push(item)
                            }
                        }
                        if (colorOption === colorThree?.option_term && flexOption === optionIdThree && ["9 or P or GW/SW/LW", 'GW/SW/LW', '9,P,GW/SW/LW'].includes(ironOption) && tipOption === tipThree?.option_term) {
                            if (item?.product?.product_details?._id === thirdTitle) {
                                variantThree.push(item);
                            }
                        }
                    }
                } else {
                    for (let item of result?.allProducts) {
                        let optionId = `W${parseInt(flexOne.option_term.match(/\d+/)[0])}`;
                        let optionIdTwo = `W${parseInt(flexTwo.option_term.match(/\d+/)[0])}`;
                        let optionIdThree = `W${parseInt(flexThree.option_term.match(/\d+/)[0])}`;

                        let flexOption;
                        let colorOption;
                        let tipOption;
                        let ironOption;
                        const variant = item?.product?.variation;
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOption = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOption = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOption = items.option_term;
                            }
                            if (ironCategory.includes(items.attribute_name)) {
                                ironOption = items.option_term;
                            }
                        }

                        if (colorOption === colorOne.option_term && flexOption === optionId && ["AW/GW/SW/LW", "AW/GW,SW,LW", 'GW/SW/LW'].includes(ironOption) && tipOption === tipOne.option_term) {
                            if (item?.product?.product_details?._id === firstTitle) {
                                variantOne.push(item)
                            }
                        }
                        if (colorOption === colorTwo.option_term && flexOption === optionIdTwo && ["AW/GW/SW/LW", "AW/GW,SW,LW", 'GW/SW/LW'].includes(ironOption) && tipOption === tipTwo.option_term) {
                            if (item?.product?.product_details?._id === secondTitle) {
                                variantTwo.push(item)
                            }
                        }
                        if (colorOption === colorThree.option_term && flexOption === optionIdThree && ["AW/GW/SW/LW", "AW/GW,SW,LW", 'GW/SW/LW'].includes(ironOption) && tipOption === tipThree.option_term) {
                            if (item?.product?.product_details?._id === thirdTitle) {
                                variantThree.push(item);
                            }
                        }
                    }
                }
                setWedges1(variantOne[0]);
                setWedges2(variantTwo[0]);
                setWedges3(variantThree[0]);
            }
        }
    }, [result]);

    useEffect(() => {

        if (result !== undefined && productSelector?.category === "iron") {
            let flexThree = result?.first_product?.variation?.find(item => item.attribute_name.toLowerCase() === "flex" && item.option_term);
            let colorThree = result?.first_product?.variation?.find(item => item.attribute_name.toLowerCase() === "color" && item.option_term);
            let tipThree = result?.first_product?.variation?.find(item => tipCategory.includes(item.attribute_name) && item.option_term);

            let colorOne, flexOne, tipOne, ironOne;

            let thirdVariant = 0;
            let filterProductVariationList;
            let productVariationList = [];
            let filteredProductVariationList = [];
            let productCountArr = [];
            let productCount = 0;
            let wedges_count = 0;

            if (productSelector?.questions?.wedges !== undefined) {
                let value = Object.values(productSelector?.questions?.wedges);
                if (value.length > 0) {
                    for (let i = 0; i < Object.values(productSelector?.questions?.wedges).length; i++) {
                        if (value[i] !== "") {
                            wedges_count = wedges_count + 1;
                        }
                    }
                }
            }

            if (productSelector?.questions?.one_length_player) {
                if (result?.productVariationFirst !== undefined) {
                    for (let item of result?.productVariationFirst) {
                        const variant = item?.variation;
                        const number = parseInt(flexThree?.option_term?.match(/\d+/)[0]);
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOne = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOne = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOne = items.option_term;
                            }
                        }
                        const flexs = [`LIP${number}`, `SIP${number}`, `MIP${number}`];
                        if (tipOne === tipThree.option_term && colorOne === colorThree.option_term && flexs.includes(flexOne)) {
                            productVariationList.push(item);
                            if (flexOne === `SIP${number}` && wedges_count !== 0) {
                                thirdVariant = thirdVariant + (item.msrp_price * wedges_count);
                            }
                        }
                    }
                }
                filterProductVariationList = productVariationList.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariant = result?.first_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if ((optionTerm >= thirdVariant?.option_term || optionTerm === "P")) {
                        return arr;
                    }
                }));
                let newFiteredProductVariationList = [];
                for (let item = 0; item <= filterProductVariationList.length; item++) {
                    const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (productSelector?.questions?.config === 'Utility Irons') {
                        if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                            newFiteredProductVariationList.push(filterProductVariationList[item]);
                        }
                    } else {
                        newFiteredProductVariationList = filterProductVariationList;
                    }
                }
                for (let item = 0; item <= newFiteredProductVariationList.length; item++) {
                    const itemOne = newFiteredProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (itemOne !== undefined) {
                        filteredProductVariationList.push(productVariationList[item]);
                        if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                            switch (productSelector?.questions?.config) {
                                case "Utility Irons":
                                    productCountArr.push(1);
                                    break;
                                case '3 - P':
                                    productCountArr.push(3);
                                    break;
                                case '4 - P':
                                    productCountArr.push(2);
                                    break;
                                case '5 - P':
                                    productCountArr.push(1);
                                    break;
                            }
                        }
                        if (["6 or 7 or 8", "6,7,8"].includes(itemOne?.option_term)) {
                            productCountArr.push(3);
                            if (productSelector?.questions?.config === '6 - P') {
                                productCount = productCount + (productVariationList[item].msrp_price * 3);
                            }
                        }
                        if (["9 or P or GW/SW/LW", "9,P,GW/SW/LW"].includes(itemOne?.option_term)) {
                            productCountArr.push(2);
                        }
                    }
                }
                let newFilteredArr = [];
                // if (wedges_count == 0) {
                //     for (let item of filteredProductVariationList) {
                //         const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                //         if (itemOne !== undefined) {
                //             if (!itemOne.option_term.includes('GW/SW/LW')) {
                //                 newFilteredArr.push(item);
                //             }
                //         }
                //     }
                // } else {
                newFilteredArr = filteredProductVariationList;
                // }

                // one length iron flexs
                if (productSelector?.questions?.config === "Utility Irons") {
                    if (wedges_count !== 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                            let itemFlagDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            if (itemFlagDetail !== undefined && ironCategory.includes(itemFlagDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setFirstFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        // console.log(flexsArr, 'flexsArrflexsArrflexsArrflexsArrflexsArr');
                        setFirstFlex([...new Set(flexsArr)]);
                    }
                } else {
                    let flexsArr = [];
                    for (let item of filterProductVariationList) {
                        let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                        if (itemDetail?.attribute_name === 'Flex') {
                            flexsArr.push(itemDetail?.option_term);
                        }
                    }
                    setFirstFlex([...new Set(flexsArr)]);
                }

                for (let item = 0; item < newFilteredArr.length; item++) {
                    if (newFilteredArr[item] !== undefined) {
                        thirdVariant = thirdVariant + (newFilteredArr[item]?.msrp_price * productCountArr[item]);
                    }
                }
                setFirstProdPrice(thirdVariant);
            } else {
                if (result?.productVariationFirst !== undefined) {
                    for (let item of result?.productVariationFirst) {
                        const variant = item?.variation;
                        const number = parseInt(flexThree?.option_term?.match(/\d+/)[0]);
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOne = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOne = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOne = items.option_term;
                            }
                            if (ironCategory.includes(items.attribute_name)) {
                                ironOne = items.option_term;
                            }
                        }
                        const flexs = [`W${number}`, `I${number}`];
                        if (tipOne === tipThree.option_term && colorOne === colorThree.option_term && flexs.includes(flexOne)) {
                            productVariationList.push(item);
                            if (ironOne.includes('GW/SW/LW') && wedges_count !== 0) {
                                thirdVariant = thirdVariant + (item.msrp_price * (wedges_count));
                            }
                        }
                    }
                }
                filterProductVariationList = productVariationList.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariantData = result?.first_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if ((optionTerm >= thirdVariantData?.option_term || optionTerm === "P")) {
                        return arr;
                    }
                }));
                // for (let item = 0; item <= filterProductVariationList.length; item++) {
                //     const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                //     if (itemOne !== undefined && itemOne.option_term !== 'GW/SW/LW' && productSelector?.questions?.config === 'Utility Irons') {
                //         if (itemOne.option_term === '1-2-3') {
                //             filteredProductVariationList.push(productVariationList[item]);
                //         }
                //     }
                //     if (itemOne !== undefined && productSelector?.questions?.config !== 'Utility Irons') {
                //         filteredProductVariationList.push(productVariationList[item]);
                //     }
                // }
                // filteredProductVariationList = [];
                for (let item = 0; item <= filterProductVariationList.length; item++) {
                    const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (itemOne !== undefined && itemOne.option_term.includes('1-2-3') && productSelector?.questions?.config === 'Utility Irons') {
                        filteredProductVariationList.push(productVariationList[item]);
                    } else {
                        if (itemOne !== undefined && !itemOne.option_term.includes('GW/SW/LW') && productSelector?.questions?.config !== 'Utility Irons') {
                            filteredProductVariationList.push(productVariationList[item]);
                        }
                    }
                }

                // flexs of without one length irons
                if (productSelector?.questions?.config === "Utility Irons") {
                    if (wedges_count !== 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                            let itemFlagDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            if (itemFlagDetail !== undefined && ironCategory.includes(itemFlagDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setFirstFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setFirstFlex([...new Set(flexsArr)]);
                    }
                } else {
                    if (wedges_count === 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail === undefined && !ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                        }
                        setFirstFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemDetail?.attribute_name === 'Flex') {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setFirstFlex([...new Set(flexsArr)]);
                    }
                }

                for (let item = 0; item < filteredProductVariationList.length; item++) {
                    if (filteredProductVariationList[item] !== undefined) {
                        thirdVariant = thirdVariant + (filteredProductVariationList[item]?.msrp_price);
                    }
                }
                setFirstProdPrice(thirdVariant);
            }
        }
    }, [result, firstProdPrice])

    useEffect(() => {

        if (result !== undefined && productSelector?.category === "iron") {
            let flexThree = result?.second_product?.variation?.find(item => item.attribute_name.toLowerCase() === "flex" && item.option_term);
            let colorThree = result?.second_product?.variation?.find(item => item.attribute_name.toLowerCase() === "color" && item.option_term);
            let tipThree = result?.second_product?.variation?.find(item => tipCategory.includes(item.attribute_name) && item.option_term);

            let colorOne, flexOne, tipOne, ironOne;

            let thirdVariant = 0;
            let filterProductVariationList;
            let productVariationList = [];
            let filteredProductVariationList = [];
            let productCountArr = [];
            let productCount = 0;
            let wedges_count = 0;

            if (productSelector?.questions?.wedges !== undefined) {
                let value = Object.values(productSelector?.questions?.wedges);
                if (value.length > 0) {
                    for (let i = 0; i < Object.values(productSelector?.questions?.wedges).length; i++) {
                        if (value[i] !== "") {
                            wedges_count = wedges_count + 1;
                        }
                    }
                }
            }

            if (productSelector?.questions?.one_length_player) {
                if (result?.productVariationSecond !== undefined) {
                    for (let item of result?.productVariationSecond) {
                        const variant = item?.variation;
                        const number = parseInt(flexThree?.option_term?.match(/\d+/)[0]);
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOne = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOne = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOne = items.option_term;
                            }
                        }
                        const flexs = [`LIP${number}`, `SIP${number}`, `MIP${number}`];
                        if (tipOne === tipThree.option_term && colorOne === colorThree.option_term && flexs.includes(flexOne)) {
                            productVariationList.push(item);
                            if (flexOne === `SIP${number}` && wedges_count !== 0) {
                                thirdVariant = thirdVariant + (item.msrp_price * wedges_count);
                            }
                        }
                    }
                }
                filterProductVariationList = productVariationList.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariant = result?.second_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if ((optionTerm >= thirdVariant?.option_term || optionTerm === "P")) {
                        return arr;
                    }
                }));
                let newFiteredProductVariationList = [];
                for (let item = 0; item <= filterProductVariationList.length; item++) {
                    const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (productSelector?.questions?.config === 'Utility Irons') {
                        if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                            newFiteredProductVariationList.push(filterProductVariationList[item]);
                        }
                    } else {
                        newFiteredProductVariationList = filterProductVariationList;
                    }
                }
                for (let item = 0; item <= newFiteredProductVariationList.length; item++) {
                    const itemOne = newFiteredProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (itemOne !== undefined) {
                        filteredProductVariationList.push(productVariationList[item]);
                        if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                            switch (productSelector?.questions?.config) {
                                case "Utility Irons":
                                    productCountArr.push(1);
                                    break;
                                case '3 - P':
                                    productCountArr.push(3);
                                    break;
                                case '4 - P':
                                    productCountArr.push(2);
                                    break;
                                case '5 - P':
                                    productCountArr.push(1);
                                    break;
                            }
                        }
                        if (["6 or 7 or 8", "6,7,8"].includes(itemOne?.option_term)) {
                            productCountArr.push(3);
                            if (productSelector?.questions?.config === '6 - P') {
                                productCount = productCount + (productVariationList[item].msrp_price * 3);
                            }
                        }
                        if (["9 or P or GW/SW/LW", "9,P,GW/SW/LW"].includes(itemOne?.option_term)) {
                            productCountArr.push(2);
                        }
                    }
                }
                let newFilteredArr = [];
                // if (wedges_count == 0) {
                //     for (let item of filteredProductVariationList) {
                //         const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                //         if (itemOne !== undefined) {
                //             if (!itemOne.option_term.includes('GW/SW/LW')) {
                //                 newFilteredArr.push(item);
                //             }
                //         }
                //     }
                // } else {
                newFilteredArr = filteredProductVariationList;
                // }

                // one length iron flexs
                if (productSelector?.questions?.config === "Utility Irons") {
                    if (wedges_count !== 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                            let itemFlagDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            if (itemFlagDetail !== undefined && ironCategory.includes(itemFlagDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setSecondFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        // console.log(flexsArr, 'flexsArrflexsArrflexsArrflexsArrflexsArr');
                        setSecondFlex([...new Set(flexsArr)]);
                    }
                } else {
                    let flexsArr = [];
                    for (let item of filterProductVariationList) {
                        let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                        if (itemDetail?.attribute_name === 'Flex') {
                            flexsArr.push(itemDetail?.option_term);
                        }
                    }
                    setSecondFlex([...new Set(flexsArr)]);
                }

                for (let item = 0; item < newFilteredArr.length; item++) {
                    thirdVariant = thirdVariant + (newFilteredArr[item]?.msrp_price * productCountArr[item]);
                }
                setSecondProdPrice(thirdVariant);
            } else {
                if (result?.productVariationSecond !== undefined) {
                    for (let item of result?.productVariationSecond) {
                        const variant = item?.variation;
                        const number = parseInt(flexThree?.option_term?.match(/\d+/)[0]);
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOne = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOne = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOne = items.option_term;
                            }
                            if (ironCategory.includes(items.attribute_name)) {
                                ironOne = items.option_term;
                            }
                        }
                        const flexs = [`W${number}`, `I${number}`];
                        if (tipOne === tipThree.option_term && colorOne === colorThree.option_term && flexs.includes(flexOne)) {
                            productVariationList.push(item);
                            if (ironOne.includes('GW/SW/LW') && wedges_count !== 0) {
                                thirdVariant = thirdVariant + (item.msrp_price * wedges_count);
                            }
                        }
                    }
                }
                filterProductVariationList = productVariationList.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariantData = result?.second_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if ((optionTerm >= thirdVariantData?.option_term || optionTerm === "P")) {
                        return arr;
                    }
                }));
                // for (let item = 0; item <= filterProductVariationList.length; item++) {
                //     const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                //     if (itemOne !== undefined && itemOne.option_term !== 'GW/SW/LW' && productSelector?.questions?.config === 'Utility Irons') {
                //         if (itemOne.option_term === '1-2-3') {
                //             filteredProductVariationList.push(productVariationList[item]);
                //         }
                //     }
                //     if (itemOne !== undefined && productSelector?.questions?.config !== 'Utility Irons') {
                //         filteredProductVariationList.push(productVariationList[item]);
                //     }
                // }
                // filteredProductVariationList = [];
                for (let item = 0; item <= filterProductVariationList.length; item++) {
                    const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (itemOne !== undefined && itemOne.option_term.includes('1-2-3') && productSelector?.questions?.config === 'Utility Irons') {
                        filteredProductVariationList.push(productVariationList[item]);
                    } else {
                        if (itemOne !== undefined && !itemOne.option_term.includes('GW/SW/LW') && productSelector?.questions?.config !== 'Utility Irons') {
                            filteredProductVariationList.push(productVariationList[item]);
                        }
                    }
                }
                // flexs of without one length irons
                if (productSelector?.questions?.config === "Utility Irons") {
                    if (wedges_count !== 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                            let itemFlagDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            if (itemFlagDetail !== undefined && ironCategory.includes(itemFlagDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setSecondFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setSecondFlex([...new Set(flexsArr)]);
                    }
                } else {
                    if (wedges_count === 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail === undefined && !ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                        }
                        setSecondFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemDetail?.attribute_name === 'Flex') {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setSecondFlex([...new Set(flexsArr)]);
                    }
                }
                for (let item = 0; item < filteredProductVariationList.length; item++) {
                    if (filteredProductVariationList[item] !== undefined) {
                        thirdVariant = thirdVariant + (filteredProductVariationList[item]?.msrp_price);
                    }
                }
                setSecondProdPrice(thirdVariant);
            }
        }
    }, [result, secondProdPrice])

    useEffect(() => {

        if (result !== undefined && productSelector?.category === "iron") {
            let flexThree = result?.third_product?.variation?.find(item => item.attribute_name.toLowerCase() === "flex" && item.option_term);
            let colorThree = result?.third_product?.variation?.find(item => item.attribute_name.toLowerCase() === "color" && item.option_term);
            let tipThree = result?.third_product?.variation?.find(item => tipCategory.includes(item.attribute_name) && item.option_term);

            let colorOne, flexOne, tipOne, ironOne;

            let thirdVariant = 0;
            let filterProductVariationList;
            let productVariationList = [];
            let filteredProductVariationList = [];
            let productCountArr = [];
            let productCount = 0;
            let wedges_count = 0;

            if (productSelector?.questions?.wedges !== undefined) {
                let value = Object.values(productSelector?.questions?.wedges);
                if (value.length > 0) {
                    for (let i = 0; i < Object.values(productSelector?.questions?.wedges).length; i++) {
                        if (value[i] !== "") {
                            wedges_count = wedges_count + 1;
                        }
                    }
                }
            }

            if (productSelector?.questions?.one_length_player) {
                if (result?.productVariationThird !== undefined) {
                    for (let item of result?.productVariationThird) {
                        const variant = item?.variation;
                        const number = parseInt(flexThree?.option_term?.match(/\d+/)[0]);
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOne = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOne = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOne = items.option_term;
                            }
                        }
                        const flexs = [`LIP${number}`, `SIP${number}`, `MIP${number}`];
                        if (tipOne === tipThree.option_term && colorOne === colorThree.option_term && flexs.includes(flexOne)) {
                            productVariationList.push(item);
                            if (flexOne === `SIP${number}` && wedges_count !== 0) {
                                thirdVariant = thirdVariant + (item.msrp_price * wedges_count);
                            }
                        }
                    }
                }
                filterProductVariationList = productVariationList.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariant = result?.third_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if ((optionTerm >= thirdVariant?.option_term || optionTerm === "P")) {
                        return arr;
                    }
                }));
                let newFiteredProductVariationList = [];
                for (let item = 0; item <= filterProductVariationList.length; item++) {
                    const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (productSelector?.questions?.config === 'Utility Irons') {
                        if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                            newFiteredProductVariationList.push(filterProductVariationList[item]);
                        }
                    } else {
                        newFiteredProductVariationList = filterProductVariationList;
                    }
                }
                for (let item = 0; item <= newFiteredProductVariationList.length; item++) {
                    const itemOne = newFiteredProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (itemOne !== undefined) {
                        filteredProductVariationList.push(productVariationList[item]);
                        if (["1,2,3,4,5", "1-2-3 or 4 or 5"].includes(itemOne?.option_term)) {
                            switch (productSelector?.questions?.config) {
                                case "Utility Irons":
                                    productCountArr.push(1);
                                    break;
                                case '3 - P':
                                    productCountArr.push(3);
                                    break;
                                case '4 - P':
                                    productCountArr.push(2);
                                    break;
                                case '5 - P':
                                    productCountArr.push(1);
                                    break;
                            }
                        }
                        if (["6 or 7 or 8", "6,7,8"].includes(itemOne?.option_term)) {
                            productCountArr.push(3);
                            if (productSelector?.questions?.config === '6 - P') {
                                productCount = productCount + (productVariationList[item].msrp_price * 3);
                            }
                        }
                        if (["9 or P or GW/SW/LW", "9,P,GW/SW/LW"].includes(itemOne?.option_term)) {
                            productCountArr.push(2);
                        }
                    }
                }
                let newFilteredArr = [];
                // if (wedges_count == 0) {
                //     for (let item of filteredProductVariationList) {
                //         const itemOne = item.variation?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                //         if (itemOne !== undefined) {
                //             if (!itemOne.option_term.includes('GW/SW/LW')) {
                //                 newFilteredArr.push(item);
                //             }
                //         }
                //     }
                // } else {
                newFilteredArr = filteredProductVariationList;
                // }

                // one length iron flexs
                if (productSelector?.questions?.config === "Utility Irons") {
                    if (wedges_count !== 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                            let itemFlagDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            if (itemFlagDetail !== undefined && ironCategory.includes(itemFlagDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setThirdFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        // console.log(flexsArr, 'flexsArrflexsArrflexsArrflexsArrflexsArr');
                        setThirdFlex([...new Set(flexsArr)]);
                    }
                } else {
                    let flexsArr = [];
                    for (let item of filterProductVariationList) {
                        let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                        if (itemDetail?.attribute_name === 'Flex') {
                            flexsArr.push(itemDetail?.option_term);
                        }
                    }
                    setThirdFlex([...new Set(flexsArr)]);
                }
                for (let item = 0; item < newFilteredArr.length; item++) {
                    thirdVariant = thirdVariant + (newFilteredArr[item]?.msrp_price * productCountArr[item]);
                }
                setThirdProdPrice(thirdVariant);
            } else {
                if (result?.productVariationThird !== undefined) {
                    for (let item of result?.productVariationThird) {
                        const variant = item?.variation;
                        const number = parseInt(flexThree?.option_term?.match(/\d+/)[0]);
                        for (const items of variant) {
                            if (items.attribute_name === "Flex") {
                                flexOne = items.option_term;
                            }
                            if (items.attribute_name === "Color") {
                                colorOne = items.option_term;
                            }
                            if (tipCategory.includes(items.attribute_name)) {
                                tipOne = items.option_term;
                            }
                            if (ironCategory.includes(items.attribute_name)) {
                                ironOne = items.option_term;
                            }
                        }
                        const flexs = [`W${number}`, `I${number}`];
                        if (tipOne === tipThree.option_term && colorOne === colorThree.option_term && flexs.includes(flexOne)) {
                            productVariationList.push(item);
                            if (ironOne.includes('GW/SW/LW') && wedges_count !== 0) {
                                thirdVariant = thirdVariant + (item.msrp_price * wedges_count);
                            }
                        }
                    }
                }
                filterProductVariationList = productVariationList.map(arr => arr.variation.filter(obj => {
                    const optionTerm = obj.option_term;
                    let thirdVariantData = result?.third_product?.variation.find(item => ironCategory.includes(item.attribute_name));
                    if ((optionTerm >= thirdVariantData?.option_term || optionTerm === "P")) {
                        return arr;
                    }
                }));
                // for (let item = 0; item <= filterProductVariationList.length; item++) {
                //     const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                //     if (itemOne !== undefined && itemOne.option_term !== 'GW/SW/LW' && productSelector?.questions?.config === 'Utility Irons') {
                //         if (itemOne.option_term === '1-2-3') {
                //             filteredProductVariationList.push(productVariationList[item]);
                //         }
                //     }
                //     if (itemOne !== undefined && productSelector?.questions?.config !== 'Utility Irons') {
                //         filteredProductVariationList.push(productVariationList[item]);
                //     }
                // }
                // filteredProductVariationList = [];
                for (let item = 0; item <= filterProductVariationList.length; item++) {
                    const itemOne = filterProductVariationList[item]?.find((elem) => ironCategory.includes(elem.attribute_name) && elem.option_term);
                    if (itemOne !== undefined && itemOne.option_term.includes('1-2-3') && productSelector?.questions?.config === 'Utility Irons') {
                        filteredProductVariationList.push(productVariationList[item]);
                    } else {
                        if (itemOne !== undefined && !itemOne.option_term.includes('GW/SW/LW') && productSelector?.questions?.config !== 'Utility Irons') {
                            filteredProductVariationList.push(productVariationList[item]);
                        }
                    }
                }

                // flexs of without one length irons
                if (productSelector?.questions?.config === "Utility Irons") {
                    if (wedges_count !== 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                            let itemFlagDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            if (itemFlagDetail !== undefined && ironCategory.includes(itemFlagDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setThirdFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => ["1-2-3", "1-2-3 or 4 or 5", '1,2,3,4,5'].includes(elem?.option_term));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail !== undefined && ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setThirdFlex([...new Set(flexsArr)]);
                    }
                } else {
                    if (wedges_count === 0) {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemWedgesDetail = item?.find((elem) => elem?.option_term.includes('GW'));
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemWedgesDetail === undefined && !ironCategory.includes(itemWedgesDetail?.attribute_name)) {
                                if (itemDetail?.attribute_name === 'Flex') {
                                    flexsArr.push(itemDetail?.option_term);
                                }
                            }
                        }
                        setThirdFlex([...new Set(flexsArr)]);
                    } else {
                        let flexsArr = [];
                        for (let item of filterProductVariationList) {
                            let itemDetail = item?.find((elem) => elem.attribute_name === "Flex" && elem.option_term)
                            if (itemDetail?.attribute_name === 'Flex') {
                                flexsArr.push(itemDetail?.option_term);
                            }
                        }
                        setThirdFlex([...new Set(flexsArr)]);
                    }
                }

                for (let item = 0; item < filteredProductVariationList.length; item++) {
                    if (filteredProductVariationList[item] !== undefined) {
                        thirdVariant = thirdVariant + (filteredProductVariationList[item]?.msrp_price);
                    }
                }
                setThirdProdPrice(thirdVariant);
            }
        }
    }, [result, thirdProdPrice])

    useEffect(() => {
        if (result !== undefined) {
            let firstProd = result?.first_product?.variation.find(variant => variant.attribute_name === 'Flex');
            firstProd = firstProd?.option_id;
            let matchProdFirst = result?.mergedArray[0]?.map(obj => obj?.product?.variation?.find(variant => variant?.attribute_name === 'Flex' && variant?.option_id === firstProd));
            matchProdFirst = matchProdFirst?.find(item => item?.option_id !== undefined);
            if (matchProdFirst !== undefined) {
                setMoreColorFirst(true);
            }
            // second variant
            let secondProd = result?.second_product?.variation.find(variant => variant.attribute_name === 'Flex');
            secondProd = secondProd?.option_id;
            let matchProdSecond = result?.mergedArray[0]?.map(obj => obj?.product?.variation?.find(variant => variant?.attribute_name === 'Flex' && variant?.option_id === secondProd));
            matchProdSecond = matchProdSecond?.find(item => item?.option_id !== undefined);
            if (matchProdSecond !== undefined) {
                setMoreColorSecond(true);
            }

            // third variant
            let thirdProd = result?.third_product?.variation.find(variant => variant.attribute_name === 'Flex');
            thirdProd = thirdProd?.option_id;
            let matchProdThird = result?.mergedArray[0]?.map(obj => obj?.product?.variation?.find(variant => variant?.attribute_name === 'Flex' && variant?.option_id === thirdProd));
            matchProdThird = matchProdThird?.find(item => item?.option_id !== undefined);
            if (matchProdThird !== undefined) {
                setMoreColorThird(true);
            }
        }
    }, [result])

    useEffect(() => {
        let count = 0;
        if (productSelector?.questions?.config === '3 - P') {
            count = count + 8;
        }
        if (productSelector?.questions?.config === '4 - P') {
            count = count + 7;
        }
        if (productSelector?.questions?.config === '5 - P') {
            count = count + 6;
        }
        if (productSelector?.questions?.config === '6 - P') {
            count = count + 5;
        }
        if (productSelector?.questions?.config === 'Utility Irons') {
            count = count + 1;
        }
        setShaftCount(count + wedgesData.length);

    }, [shaftCount, wedgesData])

    const handleModal = () => {
        setShowModal(!showModal);
    }

    useEffect(() => {
        if (addToCartResult?.isSuccess) {
            navigate("/cart");
        }
    }, [addToCartResult])

    useEffect(() => {
        if (getProductSelectorDetailResponse !== undefined && getProductSelectorDetailResponse?.data?.response?.length === 0) {
            navigate("/kinetixx-fit");
        }
    }, [getProductSelectorDetailResponse])

    return (
        getProductSelectorDetailResponse !== undefined && getProductSelectorDetailResponse?.isSuccess === false ? (<Spiner />)
            : (
                <>
                    <Row>
                        {cartUpdate ? (
                            <Col md="12">
                                <div className="productTopBar">
                                    <p>
                                        {cartUpdate?.status
                                            ? '"' +
                                            currentAddedProductTitle +
                                            '" has been added to your cart.'
                                            : cartUpdate.message}
                                    </p>
                                    {cartUpdate?.status ? (
                                        <Link to="/cart" className="btn btn btn-primary">
                                            View Cart
                                        </Link>
                                    ) : null}
                                </div>
                            </Col>
                        ) : null}
                    </Row>
                    <section className="SelectProduct-Page">
                        {result && result !== undefined && result?.first_product !== undefined ?
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md="12">
                                        <div className="selector-tools">
                                            <h5>{result?.first_product?.category_details?.name?.toUpperCase()} RECOMMENDATION</h5>
                                            <h1 style={{ textTransform: 'capitalize' }}>{result?.first_product?.product_details?.product_title} {result?.first_product?.category_details?.slug}</h1>
                                            <h5><span>Optimal Fit</span></h5>
                                            <div className="progressBar">
                                                <ProgressBar now={result?.countOne} label={`${result?.countOne}%`} visuallyHidden />
                                            </div>
                                            {/* <h6>FORGIVING SPEED & DISTANCE</h6> */}
                                            {/* <p>The perfect combination of distance and forgiveness now comes in one of the best-looking drivers in the game. {result?.first_product?.product_details?.product_title}-{result?.first_product?.category_details?.name} is a high launch, low spin, max performance driver that gives you the green light off every tee.</p> */}
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: result?.first_product?.product_details?.short_description,
                                                }}
                                            ></p>
                                        </div>
                                        <div className="selector-tools-body">

                                            <div className="select-tool-info">
                                                <div class="alltypes">
                                                    {
                                                        result?.first_product?.category_details?.slug !== "iron" &&
                                                        <p className="theme-color"><span>Model No:</span>{result?.first_product?.sku}</p>
                                                    }
                                                    {
                                                        result?.first_product?.category_details?.slug === "iron" &&
                                                        <p><span>Product Name:</span>{result?.first_product?.product_details?.product_title}</p>
                                                    }
                                                    <p><span>Category:</span>{result?.first_product?.category_details?.name}</p>
                                                    <p><span>Flex:</span>{result?.first_product?.category_details?.slug === "iron" ? firstFlex?.join(", ") : result?.first_product?.variation?.map(item => item.attribute_name === 'Flex' && item.option_term)}</p>
                                                    {
                                                        result?.first_product?.category_details?.slug === "iron" &&
                                                        <p><span>Irons #:</span>
                                                            {/* {result?.first_product?.variation?.map(item => ironCategory.includes(item.attribute_name) && item.option_term)} */}
                                                            {productSelector?.questions?.config}
                                                            {wedgesData?.length > 0 ? ` + (${Object.values(wedgesData)})` : ''}
                                                        </p>
                                                    }
                                                    {
                                                        result?.first_product?.category_details?.slug === "iron" &&
                                                        <p><span className="tip-sub">Tip<sub>{(result?.first_product?.variation?.map(item => tipCategory.includes(item.attribute_name) && item.option_term))?.find(item => item !== false) === "Parallel"
                                                            && <span className=""><BsFillInfoCircleFill onClick={handleModal} /></span>
                                                        }</sub>:</span>{result?.first_product?.variation?.map(item => tipCategory.includes(item.attribute_name) && item.option_term)}

                                                        </p>
                                                    }
                                                    <p><span>Color:</span>{result?.first_product?.variation?.map(item => item.attribute_name === 'Color' && item.option_term)}
                                                        {moreColorFirst && <span className="selectorColor">(More colors are available in the fitting options)</span>}
                                                    </p>
                                                </div>
                                                <div className="price-cart">
                                                    {
                                                        result?.first_product?.category_details?.slug === "iron" ?
                                                            <span className="price">${firstProdPrice?.toFixed(2)} <span className="price-count">({shaftCount + ' ' + (shaftCount > 1 ? `Shafts` : `Shaft`)})</span></span>
                                                            : <span className="price">${result?.first_product?.msrp_price.toFixed(2)}</span>
                                                    }
                                                    <Button onClick={handleClick}>Add to Cart</Button>
                                                    {
                                                        result?.first_product?.category_details?.slug !== "iron" &&
                                                        <Link to={`/shafts/${result?.first_product?.product_details?.slug}`}>
                                                            <Button className="explore-btn" onClick={handleExplore} data-id="explore-first">Club Build Options</Button>
                                                        </Link>
                                                    }
                                                </div>
                                            </div>
                                            <div className="select-itemImage">
                                                <div className="productborder">
                                                    <Image src={result?.first_product?.product_details?.thumbnail ? 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + result?.first_product?.product_details?.thumbnail : DUMMY_IMAGE} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                            : <h2 style={{ textAlign: 'center', margin: '15px' }}>No Result Found</h2>
                        }
                    </section>

                    <section className="Driver-selecter-outer facility-outer otherProducts">
                        {result && result !== undefined && result?.second_product !== undefined ?
                            <Container>
                                <Row className="justify-content-center">

                                    <Col md="12">
                                        <div className="other-match">
                                            <h4>Other closest matches</h4>
                                        </div>
                                    </Col>
                                    <Col md="12" xl="6" className="small">
                                        <div className="facility-bg">
                                            <Row>
                                                <Col md="4">
                                                    <div className="result-productimages">
                                                        <Image src={result?.second_product?.product_details?.thumbnail ? 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + result?.second_product?.product_details?.thumbnail : DUMMY_IMAGE} className="img-fluid w-100" />
                                                    </div>
                                                </Col>
                                                <Col md="8">
                                                    <div className="productdetails-right pt-0">
                                                        <h1 className="mt-0">
                                                            <Link to={`/shafts/${result?.second_product?.product_details?.slug}`} style={{ textTransform: 'capitalize' }}>
                                                                {result?.second_product?.product_details?.product_title} {result?.second_product?.category_details?.slug}
                                                            </Link>
                                                            <h4 className="text-center mt-0 w-70"><span>Second Alternative</span></h4>
                                                        </h1>
                                                        <div className="progressBar">
                                                            <ProgressBar now={result?.countTwo} label={`${result?.countTwo}%`} visuallyHidden />
                                                        </div>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: result?.second_product?.product_details?.short_description,
                                                            }}
                                                        ></p>
                                                        <div className="alltypes">
                                                            {
                                                                result?.second_product?.category_details?.slug !== "iron" &&
                                                                <p><span>Model No:</span>{result?.second_product?.sku}</p>
                                                            }
                                                            {
                                                                result?.second_product?.category_details?.slug === "iron" &&
                                                                <p><span>Product Name:</span>{result?.second_product?.product_details?.product_title}</p>
                                                            }
                                                            <p><span>Category:</span>{result?.second_product?.category_details?.name}</p>
                                                            <p><span>Flex:</span>{result?.second_product?.category_details?.slug === "iron" ? secondFlex?.join(", ") : result?.second_product?.variation?.map(item => item.attribute_name === 'Flex' && item.option_term)}</p>
                                                            {
                                                                result?.second_product?.category_details?.slug === "iron" &&
                                                                <p><span>Irons #:</span>
                                                                    {/* {result?.second_product?.variation?.map(item => ironCategory.includes(item.attribute_name) && item.option_term)} */}
                                                                    {productSelector?.questions?.config}
                                                                    {wedgesData.length > 0 ? ` + (${Object.values(wedgesData)})` : ''}
                                                                </p>
                                                            }
                                                            {
                                                                result?.second_product?.category_details?.slug === "iron" &&
                                                                <p><span className="tip-sub">Tip<sub>{(result?.second_product?.variation?.map(item => tipCategory.includes(item.attribute_name) && item.option_term))?.find(item => item !== false) === "Parallel"
                                                                    && <span className=""><BsFillInfoCircleFill onClick={handleModal} /></span>
                                                                }</sub>:</span>{result?.second_product?.variation?.map(item => tipCategory.includes(item.attribute_name) && item.option_term)}
                                                                </p>
                                                            }
                                                            <p><span>Color:</span>{result?.second_product?.variation?.map(item => item.attribute_name === 'Color' && item.option_term)}
                                                                {moreColorSecond && <span className="selectorColor">(More colors are available in the fitting options)</span>}
                                                            </p>
                                                        </div>
                                                        <div className={`paymentoption ${result?.first_product?.category_details?.slug === "iron" ? 'ironPaymentOption' : ''}`}>
                                                            {
                                                                result?.first_product?.category_details?.slug === "iron" ?
                                                                    <h5>${secondProdPrice?.toFixed(2)} <span className="price-count">({shaftCount + ' ' + (shaftCount > 1 ? `Shafts` : `Shaft`)})</span></h5>
                                                                    : <h5>${result?.second_product?.msrp_price.toFixed(2)}</h5>
                                                            }
                                                            <div className="addcart">
                                                                <Button onClick={handleClickSecondProd}>Add to Cart</Button>
                                                                {
                                                                    result?.second_product?.category_details?.slug !== "iron" &&
                                                                    <Link to={`/shafts/${result?.second_product?.product_details?.slug}`}>
                                                                        <Button className="ms-2 explore-btn" onClick={handleExplore} data-id="explore-second">Club Build Options</Button>
                                                                    </Link>
                                                                }
                                                            </div>
                                                            <div className="text-center">
                                                                <Link to="" className="Compare-link">
                                                                    <div onClick={handleCompareProduct} data-id="second" style={{ textTransform: 'capitalize' }}>Compare With <span>{result?.first_product?.product_details?.product_title}</span> {result?.first_product?.category_details?.slug}
                                                                        ({result?.first_product?.category_details?.slug === "iron" ? firstFlex?.join(", ") : result?.first_product?.variation?.map(item => item.attribute_name === 'Flex' && item.option_term)})</div>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md="12" xl="6" className="small">
                                        <div className="facility-bg">
                                            <Row>
                                                <Col md="4">
                                                    <div className="result-productimages">
                                                        <Image src={result?.third_product?.product_details?.thumbnail ? 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + result?.third_product?.product_details?.thumbnail : DUMMY_IMAGE} className="img-fluid w-100" />
                                                    </div>
                                                </Col>
                                                <Col md="8">
                                                    <div className="productdetails-right pt-0">
                                                        <h1 className="mt-0">
                                                            <Link to={`/shafts/${result?.third_product?.product_details?.slug}`} style={{ textTransform: 'capitalize' }}>
                                                                {result?.third_product?.product_details?.product_title} {result?.third_product?.category_details?.slug}
                                                            </Link>
                                                            <h4 className="text-center mt-0 w-70"><span>Third Alternative</span></h4>
                                                        </h1>
                                                        <div className="progressBar">
                                                            <ProgressBar now={result?.countThree} label={`${result?.countThree}%`} visuallyHidden />
                                                        </div>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: result?.third_product?.product_details?.short_description,
                                                            }}
                                                        ></p>
                                                        <div className="alltypes">
                                                            {
                                                                result?.third_product?.category_details?.slug !== "iron" &&
                                                                <p><span>Model No:</span>{result?.third_product?.sku}</p>
                                                            }
                                                            {
                                                                result?.third_product?.category_details?.slug === "iron" &&
                                                                <p><span>Product Name:</span>{result?.third_product?.product_details?.product_title}</p>
                                                            }
                                                            <p><span>Category:</span>{result?.third_product?.category_details?.name}</p>
                                                            <p><span>Flex:</span>{result?.third_product?.category_details?.slug === "iron" ? thirdFlex?.join(", ") : result?.third_product?.variation?.map(item => item.attribute_name === 'Flex' && item.option_term)}</p>
                                                            {
                                                                result?.third_product?.category_details?.slug === "iron" &&
                                                                <p><span>Irons #:</span>
                                                                    {/* {result?.third_product?.variation?.map(item => ironCategory.includes(item.attribute_name) && item.option_term)} */}
                                                                    {productSelector?.questions?.config}
                                                                    {wedgesData.length > 0 ? ` + (${Object.values(wedgesData)})` : ''}
                                                                </p>
                                                            }
                                                            {
                                                                result?.third_product?.category_details?.slug === "iron" &&
                                                                <p><span className="tip-sub">Tip<sub>{(result?.third_product?.variation?.map(item => tipCategory.includes(item.attribute_name) && item.option_term))?.find(item => item !== false) === "Parallel"
                                                                    && <span className=""><BsFillInfoCircleFill onClick={handleModal} /></span>
                                                                }</sub>:</span>{result?.third_product?.variation?.map(item => tipCategory.includes(item.attribute_name) && item.option_term)}
                                                                </p>
                                                            }
                                                            <p><span>Color:</span>{result?.third_product?.variation?.map(item => item.attribute_name === 'Color' && item.option_term)}
                                                                {moreColorThird && <span className="selectorColor">(More colors are available in the fitting options)</span>}
                                                            </p>
                                                        </div>
                                                        <div className={`paymentoption ${result?.first_product?.category_details?.slug === "iron" ? 'ironPaymentOption' : ''}`}>
                                                            {
                                                                result?.first_product?.category_details?.slug === "iron" ?
                                                                    <h5>${thirdProdPrice?.toFixed(2)} <span className="price-count">({shaftCount + ' ' + (shaftCount > 1 ? `Shafts` : `Shaft`)})</span></h5>
                                                                    : <h5>${result?.third_product?.msrp_price.toFixed(2)}</h5>
                                                            }

                                                            <div className="addcart">
                                                                <Button onClick={handleClickThirdProd}>Add to Cart</Button>
                                                                {
                                                                    result?.third_product?.category_details?.slug !== "iron" &&
                                                                    <Link to={`/shafts/${result?.third_product?.product_details?.slug}`}>
                                                                        <Button className="ms-2 explore-btn" onClick={handleExplore} data-id="explore-third">Club Build Options</Button>
                                                                    </Link>
                                                                }
                                                            </div>
                                                            <div className="text-center">
                                                                <Link to="" className="Compare-link">
                                                                    <div onClick={handleCompareProduct} data-id="third" style={{ textTransform: 'capitalize' }}>Compare with <span>{result?.first_product?.product_details?.product_title}</span> {result?.first_product?.category_details?.slug}
                                                                        ({result?.first_product?.category_details?.slug === "iron" ? firstFlex?.join(", ") : result?.first_product?.variation?.map(item => item.attribute_name === 'Flex' && item.option_term)})</div>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="start-over mt-4">
                                    <Col md='12'>
                                        <div className="text-center">
                                            <Button onClick={() => navigate('/kinetixx-fit')}>Start Over <BiRefresh /></Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Modal show={showModal} onHide={handleModal} centered>
                                    <Modal.Header className="border-bottom">
                                        <Modal.Title style={{ fontSize: "larger" }}>Tapered & Parallel Tip Information</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="pt-2" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                                        Tapered tip & parallel tip both can be worked with professional fitters.
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            variant="primary"
                                            type="button"
                                            className="btn btn-outline-primary"
                                            onClick={handleModal}
                                        >
                                            Close
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                            </Container>
                            : <h2 style={{ textAlign: 'center', margin: '15px' }}>No Result Found</h2>
                        }
                    </section>
                </>
            )
    )
}

export default ProductResult;
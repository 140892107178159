import { INQUIRY_TAG } from '../../constants';
import { ApiService } from './apiService';

const BASE_URL = '/api/front';
export const InquiryAPI = ApiService.injectEndpoints({
  endpoints: (builder) => ({
    addInquiry: builder.mutation({
      query: (inquiryData) => {
        return {
          url: `${BASE_URL}/inquiry/add`,
          method: 'POST',
          body: inquiryData,
        }
      },
      invalidatesTags: [INQUIRY_TAG],
    }),
  }),
});

export const { useAddInquiryMutation } = InquiryAPI;

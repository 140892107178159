
import React from 'react';
import { Button } from "react-bootstrap";

const CookiePolicyPopup = ({ onAccept, onDecline }) => {
    return (
        <div className="cookie-popup">
            <div className="cookie-content">
                <p>
                    We use cookies and other technologies to collect information about your interactions with our site, analyze site usage, and assist marketing efforts and customer service, in accordance with our <a href="/privacy" target="_blank">Privacy, Ad & Cookies Polices</a>. To deliver the best experience, we share this information with our social media, advertising, and analytics partners. By clicking “Accept”, or clicking other content on our site you accept these trackers and our <a href="/terms" target="_blank">Terms of Use.</a>
                </p>
                <div className="cookie-buttons">
                    <Button onClick={onAccept} type='button' variant="primary">Accept</Button>
                    <Button onClick={onDecline} type="button" variant="secondary">Decline</Button>
                </div>
            </div>
        </div >
    );
};

export default CookiePolicyPopup;
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSelectedShippingRate } from "../../../redux-services/slices/cartSlice";
import { getProductGraphDetail, setExploreFitting } from "../../../redux-services/slices/siteDataSlice";
import { useAddToCartMutation, useGetCartQuery } from "../../../redux-services/apis/cartApi";
import Spiner from "../../../components/Layouts/Spiner";
import { getUserDetails } from "../../../redux-services/slices/authSlice";
import { useGetDataBySlugQuery } from '../../../redux-services/apis/technologyApi';

const DUMMY_IMAGE = '/images/productdummy.png';
const GraphResult = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [cmsData, setCmsData] = useState();
    const [filterData, setFilterData] = useState(null);
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: location?.pathname?.replace('/', ''),
    });
    const [result, setResult] = useState(null);
    const productGraph = useSelector(getProductGraphDetail);
    const [addToCart, addToCartResult] = useAddToCartMutation();
    const user = useSelector(getUserDetails);
    const selectedShippingRate = useSelector(getSelectedShippingRate);
    let { data: cartDetail } = useGetCartQuery(
        selectedShippingRate?.totalCharges
    );

    useEffect(() => {
        if (productGraph[0]?.data?.length > 0) {
            let filteredProducts = productGraph[0]?.data;
            const ids = [];
            const newIds = [];
            for (let item of filteredProducts) {
                let data = item?.product_variation?.variation.find(obj => obj.attribute_name === "Flex" && obj.option_id);
                ids.push(data?.option_id.valueOf());
            }

            const countMap = new Map();
            const uniqueIds = [];

            for (const id of ids) {
                countMap.set(id, (countMap.get(id) || 0) + 1);
            }

            for (const [id, count] of countMap) {
                if (count >= 2) {
                    uniqueIds.push(id);
                }
            }

            let filteredArray = [];
            let finalArr = [];

            for (let obj of filteredProducts) {
                let data = obj?.product_variation?.variation?.find(variant => variant?.attribute_name === "Flex");
                data = data?.option_id?.valueOf();
                let ids = [];
                let productIds = [];
                for (let item of filteredArray) {
                    let filterIds = item?.product_variation?.variation?.find(variant => variant?.attribute_name === 'Flex');
                    filterIds = filterIds?.option_id?.valueOf();
                    let productId = item?.product_variation?.productId?.valueOf();
                    ids.push(filterIds);
                    productIds.push(productId);
                }
                if (!ids.includes(data)) {
                    finalArr.push(obj);
                }
                if (uniqueIds.includes(data) && !ids.includes(data)) {
                    filteredArray.push(obj);
                }
            }

            for (let obj of filteredArray) {
                const flexOption = obj?.product_variation?.variation?.find(
                    (variation) =>
                        variation?.attribute_name === "Flex" &&
                        variation?.option_id
                );
                newIds.push(flexOption?.option_id?.valueOf());
            }
            const unmatchedArray = filteredProducts?.filter((item, index) =>
                item?.product_variation?.variation.some(
                    (variation) =>
                        variation?.attribute_name === "Flex" && !newIds?.includes(variation?.option_id?.valueOf())
                )
            );
            const mergedArray = [filteredArray, unmatchedArray];
            setFilterData(filteredArray)
            filteredArray = filteredArray.concat(unmatchedArray);
            setResult(filteredArray);
        }
    }, [productGraph])

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    useEffect(() => {
        if (addToCartResult?.isSuccess) {
            navigate("/cart");
        }
    }, [addToCartResult])

    useEffect(() => {
        if (productGraph !== null && productGraph.length === 0) {
            navigate("/launch-spin-profiles");
        }
    }, [productGraph])

    const handleExplore = useCallback((variant, sku) => {
        const sVariations = variant?.reduce(
            (obj, item) => ({ ...obj, [item.attribute_id]: item }),
            {}
        );
        dispatch(setExploreFitting({ variation: sVariations, sku: sku }))
    }, [result]);

    const onClickAddProductToCart = (e, data) => {
        const sVariations = data.product_variation.variation.reduce(
            (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
            {}
        );
        let ironCategory = ["Iron", "Iron #"];
        let IronNumbers = ['4', '5', '6', '7', '8', '9', 'P'];
        if (data.product_variation?.category_details?.slug === "iron") {
            let checkAddToCart = false;
            if (cartDetail) {
                const filterData = cartDetail?.response?.products?.find(item => item?.productVariationSku === data.product_variation?.sku);
                const prodVariationMatch = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && IronNumbers.includes(item?.term_option));
                const checkVariationFor123 = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === '1-2-3');
                const checkVariationForWedges = filterData?.productVariationDetail.find(item => ironCategory.includes(item?.attribute_name) && item?.term_option === "GW/SW/LW");
                if (user?.userType !== 2) {
                    checkAddToCart = true;
                } else {
                    if (prodVariationMatch === undefined) {
                        checkAddToCart = true;
                    } else {
                        checkAddToCart = false;
                    }
                    if (checkVariationFor123 !== undefined && filterData?.quantity === 3) {
                        checkAddToCart = false;
                    }
                    if (checkVariationForWedges !== undefined && filterData?.quantity === 4) {
                        checkAddToCart = false;
                    }
                }
            }
            if (checkAddToCart) {
                let newProduct = {
                    product_id: data.product._id,
                    product_slug: data.product?.slug,
                    product_title: data.product?.product_title,
                    product_variation: sVariations,
                    quantity: 1,
                    fitting: {},
                    product_variation_sku: data.product_variation?.sku,
                    product_variation_id: data.product_variation?._id,
                    acknowledgementChecked: false,
                    userId: user?._id,
                };
                addToCart({ productDetails: newProduct });
                checkAddToCart = false;
            }
        } else {
            let newProduct = {
                product_id: data.product._id,
                product_slug: data.product?.slug,
                product_title: data.product?.product_title,
                product_variation: sVariations,
                quantity: 1,
                fitting: {},
                product_variation_sku: data.product_variation?.sku,
                product_variation_id: data.product_variation?._id,
                acknowledgementChecked: false,
                userId: user?._id,
            };
            addToCart({ productDetails: newProduct });
        }
    };

    return (
        productGraph !== undefined && productGraph.length === 0 ? (<Spiner />) : (

            <section className="Driver-selecter-outer Select-productOuter facility-outer otherProducts pt-4">
                <div className="mainDriver-selector technology-mat-content text-center mb-5">
                    <Container>
                        {result?.length > 0 && <h2 style={{ textTransform: "capitalize" }}>Product- {result[0]?.product?.product_title} {result[0]?.product_variation?.category_details?.slug}</h2>}
                        <h4>Launch: {productGraph[1]?.launch}, Spin: {productGraph[2]?.spin} </h4>
                        {/* <h2>{cmsData?.page_title}</h2>
                                <h4 style={{ margin: "0px 10px" }}>{cmsData?.page_subtitle}</h4> */}
                    </Container>
                </div>
                {result && result !== undefined ?
                    <Container>
                        <Row className="justify-content-center">
                            {result.map(item =>
                                <Col md="6" xl="5" className="small" style={{ padding: '10px' }} key={item?.product?.slug}>
                                    <div className="facility-bg">
                                        <Row>
                                            <Col md="4">
                                                <div className="result-productimages">
                                                    <Image src={item?.product?.thumbnail ? 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + item?.product?.thumbnail : DUMMY_IMAGE} className="img-fluid w-100" />
                                                </div>
                                            </Col>
                                            <Col md="8">
                                                <div className="productdetails-right pt-0">
                                                    <h1 className="mt-0">
                                                        <Link to={`/shafts/${item?.product?.slug}`}>
                                                            <div style={{ textTransform: 'capitalize' }}>{item?.product?.product_title} {item?.product_variation?.category_details?.slug}</div>
                                                        </Link>
                                                    </h1>
                                                    <div className="alltypes">
                                                        {
                                                            item?.product_variation?.category_details?.slug !== "iron" &&
                                                            <p><span>Model No:</span>{item?.product_variation?.sku}</p>
                                                        }
                                                        {
                                                            item?.product_variation?.category_details?.slug !== "iron" &&
                                                            <p><span>Product Name:</span>{item?.product?.product_title}</p>
                                                        }
                                                        <p><span>Category:</span>{item?.product_variation?.category_details?.name}</p>
                                                        <p><span>Flex:</span>{item?.product_variation?.variation?.map(item => item.attribute_name === 'Flex' && item.option_term)}</p>
                                                        {
                                                            item?.product_variation?.category_details?.slug === "iron" &&
                                                            <p><span>Irons #:</span>
                                                                {item?.product_variation?.variation?.map(item => ['Iron', 'Iron #'].includes(item.attribute_name) && item.option_term)}
                                                            </p>
                                                        }
                                                        {filterData?.some(obj => obj?.product_variation?.sku === item?.product_variation?.sku) ?
                                                            <p><span>Color:</span>{item?.product_variation?.variation?.map(item => item.attribute_name === 'Color' && item.option_term)} (More colors are available in the fitting options)</p>
                                                            :
                                                            <p><span>Color:</span>{item?.product_variation?.variation?.map(item => item.attribute_name === 'Color' && item.option_term)}</p>
                                                        }
                                                        {
                                                            item?.product_variation?.category_details?.slug === "iron" &&
                                                            <p><span>Tip Diameter:</span>{item?.product_variation?.variation?.map(item => ['Tip', 'Tip Diameter'].includes(item.attribute_name) && item.option_term)}
                                                            </p>
                                                        }
                                                    </div>
                                                    <div className="paymentoption">
                                                        <h5>${item?.product_variation?.msrp_price.toFixed(2)}</h5>
                                                        <div className="addcart">
                                                            <Button onClick={(e) => onClickAddProductToCart(e, item)}>Add to Cart</Button>
                                                            {
                                                                item?.product_variation?.category_details?.slug !== "iron" &&
                                                                <Link to={`/shafts/${item?.product?.slug}`}>
                                                                    <Button className="ms-2 explore-btn" onClick={() => handleExplore(item?.product_variation?.variation, item?.product_variation?.sku)}>Fitting Options</Button>
                                                                </Link>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                    : <h2 style={{ textAlign: 'center', margin: '15px' }}>No Result Found</h2>
                }
            </section>

        )
    )
}

export default GraphResult;
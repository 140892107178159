import { createSlice } from "@reduxjs/toolkit";
import { PaymentGetwayAPI } from "../apis/paymentGetWay";

const initialState = {
    token: null,
    user: null,
}
const PaymentGetwaySlice = createSlice({
    name: "paymentGetway",
    initialState,
    reducers: {
        setPayment: (state, { payload }) => {
            state.token = payload.status;
        },
    },
    extraReducers(builder) {
        builder.addMatcher(
            PaymentGetwayAPI.endpoints.generateToken.matchFulfilled,
            (state, { payload }) => {
            });
    }
});
const { reducer, actions } = PaymentGetwaySlice;
export const { } = actions;
export default reducer;
import React, { useEffect, useState } from 'react';
import { Col, Breadcrumb } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetUserAddressQuery } from "../../redux-services/apis/userApi";
import {
  getSelectedShippingRate,
  setBrainTreeToken,
  setSelectedShippingAddress,
  setSelectedShippingRate
} from "../../redux-services/slices/cartSlice";
import ShowError from "../Notifications/ShowError";
import ShowModel from "../Notifications/ShowModel";
import AddressList from "./AddressList";
import ReturnToPreviousPage from "./ReturnToPreviousPage";
import ShippingMethod from "./ShippingMethod";
import { useGenerateTokenQuery } from '../../redux-services/apis/paymentGetWay';


const Shipping = ({ cartData }) => {

  const { data: billingAddress } = useGetUserAddressQuery();
  const { data: getBrainTreeToken } = useGenerateTokenQuery();

  const shippingRate = useSelector(getSelectedShippingRate);

  const [addressList, setAddressList] = useState([]);

  const [selectedShippingAddress, setShippingAddress] = useState({});

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (getBrainTreeToken?.status === true) {
      dispatch(setBrainTreeToken(getBrainTreeToken?.response))
    }
  }, [getBrainTreeToken])

  useEffect(() => {
    if (selectedShippingAddress && addressList.length) {
      dispatch(setSelectedShippingRate(null))
      dispatch(setSelectedShippingAddress(addressList.find(address => address._id === selectedShippingAddress)))
    }
  }, [selectedShippingAddress, addressList]);

  useEffect(() => {
    if (billingAddress?.response) {
      setAddressList(billingAddress.response);
    }
  }, [billingAddress]);

  const nextPageHandle = (e) => {
    e.preventDefault();


    if (!selectedShippingAddress) {
      setErrors({ shipping_address: "Please select a shipping address" })
      return false;
    }

    if (!shippingRate) {
      setErrors({ shipping_method: "Please select a shipping method" })
      return false;
    }

    navigate('/payment');

  }
  return <><Col md={12}>
    <div className='breadcrumbOuter'>
      <Breadcrumb>
        <Breadcrumb.Item href="/cart">Cart</Breadcrumb.Item>
        <Breadcrumb.Item active>
          Shipping
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <ShowModel />
  </Col>
    <Col md={12} lg={8}>
      <div className='contactinfo'>
        <div className='Contact-informations'>
          <div className='contacttop'>
            <h5>Ship to</h5>
          </div>
          <AddressList
            calculateShipping={true}
            setAddressId={setShippingAddress}
            addressId={selectedShippingAddress}
            addressList={addressList}
          />

        </div>
        <ShowError current_key="shipping_address" errors={errors} key="shipping_address" />

      </div>
      <div className='contactinfo'>
        <ShippingMethod selectedShippingAddress={Object.keys(selectedShippingAddress).length > 0} addressList={addressList.length} />
        <ShowError current_key="shipping_method" errors={errors} key="shipping_method" />

      </div>
      <ReturnToPreviousPage
        previosPageLabel="Return to Cart"
        forwardPageLabel="Continue To Payment"
        handleOnClick={nextPageHandle}
        previousPath="/cart"
      />
    </Col></>
}

export default Shipping;
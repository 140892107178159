import { Container, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import Carousel from "react-multi-carousel";

const HomeSlider = (props) => {

    let navigate = useNavigate();
    const responsive01 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1200, min: 991 },
            items: 1
        },
        minitablet: {
            breakpoint: { max: 991, min: 768 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };

    const handleNavigate = (e, link) => {
        e.preventDefault();
        let url = link.split('.com');
        if (url.length > 0) {
            navigate(url[1])
        }
    }

    return (
        props.homeData && props.homeData.section_1 &&
        <>
            <section className="sliderPart">
                <section className='align-items-center'>
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={props.homeData?.section_1?.length > 1 ? true : false}
                        responsive={responsive01}
                        ssr={true}
                        infinite={true}
                        removeArrowOnDeviceType={props?.homeData?.section_1.length == 1 &&
                            ["superLargeDesktop", "desktop"]
                        }
                        keyBoardControl={true}
                    >
                        {props.homeData?.section_1 && props.homeData?.section_1.map((item) =>
                            <div className="imageTab" key={item?.title}>
                                <img src={item.images[0]?.original} alt="#" className="img-fluid" />
                                <Container>
                                    <div className="slider-content">
                                        <div className="sliderinnercontent">
                                            <h1>{item.title}</h1>
                                            <h4>{item.subtitle}</h4>
                                            <p>{item?.description}</p>
                                            <div className="d-flex">
                                                {item.button1_Link && item.button1_text !== "" &&
                                                    <Button onClick={(e) => handleNavigate(e, item?.button1_Link)} className="btn btn-light btn-primary">{item.button1_text}</Button>
                                                }
                                                {item.button2_Link && item.button2_text !== "" &&
                                                    <Button onClick={(e) => handleNavigate(e, item?.button2_Link)} className="btn btn-primary btn-dark ms-3">{item.button2_text}</Button>
                                                }
                                            </div>
                                        </div>
                                        <span className="text-overlay"></span>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Carousel>
                </section>
            </section>
        </>
    );
}

export default HomeSlider;
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home/Home';
import Cart from './Pages/Cart/Cart';
import Information from './Pages/Shipping/Information';
import Checkout from "./Pages/Shipping/Checkout";
import Payment from './Pages/Payment/Payment';
import './App.css';
import './Media.css';
import Compare from './Pages/Compare';
import ProductList from "./Pages/Product/ProductListing/ProductList";
import ProductDetail from "./Pages/Product/ProductDetail/ProductDetail";
import MyOrder from "./Pages/Profile/Orders/MyOrder";
import Wishlist from "./Pages/Profile/Wishlist";
import Profile from "./Pages/Profile/Profile";
import Compare1 from "./Pages/Compare1";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import YourAccount from "./Pages/Profile/YourAccount";
import OrderNew from "./Pages/Profile/Orders/OrderNew";
import Layout from "./components/Layouts/Layouts";
import setAuthToken from "./redux-services/apis/setAuthToken";
import SaveCard from "./Pages/Profile/PaymentMethods/SaveCard";
import DealersRegistration from "./Pages/Dealer/DealersRegistration";
import Dealer from "./Pages/Dealer/Dealer";
import Address from "./Pages/Profile/Adresses/Address";
import OrderDetails from "./Pages/Profile/Orders/OrderDetails";
import ThankYou from "./Pages/ThankYou";
import Technology from "./Pages/Technology";
import { ForgotPassword } from "./Pages/ForgotPassword";
import { ResetPassword } from "./Pages/ResetPassword";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import DealerInternational from "./Pages/Dealer/DealerInternational";
import { ProductType } from "./constants";
import NewsMedia from "./Pages/News/NewsMedia";
import NewsDetails from "./Pages/News/NewsDetail";
import USFitting from "./Pages/Fitting/FittingStudios/USFitting";
import InternationalFitting from "./Pages/Fitting/FittingStudios/InternationalFitting";
import PayNow from "./Pages/Payment/PayNow";
import PaymentLinkExpired from "./Pages/PaymentLinkExpired";
import ProductComparisonTool from "./Pages/Fitting/ProductComparisonTool";
import ProductSelector from "./Pages/Fitting/ProductSelector";
import Terms from "./Pages/LegalPages/Terms&Conditions";
import PrivacyPolicy from "./Pages/LegalPages/PrivacyPolicy";
import DriverSelector from "./Pages/DriverSelector";
import DriverSelectorNext from "./Pages/DriverSelectorNext";
import DriverSelectorIron from "./Pages/DriverSelectorIron";
import DriverSelectorIronTwo from "./Pages/DriverSelectorIronTwo";
import DriverSelectorIronThree from "./Pages/DriverSelectorIronThree";
import DriverSelectorIronFour from "./Pages/DriverSelectorIronFour";
import DriverSelectorIronFive from "./Pages/DriverSelectorIronFive";
import DriverSelectorIronSix from "./Pages/DriverSelectorIronSix";
import ProductSelectorMain from "./Pages/ProductSelectorMain";
import ProductResult from "./Pages/ProductResult";
import DriverSelectorIronNext from "./Pages/DriverSelectorIronNext";
import { HelmetProvider } from 'react-helmet-async';
import { setupInterceptorsTo } from "./Interceptor/Interceptor";
import axios from "axios"
import Graph from "./Pages/Product/ProductChart/Graph";
import GraphResult from "./Pages/Product/ProductChart/GraphResult";
import UnSubscribe from "./Pages/UnSubscribe";
import Resources from "./Pages/Resources/ResourceList";
import ResourceDetails from "./Pages/Resources/ResourceDetails";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const token = localStorage.userAccessToken;
  if (!token) {
    // user is not authenticated
    return <Navigate to="/login" state={`${location.pathname}${location.search}`} />;
  }
  return children;
};

const PublicRoute = ({ children }) => {
  const token = localStorage.userAccessToken;
  if (token) {
    // user is not authenticated
    return <Navigate to="/profile" />;
  }
  return children;
};

function App() {
  const location = useLocation();
  const token = localStorage.userAccessToken;
  useEffect(() => {
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const [scrollDown, setScrollDown] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location]);

  useEffect(() => {
    if (scrollDown) {
      window.scrollTo({ top: 250, left: 0, behavior: 'smooth' });
    }
    setScrollDown(false);
  }, [scrollDown]);

  setupInterceptorsTo(axios)

  return (
    <HelmetProvider>
      <Routes>
        <Route element={<Layout setScrollDown={setScrollDown} />} >
          <Route path="/" element={<Home />} />
          <Route path="/shafts/category/:category" element={<ProductList />} />
          <Route path="/shafts/:slug" element={<ProductDetail />} />
          <Route path="/gear/category/:category" element={<ProductList type={ProductType} />} />
          <Route path="/gear/:slug" element={<ProductDetail type={ProductType} />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/information" element={<Information />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/compare" element={<Compare />} />
          <Route path="/compare1" element={<Compare1 />} />
          <Route path="/pages/:page_slug" element={<Technology />} />
          <Route path="/fitting-studios" element={<USFitting />} />
          <Route path="/fitting-studios/international-fitting" element={<InternationalFitting />} />
          <Route path="/product-comparison-tool" element={<ProductComparisonTool />} />
          <Route path="/:page_slug" element={<NewsMedia />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/pay-now/:order_id" element={<ProtectedRoute><PayNow /></ProtectedRoute>} />
          <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
          <Route path="/order/:orderId" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
          <Route path="/order" element={<ProtectedRoute><MyOrder /></ProtectedRoute>} />
          <Route path="/save-card" element={<ProtectedRoute><SaveCard /></ProtectedRoute>} />
          <Route path="/address" element={<ProtectedRoute><Address /></ProtectedRoute>} />
          <Route path="/wishlist" element={<ProtectedRoute><Wishlist /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/account" element={<ProtectedRoute><YourAccount /></ProtectedRoute>} />
          <Route path="/order-new" element={<ProtectedRoute><OrderNew /></ProtectedRoute>} />
          <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
          <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
          <Route path="/reset-password/:id/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
          <Route path="/dealer-registration" element={<DealersRegistration />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="link-expired" element={<PaymentLinkExpired />} />
          <Route path="/dealer" element={<Dealer />} />
          <Route path="/pages/newsdetails/:page_slug" element={<NewsDetails />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/terms-of-sale" element={<Terms />} />
          <Route path="/shipping-policy" element={<Terms />} />
          <Route path="/return-policy" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          {/* <Route path="/faq" element={<PrivacyPolicy />} /> */}
          <Route path="/warranty-info" element={<PrivacyPolicy />} />
          {/* <Route path="/shipping-and-returns" element={<PrivacyPolicy />} /> */}
          <Route path="/international-dealer" element={<DealerInternational />} />
          <Route path="/kinetixx-fit/:product_category" element={<DriverSelector />} />
          <Route path="/kinetixx-fit-next" element={<DriverSelectorNext />} />
          <Route path="/kinetixx-fit-iron/:product_category" element={<DriverSelectorIron />} />
          <Route path="/kinetixx-fit-iron-two/:product_category" element={<DriverSelectorIronTwo />} />
          <Route path="/kinetixx-fit-iron-next/:product_category" element={<DriverSelectorIronNext />} />
          <Route path="/kinetixx-fit-iron-three/:product_category" element={<DriverSelectorIronThree />} />
          <Route path="/kinetixx-fit-iron-four/:product_category" element={<DriverSelectorIronFour />} />
          <Route path="/kinetixx-fit-iron-five/:product_category" element={<DriverSelectorIronFive />} />
          <Route path="/kinetixx-fit-iron-six/:product_category" element={<DriverSelectorIronSix />} />
          <Route path="/kinetixx-fit" element={<ProductSelectorMain />} />
          <Route path="/product-result" element={<ProductResult />} />
          <Route path="/launch-spin-profiles" element={<Graph />} />
          <Route path="/launch-spin-profiles-result" element={<GraphResult />} />
          <Route path='/unsubscribe' element={<UnSubscribe />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/resources/:slug' element={<ResourceDetails />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes >

    </HelmetProvider>
  );
}

export default App;




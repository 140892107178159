
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { getCategoryList } from "../../redux-services/slices/siteDataSlice";

const HomeProductSelector = (props) => {
  const categories = useSelector(getCategoryList);
  let navigate = useNavigate();
  const list = [];
  categories.find(category => category.name?.toLowerCase()?.includes('putter') && list.push({ Putter: category._id }))
  categories.find(category => category.name?.toLowerCase()?.includes('hybrid') && list.push({ Hybrid: category._id }))
  categories.find(category => category.name?.toLowerCase()?.includes('driver') && list.push({ Driver: category._id }))
  categories.find(category => category.name?.toLowerCase()?.includes('iron') && list.push({ Iron: category._id }))
  categories.find(category => category.name?.toLowerCase()?.includes('fairway') && list.push({ Fairway: category._id }))

  const responsive01 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1200, min: 991 },
      items: 1
    },
    minitablet: {
      breakpoint: { max: 991, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    className: "center",
    centerMode: true,
    speed: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleNavigate = (e, link) => {
    e.preventDefault();
    let url = link.split('.com');
    if (url.length > 0) {
      navigate(url[1])
    }
  }

  return (
    props.homeData &&
    <>
      <section className="product-selector">
        <Container>
          <Row>
            <Col md="12">
              <div className="product-heading">
                <img src="/images/logo-gray.png" alt='#' className='img-fluid' />
                <h2>{props.homeData?.section_4.title}</h2>
                <h3>{props.homeData?.section_4.subtitle}</h3>
                <p>{props.homeData?.section_4.description}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="productslider-outer">
          {list.length > 0 &&
            <Container fluid>
              <div className='kinetixx-product'>
                <Slider {...settings}>
                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[2]?.Driver} to="/shafts/category/driver" state={{ categoryId: list[2]?.Driver }}>
                        <img src="/images/Driverslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/DriversDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Drivers</h4>
                      </Link>
                    </div>
                  </div>

                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[4]?.Fairway} to="/shafts/category/fairway" state={{ categoryId: list[4]?.Fairway }}>
                        {/* <img src="/images/ironlight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/ironDark.png" className='img-fluid darkimg' alt='/shafts' /> */}
                        <img src="/images/hybirdslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/hybirdsDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Fairways</h4></Link>
                    </div>
                  </div>
                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[3]?.Iron} to="/shafts/category/iron" state={{ categoryId: list[3]?.Iron }}>
                        <img src="/images/ironlight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/ironDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Irons</h4>
                      </Link>
                    </div>
                  </div>

                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[1]?.Hybrid} to="/shafts/category/hybrid" state={{ categoryId: list[1]?.Hybrid }}>
                        <img src="/images/hybirdslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/hybirdsDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Hybrids</h4></Link>
                    </div>
                  </div>
                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[0]?.Putter} to="/shafts/category/putters" state={{ categoryId: list[0]?.Putter }}>
                        <img src="/images/putterslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/puttersDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Putters</h4></Link>
                    </div>
                  </div>
                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[1]?.Hybrid} to="/shafts/category/hybrid" state={{ categoryId: list[1]?.Hybrid }}>
                        <img src="/images/hybirdslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/hybirdsDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Hybrids</h4></Link>
                    </div>
                  </div>
                </Slider>
                <div className="mobile-kinetixx-product">
                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[2]?.Driver} to="/shafts/category/driver" state={{ categoryId: list[2]?.Driver }}>
                        <img src="/images/Driverslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/DriversDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Drivers</h4>
                      </Link>
                    </div>
                  </div>

                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[4]?.Fairway} to="/shafts/category/fairway" state={{ categoryId: list[4]?.Fairway }}>
                        {/* <img src="/images/ironlight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/ironDark.png" className='img-fluid darkimg' alt='/shafts' /> */}
                        <img src="/images/hybirdslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/hybirdsDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Fairways</h4></Link>
                    </div>
                  </div>
                  

                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[1]?.Hybrid} to="/shafts/category/hybrid" state={{ categoryId: list[1]?.Hybrid }}>
                        <img src="/images/hybirdslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/hybirdsDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Hybrids</h4></Link>
                    </div>
                  </div>
                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[0]?.Putter} to="/shafts/category/putters" state={{ categoryId: list[0]?.Putter }}>
                        <img src="/images/putterslight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/puttersDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Putters</h4></Link>
                    </div>
                  </div>
                  <div>
                    <div className='kinetixxproductOuter'>
                      <Link key={list[3]?.Iron} to="/shafts/category/iron" state={{ categoryId: list[3]?.Iron }}>
                        <img src="/images/ironlight.png" className='img-fluid lightimg' alt='/shafts' />
                        <img src="/images/ironDark.png" className='img-fluid darkimg' alt='/shafts' />
                        <h4>Irons</h4>
                      </Link>
                    </div>
                  </div>
                  
                </div>
              </div>
            </Container>
          }
        </div>
      </section>
      <section className="sliderPart advancements">
        <section className='align-items-center'>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={props.homeData?.section_5?.length > 1 ? true : false}
            responsive={responsive01}
            ssr={true}
            infinite={true}
            removeArrowOnDeviceType={props?.homeData?.section_5.length == 1 &&
              ["superLargeDesktop", "desktop"]
            }
            keyBoardControl={true}
          >
            {props.homeData?.section_5 && props.homeData?.section_5.map((item) =>
              <div className="imageTab" key={item?.title}>
                <img src={item.images[0]?.original} alt="#" className="img-fluid" />
                <Container>
                  <div className="slider-content">
                    <div className="sliderinnercontent">
                      <h1>{item.title}</h1>
                      <h4>{item.subtitle}</h4>
                      <p>{item?.description}</p>
                      <div className="d-flex">
                        {item.button1_Link && item.button1_text !== "" &&
                          <Button onClick={(e) => handleNavigate(e, item?.button1_Link)} className="btn btn-light btn-primary">{item.button1_text}</Button>
                        }
                        {item.button2_Link && item.button2_text !== "" &&
                          <Button onClick={(e) => handleNavigate(e, item?.button1_Link)} className="btn btn-primary btn-dark ms-3">{item.button2_text}</Button>
                        }
                      </div>
                    </div>
                    <span className="text-overlay"></span>
                  </div>
                </Container>
              </div>
            )}
          </Carousel>
        </section>
      </section>
    </>
  );
}

export default HomeProductSelector;

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { BiArrowBack } from 'react-icons/bi';
import MetaData from '../../components/MetaData/MetaData'
import { Link, useParams } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import Spiner from '../../components/Layouts/Spiner';
import SharePopup from './SharePopup';
import { useGetDataByNewsSlugQuery } from '../../redux-services/apis/newsApi';

const NewsDetails = () => {

    const [techData, setTechData] = useState(null);
    const [cmsImages, setCMSImages] = useState([]);
    const [cmsVideo, setCMSVideo] = useState([]);
    const params = useParams();

    const { isLoading, data: getDataByNewsSlug } = useGetDataByNewsSlugQuery({
        slug: params.page_slug
    });

    useEffect(() => {
        if (getDataByNewsSlug?.status && getDataByNewsSlug.response) {
            setTechData(getDataByNewsSlug?.response[0])
        }
    }, [getDataByNewsSlug]);


    const getMonthName = (monthNumber) => {
        let mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return mlist[monthNumber];
    }

    useEffect(() => {
        if (getDataByNewsSlug?.status && getDataByNewsSlug.response) {
            setTechData(getDataByNewsSlug?.response[0])
        }
        if (getDataByNewsSlug?.response[0]?.images?.length || getDataByNewsSlug?.response[0]?.videoUrl) {
            const images = [];
            const videoArray = [];
            const playImage = '/images/play.svg';
            const imageData = getDataByNewsSlug.response[0]?.images;
            imageData.map((image) => {
                images.push({
                    original: image.original,
                    thumbnail: image.thumbnail,
                })
            })
            if (getDataByNewsSlug.response[0]?.videoUrl) {
                const thumbnailCode = getDataByNewsSlug?.response[0]?.videoUrl.substring(
                    getDataByNewsSlug?.response[0]?.videoUrl.lastIndexOf('/') + 1
                );
                const regex = /youtube.com/g;
                const found = getDataByNewsSlug?.response[0]?.videoUrl.match(regex);
                images.splice(0, 0,
                    {
                        thumbnail: playImage,
                        original: playImage,
                        embedUrl: getDataByNewsSlug?.response[0]?.videoUrl,
                        thumbnailClass: 'video-slide',
                        renderItem: function (onClick, item) {
                            return <div className='image-gallery-image'>
                                <iframe title='video' width='400' height='300' src={getDataByNewsSlug?.response[0]?.videoUrl} frameBorder='0' allowFullScreen></iframe>
                            </div>
                        },
                    });
                // videoArray.splice(0, 0,
                //     {
                //         thumbnail: found
                //             ? `https://i.ytimg.com/vi/${thumbnailCode}/hqdefault.jpg`
                //             : playImage,
                //         original: playImage,
                //         embedUrl: getDataByNewsSlug?.response[0]?.videoUrl,
                //         thumbnailClass: 'video-slide',
                //         renderItem: renderVideo,
                //     }
                // );
                videoArray.splice(0, 0,
                    {
                        thumbnail: playImage,
                        original: playImage,
                        embedUrl: getDataByNewsSlug?.response[0]?.videoUrl,
                        thumbnailClass: 'video-slide',
                        renderItem: function (onClick, item) {
                            return <div className='image-gallery-image'>
                                <iframe title='video' width='400' height='300' src={getDataByNewsSlug?.response[0]?.videoUrl} frameBorder='0' allowFullScreen></iframe>
                            </div>
                        },
                    });
            }

            setCMSImages(images);
            setCMSVideo(videoArray);
        }
    }, [getDataByNewsSlug]);
    const renderVideo = useCallback((item) => {
        return (
            <div>
                {
                    <div className="video-wrapper">
                        <iframe
                            width="500"
                            height="400"
                            src={`${item.embedUrl}?autoplay=1&showinfo=0`}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                }
            </div>
        );
    }, []);
    
    return (
        isLoading ? (
            <Spiner />
        ) : (
            techData && techData?.status == 1 && techData != null ? (
                <>
                    <MetaData metaData={techData} />
                    <section className='facility-outer'>
                        <Container>
                            <Row className='align-items-center'>
                                <Col md="12">
                                    <div className='share-news mb-lg-5'>
                                        <Link to={`/news`} className='btn btn-primary'><BiArrowBack /> Back to News</Link>
                                        <SharePopup />
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className='technology-mat-content text-center mb-lg-5'>
                                        <h2>{techData.title}</h2>
                                        <p>{techData.subtitle}</p>
                                        <span className='dateTime'>{new Date(techData?.created_at).getDate() + '-' + getMonthName(new Date(techData?.created_at).getMonth()) + '-' + new Date(techData?.created_at).getFullYear()}</span>
                                        <hr />
                                    </div>
                                </Col>

                                <Col md="12">
                                    <div className='technology-mat-content'>
                                        {techData.images.length >= 1 ?
                                            <ImageGallery items={cmsImages} />
                                            : <ImageGallery items={cmsVideo} />
                                        }
                                        <div
                                            style={{ wordWrap: 'break-word' }}
                                            dangerouslySetInnerHTML={{
                                                __html: (techData.description),
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </>
            ) : (
                <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
            )
        )
    );
}

export default NewsDetails;

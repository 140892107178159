import { useEffect } from "react";

function MouseFlowScript() {
    useEffect(() => {
        // Create the script element
        const mf = document.createElement("script");
        mf.type = "text/javascript";
        mf.defer = true;
        mf.src = "//cdn.mouseflow.com/projects/6186b1ba-d6f8-4756-827d-17e3623f0fcd.js";

        // Append to head
        document.getElementsByTagName("head")[0].appendChild(mf);
        // Cleanup function to remove the script if necessary
        return () => {
            document.getElementsByTagName("head")[0].removeChild(mf);
        };
    }, []);

    return null; // This component doesn't render anything
}

export default MouseFlowScript;
import React from 'react';
import { Row, Col } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from "react-redux";
import { getFilterList, setFilterData } from "../../../redux-services/slices/productDataSlice";
import { AiOutlineSearch } from "react-icons/ai";


function ProductFilter() {

  const filters = useSelector(getFilterList);
  const dispatch = useDispatch();
  const onTextFilterChange = (e) => {

    const filterData = {
      ...filters,
      textSearch: e.target.value
    };
    dispatch(setFilterData(filterData));
  }

  const onSelectChange = (e) => {

    const filterData = {
      ...filters,
      sortBy: e.target.value
    };
    dispatch(setFilterData(filterData));

  }
  return <Row className='justify-content-between mb-4'>

    <Col md="12">
      <div className='d-flex justify-content-end'>
        <div className='form-group sortBy search-item me-3'>
          <input type="text" onChange={onTextFilterChange} placeholder="Search Products" className='form-control' />
          <AiOutlineSearch />
        </div>
        <div className='form-group sortBy'>
          <Form.Select onChange={onSelectChange} className='form-control' aria-label="Default select example">
            <option>Sort By</option>
            <option value="price_1" selected={filters.sortBy === "price_1"}>Price (low to high)</option>
            <option value="price_2" selected={filters.sortBy === "price_2"}>Price (high to low)</option>
            <option value="alpha_1" selected={filters.sortBy === "alpha_1"}>Alphabetically (A to Z)</option>
            <option value="alpha_2" selected={filters.sortBy === "alpha_2"}>Alphabetically (Z to A)</option>
          </Form.Select>
        </div>
      </div>
    </Col>
  </Row>
}

export default ProductFilter;
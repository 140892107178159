import React, { useState } from 'react';
import { Container, Row, Col, Button, Tabs, Tab, Modal } from "react-bootstrap";
import { IoIosArrowBack } from 'react-icons/io';
import { useSelector } from "react-redux";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getCategoryList } from "../redux-services/slices/siteDataSlice";
import CompareProduct from "./Product/ProductDetail/Compare/CompareProduct";
import CompareSelection from "./Product/ProductDetail/Compare/CompareSelection";
import { BsPlayCircle } from "react-icons/bs";






const About = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (

        <>
            <section className='compareOuter technology'>
                <div className='headcenter'>
                    <img src="/images/about-bg.png" alt='#' />
                    <div>
                        <h2>About Us</h2>
                    </div>
                </div>
            </section>
            <section className='kinetixx-about'>
                <Container>
                    <Row>
                        <Col md="7">
                            <div className='about-content'>
                                <h2>Filament wound = Round, concentric, straight shafts loaded with the greatest potential energy!</h2>
                                <p>KINETIXx” IMRT loaded shaft technologies are the worlds only golf club shafts that combine filament winding and table rolling construction methods with a vacuum curing process making Paderson KINETIXx loaded shafts. the most advanced, multi-structure, multi-material, precision tailored high performance composite shafts in the world!</p>
                                <p>KINETIXx IMRT = Visible fiber performance technology unlike any other shaft!</p>
                                <p>The tailored coupling of kevlar and 3K micro carbon fibers, filament wound over precisely located high modulus filament wound and or table rolled flag core sections, in select portions of every KINETIXx IMRT loaded shaft. Producing a visible rhombus web structural architecture, precisely tuned for attenuation of vibration,Energy Inertia, Torque and Feel to create the purest kinetic energy transfer to the clubhead at the moment of impact. Creating a visible fiber chain of conductivity.</p>
                                <p>KINETIXx IMRT shafts, are the worlds only filament wound kevlar golf shaft technology array specifically tailored to the impact mechanics of each club, from Driver to hybrids and iron and wedges. KINETIXx KEVLAR GREEN and BLUE woven chain of fibers visible in all Paderson KINETIXx IMRT branded shafts have 20x the elastic strength of steel!</p>
                            </div>
                        </Col>
                        <Col md="5">
                            <img src="/images/shaft-player.png" className='img-fluid' />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='about-video'>
                <img src="/images/status-shifted.png" className='w-100' />
                <div className='overlay-content'>
                <BsPlayCircle onClick={handleShow} />
                <h2>Superior Down <span className='d-md-block'>Range Performance</span></h2>
                </div>




                <Modal className='video-modal' show={show} onHide={handleClose} size="lg">
                    
                    <Modal.Body><iframe width="885" height="498" src="https://www.youtube.com/embed/Ii0CmWxRgsI" title="Paderson Kinetixx Ballistic Iron Shafts Review" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></Modal.Body>
                    
                </Modal>
            </section>
         
            
        </>
    );
}

export default About;

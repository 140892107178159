import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, FormText } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import extractNumber from '../../utills/extractNumber';
import { getUserDetails, setUpdatedUserDetails } from '../../redux-services/slices/authSlice';
import {
  useUpdateProfileMutation,
  useResetUserPasswordMutation,
} from '../../redux-services/apis/userApi';
import ShowError from '../Notifications/ShowError';
import { getCountryShort } from '../../utills/countryCode';
import ShowModel from '../Notifications/ShowModel';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { showModel } from '../../redux-services/slices/siteDataSlice';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

let customerTitle = ['Master', 'Mr', 'Miss', 'Mrs', 'Ms', 'Mx', 'Dr'];

function Profile() {
  const dispatch = useDispatch();
  const initialFormData = {
    first_name: '',
    last_name: '',
    dob: '',
    title: '',
    ccode: '',
    iute164_phone: "",
    phone: '',
    email: '',
    old_email: '',
    password: '',
  };
  const initialResetPasswordFormData = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const [showFormData, setShowFormData] = useState(false);
  const [resetPasswordFormData, setResetPasswordFormData] = useState(
    initialResetPasswordFormData
  );
  const [showEdit, setShowEdit] = useState(false);
  const [currPassword, setCurrPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [isResetPass, setIsResetPass] = useState(false);
  const [loadEditData, setLoadEditData] = useState(false);
  const [errors, setErrors] = useState({});
  const [updateProfile, profileResponse] = useUpdateProfileMutation();
  const [resetPassword, resetPasswordResponse] = useResetUserPasswordMutation();
  const [defaultCountry, setDefaultCountry] = useState('US');


  let authUser = useSelector(getUserDetails);



  //########################## change event for phone number ##############################
  const onPhoneChange = (number) => {
    let contact_no = extractNumber(number, formData.ccode);
    setFormData((form) => ({
      ...form,
      phone: contact_no ?? '',
      iute164_phone: number ?? '',
    }));
  };

  const onCountryChange = code => {
    setFormData((form) => ({
      ...form,
      "ccode": "+" + getCountryCallingCode(code ?? getCountryShort(formData.ccode)),
    }));
  }

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value.replace(/[^a-z0-9]/gi, '')
        ? value.trimLeft()
        : value.trim(),
    });
  };

  const onChangeResetPassword = (event) => {
    const { name, value } = event.target;
    setResetPasswordFormData({
      ...resetPasswordFormData,
      [name]: value.replace(/[^a-z0-9]/gi, '')
        ? value.trimLeft()
        : value.trim(),
    });
  };
  const setFocusOn = (fieldId) => {
    const fieldItem = document.querySelector('input[name=' + fieldId + ']');
    fieldItem?.focus();
  };

  useEffect(() => {
    if (!authUser?.phone) {
      const fetchDefaultCountryCode = async () => {
        try {
          const response = await fetch('https://geolocation-db.com/json/');
          const data = await response.json();
          const countryCode = data.country_code || '';

          // Use libphonenumber-js to format the country code
          const phoneNumber = getCountryCallingCode(countryCode);


          if (phoneNumber) {
            setDefaultCountry(countryCode);
            setFormData({ ...formData, ccode: `+${phoneNumber}` });

          }
        } catch (error) {
          console.error('Error fetching default country code:', error);
        }
      };

      fetchDefaultCountryCode();
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser !== null) {
      if (authUser?.ccode === undefined) {
        setFormData({
          ...formData,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          phone: authUser?.phone,
          dob: authUser?.dob,
          title: authUser?.title,
          email: authUser?.email,
          iute164_phone: (authUser?.ccode + authUser?.phone) ?? '',
          old_email: authUser?.email,
        });
      } else {
        setFormData({
          ...formData,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          ccode: authUser?.ccode,
          phone: authUser?.phone,
          dob: authUser?.dob,
          title: authUser?.title,
          email: authUser?.email,
          iute164_phone: (authUser?.ccode + authUser?.phone) ?? '',
          old_email: authUser?.email,
        });
      }
    }
  }, [authUser, showFormData]);

  useEffect(() => {
    if (
      profileResponse.isSuccess &&
      profileResponse.data.status &&
      isResetPass !== true
    ) {
      authUser = { ...authUser, first_name: formData?.first_name, last_name: formData?.last_name, ccode: formData?.ccode, phone: formData?.phone, title: formData?.title, email: formData?.email }
      dispatch(setUpdatedUserDetails(authUser));
      setLoadEditData(false);
      dispatch(
        showModel({
          isOpen: true,
          modelType: 'success',
          body: profileResponse.data.message,
        })
      );
    }
    if (profileResponse?.isError && profileResponse?.error?.data?.errors) {
      const errorList = {};
      let firstError = '';
      dispatch(
        showModel({
          isOpen: true,
          modelType: 'danger',
          body: profileResponse.error.data.message,
        })
      );

      profileResponse?.error?.data?.errors?.forEach((error, index) => {
        if (index === 0) {
          firstError = error.param;
        }
        errorList[error.param] = error.msg;
      });

      setErrors(errorList);
      setFocusOn(firstError);
    }

  }, [profileResponse]);

  useEffect(() => {
    if (resetPasswordResponse.isSuccess && resetPasswordResponse.data.status) {
      setResetPasswordFormData(initialResetPasswordFormData);
      dispatch(
        showModel({
          isOpen: true,
          modelType: 'success',
          body: resetPasswordResponse.data.message,
        })
      );
    }
    if (
      resetPasswordResponse.isError &&
      resetPasswordResponse.error.data.errors
    ) {
      dispatch(
        showModel({
          isOpen: true,
          modelType: 'danger',
          body: resetPasswordResponse.error.data.message,
        })
      );
      const errorList = {};
      let firstError = '';
      resetPasswordResponse?.error?.data?.errors?.forEach((error, index) => {
        if (index === 0) {
          firstError = error.param;
        }
        errorList[error.param] = error.msg;
      });
      setErrors(errorList);
      setFocusOn(firstError);
    }
  }, [resetPasswordResponse.isError, resetPasswordResponse.isSuccess]);

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsResetPass(false);
    setErrors({});
    updateProfile({ ...formData, id: authUser._id });
    setFormData({ ...formData, password: '' });
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (e.currentTarget.getAttribute("data-id") == "current_password") {
      setCurrPassword(!currPassword);
      setPasswordShown(passwordShown);
      setConfirmPassword(confirmPassword);
    }
    if (e.currentTarget.getAttribute("data-id") == "password") {
      setPasswordShown(!passwordShown);
      setConfirmPassword(confirmPassword);
      setCurrPassword(currPassword);
    }
    if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
      setPasswordShown(passwordShown);
      setConfirmPassword(!confirmPassword);
      setCurrPassword(currPassword);
    }
  };

  const resetPasswordHandler = async (event) => {
    event.preventDefault();
    resetForm();
    resetPassword(resetPasswordFormData);
  };
  const resetForm = async () => {
    setShowEdit(false);
    //setFormData(initialFormData)
    setIsResetPass(true);
    await dispatch(showModel({}));
    setLoadEditData(false);
    setErrors({});
    setShowFormData(!showFormData);
  };
  const showEditForm = (showVal) => {
    setShowEdit(true);
    setLoadEditData(true);
    setShowFormData(!showFormData);
  };

  return (
    <>
      <section className="profileDatatop">
        <Container>
          <Row>
            <Col md="12">
              <div className="accountInnner pt-0 ps-0 pe-0">
                <h2>Profile</h2>
                <div className="backpage">
                  <p>
                    {authUser?.first_name} {authUser?.last_name}
                  </p>
                  <div>
                    <Link to="/account">Back to Your Account</Link>
                  </div>
                </div>
                <hr />
              </div>
            </Col>
            <Col md="12">
              <ShowModel />
            </Col>
            <Col md="12" lg="6">
              <div className="stepOne">
                <div className="infoheader">
                  <h3>Profile Information</h3>
                  {!loadEditData && (
                    <Button onClick={() => showEditForm(true)}>Edit</Button>
                  )}
                </div>
                {!loadEditData && (
                  <div className="infoform">
                    <Row>
                      <Col md="12">
                        <p>
                          <label>First Name :</label> {authUser?.title}{' '}
                          {authUser?.first_name}
                        </p>
                        <p>
                          <label>Last Name :</label> {authUser?.last_name}
                        </p>
                        <p>
                          <label>Phone Number :</label> {authUser?.phone ? ((authUser?.ccode !== undefined ? authUser?.ccode : '') + authUser?.phone) : '-'}
                        </p>
                        <p>
                          <label>Email Address :</label>
                          {authUser?.email}
                        </p>
                        {/* <p><label>DOB :</label> {authUser?.dob ?? "-"}</p> */}
                      </Col>
                    </Row>
                  </div>
                )}
                {loadEditData && (
                  <div className="infoform">
                    <Form onSubmit={submitHandler}>
                      <Form.Group className="edit-form">
                        <Form.Label>Title :</Form.Label>
                        <Form.Select name="title" onChange={onChange}>
                          <option value={''}>{'Select Title'}</option>
                          {customerTitle.map((item) => (
                            <option
                              key={item}
                              value={item}
                              selected={formData.title === item ? true : false}
                            >
                              {item}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="edit-form">
                        <Form.Label>First Name * :</Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          value={formData.first_name}
                          onChange={onChange}
                          required
                          placeholder="First Name*"
                        />
                        <ShowError
                          current_key="first_name"
                          key="first_name"
                          errors={errors}
                        />
                      </Form.Group>
                      <Form.Group className="edit-form">
                        <Form.Label>Last Name * :</Form.Label>
                        <Form.Control
                          type="text"
                          name="last_name"
                          onChange={onChange}
                          value={formData.last_name}
                          required
                          placeholder="Last Name*"
                        />
                        <ShowError
                          current_key="last_name"
                          key="last_name"
                          errors={errors}
                        />
                      </Form.Group>
                      <Form.Group className="edit-form">
                        <Form.Label>Phone Number * :</Form.Label>
                        <PhoneInput
                          className="form-control"
                          autoComplete="tel"
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          smartCaret={true}
                          international={true}
                          defaultCountry={defaultCountry}
                          addInternationalOption={false}
                          required
                          value={formData?.iute164_phone}
                          name="phone"
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />
                        <ShowError
                          current_key="phone"
                          key="phone"
                          errors={errors}
                        />
                      </Form.Group>
                      <Form.Group className="edit-form">
                        <Form.Label>Email * :</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          onChange={onChange}
                          value={formData.email}
                          required
                          placeholder="Email*"
                        />
                        <ShowError
                          current_key="email"
                          key="email"
                          errors={errors}
                        />
                      </Form.Group>
                      {formData.email !== formData.old_email ? (
                        <Form.Group className="edit-form">
                          <Form.Label>Password * :</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            onChange={onChange}
                            value={formData.password}
                            required={formData.email !== formData.old_email}
                            placeholder="Password*"
                          />
                          <ShowError
                            current_key="password"
                            key="password"
                            errors={errors}
                          />
                        </Form.Group>
                      ) : null}
                      <Col md="12" className="text-end mt-2">
                        <button
                          className="btn btn-secondary me-2"
                          type="button"
                          onClick={resetForm}
                        >
                          Cancel
                        </button>
                        <Button type="submit" className="btn btn-primary ml-2">
                          Update Profile
                        </Button>
                      </Col>
                    </Form>
                  </div>
                )}
              </div>
            </Col>
            <Col md="12" lg="6">
              <div className="stepOne">
                <div className="infoheader">
                  <h3>Reset Password</h3>
                </div>
                <div className="infoform">
                  <Form onSubmit={resetPasswordHandler}>
                    <Row>
                      <Col md="12">
                        <div className="form-group">
                          {resetPasswordFormData.current_password == "" &&
                            <Tooltip anchorSelect=".my-anchor-element1" place="bottom">
                              Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                            </Tooltip>
                          }
                          <input
                            type={(currPassword) ? "text" : "password"}
                            name="current_password"
                            value={resetPasswordFormData.current_password}
                            placeholder="Current Password"
                            required
                            onChange={onChangeResetPassword}
                            className="form-control my-anchor-element1"
                          />
                          <span className='viewpass' onClick={togglePassword} data-id="current_password" >{(currPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                          <ShowError
                            current_key="current_password"
                            key="current_password"
                            errors={errors}
                          />
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group special_pass">
                          {resetPasswordFormData.new_password == "" &&
                            <Tooltip anchorSelect=".my-anchor-element2" place="bottom">
                              Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                            </Tooltip>
                          }
                          <input
                            type={(passwordShown) ? "text" : "password"}
                            name="new_password"
                            value={resetPasswordFormData.new_password}
                            required
                            placeholder="New Password"
                            onChange={onChangeResetPassword}
                            className="form-control my-anchor-element2"
                          />
                          <span className='viewpass' onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                          {/* <ShowError
                            current_key="new_password"
                            key="new_password"
                            errors={errors}
                          /> */}
                          {errors && errors?.new_password !== undefined &&
                            <div id="message">
                              <FormText style={{ fontSize: "10px" }} className='text-danger'> Password requirements:</FormText>
                              <FormText>
                                <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                              </FormText>
                            </div>
                          }
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                          {resetPasswordFormData.confirm_password == "" &&
                            <Tooltip anchorSelect=".my-anchor-element3" place="bottom">
                              Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                            </Tooltip>
                          }
                          <input
                            type={(confirmPassword) ? "text" : "password"}
                            name="confirm_password"
                            required
                            value={resetPasswordFormData.confirm_password}
                            placeholder="Confirm Password"
                            onChange={onChangeResetPassword}
                            className="form-control my-anchor-element3"
                          />
                          <span className='viewpass' onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                          <ShowError
                            current_key="confirm_password"
                            key="confirm_password"
                            errors={errors}
                          />
                        </div>
                      </Col>
                      <Col md="12" className="text-end">
                        <Button type="submit">Reset Password</Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            {authUser?.userType === 3 ?
              <Col md="12" lg="6">
                <div className="stepOne">
                  <div className="infoheader">
                    <h3>Business Profile Information</h3>
                  </div>

                  <div className="infoform">
                    <Row>
                      <Col md="12">
                        <p>
                          <label>Business Name :</label>
                          {authUser?.dealer_business_info?.business_name}
                        </p>
                        <p>
                          <label>Business Email :</label>
                          {authUser?.dealer_business_info?.business_email}
                        </p>
                        <p>
                          <label>License No :</label> {authUser?.dealer_business_info?.business_license_no}
                        </p>
                        <p>
                          <label>Business Model :</label> {authUser?.dealer_business_info?.business_model_description}
                        </p>
                        <p>
                          <label>Annual Sales :</label>
                          {authUser?.dealer_business_info?.annual_sales_volume}
                        </p>
                        <p>
                          <label>dealer Type :</label>
                          {authUser?.dealer_business_info?.dealer_type}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div style={{ fontSize: "14px" }}>*Please contact Kinetixx support at <a href="mailto:support@kinetixxgolf.com">support@kinetixxgolf.com</a> to change your business information.</div>
                </div>
              </Col> : null}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Profile;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifyEmails: [],
  value: '',
  error: null,
};

export const notifyEmailSlice = createSlice({
  name: 'notifyEmail',
  initialState,
  reducers: {
    addEmail: (state, { payload }) => {
      state.notifyEmails = [...state.notifyEmails, ...payload];
      state.value = '';
    },
    onEmailInput: (state, { payload }) => {
      state.value = payload;
      state.error = null;
    },
    onDeleteEmail: (state, { payload }) => {
      state.notifyEmails = state.notifyEmails.filter(
        (email) => email !== payload
      );
    },
    clearEmails: (state) => {
      state.notifyEmails = [];
      state.value = '';
      state.error = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const { addEmail, onEmailInput, onDeleteEmail, clearEmails, setError } =
  notifyEmailSlice.actions;

export const getNotifyEmails = (state) => state.notifyEmail.notifyEmails;
export const getEmail = (state) => state.notifyEmail.value;

export default notifyEmailSlice.reducer;

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import { useGetSiteDataQuery } from "../../../redux-services/apis/siteDataApi";
import Product from "./product";
import ProductCategoryList from './productCategoryList';
import ProductFilter from "./productFilter";
import { useGetDataBySlugQuery } from '../../../redux-services/apis/technologyApi';
import Spiner from '../../../components/Layouts/Spiner';


function ProductList({ type }) {
  const { data } = useGetSiteDataQuery();
  const location = useLocation();
  const [metaDetails, setMetaDetails] = useState({});
  const parts = location?.pathname?.split('/');
  const category = parts[1];
  const [path, setPath] = useState(location?.pathname?.split('/')?.[3]);
  const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
    slug: category
  });

  useEffect(() => {
    setMetaDetails(getDataBySlug?.response[0]);
  }, []);

  useEffect(() => {
    const parts = location?.pathname?.split('/');
    if (parts && parts[3] !== path) {
      setPath(parts[3]);
    }
  }, [location, path]);

  return (
    isLoading ? (
      <Spiner />
    ) : (
      <>
        {data &&
          <section className="store-banner">
            <Container>
              {metaDetails ? (
                <Row className='align-items-center'>
                  <Col sm="5" className='text-center'>
                    <img src={metaDetails?.banner_image ? metaDetails?.banner_image : "/images/store-man.png"} alt='banner Image' className='img-fluid' />
                  </Col>
                  <Col sm="7">
                    <div className='headingContent'>
                      <h2>{metaDetails?.status == 1 && metaDetails?.page_title ? metaDetails?.page_title : null}</h2>
                      <h4>{metaDetails?.status == 1 && metaDetails?.page_subtitle ? metaDetails?.page_subtitle : null}</h4>
                    </div>
                  </Col>
                </Row>) :
                <Row className='align-items-center'>
                  <Col sm="5" className='text-center'>
                    <img src="/images/store-man.png" alt='#' className='img-fluid' />
                  </Col>
                  <Col sm="7">
                    <div className='headingContent'>
                      <h2>KINETIXX<sup>R</sup> loaded {category === "shafts" ? "shaft" : "gear"} {((path !== "all") && (path.split("")?.[path.length - 1] !== "s")) ? `${path}s` : path}</h2>
                      <h4>Featuring the worlds only pre-loaded vacuum cured, table rolled and visible fiber structural technologies. </h4>
                    </div>
                  </Col>
                </Row>
              }
            </Container>
          </section>
        }
        <section className='pageheading storebanner'>
          <div className='mainsection'>
            <section className='productlist-outer'>
              <Container>
                <ProductFilter />
                <Row>

                  <ProductCategoryList type={type} />
                  <Product type={type} />
                </Row>
              </Container>
            </section>
          </div>
        </section>
      </>
    )
  );
}

export default ProductList;

export const USER_TYPE = {
  ADMIN: 1,
  CUSTOMER: 2,
  DEALER: 3,
  NON_REGISTERED: 4,
  STAFF: 5,
};

export const PAYMENT_STATUS_CHECK = [1, 3, 4, 5, 6, 7, 8]

export const ORDER_STATUS = {
  PENDING_PAYMENT: 1,
  PAID: 2,
  SHIPPED: 3,
  CANCELLED: 4,
  PROCESSING: 5,
  BUILD_SHAFT: 6,
  ON_HOLD: 7,
};

export const VIEW_ORDER_ORDER_STATUS = {
  1: 'PENDING PAYMENT',
  2: 'PAID',
  3: 'SHIPPED',
  4: 'CANCELLED',
  5: 'PROCESSING',
  6: 'BUILD SHOP',
  7: 'ON HOLD',
  8: 'DELIVERED',
  9: 'RETURN INITIATED',
  10: 'RETURNED',
  11: 'REFUNDED',
  12: 'PARTIALLY SHIPPED'
};

export const SHIPPO_ADDRESS_FROM = {
  name: 'Kinetixx',
  company: 'KINETIXX TECHNOLOGY INC.',
  street1: '1060 Calle Negocio',
  street2: 'Suite E',
  city: 'San Clemente',
  state: 'CA',
  zip: '92673',
  country: 'USA',
  // "ccode": "+1",
  phone: '+19494410154',
  email: 'admin@yopmail.com'
};

export const AUTH_TAG = 'AUTH_TAG';
export const CARTDETAIL_TAG = 'CARTDETAIL_TAG';
export const DO_PAYMENT = 'DO_PAYMENT';
export const SITEDATA_TAG = 'SITEDATA_TAG';
export const PRODUCTDETAIL_TAG = 'PRODUCTDETAIL_TAG';
export const USER_ADDRESS = 'USER_ADDRESS';
export const USER_ORDER = 'USER_ORDER';
export const DEALER_TAG = 'DEALER_TAG';
export const HOME_TAG = 'HOME_TAG';
export const CMS_TAG = 'CMS_TAG';
export const NEWS_TAG = 'NEWS_TAG';
export const RESOURCE_TAG = 'RESOURCE_TAG';
export const INQUIRY_TAG = 'INQUIRY_TAG';

export const DATE_TIME_OPTIONS = {
  dateStyle: 'short',
  timeStyle: 'short',
  hour12: true,
};

export const VIEW_ORDER_STATUS = [
  { key: 'Placed', showIcon: false, status: 2, index: 0 },
  { key: 'Build Shop', showIcon: true, status: 6, index: 1 },
  { key: 'Processing', showIcon: true, status: 5, index: 2 },
  { key: 'Shipped', showIcon: true, status: 3, index: 3 },
  { key: 'Delivered', showIcon: true, status: 8, index: 4 },
];

export const OrderStatus = {
  2: [0],
  6: [0, 1],
  5: [0, 1, 2],
  3: [0, 1, 2, 3],
  8: [0, 1, 2, 3, 4],
};

export const ProductType = "others";
export const envData = {
  PAYPAL_CLIENT_ID: 'ASb5nMx1zunI7ntGFRFgrAIMxUtJM8c0Cwz87kSmxI-XoFTbwJIItT1WXXTxQ3zq1WCEpucRpIhEa-bb'
}

export const ORDER_LIST_PAYMENT_METHOD = {
  1: 'Card On File',
  2: 'Cheque',
  3: "ACH/Bank transfer",
  4: "Cash",
  5: "Others",
  6: "PayPal",
  7: "Credit Card",
};

export const ORDER_LIST_PAYMENT_STATUS = {
  1: 'PENDING',
  2: 'PAID',
  3: "NET05",
  4: "NET15",
  5: "NET30",
  6: "NET45",
  7: "NET60",
  8: "NET90",
};

export const totalBeforeTax = (couponCode, fittingTotalAmount, productTotalAmount, couponDiscount) => {
  let productTotal = Number((fittingTotalAmount + productTotalAmount).toFixed(2));
  if (isNaN(couponCode) || (Number(couponDiscount) <= productTotal)) {
    let productAmount = Number(((fittingTotalAmount + productTotalAmount) - couponDiscount).toFixed(2));
    return (productAmount).toFixed(2);
  }
  return Number(productTotal).toFixed(2);
};
export const productTotal = (productTotalAmount, fittingTotalAmount) => {
  let productTotal = Number((fittingTotalAmount + productTotalAmount).toFixed(2));
  return productTotal;
};

export const amountDue = (grandTotal, paidAmount, refundedAmount = 0) => {
  const refunded = (Number(grandTotal ?? 0) + Number(refundedAmount ?? 0))

  if (Number(refunded) > Number(paidAmount)) {
    return (Number(refunded ?? 0) - Number(paidAmount ?? 0)).toFixed(2)
  }
  return Number(0).toFixed(2);
}
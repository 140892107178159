import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetCartQuery } from '../../redux-services/apis/cartApi';
import { getIsAuthenticated } from '../../redux-services/slices/authSlice';
import CheckoutSideMenu from './CheckoutSideMenu';
import Information from './Information';
import Shipping from './Shipping';
import { getSelectedShippingRate } from '../../redux-services/slices/cartSlice';

function Checkout() {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const selectedShippingRate = useSelector(getSelectedShippingRate);
  const [cartData, setCartData] = useState(null);

  const { isSuccess, data } = useGetCartQuery(
    selectedShippingRate?.totalCharges
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      if (data.response.products.length < 1) {
        navigate('/cart');
      }
      setCartData(data.response);
    }
  }, [data]);

  return (
    <>
      <section className="informations">
        <Container>
          <Row>
            {!isAuthenticated ? (
              <Information cartData={cartData} />
            ) : (
              <Shipping cartData={cartData} />
            )}
            <CheckoutSideMenu cartData={cartData} />
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Checkout;

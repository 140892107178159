import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsBox } from "react-icons/bs";
import { FiMapPin } from "react-icons/fi";
import { AiOutlineHeart } from "react-icons/ai";
import { MdOutlinePayment } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../redux-services/slices/authSlice';

function YourAccount() {
	const authUser = useSelector(getUserDetails);

	return (
		<>
			<section className="profileDatatop">
				<Container>
					<Row>
						<Col md="12">
							<div className="accountInnner">
								<h2 className='text-center'>Welcome <span>{authUser?.first_name} {authUser?.last_name}</span></h2>
								<div className='backpage'>
								</div>
								<hr />
							</div>
						</Col>
						<Col md="12">
							<div className="profilemaintap">
								<div className="accountseprate">
									<Link to="/profile">
										<span className="spetImg">
											<CgProfile />
										</span>
										<div>
											<h4>Profile</h4>
											<p>Change your profile details and password</p>
										</div>
									</Link>
								</div>
								<div className="accountseprate">
									<Link to="/order">
										<span className="spetImg">
											<BsBox />
										</span>
										<div>
											<h4>Orders </h4>
											<p>Check your order status</p>
										</div>
									</Link>
								</div>

								<div className="accountseprate">
									<Link to="/wishlist">
										<span className="spetImg">
											<AiOutlineHeart />
										</span>
										<div>
											<h4>Wishlist</h4>
											<p>Your curated products</p>
										</div>
									</Link>
								</div>
								<div className="accountseprate">
									<Link to="/save-card">
										<span className="spetImg">
											<MdOutlinePayment />
										</span>
										<div>
											<h4>Payment Methods</h4>
											<p>Your cards for faster checkouts</p>
										</div>
									</Link>
								</div>

								<div className="accountseprate">
									<Link to="/address">
										<span className="spetImg">
											<FiMapPin />
										</span>
										<div>
											<h4>Addresses</h4>
											<p>Your saved addresses for hassle-free checkout</p>
										</div>
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
}

export default YourAccount;

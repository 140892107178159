import { createApi } from '@reduxjs/toolkit/query/react';
import { AUTH_TAG, CARTDETAIL_TAG, CMS_TAG, PRODUCTDETAIL_TAG, SITEDATA_TAG, USER_ORDER } from "../../constants";
import { axiosBaseQuery } from "./AxiosBaseQuery";


export const TAGS = [
    AUTH_TAG,
    CARTDETAIL_TAG,
    SITEDATA_TAG,
    PRODUCTDETAIL_TAG,
    USER_ORDER,
    CMS_TAG
]
export const ApiService = createApi({
    reducerPath: "apiService",
    baseQuery: axiosBaseQuery(),
    tagTypes: TAGS,
    endpoints: (builder) => ({

    })
})

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { BiLock, BiHelpCircle } from 'react-icons/bi';
import { IoIosArrowBack } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  useCreateOrderMutation,
  useGetCartQuery,
} from '../../redux-services/apis/cartApi';
import {
  getAddressListData,
  getBrainTreeData,
  getPackedBoxes,
  getSelectedShippingAddress,
  getSelectedShippingRate,
  getTaxData,
  setSelectedShippingAddress,
  setSelectedShippingRate,
  totalPrice,
} from '../../redux-services/slices/cartSlice';
import {
  getNotifyEmails,
  clearEmails,
  setError,
  getEmail
} from '../../redux-services/slices/notifyEmailsSlice';
import ShowModel from '../Notifications/ShowModel';
import AddressList from '../Shipping/AddressList';
import CheckoutSideMenu from '../Shipping/CheckoutSideMenu';
import ShippingInfo from './ShippingInfo';
import NotifyEmails from '../NotifyEmails/NotifyEmails';
import PayPalCreditCardPayment from './PayPalCreditCardPayment';
import CreditCardForm from './CreditCardForm';
import { getProductSelector, setProductSelector, showModel } from '../../redux-services/slices/siteDataSlice';
import { useGetUserAddressQuery } from '../../redux-services/apis/userApi';
import { getUserDetails } from '../../redux-services/slices/authSlice';

const tokenFields = {
  clientToken: null,
  success: false,
  instance: null,
  payment_method_nonce: null,
  error: ''
}

const Payment = () => {
  const dispatch = useDispatch();

  const paypalRef = useRef(null);
  const productSelector = useSelector(getProductSelector);
  const [loadAddress, setLoadAddress] = useState(true);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [selectedBilling, setSelectedBilling] = useState(null);

  const [isCreditCard, setIsCreditCard] = useState(false);

  const [isBillingAdressSame, setIsBillingAdressSame] = useState(true);

  const [selectedBillingAddress, setBillingAddress] = useState(null);
  const grandTotal = useSelector(totalPrice);

  const shippingAddress = useSelector(getSelectedShippingAddress);
  const shippingRate = useSelector(getSelectedShippingRate);
  const taxData = useSelector(getTaxData);

  const packedBoxes = useSelector(getPackedBoxes);
  const notifyEmails = useSelector(getNotifyEmails);
  const emailField = useSelector(getEmail);

  const navigate = useNavigate();
  const { data: billingAddress } = useGetUserAddressQuery();
  const addressList = useSelector(getAddressListData);
  // const brainTreeData = useSelector(getBrainTreeData);

  const user = useSelector(getUserDetails);

  const [cartData, setCartData] = useState(null);
  const [orderDetail, setOrderDetail] = useState({
    total_amount: 0,
    item_total: 0,
    tax_total: 0,
    shipping: 0,
    discount: 0,
    items: []
  });
  const [addressListData, setAddressListData] = useState(null);
  const { isSuccess, data } = useGetCartQuery(shippingRate?.totalCharges);

  const [createOrder, createOrderResponse] = useCreateOrderMutation();

  const [paymentError, setPaymentError] = useState(null);

  const [onSubmit, setOnSubmit] = useState(false);


  const [addNotifyEmails, setAddNotifyEmails] = useState(false);

  const [tokenDetails, setTokenDetails] = useState(tokenFields);
  const [cardDetail, setCardDetail] = useState({
    cardNumber: '',
    cardName: '',
    expiryDate: '',
    cvc: '',
    isCardSave: true,
    savedCardId: '',
    paymentType: '',
  });

  const setOrderForPayPal = useCallback((tax) => {
    let productFtting = [];
    let productItem = cartData.products.map((item) => {
      const shaftType = (item?.productFitting?.length > 0) ? ' (Customized Shaft)' : '';
      item?.productFitting.filter((o) => ["Adaptors", "Grips"].includes(o.attribute_name)).map((i) => {
        productFtting.push({
          name: `${i.attribute_name.toUpperCase()}: ${i.option_name}, (SKU: ${item.productVariationSku})`,
          unit_amount: {
            currency_code: 'USD',
            value: Number(i.price).toFixed(2)
          },
          quantity: '1',
        })
        return i;
      });

      return {
        name: `${item.product_title} - ${item.categoryName}${shaftType}`,
        unit_amount: {
          currency_code: 'USD',
          value: Number(item.product_price).toFixed(2)
        },
        quantity: item.quantity.toString(),
        sku: item.productVariationSku
      }
    });
    setOrderDetail({
      ...orderDetail,
      total_amount: Number(grandTotal).toFixed(2),
      tax_total: tax.toFixed(2),
      discount: Number(cartData?.discounted_amount ?? 0).toFixed(2),
      item_total: Number(cartData.products.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.price),
        Number(cartData?.totalFitting ?? 0),
      )).toFixed(2),
      shipping: Number(shippingRate?.totalCharges).toFixed(2) ?? 0.0,
      items: [...productItem, ...productFtting]
    })
  })

  useEffect(() => {
    let tax = 0.0;
    if (taxData?.applyTaxRate && cartData) {
      const taxRate = Number(taxData?.tax?.value ?? 0);
      tax = (Number(cartData?.totalCartAmount ?? 0) * taxRate) / 100;
      setOrderForPayPal(tax)
    }
    if (!taxData?.applyTaxRate && cartData) {
      setOrderForPayPal(tax)
    }
  }, [taxData, cartData, grandTotal])

  useEffect(() => {
    if (grandTotal > 0) {
      setIsCreditCard(true)
      setTimeout(() => {
        setIsCreditCard(false)
      }, 500);
    }
  }, [grandTotal])

  useEffect(() => {
    if (!shippingAddress) {
      navigate(-1);
    }
    if (shippingAddress) {
      const address = addressList.find(item => item.id === shippingAddress?.id);
      setSelectedBilling(address);
    }
    //setBillingAddress
    setBillingAddress(shippingAddress?._id);
  }, [shippingAddress, addressList]);

  useEffect(() => {
    if (billingAddress?.response) {
      setAddressListData(billingAddress.response);
    }
  }, [billingAddress]);

  const radioCreditCardHandler = (status) => {
    setIsCreditCard(status);
  };

  const radioBillingAddressHandler = (status) => {
    setIsBillingAdressSame(status);
  };

  const onChangeNotifyEmail = (e) => {
    dispatch(clearEmails());
    setAddNotifyEmails(!addNotifyEmails);
  };

  useEffect(() => {
    if (paymentResponse) {
      submitOrder(paymentResponse)
    }
  }, [paymentResponse])

  useEffect(() => {
    if (onSubmit === true) {
      let payDetail = { ...cardDetail, paymentType: "credit_card" }

      setOnSubmit(false)
      submitOrder(payDetail)
    }
  }, [onSubmit])

  const submitOrder = async (paymentResponse) => {

    let setProduct = {
      ...productSelector,
      sale_closed: true,
    }
    dispatch(setProductSelector(setProduct))
    //vaultManager: true,

    const shippToDifferentAddressChecked =
      shippingAddress?._id !== selectedBillingAddress;

    const isEmail = (email) => {
      return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    };

    if (addNotifyEmails && notifyEmails.length <= 0 && emailField === "") {
      dispatch(setError('Please provide atleast one email.'));
      return null;
    }

    if (addNotifyEmails && notifyEmails.length <= 0 && emailField !== "") {
      if (!isEmail(emailField)) {
        dispatch(setError(`${emailField} is not a valid email address.`));
        return null;
      }
    }
    const finalEmails = notifyEmails.length <= 0 && emailField !== "" ? [emailField] : [...notifyEmails];
    const orderData = {
      paymentResponse,
      currentCart: cartData?.products,
      discounted_amount: cartData?.discounted_amount,
      totalCartAmount: cartData?.totalCartAmount,
      totalFitting: cartData?.totalFitting,
      totalProduct: cartData?.totalProduct,
      coupon: cartData?.coupon
        ? {
          coupon_code: cartData?.coupon.coupon_code,
          coupon_id: cartData?.coupon._id,
          applicable_for: cartData?.coupon.applicable_for,
        }
        : {},
      selectedBillingAddress,
      selectedShippingAddress: shippingAddress?._id,
      shippToDifferentAddressChecked,
      shippingBox: packedBoxes,
      selectedShippingMode: shippingRate,
      deliveryInstruction: cartData?.order_note,
      notifyEmails: finalEmails,
      product_selector_result_id: productSelector?.product_selector_result_id,
      sale_closed: true,
      userDetail: user,
    };


    createOrder(orderData);
  };

  useEffect(() => {
    if (createOrderResponse.isSuccess) {
      navigate({
        pathname: '/thank-you',
        search: `?order_id=${createOrderResponse.data?.response?.order_id}`,
      });
      dispatch(clearEmails());
    }
    if (createOrderResponse.isError === true) {
      dispatch(
        showModel({
          isOpen: true,
          modelType: 'danger',
          body: createOrderResponse.error.data.message,
        })
      );
    }
  }, [createOrderResponse]);

  useEffect(() => {
    if (isSuccess) {
      if (data.response.products.length < 1) {
        navigate('/cart');
      }
      setCartData(data.response);
    }
  }, [data]);


  const handleClick = () => {
    paypalRef.current.handleClick();
  };

  return (
    <>
      <section className="informations">
        <Container>
          <Row>
            <Col md="12">
              <div className="breadcrumbOuter">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/cart">Cart</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    {' '}
                    <Link to="/checkout">Shipping</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Payment</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <ShowModel />
            </Col>
            <Col md={12} lg={8}>
              <ShippingInfo />
              <div className='custom_check'>
                Recieve Order related notifications on multiple email IDs.
                <input
                  type="checkbox"
                  id="notifyEmail"
                  name="notifyEmail"
                  value={addNotifyEmails}
                  checked={addNotifyEmails}
                  onChange={(e) => onChangeNotifyEmail(e)}
                  style={{ height: '25px', width: '25px', marginRight: '10px' }}
                />
                <span class="check_indicator">&nbsp;</span>

              </div>
              {addNotifyEmails ? <NotifyEmails /> : ''}
              <div className="contactinfo">
                <div className="Contact-informations">
                  <div className="contacttop">
                    <div>
                      <h5>Payment</h5>
                      <p>All transactions are secure and encrypted.</p>
                    </div>
                  </div>
                  <ul className='addressList'>
                    <li className='cardouter'>
                      <input type="radio" id="html" checked={!isCreditCard} name="fav_language" value="HTML" onClick={(e) => radioCreditCardHandler(false)} />
                      <label for="html">
                        <span className='fulladdress'> Paypal</span>
                      </label>
                    </li>
                    <li className='cardouter'>
                      <input type="radio" id="html" checked={isCreditCard} name="fav_language" value="HTML" onClick={(e) => radioCreditCardHandler(true)} />
                      <label for="html">
                        <span className='fulladdress'> Credit card</span>
                        <span><img src="/images/visa.png" alt='#' /></span>
                      </label>
                    </li>
                    {
                      (isCreditCard && grandTotal > 0) && <CreditCardForm
                        paypalRef={paypalRef}
                        setCardDetail={setCardDetail}
                        cardDetail={cardDetail}
                        setPaymentError={setPaymentError}
                        setOnSubmit={setOnSubmit}
                      />
                      /*  (isCreditCard && grandTotal > 0) && <PayPalCreditCardPayment
                         paypalRef={paypalRef}
                         setPaymentResponse={setPaymentResponse}
                         selectedBilling={selectedBilling}
                         isCreditCard={isCreditCard}
                         amount={grandTotal}
                       /> */
                    }
                  </ul>
                </div>
                <div className="Contact-informations">
                  <div className="contacttop">
                    <div>
                      <h5>Billing address</h5>
                      <p>
                        Select the address that matches your card or payment
                        method.
                      </p>
                    </div>
                  </div>
                  <ul className="addressList">
                    <li className="cardouter">
                      <input
                        type="radio"
                        id="shipping_address"
                        name="billing_address"
                        checked={isBillingAdressSame}
                        onChange={(e) => radioBillingAddressHandler(true)}
                      />
                      <label htmlFor="shipping_address">
                        <span className="fulladdress">
                          {' '}
                          Same as shipping address
                        </span>
                        <span></span>
                      </label>
                    </li>
                    <li className="cardouter">
                      <input
                        type="radio"
                        id="different_address"
                        name="billing_address"
                        checked={!isBillingAdressSame}
                        onChange={(e) => radioBillingAddressHandler(false)}
                      />
                      <label htmlFor="different_address">
                        <span className="fulladdress">
                          {' '}
                          Use a different billing address
                        </span>
                        <span></span>
                      </label>
                    </li>
                  </ul>
                  {!isBillingAdressSame && (
                    <AddressList
                      calculateShipping={false}
                      setAddressId={setBillingAddress}
                      addressId={selectedBillingAddress}
                      addressList={addressListData}
                    />
                  )}
                </div>
                <div className="Contact-informations">
                  <Row>
                    <Col md="12">
                      <div className="d-flex justify-content-between align-items-center mt-1 mb-3">
                        <Link to="/checkout" className="returnshopping">
                          {' '}
                          <IoIosArrowBack /> Return to Shipping
                        </Link>
                        {/* {tokenDetails.clientToken && <Button onClick={payNow}>Pay Now</Button>} */}
                        {
                          (!isCreditCard && grandTotal > 0 && Number(orderDetail.total_amount) > 0) && <PayPalCreditCardPayment
                            paypalRef={paypalRef}
                            setPaymentResponse={setPaymentResponse}
                            isCreditCard={isCreditCard}
                            orderDetail={orderDetail}
                          />
                        }
                        {isCreditCard && <Button onClick={handleClick}>Pay Now</Button>}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <CheckoutSideMenu cartData={cartData} />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Payment;

import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux"
import { FiSearch } from 'react-icons/fi';
import whitePng from "../../img/white-BG.jpg";
import { Link, useParams, useNavigate } from "react-router-dom";
import Spiner from '../../components/Layouts/Spiner';
import ImageGallery from 'react-image-gallery';
import { useGetDataBySlugQuery } from '../../redux-services/apis/technologyApi';
import { useGetAllResourceDataQuery } from '../../redux-services/apis/resourceApi';
import { getUserDetails } from "../../redux-services/slices/authSlice";

const ResourceList = () => {
    const user = useSelector(getUserDetails);
    const [techData, setTechData] = useState(null);
    const [resourceData, setResourceData] = useState(null);
    const [techList, setTechList] = useState({
        query: '',
        list: []
    });
    const [search, setSearch] = useState('');
    const params = useParams();
    const navigate = useNavigate();
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: "resources",
    });

    const { data: getAllResourceData } = useGetAllResourceDataQuery(user);

    useEffect(() => {
        console.log(user, "USER");
        if (user?.userType === 2) {
            navigate('/');
        }
    }, [user])

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setTechData(getDataBySlug?.response[0])
        }
        if (getAllResourceData?.status && getAllResourceData.response) {
            setResourceData(getAllResourceData.response);
        }

    }, [getDataBySlug, getAllResourceData]);

    const renderVideo = useCallback((item) => {
        return (
            <div>
                {
                    <div className="video-wrapper">
                        <iframe
                            width="500"
                            height="400"
                            src={`${item.embedUrl}?autoplay=1&showinfo=0`}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                }
            </div>
        );
    }, []);

    const handleSearch = useCallback(
        (e) => {
            setSearch(e.target.value.trimStart());
            let delayTimer;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
                const results = resourceData.filter(post => {
                    if (e.target.value.trimStart() === "") return resourceData
                    return post.article_title.toLowerCase().includes(e.target.value.toLowerCase())
                })
                setTechList({
                    query: e.target.value,
                    list: results
                })
            }, 750);
        }, [search]);

    return (
        isLoading ? (
            <Spiner />
        ) : (
            <>
                {techData && techData?.status == 1 && techData != null ? (
                    <section className='InnerPage'>
                        <img src={techData.banner_image} alt='#' />
                        <div className='banner-contentInner'>
                            <h2>{techData?.page_title}</h2>
                            <h4>{techData?.page_subtitle}</h4>
                        </div>
                    </section>
                ) : (
                    <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                )}
                {resourceData?.length > 0 ? (
                    <section className='facility-outer NewsMedia'>
                        <Container className="">
                            <Row>
                                <Col md="4" className='ms-auto'>
                                    <div className='form-group'>
                                        <div className='searchdealers'>
                                            <input
                                                type="text"
                                                placeholder='Search Resources'
                                                className='form-control'
                                                value={search}
                                                onChange={handleSearch}
                                            />
                                            <Button><FiSearch /></Button>
                                        </div>
                                    </div>
                                </Col>
                                {resourceData && (techList.query === "" ? resourceData : techList?.list).map((techItems, index) =>
                                (techItems?.status == 1 && <Col md="12">
                                    <ul className='mediaOuter'>
                                        {/* <div className='mediapanel'>
                                            <Link to={`/resources/${techItems?.article_slug}`}>
                                                {techItems?.downloads[0]?.original && (techItems?.downloads[0]?.original.includes(".jpg") || techItems?.downloads[0]?.original.includes(".png") || techItems?.downloads[0]?.original.includes(".jpeg")) ?
                                                    <img src={techItems?.downloads[0]?.original} className='img-fluid' />
                                                    : <img src={whitePng} className='img-fluid' />}
                                            </Link>
                                        </div> */}
                                        <li className='resource-list'>
                                            <h4 style={{ marginBottom: "0px" }}><Link to={`/resources/${techItems?.article_slug}`}>{techItems?.article_title}</Link></h4>
                                            <p>{techItems?.article_subtitle}</p>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: (techItems?.description.slice(0, 250)),
                                                }}
                                                style={{ color: "black" }}
                                            />
                                            <Link to={`/resources/${techItems?.article_slug}`} className='btn btn-primary'>Read More</Link>
                                            <hr />
                                        </li>
                                    </ul>
                                </Col>)
                                )}
                                {(techList.query !== "" && techList.list.length == 0) && <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>}
                            </Row>
                        </Container>
                    </section>
                ) : (
                    <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                )}
            </>

        )
    );
}

export default ResourceList;

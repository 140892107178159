import { createSlice } from "@reduxjs/toolkit";
import { SiteDataAPI } from "../apis/siteDataApi";

const initialState = {
  products: [],
  filters: {
    textSearch: '',
    category: '',
    sortBy: 'alpha_1'
  },
  filteredProducts: [],
  gearFilteredProducts: [],
  productDetails: {},

}

const ProductDataSlices = createSlice({
  name: "productData",
  initialState,
  reducers: {
    setFilterData: (state, { payload }) => {
      let products = state.products;
      if (payload.textSearch !== '') {
        products = products.filter((product) => product.product_title.toLowerCase().includes(payload.textSearch.toLowerCase()));
      }

      if (payload.sortBy !== '') {
        if (payload.sortBy === 'price_1') {
          products = products.sort(
            (p1, p2) => (p1.discounted_price?.$numberDecimal ? p1.discounted_price?.$numberDecimal : Math.min(...p1?.sale_price?.filter((number) => number > 0)) !== Infinity || 0 ? Math.min(...p1?.sale_price?.filter((number) => number > 0)) : p1.msrp_price)
              - (p2.discounted_price?.$numberDecimal ? p2.discounted_price?.$numberDecimal : Math.min(...p2?.sale_price?.filter((number) => number > 0)) !== Infinity || 0 ? Math.min(...p2?.sale_price?.filter((number) => number > 0)) : p2.msrp_price)
          );
        }
        if (payload.sortBy === 'price_2') {
          products = products.sort(
            (p1, p2) => (p2.discounted_price?.$numberDecimal ? p2.discounted_price?.$numberDecimal : Math.min(...p2?.sale_price?.filter((number) => number > 0)) !== Infinity || 0 ? Math.min(...p2?.sale_price?.filter((number) => number > 0)) : p2.msrp_price)
              - (p1.discounted_price?.$numberDecimal ? p1.discounted_price?.$numberDecimal : Math.min(...p1?.sale_price?.filter((number) => number > 0)) !== Infinity || 0 ? Math.min(...p1?.sale_price?.filter((number) => number > 0)) : p1.msrp_price)
          );
        }
        if (payload.sortBy === 'alpha_1') {
          products = products.sort((a, b) => {
            const regex = /(\d+)g/;
            const nameA = a.product_title.toLowerCase().replace(regex, '').trim();
            const nameB = b.product_title.toLowerCase().replace(regex, '').trim();

            if (nameA !== nameB) {
              return nameA.localeCompare(nameB);
            } else {
              const numA = a.product_title.toLowerCase().match(regex) ? parseInt(a.product_title.toLowerCase().match(regex)[1]) : 0;
              const numB = b.product_title.toLowerCase().match(regex) ? parseInt(b.product_title.toLowerCase().match(regex)[1]) : 0;
              return numA - numB;
            }
          });
        }
        if (payload.sortBy === 'alpha_2') {
          products = products.sort((a, b) => {
            const regex = /(\d+)g/;
            const nameA = a.product_title.toLowerCase().replace(regex, '').trim();
            const nameB = b.product_title.toLowerCase().replace(regex, '').trim();

            if (nameA !== nameB) {
              return nameB.localeCompare(nameA);
            } else {
              const numA = a.product_title.toLowerCase().match(regex) ? parseInt(a.product_title.toLowerCase().match(regex)[1]) : 0;
              const numB = b.product_title.toLowerCase().match(regex) ? parseInt(b.product_title.toLowerCase().match(regex)[1]) : 0;
              return numB - numA;
            }
          });
        }

      }
      if (payload.category !== '' && payload.category !== "all") {
        products = products.filter((product) => product.categoryId === payload.category);
      }

      state.filteredProducts = products.filter(cat => cat.categoryType === "SHAFTS");
      state.gearFilteredProducts = products.filter(cat => cat.categoryType === "OTHER");

      if (!payload.category || payload.category === "") {
        state.filters = { ...payload, category: "all" }
      } else {
        state.filters = payload
      }

      // state.filters
    },
    setFittingData: (state, { payload }) => {
      state.productDetails[payload.id] = payload.fittingData;
    }
  },
  extraReducers(builder) {
    builder.addMatcher(
      SiteDataAPI.endpoints.getSiteData.matchFulfilled,
      (state, { payload }) => {
        if (payload.status) {
          const { products } = payload.response;
          state.products = products;
          state.filteredProducts = products.filter(cat => cat.categoryType === "SHAFTS");
          state.gearFilteredProducts = products.filter(cat => cat.categoryType === "OTHER");
        }
      }
    );

  },
});

const { reducer, actions } = ProductDataSlices;

export const { setFilterData, setFittingData } = actions;
export const getProductsList = state => state.productData.products;
export const getFilterList = state => state.productData.filters;
export const getFilteredProducts = state => state.productData.filteredProducts;
export const getGearFilteredProducts = state => state.productData.gearFilteredProducts;


export default reducer;
import React, { useEffect, useState, createRef } from 'react';
import { Container, Row, Col, Button, Form, FormText } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ShowError from "./Notifications/ShowError";
import { useDoRegisterMutation } from "../redux-services/apis/authApi";
import { getPreviousLocation } from "../redux-services/slices/authSlice";
import ReCAPTCHA from "react-google-recaptcha";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { RECAPTCHA_SITE_KEY } from '../config';

function Register() {

  let navigate = useNavigate();
  const recaptchaRef = createRef();

  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  const { first_name, last_name, email, password, confirm_password } = formData;

  const previousLocation = useSelector(getPreviousLocation);

  const [register, registerResponse] = useDoRegisterMutation();

  const onChange = (e) => {
    if (e.target.name == "password" || e.target.name == "confirm_password") {
      setFormData({ ...formData, [e.target.name]: e.target.value?.trimStart() });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
    }
    setErrors({});
  };

  useEffect(() => {

    if (registerResponse.isError) {
      setMessage(registerResponse.error.data.message);
      if (registerResponse.error?.data?.errors.length != undefined) {
        let errorsData = {};
        registerResponse.error?.data?.errors?.forEach(element => {
          errorsData[element.param] = element.msg;
        });
        setErrors(errorsData);
      }
      // registerResponse.data.errors
    } else if (registerResponse.isSuccess) {
      navigate(previousLocation ?? "/");
    }
  }, [registerResponse]);

  const onReCAPTCHAChange = async (captchaCode) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      setMessage("Please Select the  Recaptcha")
      return false;
    } else {
      onSubmit(captchaCode)
    }
  }

  const generateCaptach = async (e) => {
    e.preventDefault();
    if (password !== confirm_password) {
      setErrors(err => ({ ...err, confirm_password: "Password do not match" }));
    }
    recaptchaRef.current.execute();
  }

  const togglePassword = (e) => {
    e.preventDefault();
    if (e.currentTarget.getAttribute("data-id") == "password") {
      setPasswordShown(!passwordShown);
      setConfirmPassword(confirmPassword);
    }
    if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
      setPasswordShown(passwordShown);
      setConfirmPassword(!confirmPassword);
    }
  };

  const onSubmit = (token) => {
    if (!Object.keys(errors).length) {
      register({ first_name, last_name, email, password, token });
    }
    recaptchaRef.current.reset();
  };

  return (

    <>
      <section className="loginOuter">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="7" xl="5">
              <div className="logoOuter">
                <Link to=""><img src="images/logo.svg" /></Link>
              </div>
              <Form onSubmit={generateCaptach}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={RECAPTCHA_SITE_KEY}
                  onChange={onReCAPTCHAChange}
                />
                <div className="loginformOuter">
                  <div className="backpage">
                    <h4>Sign Up</h4>
                    {/* <div>
                      <Link to="/dealer-registration"><h5 style={{ textDecoration: "underline" }}><b>Dealer Registration</b></h5></Link>
                    </div> */}
                  </div>
                  <hr />
                  {(registerResponse.isError || message != '') ? <div className="alert alert-danger">{message}</div> : null}
                  <Row className="align-items-center">
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          value={first_name}
                          name="first_name"
                          onChange={onChange}
                          min="2"
                          maxLength="50"
                          placeholder="First Name*"
                          className="form-control"
                          autoComplete="first_name"
                          required="true"
                          invalid={errors.first_name ? true : false}
                        />
                        <ShowError current_key="first_name" key="first_name" errors={errors} />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          value={last_name}
                          name="last_name"
                          maxLength="25"
                          onChange={onChange}
                          autoComplete="last_name"
                          invalid={errors.last_name ? true : false}
                          placeholder="Last Name"
                          className="form-control"
                        />
                        <ShowError current_key="last_name" key="last_name" errors={errors} />

                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <input
                          type="Email"
                          value={email}
                          name="email"
                          onChange={onChange}
                          autoComplete="email"
                          invalid={errors.email ? true : false}
                          placeholder="Email*"
                          required="true"
                          className="form-control"
                        />
                        <ShowError current_key="email" key="email" errors={errors} />

                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        {password == "" &&
                          <Tooltip anchorSelect=".my-anchor-element1" place="bottom">
                            Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                          </Tooltip>
                        }
                        <input
                          type={(passwordShown) ? "text" : "password"}
                          value={password}
                          name="password"
                          onChange={onChange}
                          autoComplete="password"
                          invalid={errors.password ? true : false}
                          placeholder="Password*"
                          required="true"
                          className="form-control my-anchor-element1"
                        />
                        <span className='viewpass' onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                        {/* <div style={{ wordWrap: "break-word", border: "2px solid black" }}> */}
                        {/* <ShowError current_key="password" key="password" errors={errors} /> */}
                        {/* </div> */}
                      </div>
                      {errors && errors?.password !== undefined &&
                        <div id="message" style={{ marginBottom: '12px' }}>
                          <FormText style={{ fontSize: "10px", marginTop: "-10px" }} className='text-danger'> Password requirements:</FormText>
                          <FormText>
                            <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                          </FormText>
                        </div>
                      }
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        {confirm_password == "" &&
                          <Tooltip anchorSelect=".my-anchor-element2" place="bottom">
                            Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                          </Tooltip>
                        }
                        <input
                          type={(confirmPassword) ? "text" : "password"}
                          value={confirm_password}
                          name="confirm_password"
                          onChange={onChange}
                          autoComplete="confirm_password"
                          invalid={errors.confirm_password ? true : false}
                          placeholder="Confirm Password*"
                          required="true"
                          className="form-control my-anchor-element2"
                        />
                        <span className='viewpass' onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                        <ShowError current_key="confirm_password" key="confirm_password" errors={errors} />

                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <div className='checkouter m-0'>
                          <span className="custom_check">I agree to the <Link to="/privacy" target="_blank">Privacy Policy </Link> and the <Link to="/terms" target="_blank">Terms Of Use.</Link><input type="checkbox" required /><span className="check_indicator">&nbsp;</span></span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="form-group text-center">
                    <Button type="submit">Sign Up</Button>
                  </div>
                  <div className="account">
                    <p>Already have an account ? <Link to="/login">Login</Link></p>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Register;

import { useCallback, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getModelData, showModel } from "../../redux-services/slices/siteDataSlice";

import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

const ShowModel = () => {

    const modelData = useSelector(getModelData)

    const dispatch = useDispatch();

    const toggle = useCallback(() => {
        dispatch(showModel({}));
    })

    useEffect(() => {
        if (modelData?.isOpen) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }, [modelData])

    return (
        modelData?.isOpen && <Alert key={modelData?.modelType} variant={modelData?.modelType} className={modelData?.modalClass === 'dealer' ? 'modalClass': '' } onClose={toggle} dismissible>
            <Alert.Heading>{modelData?.title}</Alert.Heading>
            <p>
               {(modelData?.modelType === 'success') ? <AiFillCheckCircle /> : <AiFillCloseCircle />} {modelData.body}
            </p>
        </Alert>

    );
}

export default ShowModel;
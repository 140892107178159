import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";

const PaymentLinkExpired = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);
    return <>
        <section className="loginOuter">
            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <div className="thankyou-outer">
                            <img src="images/failure.png" alt="#" />
                            <h3 className="alert alert-success">Your link has been expired! </h3>
                            <h4 className="text-success">Please contact to kinetixx customer care.</h4>
                            <Link to="/order" className="btn btn-primary">View Orders</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
}

export default PaymentLinkExpired;
import { useEffect, useState } from "react";
import { Col, Container, Row, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { Range } from 'react-range';
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { getUserDetails } from "../redux-services/slices/authSlice";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';
//state = { values: [50] };
const DriverSelectorIronSix = () => {
    const { product_category } = useParams();
    const productSelector = useSelector(getProductSelector);
    const dispatch = useDispatch();
    const authUser = useSelector(getUserDetails);
    const [rangeSlider, setRangeSlider] = useState({
        PreferredballFlight: [3]
    });
    
    const [trajectoryText, setTrajectoryText] = useState("Low");
    const location = useLocation();
    const parts = location?.pathname.split("/");
    const endString = parts[parts.length - 1];
    const [cmsData, setCmsData] = useState();
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: endString,
    });

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    useEffect(() => {
        let setProduct = {
            ...productSelector,
            userDetail: authUser,
            questions: { ...productSelector.questions, ball_flight: trajectoryText },
        }
        dispatch(setProductSelector(setProduct))
    }, [trajectoryText])

    useEffect(() => {
        let trajectory1 = rangeSlider.PreferredballFlight[0];
        switch (trajectory1) {
            case 1:
                setTrajectoryText("Low");
                break;
            case 2:
                setTrajectoryText("Mid");
                break;
            case 3:
                setTrajectoryText("High");
                break;
        }
    }, [rangeSlider.PreferredballFlight[0]])

    const now = 100;

    return <>
        <section className="Driver-selecter-outer facility-outer irons-tabs pb-0">
            <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="technology-mat-content text-center"><h2>{cmsData?.page_title}</h2><h4>{cmsData?.page_subtitle}</h4><hr /></div>
                    </Col>
                    <Col md="12">
                        <div className="iron-content text-center">
                            <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                            <p className="questions">QUESTION 7/7</p>
                            <h4>Preferred Ball Flight</h4>
                            <span className="selectone">Please select one.</span>
                            <div className="labes-type">
                                <div className="sliders" id="sliders">
                                    <div className="border-box">
                                        <div className="slider average-dis" id="q1-slider">
                                            <div class="labels">
                                                <div class="start-label extra text-start">Low</div>
                                                <div className="start-label extra">Mid</div>
                                                <div class="start-label extra">High</div>
                                            </div>
                                            <div className="range-outer">
                                                <Range
                                                    step={1}
                                                    min={1}
                                                    max={3}
                                                    values={rangeSlider.PreferredballFlight}
                                                    onChange={(values) => setRangeSlider({ ...rangeSlider, PreferredballFlight: values })}
                                                    renderTrack={({ props, children }) => (
                                                        <div  className={rangeSlider.PreferredballFlight[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div className="inner-label">
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                            </div>
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div className="thumb-dots"
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                height: '42px',
                                                                width: '52px',
                                                            }}
                                                        />
                                                    )}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="back-next">
                                <Link to="/kinetixx-fit-iron-five/iron" >Go Back</Link>
                                <Link to="/product-result" className="btn-primary">Submit</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    </>
}

export default DriverSelectorIronSix;
import { SITEDATA_TAG } from "../../constants";
import { ApiService } from "./apiService";

const URL = "/api/front/config/";

export const SiteDataAPI = ApiService.injectEndpoints({
  endpoints: (builder) => ({
    getSiteData: builder.query(
      {
        query: () => ({
          url: `${URL}/get-site-data`,
          method: "GET",
        }),
        providesTags: [SITEDATA_TAG],
      }
    )
  })
})

export const { useGetSiteDataQuery } = SiteDataAPI;
import VISA_ICON from "../img/VisaCard.png";
import AMERICAN_EXPRESS_ICON from "../img/American-Express.png";
import MASTER_CARD_ICON from "../img/MasterCard.png";
import DISCOVER_ICON from "../img/Discover.png";
import DINERS_CLUB_ICON from "../img/diners.png";
import JCB_ICON from "../img/JCB.png";
export const OTHERCARDS = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
export const AMERICANEXPRESS = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
export const EXPIRYDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const CVC = [/[0-9]/, /\d/, /\d/, /\d/];

export const CARDICON = {
    VISA: VISA_ICON,
    MASTERCARD: MASTER_CARD_ICON,
    DISCOVER: DISCOVER_ICON,
    AMERICAN_EXPRESS: AMERICAN_EXPRESS_ICON,
    DINERS_CLUB: DINERS_CLUB_ICON,
    JCB: JCB_ICON
};

export const CARDARR = [
    "VISA",
    "MASTERCARD",
    "AMERICAN_EXPRESS",
    "DISCOVER",
    "DINERS_CLUB",
    "JCB"
];

export const COLORARR = [
    ["#20bdff", "#5433ff"],
    ["#ff4b1f", "#ff9068"],
    ["#ffb347", "#ffcc33"],
    ["#D38312", "#A83279"],
    ["#83a4d4", "#b6fbff"],
    ["#fbd3e9", "#bb377d"]
];
import {
  AUTH_TAG,
  CARTDETAIL_TAG,
  DO_PAYMENT,
  USER_ORDER,
} from '../../constants';
import { ApiService } from './apiService';

const BASE_URL = '/api/front';

export const CartAPI = ApiService.injectEndpoints({
  endpoints: (builder) => ({
    addToCart: builder.mutation({
      query: (product) => ({
        url: `${BASE_URL}/cart/add-to-cart`,
        method: 'POST',
        body: {
          ...product,
        },
      }),
      invalidatesTags: [CARTDETAIL_TAG],
    }),
    updateCart: builder.mutation({
      query: (product) => ({
        url: `${BASE_URL}/cart/update-cart`,
        method: 'POST',
        body: {
          ...product,
        },
      }),
      invalidatesTags: [CARTDETAIL_TAG],
    }),
    getCart: builder.query({
      query: (productDetails, is_delete = false) => ({
        url: `${BASE_URL}/cart/get-cart-detail?shippingRate=${productDetails}`,
        method: 'GET',
        body: {
          productDetails,
          is_delete,
        },
      }),
      providesTags: [CARTDETAIL_TAG],
    }),
    applyCoupon: builder.mutation({
      query: (coupon) => ({
        url: `${BASE_URL}/coupon/apply-coupon`,
        method: 'POST',
        body: {
          ...coupon,
        },
      }),
      invalidatesTags: [CARTDETAIL_TAG],
    }),
    removeCoupon: builder.mutation({
      query: () => ({
        url: `${BASE_URL}/coupon/remove-coupon`,
        method: 'POST',
        body: {},
      }),
      invalidatesTags: [CARTDETAIL_TAG],
    }),
    calculateShippingRate: builder.mutation({
      query: (shippingData) => ({
        url: `${BASE_URL}/checkout/calculate-shipping-rates`,
        method: 'POST',
        body: {
          ...shippingData,
        },
      }),
      invalidatesTags: [CARTDETAIL_TAG],
    }),
    createOrder: builder.mutation({
      query: (orderData) => ({
        url: `${BASE_URL}/checkout/add-order`,
        method: 'POST',
        body: {
          ...orderData,
        },
      }),
      invalidatesTags: [CARTDETAIL_TAG, USER_ORDER, "AUTH_TAG"],
    }),
    doPayment: builder.mutation({
      query: (paymentData) => ({
        url: `${BASE_URL}/checkout/payment`,
        method: 'POST',
        body: {
          ...paymentData,
        },
      }),
      invalidatesTags: [DO_PAYMENT],
    }),
    notifyMe: builder.mutation({
      query: (product) => ({
        url: `${BASE_URL}/notify-me/add`,
        method: 'POST',
        body: {
          ...product,
        },
      }),
      invalidatesTags: [DO_PAYMENT],
    }),
    updateCartQuantity: builder.mutation({
      query: (product) => ({
        url: `${BASE_URL}/cart/update-cart-quantity`,
        method: 'POST',
        body: {
          ...product,
        },
      }),
      invalidatesTags: [CARTDETAIL_TAG],
    }),
    thankYouDeatil: builder.mutation({
      query: (product) => ({
        url: `${BASE_URL}/checkout/thank-you`,
        method: 'POST',
        body: {
          ...product,
        },
      }),
      invalidatesTags: [CARTDETAIL_TAG],
    }),
  }),
});

export const {
  useAddToCartMutation,
  useGetCartQuery,
  useApplyCouponMutation,
  useRemoveCouponMutation,
  useUpdateCartMutation,
  useCalculateShippingRateMutation,
  useCreateOrderMutation,
  useDoPaymentMutation,
  useNotifyMeMutation,
  useUpdateCartQuantityMutation,
  useThankYouDeatilMutation,
} = CartAPI;

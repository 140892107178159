
import { AUTH_TAG } from "../../constants";
import { ApiService, TAGS } from "./apiService";
import setAuthToken from "./setAuthToken";


const storeAuth = (response) => {
    if (response.status) {
        if (response.response.otpVerified) {
            localStorage.setItem("userAccessToken", response.response.token);
            localStorage.setItem("user", JSON.stringify(response.response.user));
            setAuthToken(response.response.token);
        }
    }
}

const storeAuthOnRegister = (response) => {
    if (response.status) {
        localStorage.setItem("userAccessToken", response.response.token);
        localStorage.setItem("user", JSON.stringify(response.response.user));
        setAuthToken(response.response.token);
    }
}

const storeSubAuth = (response) => {
    if (response.response.redirect) {
        localStorage.setItem("userAccessToken", response.response.token);
        localStorage.setItem("user", JSON.stringify(response.response.user));
        setAuthToken(response.response.token);
    }
}

export const AuthAPI = ApiService.injectEndpoints({
    endpoints: (builder) => ({
        doLogin: builder.mutation(
            {
                query: (user) => ({
                    url: `/api/auth/verify-otp`,
                    method: "POST",
                    body: user
                }),
                invalidatesTags: TAGS,
                transformResponse: (response, _meta, _arg) => {
                    storeAuth(response);
                    return response;
                }
            }
        ),
        doSubLogin: builder.mutation(
            {
                query: (user) => ({
                    url: '/api/auth/sub-login',
                    method: 'POST',
                    body: user
                }),
                invalidatesTags: TAGS,
                transformResponse: (response, _meta, _arg) => {
                    storeSubAuth(response);
                    return response;
                }
            }
        ),
        sendOtp: builder.mutation(
            {
                query: (user) => ({
                    url: '/api/auth/send-otp',
                    method: 'POST',
                    body: user
                }),
                invalidatesTags: TAGS,
                transformResponse: (response, _meta, _arg) => {
                    return response;
                }
            }
        ),
        getUser: builder.query(
            {
                query: () => ({
                    url: `/api/auth`,
                    method: "GET",
                }),
                providesTags: [AUTH_TAG],
            }
        ),
        doRegister: builder.mutation(
            {
                query: (user) => ({
                    url: `/api/register`,
                    method: "POST",
                    body: user
                }),
                invalidatesTags: TAGS,
                transformResponse: (response, _meta, _arg) => {
                    storeAuthOnRegister(response);
                    return response;
                }
            }
        ),
        forgetPassword: builder.mutation(
            {
                query: (user) => ({
                    url: `/api/front/forget-password`,
                    method: "POST",
                    body: user
                }),
                invalidatesTags: TAGS,
            }
        ),
        checkTokenExpiryTime: builder.query(
            {
                query: ({ id, token }) => ({
                    url: `/api/front/forget-password/${id}/${token}`,
                    method: "GET",
                }),
            }
        ),
        resetPassword: builder.mutation(
            {
                query: (user) => ({
                    url: `/api/front/forget-password/${user.id}`,
                    method: "PUT",
                    body: user
                }),
            }
        ),
    })
})

export const { useDoLoginMutation, useDoSubLoginMutation, useSendOtpMutation, useDoRegisterMutation, useGetUserQuery, useForgetPasswordMutation, useResetPasswordMutation, useCheckTokenExpiryTimeQuery } = AuthAPI;
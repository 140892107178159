import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { Range } from 'react-range';
import convert from 'convert-units';
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';
//state = { values: [50] };
const DriverSelectorIronTwo = () => {
    const { product_category } = useParams();
    const productSelector = useSelector(getProductSelector);
    const [parameter, setParameter] = useState(false);
    const [config, setConfig] = useState('70-80 mph');
    const dispatch = useDispatch();
    const location = useLocation();
    const parts = location?.pathname.split("/");
    const endString = parts[parts.length - 1];
    const [cmsData, setCmsData] = useState();
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: endString,
    });

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    useEffect(() => {
        let setProduct = {
            ...productSelector,
            questions: { ...productSelector.questions, swing_speed: config, metric: parameter },
        }
        dispatch(setProductSelector(setProduct))
    }, [config, parameter])


    const handleClick = useCallback((e) => {
        let data = e.currentTarget.getAttribute("data-id");
        if (parameter && data === '120 mph +') {
            data = data.split('-')
            let first = parseInt(data[0]);
            data = `${Math.trunc(convert(first).from('m/h').to('km/h'))} km/h +`;
        }
        if (parameter && data !== 'Not Sure' && e.currentTarget.getAttribute("data-id") !== '120 mph +') {
            data = data.split('-')
            let first = parseInt(data[0]);
            let second = parseInt(data[1]);
            data = `${Math.trunc(convert(first).from('m/h').to('km/h'))}-${Math.trunc(convert(second).from('m/h').to('km/h'))} km/h`;
        }
        setConfig(data);
    });

    const now = 28.56;

    const onChangeValue = () => {
        setParameter(!parameter);
    }

    return <>
        <section className="Driver-selecter-outer facility-outer irons-tabs pb-0">
            <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="technology-mat-content text-center"><h2>{cmsData?.page_title}</h2><h4>{cmsData?.page_subtitle}</h4><hr /></div>
                    </Col>
                    <Col md="12">
                        <div className="iron-content text-center metricChange">
                            <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                            <div className="metricChange">
                                <div className="changeMetirc">
                                    <span className="custom_check">
                                        Metric Scale
                                        <input
                                            type="checkbox"
                                            checked={parameter}
                                            onChange={onChangeValue}
                                        />
                                        <span className="check_indicator">&nbsp;</span>
                                    </span>
                                    {/* <Link
                                    value={parameter}
                                    onClick={onChangeValue}
                                >
                                    <>{parameter ? "Imperial Scale" : "Metric Scale"} </>
                                </Link> */}
                                </div>
                                <p className="questions">QUESTION 2/7</p>
                                <h4>Average Driver Swing Speed</h4>
                                <span className="selectone">Please select one.</span>
                                <div className="labes-type">
                                    <div className="lab-outer">
                                        <input type="radio" id="configuration1" name="Configuration" value="configuration1" onClick={handleClick} data-id="70-80 mph" defaultChecked />
                                        <label for="configuration1"> {parameter ? `${Math.trunc(convert(70).from('m/h').to('km/h'))} - ${Math.trunc(convert(80).from('m/h').to('km/h'))} km/h` : '70-80 mph'}</label>
                                    </div>
                                    <div className="lab-outer">
                                        <input type="radio" id="configuration2" name="Configuration" value="configuration2" onClick={handleClick} data-id="81-95 mph" />
                                        <label for="configuration2">{parameter ? `${Math.trunc(convert(81).from('m/h').to('km/h'))} - ${Math.trunc(convert(95).from('m/h').to('km/h'))} km/h` : '81-95 mph'}</label>
                                    </div>
                                    <div className="lab-outer">
                                        <input type="radio" id="configuration3" name="Configuration" value="configuration3" onClick={handleClick} data-id="96-105 mph" />
                                        <label for="configuration3">{parameter ? `${Math.trunc(convert(96).from('m/h').to('km/h'))} - ${Math.trunc(convert(105).from('m/h').to('km/h'))} km/h` : '96-105 mph'}</label>
                                    </div>
                                    <div className="lab-outer">
                                        <input type="radio" id="configuration4" name="Configuration" value="configuration4" onClick={handleClick} data-id="106-120 mph" />
                                        <label for="configuration4">{parameter ? `${Math.trunc(convert(106).from('m/h').to('km/h'))} - ${Math.trunc(convert(120).from('m/h').to('km/h'))} km/h` : '106-120 mph'}</label>
                                    </div>
                                    <div className="lab-outer">
                                        <input type="radio" id="configuration5" name="Configuration" value="configuration5" onClick={handleClick} data-id="120 mph +" />
                                        <label for="configuration5">{parameter ? `${Math.trunc(convert(120).from('m/h').to('km/h'))} km/h +` : '120 mph +'}</label>
                                    </div>
                                    <div className="lab-outer">
                                        <input type="radio" id="configuration6" name="Configuration" value="configuration6" onClick={handleClick} data-id="Not Sure" />
                                        <label for="configuration5">Not Sure</label>
                                    </div>
                                </div>
                                <div className="back-next">
                                    <Link to="/kinetixx-fit-iron/iron" >Go Back</Link>
                                    <Link to="/kinetixx-fit-iron-next/iron" className="btn-primary">Next Question</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
}

export default DriverSelectorIronTwo;
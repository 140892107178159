import React, { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Table,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FiSearch, FiChevronDown } from 'react-icons/fi';
import { BsCurrencyDollar } from 'react-icons/bs';
import { AiOutlineRight } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import {
  useDownLoadOrderInvoiceQuery,
  useGetUserOrderQuery,
  useOrderPaymentMutation,
} from '../../../redux-services/apis/userApi';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../../redux-services/slices/authSlice';
import { getProductsList } from '../../../redux-services/slices/siteDataSlice';
import {
  DATE_TIME_OPTIONS,
  OrderStatus,
  VIEW_ORDER_STATUS,
  SHIPPO_ADDRESS_FROM,
  PAYMENT_STATUS_CHECK,
  ORDER_LIST_PAYMENT_STATUS,
} from '../../../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';

const perPage = 10;
const loadMore = {
  page: 1,
  stop: true,
  length: 0,
  totalLength: 0,
  resetOrder: false,
};

const ProductTracking = (props) => {
  const fleetReceipt = "https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/fleet_receipt"
  const shippingStatus = (status) => {
    switch (status) {
      case 1:
        return "Not yet shipped";
      case 2:
        return "Shipped";
      case 3:
        return "DELIVERED";
      default:
        break;
    }
  }
  const returnProduct = useCallback((productItem, productField, type, item) => {
    const product = props?.shippingBox?.products.find(product => product.product_variation_sku === item.product_variation_sku && product.fitting_option.length === item?.fitting_options?.length)

    if (product === undefined && ((type && type.toLowerCase() === "build shop supply product") || (type && type.toLowerCase() === "services"))) {
      return type.toLowerCase() === "services" ? "Service" : "Build Shop Supply";
    }
    if (productField === "fitting_option") {
      if (product === undefined) {
        if (item?.categoryType === "OTHER") {
          return "Gear";
        }
        return item?.fitting_options?.length > 0 ? "Customize Shaft" : "Raw Shaft";
      } else {
        if (product.categoryType === "OTHER") {
          return "Gear";
        }
        return product[productField]?.length > 0 ? "Customize Shaft" : "Raw Shaft";
      }
    }
    if (product === undefined) {
      return item.category_name
    }

    return product[productField]
  })
  return (
    <Modal {...props} backdrop="static" size="xl" >
      <Modal.Header className="border-bottom" >
        <Modal.Title>Track Shipping</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <Row>
          <Table responsive striped="rows" bordered >
            <thead style={{ verticalAlign: 'middle' }}>
              <tr>
                <th rowSpan="2">Shipping Box</th>
                <th colSpan="4" style={{ textAlign: "center" }}>Products Detail</th>
                <th rowSpan="2">Shipping Address</th>
                <th rowSpan="2">Shipping Status</th>
                <th rowSpan="2">Tracking Url</th>
              </tr>
              <tr>
                <th>Order-ID</th>
                <th>Product</th>
                <th>Product Type</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'middle' }}>
              {props?.shippingBox?.orderShippingBox && props?.shippingBox?.orderShippingBox?.map((item) => item.items.map((productItem, index) => ((index === 0 ? <tr key={productItem._id}>
                <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>{"Shippment : "}{item?.box?.name} (KXS-{item?.boxId})</td>
                <td><div>{productItem?.order_id ?? props?.shippingBox?.order_id}</div></td>
                <td >{productItem.product_title} {" - "}{returnProduct(productItem.product_variation_sku, "categoryName", productItem?.category_name, productItem)} {" "} ({productItem.product_variation_sku})</td>
                <td>{returnProduct(productItem.product_variation_sku, "fitting_option", productItem?.category_name, productItem)}</td>
                <td>{productItem.quantity}</td>
                <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                  <p>{item?.shipping_address?.address_line_1}  {item?.shipping_address?.address_line_2}<span className="d-block">{item?.shipping_address?.city}, {item?.shipping_address?.state} - {item?.shipping_address?.zipcode}</span>{item?.shipping_address?.country}</p>
                </td>
                <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"}>
                  {shippingStatus(item?.shipping_box_status)}
                </td>
                <td rowSpan={item.items?.length > 0 ? `${item.items?.length}` : "1"} className="trackPackage">
                  {[2, 3].includes(item?.shipping_box_status) ? (
                    item?.shipping_box_status === 2 ? (<>
                      {(item?.shippingServiceCategory !== 4) && <a className="btn btn-primary" href={item?.easy_post_shipments?.length !== undefined ? item?.easy_post_shipments[0]?.tracking_url : item?.easy_post_shipments?.tracking_url} target={'_blank'}>
                        Track Package
                      </a>}
                      {(item?.shippingServiceCategory === 4 && item?.fleet_receipt) && <a className="btn btn-primary" href={`${fleetReceipt}/${item?.fleet_receipt}`} target={'_blank'}>
                        Download Freight Receipt
                      </a>}
                      {(item?.shippingServiceCategory === 4 && !item?.fleet_receipt) && <Button disabled type="button" className="btn btn-primary" title="fleet reciept not available">Download Freight Receipt</Button>}
                    </>) : <>
                      {(item?.shippingServiceCategory !== 4) && <Button disabled type="button" className="btn btn-primary">Track Package</Button>}
                      {(item?.shippingServiceCategory === 4) && <Button disabled type="button" className="btn btn-primary">Download Freight Receipt</Button>}
                    </>
                  ) : <Link className="btn btn-primary" disabled to="#" title='The order is not yet shipped'> Not yet shipped </Link>}
                </td>
              </tr> : <tr key={productItem.product_variation_sku}>
                <td><div>{productItem?.order_id ?? props?.shippingBox?.order_id}</div></td>
                <td >{productItem.product_title} {" - "}{returnProduct(productItem.product_variation_sku, "categoryName", productItem?.category_name, productItem)} {" "} ({productItem.product_variation_sku})</td>
                <td>{returnProduct(productItem.product_variation_sku, "fitting_option", productItem?.category_name, productItem)}</td>
                <td>{productItem.quantity}</td>
              </tr>
              ))))
              }
            </tbody>

          </Table>

        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col md="12">
          <div className="form-group text-end">
            <Button className="ms-2" onClick={props.toggle}>
              Close
            </Button>
          </div>
        </Col>
      </Modal.Footer>
    </Modal>
  )
}

const HelpPopUp = (props) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Help</Modal.Title>
      </Modal.Header>
      <Modal.Body closeButton>
        <p>
          Please get in touch with Kinetixx Golf support by Phone or email for
          any help regarding your order.
          <br />
        </p>
        <p>
          Contact details are available at below:
          <br />
          Phone: {SHIPPO_ADDRESS_FROM.phone}
          <br />
          Email: {SHIPPO_ADDRESS_FROM.email}
        </p>
        <Col md="12">
          <div className="form-group text-end">
            <Button className="ms-2" onClick={props.onHide}>
              Close
            </Button>
          </div>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

const MyOrder = () => {
  const initialState = {
    search: '',
  };
  const initialFilterState = {
    search: '',
    days_filter: 'all',
  };

  const navigate = useNavigate()

  const [loadMoreData, setLoadMoreData] = useState(loadMore);
  const [orders, setOrders] = useState([]);
  const [productFilterData, setProductFilterData] = useState(initialState);
  const [productFilter, setProductFilter] = useState(initialFilterState);
  const [orderId, setOrderId] = useState('');
  const [downloadData, setDownloadData] = useState([]);

  const { data: downloadInvoice } = useDownLoadOrderInvoiceQuery(
    { orderId },
    {
      skip: !orderId,
    }
  );
  const [orderPayment, resOrderPayment] = useOrderPaymentMutation();
  const { data: orderList } = useGetUserOrderQuery({
    page: loadMoreData.page,
    per_page: perPage,
    search: productFilter.search ?? '',
    days_filter: productFilter.days_filter ?? '',
  });

  const authUser = useSelector(getUserDetails);
  const productList = useSelector(getProductsList);

  const [modalShow, setModalShow] = useState(false);
  const [showTrackingUrl, setsShowTrackingUrl] = useState(false);
  const [productVariations, setProductVariations] = useState([]);
  const [trackingData, setTrackingData] = useState(null);



  const handleClickonPayNow = (paymentId, prevStatus) => {
    orderPayment({
      order_id: paymentId,
      previous_order_status: prevStatus,
      new_order_status: 2
    })
  };

  const checkIsOrderEditCreate = (order) => {
    if (order?.paid_amount?.$numberDecimal > 0) {

      return 2
    }
    return 1;
  }

  useEffect(() => {
    if (downloadInvoice) {
      setDownloadData(downloadInvoice?.response?.data);
      setTimeout(() => {
        document.getElementById('downloadCsv').click();
      }, 100);
    }

    if (resOrderPayment.isSuccess && resOrderPayment.data.status) {
      navigate(`/thank-you?order_id=${resOrderPayment?.data?.response?.order_id}`);
    }
  }, [downloadInvoice, resOrderPayment]);

  useEffect(() => {
    if (loadMoreData.page === 1 && orderList) {
      setOrders([...(orderList?.response?.data ?? [])]);
      setLoadMoreData({
        ...loadMoreData,
        length: orderList?.response?.pagedata?.totalRecord,
        totalLength: orderList?.response?.pagedata?.total_pages,
      });
    } else {
      setOrders((items) => [...items, ...(orderList?.response?.data ?? [])]);
      setLoadMoreData({
        ...loadMoreData,
        length: orders?.length,
        totalLength: orderList?.response?.pagedata?.total_pages,
      });
    }
  }, [orderList]);

  const changeProductFilters = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'days_filter':
        setLoadMoreData({ ...loadMoreData, stop: true });
        setLoadMoreData({ ...loadMoreData, page: 1, stop: true });
        setProductFilter({ ...productFilter, days_filter: value });
      default:
        setLoadMoreData({ ...loadMoreData, page: 1, stop: true });
        setProductFilterData({ ...productFilterData, [name]: value });
    }
  };

  const fetchMoreData = async () => {
    const pageNum = loadMoreData.page + 1;
    setLoadMoreData({ ...loadMoreData, stop: true });
    setLoadMoreData({ ...loadMoreData, resetOrder: false });
    if (pageNum > loadMoreData.totalLength) {
      setLoadMoreData({ ...loadMoreData, stop: false });
      return false;
    }
    setLoadMoreData({ ...loadMoreData, page: pageNum });
  };

  const search = () => {
    setLoadMoreData({ ...loadMoreData, page: 1 });
    setProductFilter({ ...productFilter, search: productFilterData.search });
  };

  const debounceFn = useCallback(
    debounce((searchValue) => {
      setProductFilter({ ...productFilter, search: searchValue });
    }, 1000),
    []
  );

  const searchFilters = (event) => {
    setLoadMoreData({ ...loadMoreData, page: 1, stop: true });
    debounceFn(event.target.value);
  };

  const orderStatusCard = (statusDetail, order_status, order_category, shippingServiceCategory) => {
    let status = 0;
    if ((typeof statusDetail === "object" && statusDetail) && (order_category !== "service_order" && shippingServiceCategory !== 1)) {
      const currentStatus = statusDetail[statusDetail.length - 1];
      status = OrderStatus[currentStatus?.new_order_status];
    } else {
      if (order_category !== "service_order" && shippingServiceCategory !== 1) {
        status = OrderStatus[statusDetail];
      } else {
        if ([9, 10, 11].includes(order_status)) {
          status = OrderStatus[8];
        } else if ([12].includes(order_status)) {
          status = OrderStatus[3];
        } else {
          status = OrderStatus[order_status];
        }
      }

    }
    return (
      <ul className="list-unstyled multi-steps">
        {VIEW_ORDER_STATUS.map((item, index) => (
          <li
            key={item.key}
            id={`step-${index}`}
            className={status && status[index] === index ? 'is-active' : ''}
          >
            {' '}
            {item.showIcon && <AiOutlineRight />} {item.key}
          </li>
        ))}
      </ul>
    );
  };

  const downLoadInvoice = (id) => {
    setOrderId(id);
  };
  const showProductImage = (productId) => {
    const product = productList.find((item) => item._id === productId);
    if (product !== undefined) {
      return product.thumbnail;
    } else {
      return 'images/noproduct.png';
    }
  };

  useEffect(() => {
    if (orders) {
      const list = [];
      const newList = [];
      let finalObj = {};
      list.push(...orders.map(item => item.products[0].product_variation));
      list?.map((a, b) => {
        let array = Object.keys(a ?? {}).map(function (item) {
          return ({ [item]: a[item] });
        })
        array.sort((key) => {
          if (Object.keys(key)[0] == 'Iron #') {
            return Object.keys(key)[0] == 'Iron #' ? -1 : (Object.keys(key)[0] === 'Flex' ? -1 : 1);
          } else {
            return Object.keys(key)[0] === 'Flex' ? -1 : 1;
          }
        })
        for (let i = 0; i < array.length; i++) {
          Object.assign(finalObj, array[i]);
        }
        newList.push(finalObj);
        finalObj = {};
      })
      setProductVariations(newList);
    }
  }, [orders]);

  const openTrackingModel = (openTrackingModel) => {
    setTrackingData(openTrackingModel)
    setsShowTrackingUrl(!showTrackingUrl)
  }

  return (
    <>
      <section className="profileDatatop">
        <HelpPopUp show={modalShow} onHide={() => setModalShow(false)} />
        <ProductTracking show={showTrackingUrl} shippingBox={trackingData} toggle={() => setsShowTrackingUrl(!showTrackingUrl)} />
        <Container>
          <Row>
            <Col md="12">
              <div className="accountInnner ps-0 pe-0">
                <h2>Orders</h2>
                <div className="backpage">
                  <p>{authUser?.first_name} {authUser?.last_name}</p>
                  <div><Link to="/account">Back to Your Account</Link></div>
                </div>
                <hr />
              </div>
            </Col>
            <Col md="12">
              <div className="cartmain">
                <div className="cartheader">
                  <div className="ordereditem">
                    <p>
                      <span>
                        {orderList?.response?.pagedata?.totalRecord} orders
                      </span>{' '}
                      placed in
                    </p>
                    <Form.Select
                      className="form-control"
                      name="days_filter"
                      aria-label="Default select example"
                      onChange={changeProductFilters}
                    >
                      <option value="all">All</option>
                      <option value="3_month">Last 3 Month</option>
                      <option value="6_month">Last 6 Month</option>
                    </Form.Select>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {(orderList && orderList?.response?.length == 0 || orderList?.response?.data?.length == 0) &&
                      <div>
                        <Link className="btn btn-primary" to="/shafts/category/all">
                          Continue Shopping
                        </Link>
                      </div>
                    }
                    <div className="searchProduct">
                      <input
                        type="text"
                        className="form-control"
                        name="search"
                        onChange={searchFilters}
                        placeholder="Search all Order"
                      />
                      <Button onClick={search}>
                        <FiSearch />
                      </Button>
                    </div>
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={loadMoreData.length}
                  next={fetchMoreData}
                  hasMore={loadMoreData.stop}
                  endMessage={
                    <div>
                      {(orderList && orderList?.response?.length == 0 || orderList?.response?.data?.length == 0) &&
                        <span className="text-center d-block fw-bold text-bold mt-3">
                          No Result Found.
                        </span>
                      }
                    </div>
                  }
                >
                  <div>
                    {(orderList && orderList?.response?.length == 0 || orderList?.response?.data?.length == 0) &&
                      <span className="text-center d-block fw-bold text-bold mt-3">
                        No Result Found.
                      </span>
                    }
                  </div>
                  {orders?.length > 0 &&
                    orders?.map((order, index) => (
                      <div key={order._id} className="cartproductMain">
                        <div className="cartlistheader">
                          <div className="cartleftheader">
                            <div className="orderplaced">
                              <p>
                                Order Date
                                <span className="d-block">
                                  {new Intl.DateTimeFormat(
                                    'en-US',
                                    DATE_TIME_OPTIONS
                                  ).format(new Date(order.ordered_on))}
                                </span>
                              </p>
                            </div>
                            <div className="orderplaced">
                              <p>
                                Total{' '}
                                <span className="d-block">
                                  <BsCurrencyDollar />
                                  {order.grand_total.$numberDecimal}
                                </span>
                              </p>
                            </div>
                            <div className="orderplaced">
                              <p>
                                Ship To{' '}
                                <span className="d-block">
                                  <Link to="">
                                    {order.shipping_address[0].name}{' '}
                                    <FiChevronDown />
                                  </Link>
                                </span>
                              </p>
                              <span className="usercontentdetails">
                                <h6>
                                  <Link>
                                    {order?.shipping_address[0]?.name}
                                  </Link>
                                </h6>
                                <address>
                                  <p>
                                    {order?.shipping_address[0]?.address_line_1}
                                    {order?.shipping_address[0]
                                      ?.address_line_2 && (
                                        <span className="d-block">
                                          {
                                            order?.shipping_address[0]
                                              ?.address_line_2
                                          }
                                        </span>
                                      )}
                                    <span className="d-block">
                                      {order?.shipping_address[0]?.city},{' '}
                                      {order?.shipping_address[0]?.state} -{' '}
                                      {order?.shipping_address[0]?.zipcode}
                                    </span>
                                    {order?.shipping_address[0]?.country}
                                  </p>
                                  {/*  {order?.shipping_address?.address && order?.shipping_address?.address.split(",")[0]}, <br></br> {`${ order?.cities[0]?.city } `}, <br></br> {order?.cities[0]?.state} {"-"} {order?.cities[0]?.zipcode} {order?.cities[0]?.country_code} */}
                                </address>
                              </span>
                            </div>
                            {(order?.order_status === 4) &&
                              <div className="orderplaced">
                                <p>
                                  Order Status
                                  <span className="d-block" style={{ fontWeight: '500', color: "#6d2a5f" }}>
                                    Canceled
                                  </span>
                                </p>
                              </div>
                            }
                            {
                              <div className="orderplaced">
                                <p>
                                  Payment Status
                                  <span className="d-block" style={{ fontWeight: '500', color: "#6d2a5f" }}>
                                    {ORDER_LIST_PAYMENT_STATUS[order?.payment_status]}
                                  </span>
                                </p>
                              </div>
                            }
                          </div>
                          <div className="cartlistheaderRight">
                            <p>ORDER # {order.order_id}</p>
                          </div>
                        </div>
                        <div className="cartproductdetails">
                          <div className="stpes">
                            <div className="stpeouterbox">
                              {orderStatusCard(
                                order?.order_logs[0]?.status_log ?? order?.order_status,
                                order?.order_status,
                                order?.order_category,
                                order?.shippingServiceCategory
                              )}
                            </div>
                          </div>
                          <div className="cartdetailsleft">
                            <div className="cartproductdetailstab">
                              <div className="cartproductimg">
                                <Link to={`/shafts/${order?.products[0].slug}`}>
                                  <img
                                    src={showProductImage(
                                      order?.products[0].product_id
                                    )}
                                    alt="#"
                                    className="img-fluid"
                                  />{' '}
                                  {order?.order_category !== "service_order" && <span className="additems">
                                    {order?.products[0].quantity}
                                  </span>}
                                </Link>
                              </div>
                              <div className="cartproductcontentdetails">

                                {order?.order_category !== "service_order" && <>
                                  <Link to={`/shafts/${order?.products[0].slug}`}>
                                    <h6 style={{ textTransform: 'capitalize' }}>{order?.products[0]?.product_title} - {order?.products[0]?.quantity > 1 ? order?.products[0]?.categoryName : order?.products[0]?.categorySlug}</h6>
                                  </Link>
                                  {Object.keys(
                                    productVariations[index] ?? {}
                                  ).map((variation, index) => (
                                    <p key={'var_' + variation}>
                                      <span>{variation}: </span>
                                      {
                                        order?.products[0]?.product_variation[
                                        variation
                                        ]
                                      }{' '}
                                    </p>
                                  ))}
                                  <p className="customizedshaft">
                                    {order?.products[0]?.categoryType === "OTHER" ? "Gear" : (order?.products[0]?.fitting_option?.length
                                      ? 'Customized Shaft'
                                      : 'Raw Shaft')}
                                  </p>
                                </>}
                                {(order?.order_category === "service_order" && order?.buildShopSupplyProduct.length > 0) && <>
                                  <Link to={`#`}>
                                    <h6 style={{ textTransform: 'capitalize' }}>{order?.buildShopSupplyProduct[0]?.product_title} - {"Build Shop Supply"}</h6>
                                  </Link>
                                  <p >
                                    <span>{"Quantity"}: </span>
                                    {order?.buildShopSupplyProduct[0]?.quantity}
                                  </p>
                                  <p >
                                    <span>{"SKU"}: </span>
                                    {order?.buildShopSupplyProduct[0]?.sku}
                                  </p>
                                  <p >
                                    <span>{"Price"}: </span>
                                    {order?.buildShopSupplyProduct[0]?.price}
                                  </p>
                                </>
                                }
                                {(order?.order_category === "service_order" && (order?.buildShopSupplyProduct.length === 0 && order?.serviceProduct.length > 0)) && <>
                                  <Link to={`#`}>
                                    <h6 style={{ textTransform: 'capitalize' }}>{order?.serviceProduct[0]?.product_title} - {"Service"}</h6>
                                  </Link>
                                  <p >
                                    <span>{"Quantity"}: </span>
                                    {order?.serviceProduct[0]?.quantity}
                                  </p>
                                  <p >
                                    <span>{"SKU"}: </span>
                                    {order?.serviceProduct[0]?.sku}
                                  </p>
                                  <p >
                                    <span>{"Price"}: </span>
                                    {order?.serviceProduct[0]?.price}
                                  </p>
                                </>
                                }
                              </div>
                            </div>
                            {order?.products.length > 1 && (
                              <Link className='mt-2 more-items' to={`/order/${order._id}`}>{order?.products.length - 1} more items</Link>
                            )}
                          </div>
                          <div className="cartdetailsright">
                            {
                              (PAYMENT_STATUS_CHECK.includes(order?.payment_status) && order?.order_status !== 4) ? <a href={`/pay-now/${order._id}?ptype=${checkIsOrderEditCreate(order)}`}
                                //onClick={(e) => handleClickonPayNow(order._id, order.order_status)}
                                style={{
                                  backgroundColor: '#6d2a5f',
                                  color: 'white',
                                }}
                              >
                                Pay Now
                              </a> : (order?.order_status !== 4 && <a
                                href="javascript:void(0)"
                                onClick={() => downLoadInvoice(order._id)}
                              >
                                Invoice
                              </a>)
                            }
                            {((![1, 2, 5, 6].includes(order.order_status) && order?.orderShippingBox) &&
                              order.orderShippingBox.length > 0 || order?.orderMergeShippingId) ? (
                              <a href="javascript:void(0)" onClick={() => openTrackingModel(order?.orderMergeShippingId ? orders.find((i) => i._id === order?.orderMergeShippingId) : order)}>
                                Track Packages
                              </a>
                            ) : (
                              <Link disabled to="#" title='The order is not yet shipped'> Track Package </Link>
                            )}
                            <Link to={`/order/${order._id}`}>
                              Review Product
                            </Link>
                            <Link onClick={() => setModalShow(true)}>Help</Link>
                            <Link to={`/order/${order._id}`}>
                              Order Details
                            </Link>

                            <a
                              href={downloadData}
                              download
                              id="downloadCsv"
                              hidden
                              className="hide"
                              target="_blank"
                              rel="noopener noreferrer"
                            ></a>
                          </div>
                        </div>
                      </div>
                    ))}
                </InfiniteScroll>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MyOrder;

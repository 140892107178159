import { useCallback, useEffect, useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddRemoveWishItemMutation } from "../../redux-services/apis/productDetailApi";
import { getUserDetails, setPreviousLocation } from "../../redux-services/slices/authSlice";
import { getWishList } from "../../redux-services/slices/siteDataSlice";

const WishListMark = ({ productId, removeItem }) => {

    const [liked, setLiked] = useState(false);

    const authUser = useSelector(getUserDetails);

    const [addRemoveItem, _response] = useAddRemoveWishItemMutation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const location = useLocation();

    const addRemoveWishList = useCallback((e) => {
        if (authUser)
            addRemoveItem({ productId, addRemove: !liked })
        else {
            dispatch(setPreviousLocation(location.pathname))
            navigate('/login');
        }
    }, [liked, productId]);

    const myWishList = useSelector(getWishList);

    // Check item is in wishList or not
    useEffect(() => {
        if (myWishList && myWishList.includes(productId)) {
            setLiked(true);
        } else {
            setLiked(false);
        }
    }, [myWishList, productId])

    return liked ?
    removeItem? "Remove Item": <AiFillHeart title="Click to remove from wishlist" onClick={addRemoveWishList} /> :
        <AiOutlineHeart title="Click to add in wishlist" onClick={addRemoveWishList} />
}

export default WishListMark;
import { Button, Card, Col, Form, Input, Image, ListGroup, Row } from 'react-bootstrap';
import { useCreditCardValidator, images } from 'react-creditcard-validator';
import './CardCss.css';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../redux-services/slices/authSlice';
import VisaCard from '../../img/VisaCard.png';
import { BiTrash } from 'react-icons/bi';
import { AiFillCreditCard } from 'react-icons/ai';
import { GoCreditCard } from 'react-icons/go';
import { MdOutlineCreditCardOff } from 'react-icons/md';

import { Link } from 'react-router-dom';
import { showModel } from '../../redux-services/slices/siteDataSlice';
import { useDeleteCreditCardMutation } from '../../redux-services/apis/paymentGetWay';
import MaskedInput from "react-text-mask";
import {
    AMERICANEXPRESS,
    OTHERCARDS,
    EXPIRYDATE,
    CVC,
    CARDARR,
    CARDICON
} from "../../constants/CardConstant";
import {
    stripeCardNumberValidation,
    stripeCardExpirValidation,
    textWithSpacesOnly,
    minLength
} from "../../components/validation/CardValidations";

const CardImages = () => {
    return (
        <Image src={VisaCard} thumbnail />
    )
}


const expDateValidate = (month, year) => {
    if (Number(year) > 2035) {
        return 'Expiry Date Year cannot be greater than 2035';
    }
    return;
}

const reducer = (state, action) => {
    console.log("action", action.data);
    switch (action.type) {
        case "card":
            return { ...state, card: action.data };
        case "expiry":
            return { ...state, expiry: action.data };
        case "securityCode":
            return { ...state, securityCode: action.data };
        case "cardHodler":
            return { ...state, cardHodler: action.data };
        case "cleanState":
            return { ...action.data };
        default:
            return state;
    }
};

function findDebitCardType(cardNumber) {
    const regexPattern = {
        MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
        VISA: /^4[0-9]{2,}$/,
        AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
        DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
        DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
        JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/
    };
    for (const card in regexPattern) {
        if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) return card;
    }
    return "";
}

const CreditCardForm = forwardRef(({ setCardDetail, cardDetail, setPaymentError, setOnSubmit, paypalRef }) => {
    const dispatchFn = useDispatch();

    const [error, setError] = useState({});
    const [cardType, setCardType] = useState();
    const [state, dispatch] = useReducer(reducer, {
        card: "",
        expiry: "",
        securityCode: "",
        cardHodler: ""
    });


    const handleValidations = (type, value) => {
        let errorText;
        if (value === "") {
            setCardDetail({ ...cardDetail, [type]: "" });
        }
        switch (type) {
            case "cardNumber":
                setCardType(findDebitCardType(value));
                errorText = stripeCardNumberValidation(value);
                setError({ ...error, cardNumber: errorText });
                break;
            case "cardName":
                errorText = value === "" ? "card name is required." : textWithSpacesOnly(value);
                setError({ ...error, cardName: errorText });
                break;
            case "expiryDate":
                errorText =
                    value === "" ? "expiry date is required." : stripeCardExpirValidation(value);
                setError({ ...error, expiryDate: errorText });
                break;
            case "cvc":
                errorText = value === "" ? "cvc number is required." : minLength(3)(value);
                setError({ ...error, cvc: errorText });
                break;
            default:
                break;
        }
    };

    const checkErrorBeforeSave = () => {
        let errorValue = {};
        let isError = false;

        Object.keys(cardDetail).forEach(async (val) => {
            if (["cardNumber", "cardName", "expiryDate", "cvc"].includes(val) && cardDetail[val] === "") {
                errorValue = { ...errorValue, [`${val}`]: `${val.split(/(?=[A-Z])/).map(s => s.toLowerCase()).join(' ')} is required.` };
                isError = true;
            }
        });
        setError(errorValue);
        return isError;
    };

    const { user_info } = useSelector(getUserDetails);
    const [deleteCreditCard, deleteCreditCardResponse] = useDeleteCreditCardMutation();
    const {
        getCardNumberProps,
        getCardImageProps,
        getCVCProps,
        getExpiryDateProps,
        meta: { erroredInputs, touchedInputs, focused }
    } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

    const [isCardSave, setIsCardSave] = useState(true);
    const [showCardForm, setShowCardForm] = useState(true);
    const [cardedSave, setCardedSave] = useState([]);
    const [showAutoFocus, setShowAutoFocus] = useState(false);

    useEffect(() => {
        if (user_info && user_info.length > 0) {
            setShowCardForm(false)
            setCardedSave(user_info)
        }
        if (user_info && user_info.length === 0) {
            setShowCardForm(true)
            setCardedSave([])
        }
    }, [user_info])

    const onChange = (event) => {
        setCardDetail({ ...cardDetail, [event.target.name]: event.target.value })
    };

    const showPaymentType = useCallback(() => {

        let changevalue = !showCardForm
        if (changevalue === false) {
            setCardDetail({
                ...cardDetail,
                cardNumber: '',
                cardName: '',
                expiryDate: '',
                cvc: '',
                isCardSave: false
            })
            setError({
                ...error, cardNumber: '',
                cardName: '',
                expiryDate: '',
                cvc: '',
            })
            setCardType("")
        } else {
            setCardDetail({
                ...cardDetail,
                savedCardId: "",
                isCardSave: true
            })
        }
        setShowCardForm(!showCardForm)
    })

    const removeSaveCard = useCallback((id) => {
        deleteCreditCard({ id })
    })

    const selectedCard = useCallback((id) => {
        setCardDetail({
            ...cardDetail,
            savedCardId: id,
            isCardSave: false
        })
    })

    const checkErrors = () => {
        let isError = false;
        Object.keys(error).forEach(async (val) => {
            if (["cardNumber", "cardName", "expiryDate", "cvc"].includes(val) && error[val] !== undefined) {
                isError = true;
            }
        });
        return isError;
    }

    const handleClick = () => {
        dispatchFn(showModel({}))
        if (showCardForm) {
            if (checkErrors()) {
                setOnSubmit(false);
                return false;
            }
            let errorCheck = checkErrorBeforeSave();
            if (errorCheck) {
                setOnSubmit(false);
                return false;
            }
        } else {

            if (!cardDetail.savedCardId) {
                dispatchFn(
                    showModel({
                        isOpen: true,
                        modelType: 'danger',
                        body: "Please select the payment card option.",
                    })
                );
                return false;
            }
        }
        setOnSubmit(true);
    };

    useImperativeHandle(paypalRef, () => {
        return {
            handleClick: handleClick
        };
    });

    return (
        <div className='creditcardform'>
            {!showCardForm && <Card className='mb-3' >
                <ListGroup variant="flush">
                    {cardedSave.length > 0 && cardedSave.map((cardList) => (<ListGroup.Item key={cardList.paypal_order_id}  >
                        {/* <img src={CARDICON[cardList.brand]} /> */}
                        <Form.Check
                            type="radio"
                            className='select-payment'
                            name={`selected-payment`}
                            id={`select-payment_${cardList._id}`}
                            value={cardList._id}
                            onChange={(event) => selectedCard(event.target.value)}
                            label={<span><img height={20} src={CARDICON[cardList.brand]} />{" ****"}{cardList.last_digits}</span>}
                        />
                        <Button
                            className="delete-card"
                            style={{ color: "#6d2a5f" }}
                            onClick={(e) => removeSaveCard(cardList._id)}
                        >
                            <BiTrash />
                        </Button>
                    </ListGroup.Item>))}
                </ListGroup>
            </Card>}
            {showCardForm && <Form>
                <Row>
                    <Col md="12">
                        <div className='flex-box'>
                            <span className='card-flex'>
                                {(!error || !error.cardNumber) && CARDARR.includes(cardType) ? (
                                    <img
                                        src={CARDICON[cardType]}
                                        alt="card"
                                        width="30px"
                                        height="20px"
                                    />
                                ) : <AiFillCreditCard />}
                            </span>
                            <div>
                                <MaskedInput mask={
                                    ["37", "34"].includes(
                                        state && state.card.split("").splice(0, 2).join("")
                                    )
                                        ? AMERICANEXPRESS
                                        : OTHERCARDS
                                }
                                    guide={false}
                                    placeholderChar={"\u2000"}
                                    placeholder="Card Number *"
                                    name="cardNumber"
                                    value={cardDetail.cardNumber}
                                    onChange={onChange}
                                    onKeyUp={(event) => handleValidations(event.target.name, event.target.value)}
                                    type="text"
                                    className="form-control card-number"
                                />
                                {error && error.cardNumber && error.cardNumber.length > 1 && (
                                    <small>{error.cardNumber}</small>
                                )}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="cardName"
                                    className="form-control"
                                    required
                                    placeholder="CardHolder's Name"
                                    value={cardDetail.cardName}
                                    onChange={onChange}
                                    onKeyUp={(event) => handleValidations(event.target.name, event.target.value)}
                                />
                                {error &&
                                    error.cardName &&
                                    error.cardName.length > 1 && (
                                        <small>{error.cardName}</small>
                                    )}
                            </div>
                        </div>
                    </Col>
                    <Col md="12">
                        <div className='flex-box'>
                            <div>
                                <MaskedInput
                                    mask={EXPIRYDATE}
                                    guide={false}
                                    name="expiryDate"
                                    className="form-control border-0 mmyy"
                                    required
                                    placeholderChar={"\u2000"}
                                    placeholder="MM/YY*"
                                    value={cardDetail.expiryDate}
                                    onChange={onChange}
                                    onKeyUp={(event) => handleValidations(event.target.name, event.target.value)}
                                />
                                {error &&
                                    error.expiryDate &&
                                    error.expiryDate.length > 1 && (
                                        <small>{error.expiryDate}</small>
                                    )}
                            </div>
                            {/*  <input type="text" className="form-control border-0 mmyy" placeholder="MM/YY*" /> */}
                            {/* <input type="text" className="form-control Security-tab" placeholder="Security Code*" /> */}
                            <div>
                                <MaskedInput
                                    mask={CVC}
                                    guide={false}
                                    name="cvc"
                                    type='password'
                                    className="form-control  Security-tab"
                                    required
                                    placeholderChar={"\u2000"}
                                    placeholder="CVC/CVV*"
                                    value={cardDetail.cvc}
                                    onChange={onChange}
                                    onKeyUp={(event) => handleValidations(event.target.name, event.target.value)}
                                />
                                {error &&
                                    error.cvc &&
                                    error.cvc.length > 1 && (
                                        <small>{error.cvc}</small>
                                    )}
                            </div>
                            {cardDetail.cvc.length >= 3 ? <span className='card-flex cvv'><GoCreditCard /></span> : <span className='card-flex cvv'><MdOutlineCreditCardOff /></span>}
                            {/* <span className='card-flex'><GoCreditCard /></span> */}

                        </div>
                    </Col>
                    <Col md="6">
                        <div className='custom_check save_card_check mb-3'>
                            Save Card
                            <input
                                type="checkbox"
                                id="custom-switch"
                                name="custom-switch"
                                value={isCardSave}
                                checked={isCardSave}
                                onChange={(events) => {
                                    setIsCardSave(events.target.checked)
                                    setCardDetail({ ...cardDetail, isCardSave: events.target.checked })
                                }}
                            />
                            <span class="check_indicator">&nbsp;</span>
                        </div>
                        {/* <div className='form-group'>
                            <Form.Check
                                type="checkbox"
                                checked={isCardSave}
                                id="custom-switch"
                                label="Save Card"
                                onChange={(events) => {
                                    setIsCardSave(events.target.checked)
                                    setCardDetail({ ...cardDetail, isCardSave: events.target.checked })
                                }}
                            />
                        </div> */}
                    </Col>
                </Row>
                <button
                    className={`btn btn-primary`}
                    style={{ float: "right", display: "none" }}
                    onClick={handleClick}
                >
                    {"Pay"}
                </button>
            </Form>}
            {cardedSave.length > 0 && <Button className='show-card' onClick={showPaymentType}> <AiFillCreditCard />{showCardForm ? " Show Saved Card" : " Add New Card"}</Button>}
        </div >
    )
});
export default CreditCardForm;
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCartCount } from "../../../redux-services/slices/siteDataSlice";

const HeaderCart = () => {

    const cartCount = useSelector(getCartCount);

    return <div className='cart'>
        <Link to="/cart">
            <img src={`/images/${cartCount ? "carticon.png" : "carticonempty.png"}`} alt='#' />
            <span className='count'>{cartCount}</span>
        </Link>
            
    </div>
}

export default HeaderCart;
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getSpecs, setExploreFitting } from '../../../../redux-services/slices/siteDataSlice';
import CompareSpecification from './CompareSprecification';
import { getUserDetails } from '../../../../redux-services/slices/authSlice';
import { useAddToCartMutation } from '../../../../redux-services/apis/cartApi';

const SelectButton = ({ specs }) => {

  const user = useSelector(getUserDetails);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [addToCart, addToCartResult] = useAddToCartMutation();

  const handleAddToCart = (product) => {
    const sVariations = product.variations[0].variation.reduce(
      (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
      {}
    );

    let newProduct = {
      product_id: product.productId !== undefined ? product.productId : product._id,
      product_slug: product?.slug,
      product_title: product?.product_title,
      product_variation: sVariations,
      quantity: 1,
      fitting: {},
      product_variation_sku: product.variations[0]?.sku,
      product_variation_id: product.variations[0]._id !== undefined ? product.variations[0]._id : product.product_variation_id[0],
      userId: user?._id,
    };
    addToCart({ productDetails: newProduct });
  }

  const handleFitting = (product) => {
    const sVariations = product?.variations[0].variation?.reduce(
      (obj, item) => ({ ...obj, [item.attribute_id]: item }),
      {}
    );
    dispatch(setExploreFitting(sVariations))
  }

  useEffect(() => {
    if (addToCartResult?.isSuccess) {
      navigate('/cart');
    }
  }, [addToCartResult])

  return (
    <div className="compare-footer">
      <div className="compare-head">
        <div className="compare-leftpart border-0"></div>
        <div className="compare-center text-center border-0 compare-btn-outer">
          {specs?.productOne?.slug && (
            <button
              className="btn btn-primary"
              onClick={(e) => handleAddToCart(specs?.productOne)}
            >
              Add to Cart
            </button>
          )}
          {specs?.productOne?.slug && (
            <Link
              className="btn btn-secondary"
              to={'/shafts/' + specs?.productOne?.slug}
              onClick={(e) => handleFitting(specs?.productOne)}
            >
              Fitting Options
            </Link>
          )}
        </div>

        <div className="compare-right text-center border-0 compare-btn-outer">
          {specs?.productTwo?.slug && (
            <button
              className="btn btn-primary"
              onClick={(e) => handleAddToCart(specs?.productTwo)}
            >
              Add to Cart
            </button>
          )}
          {specs?.productTwo?.slug && (
            <Link
              className="btn btn-secondary"
              to={'/shafts/' + specs?.productTwo?.slug}
              onClick={(e) => handleFitting(specs?.productTwo)}
            >
              Fitting Options
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
const CompareProduct = ({
  productOneSlug,
  flex1,
  flex2,
  iron1,
  iron2,
  tip1,
  tip2,
}) => {
  const specs = useSelector(getSpecs);
  const [skuOne, setSkuOne] = useState("N/A");
  const [skuTwo, setSkuTwo] = useState("N/A");
  const navigate = useNavigate();
  let tipCategory = ['Tip', 'Tip Diameter'];
  let ironCategory = ['Iron', 'Iron #'];
  useEffect(() => {
    if (!specs.productOne || !specs.productTwo) {
      navigate(`/shafts/${productOneSlug !== undefined ? productOneSlug : ''}`);
    }
  }, [navigate, productOneSlug, specs]);

  useEffect(() => {
    if (specs?.productOne?.categoryName == "Iron") {
      specs?.productOne?.variations.forEach(variants => {
        const data = variants?.variation.find(item => (ironCategory.includes(item.attribute_name) && item.option_id === iron1))
        if (data) {
          const flexData = variants?.variation?.find(item => (item.attribute_name == "Flex" && item.option_id === flex1))
          if (flexData) {
            const tipData = variants?.variation?.find(item => (tipCategory.includes(item.attribute_name) && item.option_id === tip1))
            if (tipData) {
              setSkuOne(variants?.sku);
            }
          }
        }
      });
      specs?.productTwo?.variations.forEach(variants => {
        const data = variants?.variation.find(item => (ironCategory.includes(item.attribute_name) && item.option_id === iron2))
        if (data) {
          const flexData = variants?.variation?.find(item => (item.attribute_name == "Flex" && item.option_id === flex2))
          if (flexData) {
            const tipData = variants?.variation?.find(item => (tipCategory.includes(item.attribute_name) && item.option_id === tip2))
            if (tipData) {
              setSkuTwo(variants?.sku);
            }
          }
        }
      })
    } else {
      specs?.productOne?.variations.forEach(variants => {
        const data = variants?.variation.find(item => (item.attribute_name == "Flex" && item.option_id === flex1))
        if (data) {
          setSkuOne(variants?.sku);
        }
      })
      specs?.productTwo?.variations.forEach(variants => {
        const data = variants?.variation.find(item => (item.attribute_name == "Flex" && item.option_id === flex2))
        if (data) {
          setSkuTwo(variants?.sku);
        }
      })
    }

  }, [specs, skuOne, skuTwo]);

  return (
    <>
      {specs?.productOne && specs?.productTwo && (
        <Container>
          <div className="box-outer">
            <div className="compare-product-list">
              <div className="compare-table basic-information">
                <div className="compare-head">
                  <div className="compare-leftpart">Basic Information</div>
                  <div className="compare-center">
                    {specs?.productOne?.product_title} ({
                      specs?.productOne?.flexData.find(
                        (flx) => flx.option_id === flex1
                      )?.option_term
                    })
                  </div>
                  <div className="compare-right">
                    {specs?.productTwo?.product_title} ({
                      specs?.productTwo?.flexData.find(
                        (flx) => flx.option_id === flex2
                      )?.option_term
                    })
                  </div>
                </div>
                <div className="compare-body compare-bodyone">
                  <div className="bodytr">
                    <div className="partleft">
                      <div className="compareInner">
                        <div className="compareTd productImageOuterCompare">
                          <h2>Product Image</h2>
                        </div>
                        <div className="compareTd">
                          <h2>Shaft Category</h2>
                        </div>
                        <div className="compareTd">
                          <h2>Model Number</h2>
                        </div>
                        {specs?.productOne?.categoryName.toLowerCase() !==
                          'iron' && (
                            <div className="compareTd">
                              <h2>Flex</h2>
                            </div>
                          )}
                        {specs?.productOne?.categoryName.toLowerCase() ===
                          'iron' && (
                            <>
                              <div className="compareTd">
                                <h2>Flex</h2>
                              </div>
                              <div className="compareTd">
                                <h2>Iron #</h2>
                              </div>
                              <div className="compareTd">
                                <h2>Tip</h2>
                              </div>
                            </>
                          )}

                        <div className="compareTd">
                          <h2>Material</h2>
                        </div>
                      </div>
                    </div>
                    <div className="compareOuterpanel">
                      <div className="compareTd productImageOuterCompare">
                        <img
                          src={
                            specs?.productOne?.thumbnail ??
                            '/images/productdummy.png'
                          }
                          className="prdtImg img-fluid"
                          alt="#"
                        />
                      </div>
                      <div className="compareTd" style={{ textTransform: "capitalize" }}>
                        {specs?.productOne?.categorySlug ?? 'N/A'}
                      </div>
                      <div className="compareTd">
                        {/* {specs?.productOne?.skuId ?? 'N/A'} */}
                        {skuOne}
                      </div>
                      {specs?.productOne?.categoryName.toLowerCase() !==
                        'iron' && (
                          <div className="compareTd">
                            {
                              specs?.productOne?.flexData.find(
                                (flx) => flx.option_id === flex1
                              ).option_term
                            }
                          </div>
                        )}
                      {specs?.productOne?.categoryName.toLowerCase() ===
                        'iron' && (
                          <>
                            <div className="compareTd">
                              {
                                specs?.productOne?.flexData.find(
                                  (flx) => flx.option_id === flex1
                                ).option_term
                              }
                            </div>
                            <div className="compareTd">
                              {
                                specs?.productOne?.ironData.find(
                                  (flx) => flx.option_id === iron1
                                ).option_term
                              }
                            </div>
                            <div className="compareTd">
                              {
                                specs?.productOne?.tipData.find(
                                  (flx) => flx.option_id === tip1
                                ).option_term
                              }
                            </div>
                          </>
                        )}
                      <div className="compareTd">
                        {specs?.productOne?.meterial ?? 'N/A'}
                      </div>
                    </div>
                    <div className="compareOuterpanel">
                      <div className="compareTd productImageOuterCompare">
                        <img
                          src={
                            specs?.productTwo?.thumbnail ??
                            '/images/productdummy.png'
                          }
                          className="prdtImg img-fluid"
                          alt="#"
                        />
                      </div>
                      <div className="compareTd" style={{ textTransform: "capitalize" }}>
                        {specs?.productTwo?.categorySlug ?? 'N/A'}
                      </div>
                      <div className="compareTd">
                        {/* {specs?.productTwo?.skuId ?? 'N/A'} */}
                        {skuTwo}
                      </div>
                      {specs?.productOne?.categoryName.toLowerCase() !==
                        'iron' && (
                          <div className="compareTd">
                            {specs?.productTwo &&
                              specs?.productTwo?.flexData?.find(
                                (flx) => flx.option_id === flex2
                              ).option_term
                            }
                          </div>
                        )}
                      {specs?.productOne?.categoryName.toLowerCase() ===
                        'iron' && (
                          <>
                            <div className="compareTd">
                              {
                                specs?.productTwo?.flexData.find(
                                  (flx) => flx?.option_id === flex2
                                ).option_term
                              }
                            </div>
                            <div className="compareTd">
                              {
                                specs?.productTwo?.ironData.find(
                                  (flx) => flx?.option_id === iron2
                                ).option_term
                              }
                            </div>
                            <div className="compareTd">
                              {
                                specs?.productTwo?.tipData.find(
                                  (flx) => flx?.option_id === tip2
                                ).option_term
                              }
                            </div>
                          </>
                        )}
                      <div className="compareTd">
                        {specs?.productTwo?.meterial ?? 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <SelectButton specs={specs} /> */}
          <CompareSpecification
            data={specs?.technical_spec}
            specs={specs}
            flex1={flex1}
            flex2={flex2}
            title="Technical Information"
          />
          {/* <SelectButton specs={specs} /> */}
          <CompareSpecification
            data={specs?.fitting_spec}
            specs={specs}
            flex1={flex1}
            flex2={flex2}
            title="Fitting Information"
          />

          <SelectButton specs={specs} />
        </Container>
      )}
    </>
  );
};

export default CompareProduct;

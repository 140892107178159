import { DEALER_TAG } from '../../constants';
import { ApiService } from './apiService';

const BASE_URL = '/api/front';
const token = localStorage.userAccessToken;
export const DealerAPI = ApiService.injectEndpoints({
  endpoints: (builder) => ({
    addDealerDetails: builder.mutation({
      query: (dealerData) => {
        return {
          url: `${BASE_URL}/dealers/registration`,
          method: 'POST',
          body: dealerData,
        }
      },
      invalidatesTags: [DEALER_TAG],
    }),

    getDealerList: builder.query({
      query: (query) => ({
        url: `${BASE_URL}/dealers/`,
        method: 'GET',
        params: {
          search: query.searchText,
          filterOrder: query.filterOrder,
          latitute: query.latitute,
          longitude: query.longitude,
          radius: query.radius,
        },
      }),
      providesTags: [DEALER_TAG],
    }),
  }),
});

export const { useAddDealerDetailsMutation, useGetDealerListQuery } = DealerAPI;

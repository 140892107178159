import { useCallback, useEffect, useState } from 'react';
import { Col, Form, Modal, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { USER_TYPE } from '../../../constants';
import { BsFillInfoCircleFill } from "react-icons/bs";
import { getUserDetails } from '../../../redux-services/slices/authSlice';
import { getAttributesList, getExploreFitting } from '../../../redux-services/slices/siteDataSlice';

const AWS_PRODUCT_URL =
  'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/';

const SelectVariations = ({
  product,
  setProduct,
  setProductImages,
  setProductRates,
  renderVideo,
  setResetSelectedQuantity,
  setStopCount,
  flexCodeList,
  ironNumberList
}) => {
  const attributes = useSelector(getAttributesList);
  const exploreFitting = useSelector(getExploreFitting);
  const user = useSelector(getUserDetails);
  const [showModal, setShowModal] = useState({ status: false, title: '' });

  const [productVariations, setProductVariations] = useState([]);
  const [productId, setproductId] = useState(null);

  const [selectedProductVariations, setSelectedProductVariations] =
    useState(null);

  const getVariationForSelectedProductVariations = (selectedVars) => {
    let selectedVariationDataFound = null;
    product.variations.every((variation) => {
      const totalVariations = variation.variation.length;
      let quelifiedAttr = 0;
      variation.variation.forEach((variationOption) => {
        if (
          variationOption.option_id ===
          selectedVars[variationOption.attribute_id].option_id
        ) {
          quelifiedAttr += 1;
        }
      });

      if (totalVariations === quelifiedAttr) {
        selectedVariationDataFound = variation;
        return false;
      }
      return true;
    });
    return selectedVariationDataFound;
  };

  const changeImages = (newVariation) => {
    const selectedColor = newVariation?.variation?.find(
      (variavtionD) => variavtionD.attribute_name.toLowerCase() === 'color'
    );

    if (selectedColor) {
      const imagesAttr = product.attributes.find(
        (pAttr) => pAttr.attributeId._id === selectedColor.attribute_id
      );
      if (imagesAttr && imagesAttr.branding_attribute?.length) {
        const images = imagesAttr.branding_attribute.find(
          (bAttr) => bAttr.value === selectedColor.option_id
        );
        if (images && images.images?.length) {
          const imagesArray = [];
          images.images?.map((image) => {
            imagesArray.push({
              original: AWS_PRODUCT_URL + image,
              thumbnail: AWS_PRODUCT_URL + image,
            });
          });
          if (product?.videoLink) {
            const playImage = '/images/play.svg';
            const thumbnailCode = product?.videoLink.substring(
              product?.videoLink.lastIndexOf('/') + 1
            );
            const regex = /youtube.com/g;
            const found = product?.videoLink.match(regex);
            imagesArray.push({
              thumbnail: playImage,
              original: playImage,
              thumbnailClass: 'video-slide',
              embedUrl: product?.videoLink,
              renderItem: function (onClick, item) {
                return <div className='image-gallery-image'>
                  <iframe title='video' width='400' height='300' src={product?.videoLink} frameBorder='0' allowFullScreen></iframe>
                </div>
              },
            });
          }
          setProductImages(imagesArray);
        }
      }
    }
  };

  //################ On variation selection change i.e color or flex value change #################

  const filterAttr = (a, b) => {
    if (product?.categoryId?.name == ('Iron' || 'Iron #')) {
      return a.attributeId.name === 'Iron #'
        ? -1
        : a.attributeId.name === 'Flex'
          ? -1
          : 1;
    } else {
      return a.attributeId.name === 'Flex' ? -1 : 1;
    }
  };
  const customSort = (a, b) => {
    const isNumberA = !isNaN(parseFloat(a.option.replace('-', '.')));
    const isNumberB = !isNaN(parseFloat(b.option.replace('-', '.')));

    if (isNumberA && isNumberB) {
      return parseFloat(a.option.replace('-', '.')) - parseFloat(b.option.replace('-', '.')); // Sort numbers in ascending order
    } else if (isNumberA && !isNumberB) {
      return -1; // Numbers before alphabets
    } else if (!isNumberA && isNumberB) {
      return 1; // Alphabets after numbers
    } else {
      // Sort alphabets in descending order
      return b.option.localeCompare(a.option);
    }
  };

  const updateOptionList = (selectedVar) => {
    const list = [];
    // console.log('from Attr', selectedVar);
    if (selectedVar) {
      const pAttr = [...product.attributes].sort(filterAttr);
      let isInclude = '';
      pAttr.forEach((attribute, index) => {
        const variations = product.variations
          .filter((variationF) => {
            let counterS = 0;

            pAttr.forEach((pAt, innerIndex) => {
              if (index > innerIndex) {
                let optionId = variationF.variation.find(
                  (item) => item.attribute_id === pAt.attributeId._id
                )?.option_id;
                isInclude =
                  selectedVar[pAt.attributeId._id]?.option_id === optionId;

                if (isInclude) {
                  counterS += 1;
                }
              }
            });

            return counterS === index;
          })
          .map((variation) => {
            return variation.variation.find(
              (item) => item.attribute_id === attribute.attributeId._id
            )?.option_id;
          });

        const attributeData = attributes.find(
          (attr) => attr._id === attribute.attributeId._id
        ); // list.push({ ...attribute, attributeOptions: attributeData?.terms.filter((term) => attribute.values.includes(term._id)).sort((a, b) => a.option.toLowerCase() < b.option.toLowerCase() ? -1 : 1) })

        if (attributeData?.name === "Iron #" || attributeData?.name === "Iron") {
          list.push({
            ...attribute,
            attributeOptions: attributeData?.terms
              .filter((term) => variations.includes(term._id))
              .sort(customSort
              ),
          });
        } else {
          list.push({
            ...attribute,
            attributeOptions: attributeData?.terms
              .filter((term) => variations.includes(term._id))
              .sort((a, b) =>
                a.option.toLowerCase() < b.option.toLowerCase() ? -1 : 1
              ),
          });
        }
      });
      // list.sort(filterAttr);

      setProductVariations(list);
      return list;
    }
  };

  const updateAttr = useCallback(
    (selectedVar, selectedIndex = 1) => {
      const list = updateOptionList(selectedVar);

      let selectedData = {};
      if (list?.length) {
        let i = selectedIndex + 1;
        if (list.length !== selectedIndex + 1 && list[i]?.attributeOptions) {
          let item = {
            attribute_name: list[i]?.attributeId?.name,
            option_term: list[i]?.attributeOptions[0]?.option,
            attribute_id: list[i]?.attributeId?._id,
            option_id: list[i]?.attributeOptions[0]?._id,
            _id: list[i]._id,
          };
          selectedData = {
            ...selectedData,
            ...selectedVar,
            [list[i].attributeId._id]: item,
          };

          updateAttr(selectedData, i);
          return;
        }
      }
      if (selectedProductVariations && selectedIndex === list.length - 1) {
        const newVariation = {
          ...getVariationForSelectedProductVariations(selectedVar),
        };

        if (newVariation) {
          changeImages(newVariation);
        }

        if (newVariation.sale_price == 0) {
          delete newVariation['sale_price'];
        }
        if (newVariation.sku !== product.defaultVariation.sku) {
          setProduct({ ...product, defaultVariation: newVariation });
          let rate = newVariation?.sale_price ?? newVariation?.msrp_price ?? 0;
          if (
            user?.userType === USER_TYPE.DEALER &&
            newVariation?.discounted_price
          ) {
            rate =
              newVariation?.discounted_price ?? newVariation?.msrp_price ?? 0;
          }

          setProductRates((rates) => ({ ...rates, product: rate }));
        }
      }
      // console.log('--selected Var', selectedVar);
      // console.log('--selected Data', selectedData);
      setSelectedProductVariations({
        ...selectedVar,
        ...selectedData,
      });
    },
    [
      product,
      selectedProductVariations,
      changeImages,
      getVariationForSelectedProductVariations,
      updateOptionList,
    ]
  );

  const onVariationChange = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-attribute-id');
      const value = e.currentTarget.value;

      setResetSelectedQuantity(value);
      const selectedAttribute = attributes.find((atr) => atr._id === id);

      const option_term = selectedAttribute?.terms.find(
        (opt) => opt._id === value
      );

      const updateSelectedProductVariations = {
        ...selectedProductVariations[id],
        option_term: option_term?.option,
        option_id: option_term?._id,
      };
      const IronNo = ['4', '5', '6', '7', '8', '9', 'P'];
      if (updateSelectedProductVariations?.attribute_name.includes("Iron") && IronNo.includes(updateSelectedProductVariations?.option_term)) {
        setStopCount(true);
      } else {
        setStopCount(false);
      }
      const currentIndex = [...product.attributes]
        .sort(filterAttr)
        .findIndex((pAttr) => pAttr.attributeId._id === id);
      const newSelectedVar = {
        ...selectedProductVariations,
        [id]: updateSelectedProductVariations,
      };

      const newVariation = {
        ...getVariationForSelectedProductVariations(newSelectedVar),
      };

      updateAttr(newSelectedVar, currentIndex);
    },
    [product, attributes, selectedProductVariations]
  );

  useEffect(() => {
    if (product && attributes) {
      let selectedDefaultAtr = { ...(selectedProductVariations ?? {}) };
      if (product._id !== productId) {
        setproductId(product._id);

        selectedDefaultAtr = product.defaultVariation?.variation?.reduce(
          (obj, item) => ({ ...obj, [item.attribute_id]: item }),
          {}
        );

        if (product.defaultVariation) {
          changeImages(product.defaultVariation);
        }
        if (exploreFitting == null) {
          setSelectedProductVariations(selectedDefaultAtr);
        } else {
          setSelectedProductVariations(exploreFitting?.variation)
        }
      }

      const _list = updateOptionList(selectedDefaultAtr);
    }
  }, [product, attributes, exploreFitting]);

  const handleModal = (e, title) => {
    e.preventDefault();
    setShowModal({ status: !showModal?.status, title: title });
  }

  return (
    <>
      {productVariations.length &&
        selectedProductVariations &&
        productVariations.map((variation) => (
          <Col md="12" key={variation._id}>
            <div className="form-group">
              <label>{variation.attributeId.name === "Flex" && "Flex Code"}
                {variation.attributeId.name === 'Flex' && <BsFillInfoCircleFill onClick={(e) => handleModal(e, 'flex')} />}
              </label>
              <label>{variation.attributeId.name === "Iron #" && "Iron #"}
                {variation.attributeId.name === 'Iron #' && <BsFillInfoCircleFill onClick={(e) => handleModal(e, 'iron')} />}
              </label>
              {
                !['Iron #', 'Flex'].includes(variation?.attributeId?.name) &&
                <label>{variation?.attributeId?.name ?? ''}</label>
              }
              <Form.Select
                aria-label="Default select example"
                value={
                  selectedProductVariations[variation.attributeId._id]
                    ?.option_id
                }
                name={variation.attributeId._id}
                data-attribute-id={variation.attributeId._id}
                onChange={onVariationChange}
              >
                {variation.attributeOptions?.length &&
                  variation.attributeOptions.map((optionData) => (
                    <option
                      value={optionData._id}
                      key={optionData._id}
                      defaultValue={optionData._id}
                    >
                      {optionData.option}
                    </option>
                  ))}
              </Form.Select>
            </div>
          </Col>
        ))}
      <Modal className='modal-lg' show={showModal?.status && showModal?.title === 'flex'} onHide={(e) => handleModal(e, '')} centered>
        <Modal.Header className="border-bottom">
          <Modal.Title style={{ fontSize: "larger" }}>Flex Code Nomenclature</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2" style={{ textAlign: "justify", textJustify: "inter-word" }}>
          {flexCodeList?.length > 0 &&
            <div
              className="bordered table striped w-100 text-center tablebox"
              dangerouslySetInnerHTML={{ __html: flexCodeList[0]?.description }}
            />
          }
          {/* <Table bordered striped>
            <thead>
              <tr>
                <th>Shaft Flex-Code</th>
                <th>Shaft Flex Equivalent</th>
              </tr>
            </thead>
            <tbody>
              {flexCodeList && flexCodeList?.status && flexCodeList?.response?.map((item) => (
                <tr>
                  <td>{item?.flex_code ?? ''}</td>
                  <td>{item?.flex_equivalent ?? ''}</td>
                </tr>
              ))} */}
          {/* <tr>
                <td>LD60+</td>
                <td>3X</td>
              </tr>
              <tr>
                <td>LD50; D50; F50</td>
                <td>2X</td>
              </tr>
              <tr>
                <td>D40; F40; HUT40; IRT40; IRP40; LIP40; MIP40; SIP40</td>
                <td>X</td>
              </tr>
              <tr>
                <td>D30+; Stand Alone Gap Flex Product Technology.</td>
                <td>S+</td>
              </tr>
              <tr>
                <td>D30; F30; HUT30; IRT30; IRP30; LIP30; MIP30; SIP30</td>
                <td>S</td>
              </tr>
              <tr>
                <td>LD20; D20; F20; HUT20; IRT20; IRP20; LIP20; MIP20; SIP20</td>
                <td>R</td>
              </tr>
              <tr>
                <td>D15; Stand Alone Gap Flex Product Technology.</td>
                <td>A Flex</td>
              </tr>
              <tr>
                <td>D10; F10; HUT10; IRT10; IRP10; LIP10; MIP10; SIP10</td>
                <td>LTE Flex</td>
              </tr>
              <tr>
                <td>D05; F05; HUT05; IRT05; IRP05; LIP05; MIP05; SIP05</td>
                <td>Senior-Lady-Junior Flex</td>
              </tr> */}
          {/* </tbody>
          </Table> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="primary"
            type="button"
            className="btn btn-outline-primary"
            onClick={(e) => handleModal(e, '')}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal className='modal-lg' show={showModal?.status && showModal?.title === 'iron'} onHide={(e) => handleModal(e, '')} centered>
        <Modal.Header className="border-bottom">
          <Modal.Title style={{ fontSize: "larger" }}>Iron Shafts Nomenclature</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2" style={{ textAlign: "justify", textJustify: "inter-word" }}>
          {ironNumberList?.length > 0 &&
            <div
              className="bordered table striped w-100 text-center tablebox"
              dangerouslySetInnerHTML={{ __html: ironNumberList[0]?.description }}
            />
          }
          {/* <Table bordered striped>
            <thead>
              <tr>
                <th>Iron # / Iron Type</th>
                <th>Raw Shaft Length Recommendation</th>
              </tr>
            </thead>
            <tbody>
              {ironNumberList && ironNumberList?.status && ironNumberList?.response?.map((item) => (
                <tr>
                  <td>{item?.iron_type ?? ''}</td>
                  <td>{item?.shaft_length ?? ''}</td>
                </tr>
              ))}
            </tbody>
          </Table> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="primary"
            type="button"
            className="btn btn-outline-primary"
            onClick={(e) => handleModal(e, '')}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectVariations;

import React, { useState, useEffect } from 'react';
import Layout from "../../components/Layouts/Layouts";
import 'react-multi-carousel/lib/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeSlider from './homeSlider';
import HomeProduct from './homeProduct';
import HomeVideoOuter from './homeVideoOuter';
import HomeProductSelector from './homeProductSelector';
import HomeAdvanceShaft from './homeAdvanceShaft';
import { useGetHomeListQuery } from "../../redux-services/apis/homeApi";
import MetaData from '../../components/MetaData/MetaData';

function Home() {
  const { isLoading, data: getHomeList } = useGetHomeListQuery();
  const [homeData, setHomeData] = useState();
  useEffect(() => {
    setHomeData(getHomeList?.response[0])
  }, [getHomeList])

  return (
    <>
      <MetaData metaData={homeData} />
      <HomeSlider homeData={homeData} />
      <HomeProduct homeData={homeData} />
      <HomeVideoOuter homeData={homeData} />
      <HomeProductSelector homeData={homeData} />
      <HomeAdvanceShaft homeData={homeData} />

    </>
  );
}

export default Home;

import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../../redux-services/slices/authSlice';
import { Link } from 'react-router-dom';

const FooterUpdatesForm = () => {
  const authUser = useSelector(getUserDetails);

  return (
    <>
      {!authUser?.userType && (
        <section className="exclusiveUpdates">
          <Container>
            <Row>
              <Col md="12">

                <div className="product-heading mb-0">
                  <div className='exclusive-text'>
                    <h2>Fore! Exclusive product updates and offers</h2>
                    <p>based on your personalized fitting.</p>
                  </div>
                  <div className="text-end">
                    <Link to="/register"><Button>Sign up</Button></Link>
                  </div>
                </div>



                {/* <div className="product-heading mb-0">
                  <h2>Get exclusive updates and discounts</h2>
                  <p>based on your personalized fitting.</p>
                  <div className="text-center">
                    <Link to="/register">
                      <Button>
                        Sign up
                      </Button>
                    </Link>
                  </div>
                </div> */}
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default FooterUpdatesForm;

import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from "./AxiosBaseQuery";
import { ApiService } from './apiService';
import { AUTH_TAG } from '../../constants';

export const PaymentGetwayAPI = ApiService.injectEndpoints({
    endpoints: (builder) => ({
        generateToken: builder.query(
            {
                query: () => ({
                    url: `/api/front/checkout/generate-client-token`,
                    method: "GET",
                }),
            }
        ),
        generateClientToken: builder.query(
            {
                query: () => ({
                    url: `/api/front/checkout/paypal-client-token`,
                    method: "GET",
                }),
                providesTags: ['GenerateClientToken']
            }
        ),
        deleteCreditCard: builder.mutation(
            {
                query: ({ id }) => ({
                    url: `/api/front/checkout/delete-credit-card/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: [AUTH_TAG]
            }
        ),
        createPaypalOrder: builder.mutation({
            query: (paymentData) => ({
                url: `/api/front/checkout/create-paypal-order`,
                method: 'POST',
                body: {
                    ...paymentData,
                },
            }),
            invalidatesTags: ["CREATE_ORDER"],
        }),
        capturePaypalOrder: builder.mutation({
            query: (paymentDatas) => ({
                url: `/api/front/checkout/capture-paypal-order`,
                method: 'POST',
                body: {
                    ...paymentDatas,
                },
            }),
            invalidatesTags: ["CAPTURE_ORDER"],
        }),
    })
})

export const { useGenerateTokenQuery, useGenerateClientTokenQuery, useCreatePaypalOrderMutation, useCapturePaypalOrderMutation, useDeleteCreditCardMutation } = PaymentGetwayAPI;
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import throttle from 'lodash/throttle';
import { Container, Navbar, Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getSocialSetting } from '../../../redux-services/slices/siteDataSlice';
import HeaderCart from "../Header/HeaderCart";
import FooterUpdatesForm from './footerUpdatesForm';
import { getUserDetails } from '../../../redux-services/slices/authSlice';
import { useSubscribeNewsLetterMutation } from '../../../redux-services/apis/newsApi';
import CookiePolicyPopup from '../../../Pages/CookiePolicyPopup';




function Footer() {
  const authUser = useSelector(getUserDetails);
  const socialOptions = useSelector(getSocialSetting);
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const [subscribeNewsLetter, subscribeNewsLetterResponse] = useSubscribeNewsLetterMutation();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(true);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const isCoockieAccepted = Cookies.get("kinetixxCookiesAccepted");
    if (isCoockieAccepted || !isCoockieAccepted) {
      setCookiesAccepted(isCoockieAccepted);
    }
  }, []);

  // useEffect(() => {
  //   const handleScroll = throttle(() => {
  //     setTimeout(() => {
  //       if (Cookies.get("kinetixxCookiesAccepted") === undefined) {
  //         Cookies.set('kinetixxCookiesAccepted', true, { expires: 180 });
  //         setCookiesAccepted(true);
  //         setShowPopup(false);
  //       }
  //     }, 5000)
  //   }, 200);

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (location.pathname !== "/") {
      if (Cookies.get("kinetixxCookiesAccepted") === undefined) {
        Cookies.set('kinetixxCookiesAccepted', true, { expires: 180 });
        setCookiesAccepted(true);
        setShowPopup(false);
      }
    }
  }, [location]);

  const handleAccept = () => {
    Cookies.set('kinetixxCookiesAccepted', true, { expires: 180 });
    setCookiesAccepted(true);
    setShowPopup(false);
  };

  const handleDecline = () => {
    Cookies.set('kinetixxCookiesAccepted', false, { expires: 180 });
    setCookiesAccepted(false);
    setShowPopup(false);
  };


  const year = new Date().getFullYear();
  useEffect(() => {
    if (subscribeNewsLetterResponse?.isSuccess) {
      setShow(true);
      setEmail('');
    }
    if (subscribeNewsLetterResponse?.isError) {
      setShow(true);
    }
  }, [subscribeNewsLetterResponse])

  const handleClick = (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== '' && emailPattern.test(email)) {
      subscribeNewsLetter({ email: email });
    }
  }

  const handleShow = () => {
    setShow(!show);
  }

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  return (
    <>
      <FooterUpdatesForm />
      {subscribeNewsLetterResponse?.isSuccess && show &&
        <Col md="12">
          <div className="productTopBar">
            <p>
              {subscribeNewsLetterResponse?.data?.message}
            </p>
            <Button onClick={handleShow} className="btn btn btn-primary">
              Close
            </Button>
          </div>
        </Col>
      }
      {subscribeNewsLetterResponse?.isError && show &&
        <Col md="12">
          <div className="productTopBar">
            <p>
              {subscribeNewsLetterResponse?.error?.data?.message}
            </p>
            <Button onClick={handleShow} className="btn btn btn-primary">
              Close
            </Button>
          </div>
        </Col>
      }
      <footer>
        {/* <header>
          <Navbar expand="lg" className='w-100 p-0'>
            <Container>
              <Navbar.Brand key="logo">
                <Link to="/">
                  <img src="/images/logo.svg" alt='#' />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  
                  <Link key="Shafts" to="/shafts/category/all" className='nav-link'>Shafts</Link>
                  <Link key="Gear" to="/gear/category/all" className="nav-link">Gear</Link>
                  <Link key="Compare" to="/product-comparison-tool" className="nav-link">Compare</Link>
                  <Link key="Dealers" to="/dealer" className="nav-link">Dealers</Link>
                  <Link key="Press Center" to="/press-center" className="nav-link">Press Center</Link>
                  <Link key="Support" to="/support" className="nav-link">Support</Link>
                 
                  <NavDropdown title="Store" id="basic-nav-dropdown">
                    <NavDropdown.Item >Drivers</NavDropdown.Item>
                    <NavDropdown.Item > Fairways </NavDropdown.Item>
                    <NavDropdown.Item >Hybrids</NavDropdown.Item>
                    <NavDropdown.Item >Irons</NavDropdown.Item>
                    <NavDropdown.Item > Putters </NavDropdown.Item>
                    <NavDropdown.Item >Gears</NavDropdown.Item>
                    <NavDropdown.Item >Kinetixx Fitting Tool</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Company" id="basic-nav-dropdown">
                    <NavDropdown.Item >Dealers</NavDropdown.Item>
                    <NavDropdown.Item > Kinetixx technology </NavDropdown.Item>
                    <NavDropdown.Item >Carbon Fiber technology</NavDropdown.Item>
                    <NavDropdown.Item >Press center</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Help" id="basic-nav-dropdown">
                    <NavDropdown.Item >Support</NavDropdown.Item>
                    <NavDropdown.Item > Kinetixx terms of use </NavDropdown.Item>
                    <NavDropdown.Item >Kinetixx privacy policy</NavDropdown.Item>
                  </NavDropdown>
                </Nav>

              </Navbar.Collapse>
              <div className='boxtab'>
                
              </div>
              {authUser?.userType != 3 && (
                <Link to="/dealer-registration" className='btn btn-primary'>Become a Dealer</Link>
              )}
            </Container>
          </Navbar>

        </header> */}
        <Container>
          <Row>
            <Col md="3">
              <Navbar.Brand key="logo">
                <Link to="/">
                  <img src="/images/logo.svg" alt='#' />
                </Link>
              </Navbar.Brand>
              <div className='social-icon my-4'>

                <ul>
                  {socialOptions.map(social => social.value ? <li key={social.key}><a href={social.value} target="_blank"><img src={`/images/${social.key}.svg`} alt="#" /></a></li> : null)}
                </ul>


              </div>
              <div className='social-icon m-0'>
                <form className='emailnews form-group' >
                  <input type="email" name="email" value={email} required className='form-control' placeholder='Enter your Email' onChange={handleChange} />
                  <button type="submit" class="btn-primary" onClick={handleClick}>GO</button>
                </form>
              </div>
            </Col>
            <Col md="1"></Col>
            <Col md="8">
              <div className='boxtype'>
                <div className='headingtab'>
                  <h4>Store</h4>
                  <ul>
                    <li><Link to="/shafts/category/driver">Drivers</Link></li>
                    <li><Link to="/shafts/category/fairway">Fairways</Link></li>
                    <li><Link to="/shafts/category/hybrid">Hybrids</Link></li>
                    <li><Link to="/shafts/category/iron">Irons</Link></li>
                    <li><Link to="/shafts/category/putters">Putters</Link></li>
                    <li><Link to="/gear/category/all">Gear</Link></li>
                    {/* <li><Link to="">Kinetixx Fitting Tool</Link></li> */}
                  </ul>
                </div>
                <div className='headingtab'>
                  <h4>Company</h4>
                  <ul>
                    <li><Link to="/pages/kinetixx-technology">Kinetixx Technology</Link></li>
                    {/* <li><Link to="">Dealers</Link></li> */}
                    <li><Link to="/pages/composite-fiber-technology">Composite Fiber Technology</Link></li>
                    <li><Link to="/press-center">Press Center</Link></li>
                    {/* <li><Link to="/pages/career">Careers</Link></li> */}
                    {/* <li><Link to="/pages/investor-relation">Investor Relations</Link></li> */}
                    <li><Link to="/dealer-registration">Become a Dealer</Link></li>
                    <li><Link to="/dealer">Find a Dealer</Link></li>
                    <li><Link to="/fitting-studios">Fitting Studios</Link></li>
                    <li><Link to="/contact">Contact Us</Link></li>
                  </ul>
                </div>
                <div className='headingtab'>
                  <h4>Help</h4>
                  <ul>
                    <li><Link to="/order">Order Status</Link></li>
                    <li><Link to="/terms">Terms of Use</Link></li>
                    <li><Link to="/terms-of-sale">Terms of Sale</Link></li>
                    <li><Link to="/privacy">Privacy Policy</Link></li>
                    <li><Link to="/warranty-info">Warranty Info</Link></li>
                    <li><Link to="/shipping-policy">Shipping Policy</Link></li>
                    <li><Link to="/return-policy">Return Policy</Link></li>

                    {/* <li><Link to="/faq">FAQs</Link></li>
                    <li><Link to="/shipping-and-returns">Shipping & Returns</Link></li> */}
                  </ul>
                </div>
              </div>
            </Col>
            {/* <Col md="3" className='text-end'>
                <Link to="/dealer-registration" className='btn btn-primary'>Become a Dealer</Link>
                
            </Col> */}
          </Row>
          <Row>
            {!cookiesAccepted && showPopup && <CookiePolicyPopup onAccept={handleAccept} onDecline={handleDecline} />}
          </Row>

        </Container>
        <p className='border-top'>©{year} Kinetixx<sup>R</sup>. All rights reserved.</p>
      </footer>
    </>
  );
}

export default Footer;

import { PRODUCTDETAIL_TAG } from "../../constants";
import { ApiService } from "./apiService";

export const ProductDetailAPI = ApiService.injectEndpoints({
  endpoints: (builder) => ({
    getProductDetail: builder.query(
      {
        query: (productSlug) => ({
          url: `/api/front/product/${productSlug}`,
          method: "GET",
        }),
        providesTags: [PRODUCTDETAIL_TAG],
      }
    ),
    addRemoveWishItem: builder.mutation(
      {
        query: ({ productId, addRemove }) => ({
          url: `/api/front/product/wishlist/${productId}`,
          method: "POST",
          body: {
            addRemove: addRemove
          }
        }),
      }
    ),
    addProductRating: builder.mutation(
      {
        query: (formData) => ({
          url: `/api/front/product/reviews/${formData.productId}`,
          method: "POST",
          body: {
            ...formData
          }
        }),
        invalidatesTags: ["orderDetailTag"]
      }
    ),
    getProductSelectorDetail: builder.mutation(
      {
        query: (formData) => ({
          url: `/api/front/product/product-selector`,
          method: "POST",
          body: {
            ...formData
          }
        }),
        invalidatesTags: [PRODUCTDETAIL_TAG],
      }
    ),
    getProductGraph: builder.mutation(
      {
        query: (formData) => ({
          url: `/api/front/product/product-chart`,
          method: "POST",
          body: {
            formData
          }
        }),
        invalidatesTags: [PRODUCTDETAIL_TAG],
      }
    ),
    getFlexCodeList: builder.query(
      {
        query: (productSlug) => ({
          url: `/api/front/product/flex-code`,
          method: "GET",
        }),
        providesTags: [PRODUCTDETAIL_TAG],
      }
    ),
    getIronNumberList: builder.query(
      {
        query: (productSlug) => ({
          url: `/api/front/product/iron-number`,
          method: "GET",
        }),
        providesTags: [PRODUCTDETAIL_TAG],
      }
    ),
  })
})

export const { useGetProductDetailQuery, useAddRemoveWishItemMutation, useAddProductRatingMutation, useGetProductSelectorDetailMutation, useGetProductGraphMutation, useGetFlexCodeListQuery, useGetIronNumberListQuery } = ProductDetailAPI;
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { useThankYouDeatilMutation } from "../redux-services/apis/cartApi";
import WishListMark from "./Product/WishListMark";
import Carousel from "react-multi-carousel";
import { getGearFilteredProducts } from "../redux-services/slices/productDataSlice";

const ThankYou = () => {
    const [params] = useSearchParams();
    const dispatch = useDispatch();
    const [thankYouDeatil, thankYouDeatilResponse] = useThankYouDeatilMutation();
    const [productList, setProductList] = useState();
    const getGearProducts = useSelector(getGearFilteredProducts);
    
    const responsive01 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1200, min: 991 },
            items: 2
        },
        minitablet: {
            breakpoint: { max: 991, min: 768 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    };
    useEffect(() => {
        if (params.get('order_id') !== '') {
            let paramId = params.get('order_id');
            thankYouDeatil({ paramId: paramId });
        }
    }, [params])

    useEffect(() => {
        if (thankYouDeatilResponse?.isSuccess) {
            let data = thankYouDeatilResponse?.data?.response?.stackableProducts;
            setProductList(data);
        }
    }, [thankYouDeatilResponse, productList]);

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);
    return <>
        <section className="loginOuter">
            <Container>
                <Row className="justify-content-center">
                    <Col md="8">
                        <div className="thankyou-outer">
                            <img src="images/checked.png" alt="#" />
                            <h3 className="alert alert-success">THANK YOU FOR YOUR PURCHASE</h3>
                            <h4 className="text-success">Your order has been placed and will be processed as soon as possible</h4>
                            <h5>Order Id: {params.get('order_id') ?? '#12345678'}</h5>
                            <Link to="/order" className="btn btn-primary">View Orders</Link>
                        </div>
                    </Col>
                    {productList !== undefined && productList?.length > 0 &&
                        <Col md="12" lg="12">
                            <div className="thankyou-outer">
                            <h3 className="thankyou-carousel-header">You might also like</h3>
                            </div>
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={true}
                                className={productList?.length <= 3 ? "thankyou-slider" : ''}
                                responsive={responsive01}
                                ssr={true}
                                infinite={true}
                                removeArrowOnDeviceType={productList == 1 &&
                                    ["superLargeDesktop", "desktop"]
                                }
                                keyBoardControl={true}
                            >
                                {productList.map((product) => (
                                    <div className="stackable-carousel" key={product?._id}>
                                        <div className="mainOuter-list">
                                            <div className="mainOuter">
                                                <div className="ItemImgOuter">
                                                    <Link to={`/${getGearProducts.includes(product?._id) ? 'gear' : 'shafts'}/${product?.slug}`}>
                                                        <img
                                                            src={product?.thumbnail ? process.env.REACT_APP_AWS_STORAGE_URL + '/uploads/product/' + product?.thumbnail : '/images/productdummy.png'}
                                                            alt={product?.product_title}
                                                            className="img-fluid"
                                                            onError={() => (this.src = '/images/productdummy.png')}
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="itemContentOuter">
                                                    <WishListMark productId={product?._id} />
                                                    <h4>{product?.sub_title}</h4>
                                                    <p>{product?.sub_heading}</p>
                                                    <h5>
                                                        <span style={{textTransform: 'capitalize'}}>
                                                            <Link to={`/${getGearProducts.includes(product?._id) ? 'gear' : 'shafts'}/${product?.slug}`}>
                                                                {product?.product_title} - {product?.category_detail?.slug}
                                                            </Link>
                                                        </span>
                                                    </h5>
                                                    <span>
                                                        From{' '}
                                                        <span>
                                                            $ {product?.msrp_price}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </Col>
                    }
                </Row>
            </Container>
        </section>
    </>
}

export default ThankYou;
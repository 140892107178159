import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';
import Spiner from '../components/Layouts/Spiner';

const DUMMY_IMAGE = '/images/productdummy.png';

const Technology = () => {
    const [techData, setTechData] = useState(null);
    const [cmsImages, setCMSImages] = useState([]);
    const params = useParams();
    let navigate = useNavigate();

    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: params.page_slug
    });


    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setTechData(getDataBySlug?.response[0])
        }
        if (getDataBySlug?.response[0]?.data) {
            const videoArray = [];
            const images = [];
            const playImage = '/images/play.svg';
            const productData = getDataBySlug.response[0]?.data;

            for (const item of productData) {
                images.push({
                    imageData: item?.images.length > 0 ? item.images.map(items => items) : [{
                        original: DUMMY_IMAGE,
                        thumbnail: DUMMY_IMAGE,
                    }],
                    videoUrl: item.videoUrl
                });
            }
            let videoData = getDataBySlug?.response[0]?.data.map(items => items.videoUrl)
            for (let key of videoData) {
                const thumbnailCode = key.substring(
                    key.lastIndexOf('/') + 1
                );
                const regex = /youtube.com/g;
                const found = key.match(regex);
                // videoArray.push({
                //     thumbnail: found
                //         ? `https://i.ytimg.com/vi/${thumbnailCode}/hqdefault.jpg`
                //         : playImage,
                //     original: playImage,
                //     embedUrl: key,
                //     thumbnailClass: 'video-slide',
                //     renderItem: renderVideo,
                // });
                videoArray.push({
                    thumbnail: playImage,
                    original: playImage,
                    embedUrl: key,
                    thumbnailClass: 'video-slide',
                    renderItem: function (onClick, item) {
                        return <div className='image-gallery-image'>
                            <iframe title='video' width='400' height='300' src={key} frameBorder='0' allowFullScreen></iframe>
                        </div>
                    },
                });
            }
            setCMSImages(images);

            for (let index in images) {
                if (images[index]?.videoUrl !== '' && images[index]?.videoUrl === videoArray[index]?.embedUrl) {
                    images[index].imageData.splice(0, 0, videoArray[index]);
                }
            }

        }
    }, [getDataBySlug]);

    const renderVideo = useCallback((item) => {
        return (
            <div className='video-outerpanel'>
                {
                    <div className="video-wrapper">
                        <iframe
                            src={`${item.embedUrl}`}
                        ></iframe>
                    </div>
                }
            </div>
        );
    }, []);

    const handleNavigate = (e, link) => {
        e.preventDefault();
        let url = link.split('.com');
        if (url.length > 0) {
            navigate(url[1])
        }
    }

    return (
        isLoading ? (
            <Spiner />
        ) : (
            <>
                <div>
                    {techData && techData?.status == 1 && techData != null ? (
                        <section className='InnerPage'>
                            <img
                                src={techData?.banner_image}
                                alt='Banner image'
                            />
                            <div className='banner-contentInner'>
                                <h2>{techData.page_title}</h2>
                                {/* <h4>Our proprietary vacuum curing process is catalyst for incomparable <span className='d-lg-block'>consistency between shafts of the same tolerance range.</span></h4> */}
                                <h4 style={{ margin: "0px 10px" }}>{techData.page_subtitle}</h4>
                            </div>
                        </section>
                    ) : (
                        <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                    )}
                    {techData?.data?.length > 0 && techData?.data.map((techItems, index) =>
                        <section className={index % 2 == 0 ? 'facility-outer' : 'technology-tabs'} key={techItems.title}>
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <div className='technology-mat-content text-center'>
                                            <h2>{techItems.title}</h2>
                                            <h4>{techItems.subtitle}</h4>
                                            <hr />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className='technology-mat-content'>
                                            {<ImageGallery items={cmsImages[index]?.imageData} />}
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: techItems.description,
                                                }}
                                            />
                                        </div>
                                        <div className="d-flex">
                                            {techItems?.button_Link && techItems?.button_Text !== "" &&
                                                <Button onClick={(e) => handleNavigate(e, techItems?.button_Link)} target="_blank" className="btn btn-primary">{techItems?.button_Text}</Button>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    )}
                </div>
            </>

        )
    );
}

export default Technology;

import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Button, ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { Range } from 'react-range';
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';
//state = { values: [50] };
const DriverSelectorIronFour = () => {
    const { product_category } = useParams();
    const productSelector = useSelector(getProductSelector);
    const [config, setConfig] = useState('60-75g');
    const dispatch = useDispatch();
    const location = useLocation();
    const parts = location?.pathname.split("/");
    const endString = parts[parts.length - 1];
    const [cmsData, setCmsData] = useState();
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: endString,
    });

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    useEffect(() => {
        let setProduct = {
            ...productSelector,
            questions: { ...productSelector.questions, iron_shaft_weight: config },
        }
        dispatch(setProductSelector(setProduct))
    }, [config])

    const handleClick = useCallback((e) => {
        let data = e.currentTarget.getAttribute("data-id");
        setConfig(data);
    });

    const now = 71.4;

    return <>
        <section className="Driver-selecter-outer facility-outer irons-tabs pb-0">
            <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="technology-mat-content text-center"><h2>{cmsData?.page_title}</h2><h4>{cmsData?.page_subtitle}</h4><hr /></div>
                    </Col>
                    <Col md="12">
                        <div className="iron-content text-center">
                            <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                            <p className="questions">QUESTION 5/7</p>
                            <h4>Current Iron Shaft weight</h4>
                            <span className="selectone">Please select one.</span>
                            <div className="labes-type">
                                <div className="lab-outer">
                                    <input type="radio" id="configuration1" name="Configuration" value="configuration1" onClick={handleClick} data-id="60-75g" defaultChecked />
                                    <label for="configuration1">60-75g</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration2" name="Configuration" value="configuration2" onClick={handleClick} data-id="76-90g" />
                                    <label for="configuration2">76-90g</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration3" name="Configuration" value="configuration3" onClick={handleClick} data-id="91-105g" />
                                    <label for="configuration3">91-105g</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration4" name="Configuration" value="configuration4" onClick={handleClick} data-id="106-130g" />
                                    <label for="configuration4">106-130g</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration5" name="Configuration" value="configuration5" onClick={handleClick} data-id="Not Sure" />
                                    <label for="configuration5">Not Sure</label>
                                </div>
                            </div>
                            <div className="back-next">
                                <Link to="/kinetixx-fit-iron-three/iron" >Go Back</Link>
                                <Link to="/kinetixx-fit-iron-five/iron" className="btn-primary">Next Question</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </>
}

export default DriverSelectorIronFour;
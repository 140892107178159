import { CMS_TAG } from '../../constants';
import { ApiService } from './apiService';

const BASE_URL = '/api/front';
export const TechnologyApi = ApiService.injectEndpoints({
    endpoints: (builder) => ({
        getDataBySlug: builder.query({
            query: (query) => ({
                url: `${BASE_URL}/cms`,
                method: 'GET',
                params: {
                    slug: query.slug,
                },
            }),
            providesTags: [CMS_TAG],
        }),
        getAllCMSData: builder.query({
            query: (cmsData) => ({
                url: `${BASE_URL}/cms/allData`,
                method: 'GET',
                body: {
                    ...cmsData,
                }
            }),
        }),
        getDataBySectionSlug: builder.query({
            query: (query) => ({
                url: `${BASE_URL}/cms/section-slug`,
                method: 'GET',
                params: {
                    slug: query.slug,
                },
            }),
            providesTags: [CMS_TAG],
        }),
    }),
});

export const { useGetDataBySlugQuery, useGetDataBySectionSlugQuery } = TechnologyApi;

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetDataBySlugQuery } from '../../redux-services/apis/technologyApi';
import Spiner from '../../components/Layouts/Spiner';
import { Container, Row } from 'react-bootstrap';

const PrivacyPolicy = () => {
    const [techData, setTechData] = useState(null);
    const location = useLocation();

    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: location?.pathname.replace('/', '')
    });

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setTechData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    return (
        isLoading ? (
            <Spiner />
        ) : (
            techData && techData?.status == 1 && techData != null ? (
                <>
                    <div>
                        <section className='InnerPage'>
                            <img
                                src={techData?.banner_image}
                                alt='Banner image'
                            />
                            <div className='banner-contentInner'>
                                <h2>{techData.page_title}</h2>
                                <h4 style={{ margin: "0px 10px" }}>{techData.page_subtitle}</h4>
                            </div>
                        </section>
                        <section className='productlist-outer'>
                            <Container>
                                <Row className='justify-content-center'>
                                     <div
                                        dangerouslySetInnerHTML={{
                                            __html: techData?.page_description,
                                        }}
                                    />
                                </Row>
                            </Container>
                        </section>
                    </div>
                </>
            ) : (
                <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
            )
        )
    );
}

export default PrivacyPolicy;

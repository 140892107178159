import React, { useCallback, useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { IoIosArrowBack } from 'react-icons/io';
import { useSelector } from "react-redux";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getCategoryList } from "../redux-services/slices/siteDataSlice";
import CompareProduct from "./Product/ProductDetail/Compare/CompareProduct";
import CompareSelection from "./Product/ProductDetail/Compare/CompareSelection";


const Compare = () => {

    const navigate = useNavigate();
    const [searchParams, _setSearchParams] = useSearchParams();
    const categories = useSelector(getCategoryList);
    const [isActive, setIsActive] = useState(false);
    const handleToggle = useCallback((e) => {
        e.preventDefault();
        setIsActive(!isActive);
        // goBack();
    }, [isActive]);


    const goBack = useCallback(() => {
        navigate(-1);
    }, []);

    return (

        <>
            <section className='compareOuter '>
                <div className='headcenter'>
                    <img src="/images/compare-banner.png" alt='#' />
                    <div>
                        <h2>For Guaranteed Longer, Straighter Play</h2>
                        <h4>Use this Comparison Tool</h4>
                    </div>
                </div>
                <div className='compareproduct-mainouter'>
                <div className='box-outer'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md="12">
                                <div className="showcompare">
                                    <Row>
                                        <Col md="6" className="text-start"><Button variant="link" className="btn-link" onClick={goBack} style={{fontSize: "20px"}}><IoIosArrowBack />Back</Button></Col>
                                        <Col md="6"><Link onClick={handleToggle} style={{fontSize: "20px"}}>new comparison</Link></Col>
                                    </Row>
                                </div>
                                {categories.length && isActive &&
                                    <CompareSelection
                                        setIsActive={setIsActive}
                                        product={searchParams.get("productOne")}
                                        product2={searchParams.get("productTwo")}
                                        flex1={searchParams.get("flexOne")}
                                        flex2={searchParams.get("flexTwo")}
                                        catDisabled={false}
                                    />
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
                {!isActive &&
                    <CompareProduct
                        productOneSlug={searchParams.get("productOne")}
                        flex1={searchParams.get("flexOne")}
                        flex2={searchParams.get("flexTwo")}
                        iron1={searchParams.get("ironOne")}
                        iron2={searchParams.get("ironTwo")}
                        tip1={searchParams.get("tipOne")}
                        tip2={searchParams.get("tipTwo")}
                    />
                }
                </div>
            </section>
        </>
    );
}

export default Compare;

import React, { } from "react";
import Header from "./Header/Header";
import Footer from './Footer/Footer';
import { Outlet } from "react-router-dom";
import Spiner from "./Spiner";
import { useRef } from "react";


import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Layout = ({ setScrollDown }) => {
  const tawkMessengerRef = useRef();

  return (

    <>
      <Header setScrollDown={setScrollDown} />
      <Spiner />
      <Outlet />
      <Footer />

      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_TO_PRODUCT_ID}
        widgetId={process.env.REACT_APP_TAWK_TO_WIDGET_ID}
        useRef={tawkMessengerRef} />

    </>
  );
};

export default Layout;
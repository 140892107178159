import { AUTH_TAG, USER_ADDRESS, USER_ORDER } from "../../constants";
import { ApiService } from "./apiService";

const URL = "/api/front/users";

export const UserApi = ApiService.injectEndpoints({
    endpoints: (builder) => ({
        getUserAddress: builder.query(
            {
                query: () => ({
                    url: `${URL}/get-address`,
                    method: "GET",
                }),
                providesTags: [USER_ADDRESS, AUTH_TAG],
            }
        ),
        addAddress: builder.mutation({
            query: (addressData) => ({
                url: `${URL}/add-address`,
                method: "POST",
                body: addressData,
            }),
            invalidatesTags: [USER_ADDRESS]
        }),
        editAddress: builder.mutation({
            query: (addressData) => ({
                url: `${URL}/edit-address/${addressData.id}`,
                method: "PUT",
                body: addressData,
            }),
            invalidatesTags: [USER_ADDRESS]
        }),
        getUserOrder: builder.query(
            {
                query: (pageData) => ({
                    url: `/api/front/order`,
                    method: "GET",
                    params: {
                        page: pageData.page ?? 1,
                        per_page: pageData.per_page ?? 10,
                        search: pageData.search ?? "",
                        days_filter: pageData.days_filter ?? "",
                    }
                }),
                providesTags: [USER_ORDER],
            }
        ),
        getOrderDetail: builder.query(
            {
                query: ({ orderId }) => ({
                    url: `/api/front/order/${orderId}`,
                    method: "GET",
                }),
                providesTags: [USER_ORDER],
            }
        ),
        updateProfile: builder.mutation({
            query: (addressData) => ({
                url: `${URL}/update-profile/${addressData.id}`,
                method: "PUT",
                body: addressData,
            }),
            invalidatesTags: (result, _error) => result === undefined ? [] : [AUTH_TAG]
        }),
        resetUserPassword: builder.mutation({
            query: (resetData) => ({
                url: `${URL}/reset-password`,
                method: "POST",
                body: resetData,
            }),
            invalidatesTags: [AUTH_TAG]
        }),
        deleteAddress: builder.mutation({
            query: ({ address_id }) => ({
                url: `${URL}/delete-address/${address_id}`,
                method: "DELETE",
            }),
            invalidatesTags: [USER_ADDRESS]
        }),
        markAsPrimary: builder.mutation({
            query: ({ dealer_id, dealer_address_id }) => ({
                url: `${URL}/markPrimary/${dealer_id}/${dealer_address_id}`,
                method: "POST",
            }),
            invalidatesTags: [USER_ADDRESS]
        }),
        downLoadOrderInvoice: builder.query(
            {
                query: ({ orderId }) => ({
                    url: `/api/front/order/download-invoice/${orderId}`,
                    method: "GET",
                }),
            }
        ),
        orderPayment: builder.mutation(
            {
                query: (paymentDetail) => ({
                    url: `/api/front/order/order-payment/${paymentDetail.order_id}`,
                    method: "POST",
                    body: paymentDetail
                }),
                invalidatesTags: [USER_ORDER],
            }
        ),
    })
})

export const {
    useGetUserAddressQuery,
    useAddAddressMutation,
    useEditAddressMutation,
    useGetUserOrderQuery,
    useGetOrderDetailQuery,
    useUpdateProfileMutation,
    useResetUserPasswordMutation,
    useDeleteAddressMutation,
    useMarkAsPrimaryMutation,
    useDownLoadOrderInvoiceQuery,
    useOrderPaymentMutation
} = UserApi;
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEmail,
  onEmailInput,
  onDeleteEmail,
  setError,
} from '../../redux-services/slices/notifyEmailsSlice';

const NotifyEmails = () => {
  const notifyEmails = useSelector((state) => state.notifyEmail.notifyEmails);
  const inputEmail = useSelector((state) => state.notifyEmail.value);
  const error = useSelector((state) => state.notifyEmail.error);

  const dispatch = useDispatch();

  const isInList = (email) => {
    return notifyEmails.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      dispatch(setError(error));

      return false;
    }

    return true;
  };

  const onKeyDown = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();

      let value = inputEmail.trim();

      if (value && isValid(value)) {
        dispatch(addEmail([value]));
      }
    }
  };

  const onChange = (e) => {
    dispatch(onEmailInput(e.target.value));
  };

  const onDelete = (email) => {
    dispatch(onDeleteEmail(email));
  };

  const onPaste = (e) => {
    e.preventDefault();

    let paste = e.clipboardData.getData('text');
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !isInList(email));

      dispatch.addEmail([...toBeAdded]);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {notifyEmails.map((email) => (
          <div key={email} style={{ display: 'flex' }}>
            {email}
            <Button
              type="button"
              className="button"
              onClick={() => onDelete(email)}
              style={{
                marginLeft: '3px',
                marginRight: '5px',
                height: '6px',
                width: '1px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              &times;
            </Button>
          </div>
        ))}
      </div>

      <div className='form-group mt-2'>
        <input
          value={inputEmail}
          placeholder={'Type the email ID and hit "enter" or "return" key'}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onPaste={onPaste}
          className='form-control'

        />
      </div>
      {error && (
        <p className="error" style={{ color: 'red', marginBottom: '20px' }}>
          {error}
        </p>
      )}
    </>
  );
};

export default NotifyEmails;

import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { setFittingOnSpecs } from "../../../../redux-services/slices/siteDataSlice";
import SelectProduct from "./SelectProduct";

const CompareSelection = ({ product, product2, flex1, flex2,iron1, iron2, tip1, tip2, setIsActive, productDetail }) => {
	const [productOne, setProductOne] = useState(null);
	const [productTwo, setProductTwo] = useState(null);
	
	const [flexOne, setFlexOne] = useState(null);
	const [flexTwo, setFlexTwo] = useState(null);
	
	const [ironOne, setIronOne] = useState(null);
	const [ironTwo, setIronTwo] = useState(null);	
	
	const [tipOne, setTipOne] = useState(null);
	const [tipTwo, setTipTwo] = useState(null);
	
	const [category, setCategory] = useState('');

	useEffect(() => {

		if (flex1) {
			setFlexOne(flex1);
		}
		if (flex2) {
			setFlexTwo(flex2);
		}
		if (iron1) {
			setIronOne(iron1);
		}
		if (iron2) {
			setIronTwo(iron2);
		}
		if (tip2) {
			setTipTwo(tip2);
		}
		if (tip1) {
			setTipOne(tip1);
		}

	}, [flex1, flex2, iron1, iron2, tip1, tip2])

	const navigate = useNavigate();

	const onCompare = (e) => {
		e.preventDefault();
		navigate({
			pathname: "/compare",
			search: createSearchParams({
				productOne: productOne.slug,
				productTwo: productTwo.slug,
				flexOne,
				ironOne,
				tipOne,
				flexTwo,
				ironTwo,
				tipTwo,
			}).toString()

		})
		if (setIsActive) {
			     setIsActive(false);
		}
	}

	const dispatch = useDispatch();

	useEffect(() => {

		if (productOne && flexOne && ((
			category.name.toLowerCase() === 'iron' && ironOne && tipOne) || 
			(category.name.toLowerCase() !== 'iron'
			))) {
			dispatch(setFittingOnSpecs({ 
				key: 'productOne', 
				product: productOne, 
				flex: flexOne, 
				iron: ironOne, 
				tip: tipOne 
			}));
		}
	}, [flexOne, productOne, ironOne, tipOne]);

	useEffect(() => {
		if (productTwo && flexTwo && ((category.name.toLowerCase() === 'iron' && ironTwo && tipTwo) || (category.name.toLowerCase() !== 'iron'))) {
			dispatch(setFittingOnSpecs({ 
				key: 'productTwo', 
				product: productTwo, 
				flex: flexTwo, 
				iron: ironTwo, 
				tip: tipTwo 
			}));
		}
	}, [flexTwo, productTwo, ironTwo, tipTwo]);


	return <div className="comparehideblock" >
		<form onSubmit={onCompare}>
			<div className='headmain'>
				<SelectProduct
					SelectTitle={'Select Shaft'}
					productId={product}
					setSelectedProduct={setProductOne}
					selectedProduct={productOne}
					flex={flexOne}
					setFlex={setFlexOne}
					iron={ironOne}
					setIron={setIronOne}
					tip={tipOne}
					setTip={setTipOne}
					category={category}
					setCategory={setCategory}
					productDetail={productDetail}
				/>

				<SelectProduct
					SelectTitle={'Select Shaft'}
					setSelectedProduct={setProductTwo}
					flex={flexTwo}
					productId={product2}
					selectedProduct={productTwo}
					setFlex={setFlexTwo}
					iron={ironTwo}
					setIron={setIronTwo}
					tip={tipTwo}
					setTip={setTipTwo}
					category={category}
					setCategory={setCategory}
					productDetail={productDetail}
				/>
			</div>

			<div className="text-center mt-5 mb-3">
				<Button type="submit">Compare</Button>
			</div>

		</form>
	</div>
}

export default CompareSelection;
import React, { useState, useEffect } from 'react';
import { Container, Row } from "react-bootstrap";
import { useParams, useLocation } from 'react-router-dom';
import { useGetDataBySlugQuery } from '../../redux-services/apis/technologyApi';
import Spiner from '../../components/Layouts/Spiner';
import CompareSelection from '../Product/ProductDetail/Compare/CompareSelection';

const ProductComparisonTool = () => {
    const [techData, setTechData] = useState(null);
    const params = useParams();
    const location = useLocation();

    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: location?.pathname.replace('/', '')
    });

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setTechData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    return (
        isLoading ? (
            <Spiner />
        ) : (
            <>
                {techData && techData?.status == 1 && techData != null ? (
                    <div>
                        <section className='InnerPage'>
                            <img
                                src={techData?.banner_image}
                                alt='Banner image'
                            />
                            <div className='banner-contentInner'>
                                <h2>{techData.page_title}</h2>
                                <h4 style={{ margin: "0px 10px" }}>{techData.page_subtitle}</h4>
                            </div>
                        </section>
                    </div>
                ) : (
                    <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                )}
                <div className='productlist-outer'>
                    <Container>
                        <Row className='justify-content-center'>
                            <CompareSelection />
                        </Row>
                    </Container>
                </div>
            </>
        )
    );
}

export default ProductComparisonTool;

import React from 'react';
import {Helmet} from "react-helmet";

const MetaData = ({ metaData }) => {
  return (
    <Helmet>
      <title>{metaData ? metaData?.meta_title : ""}</title>
      <meta
        name="description"
        content={metaData ? metaData?.meta_description : ""}
      />
      {/* <meta name="robots" content="max-snippet:100" /> */}
       <meta property="og:title" content={metaData ? metaData?.meta_title : ""} />
      <meta
        property="og:description"
        content={metaData ? metaData?.meta_description : ""}
      />
      {/* <meta name="og:tags" content={metaData ? metaData?.tags : ""} /> */}
      {/* <meta
        name="og:images"
        content={metaData ? metaData?.seo_image?.fullUrl : ""}
      /> */}
    </Helmet>
  );
};
export default MetaData;

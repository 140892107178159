import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { useEffect } from 'react';
import {
  getSelectedShippingRate,
  getShippingRates,
  setSelectedShippingRate,
  getCartData,
} from '../../redux-services/slices/cartSlice';
import {
  useApplyCouponMutation,
  useRemoveCouponMutation,
} from '../../redux-services/apis/cartApi';
import { getUserDetails } from '../../redux-services/slices/authSlice';
const ShippingMethod = ({ selectedShippingAddress, addressList }) => {
  console.log('selectedShippingAddress', selectedShippingAddress);
  const [applyCouponSubmit, couponResponse] = useApplyCouponMutation();
  const [removeCouponSubmit, _removeCouponResponse] = useRemoveCouponMutation();
  const shippingMethod = useSelector(getShippingRates);
  let groupedRatesByProvider = {};
  const user = useSelector(getUserDetails);
  const carDetails = useSelector(getCartData);
  const dispatch = useDispatch();

  const selectedShippingMethod = useSelector(getSelectedShippingRate);

  const groupRatesByProvider = (rate, key) => {
    return rate.reduce((reduceRate, reduceKey) => {
      (reduceRate[reduceKey[key]] = reduceRate[reduceKey[key]] ?? []).push(
        reduceKey
      );
      return reduceRate;
    }, {});
  };

  if (shippingMethod?.length > 0) {
    groupedRatesByProvider = groupRatesByProvider(shippingMethod, 'carrier');
  }

  const applyCoupon = (shipRate) => {
    applyCouponSubmit({
      couponCode: carDetails.coupon?.coupon_code,
      cartTotal: carDetails.totalProduct,
      userId: user?._id,
      shippingPrice: shipRate,
    });
  };

  const convertServiceTypes = (inputString) => {
    if (inputString.includes("_")) {
      return inputString
    }
    // Use a regular expression to insert spaces before capital letters
    const spacedString = inputString.replace(/([A-Z])/g, ' $1').trim();
    // Separate "AM" from other words by inserting a space before and after "AM"
    let finalString = spacedString.replace(/\bA M\b/g, 'AM');
    finalString = finalString.replace(/\bU P S\b/g, 'UPS');
    return finalString;
  }

  const convertCarrierTypes = (inputString) => {
    if (inputString === "UPSDAP") {
      return "UPS"
    }
    if (inputString === "FedExDefault") {
      return "FedEx Default"
    }
    if (inputString === "DHLExpress") {
      return "DHL Express"
    }
    return inputString
  }

  const onChangeShipping = (shipRate) => {
    console.log('CART DETAILS', carDetails);
    if (carDetails.coupon?.applicable_for === 'shipping') {
      applyCoupon(shipRate);
    }
    dispatch(setSelectedShippingRate(shipRate ?? {}));
  };

  return (
    <div className="Contact-informations">
      <div className="contacttop">
        <h5>Shipping method</h5>
      </div>
      {/* <ul className="addressList">
        {selectedShippingAddress && shippingMethod?.length > 0 ? (
          shippingMethod?.map((shipRate) => (
            <li key={shipRate.rate_id}>
              <input
                type="radio"
                checked={selectedShippingMethod?.rate_id == shipRate.rate_id}
                onChange={(e) => onChangeShipping(shipRate)}
                id={shipRate.rate_id}
                name="ship_rate"
                value="HTML"
              />
              <label htmlFor={shipRate.rate_id}>
                <span className="fulladdress">
                  <b>{shipRate.carrier}</b> ({shipRate.service}){' '}
                  <span>
                    {shipRate.est_delivery_days ?? 6} to{' '}
                    {shipRate.est_delivery_days
                      ? shipRate.est_delivery_days + 2
                      : 12}{' '}
                    business days
                  </span>
                </span>
                <span>${shipRate.totalCharges}</span>
              </label>
            </li>
          ))
        ) : (
          <div className="emptyaddressmsg">
            {selectedShippingAddress
              ? 'We can not ship to the selected address.'
              : 'Please ' +
                (addressList > 0 ? 'select' : 'add') +
                ' shipping address first.'}
          </div>
        )}
      </ul> */}
      {selectedShippingAddress ?? shippingMethod.length > 0 ? (
        <div style={{
          border: '1px solid #ccc', overflowY: 'auto', padding: "10px 0px 10px 0px", borderRadius: "5px", background: "#fffef5",
          maxHeight: "500px"
        }}>
          <Container style={{ margin: "0" }}>

            {Object.entries(groupedRatesByProvider)?.map(
              ([carrier, rates]) => {
                return (
                  <div className="shipping-flex" key={carrier} style={{ marginBottom: "10px" }}>
                    <div className="contacttop">
                      <h5>
                        {convertCarrierTypes(carrier)}
                      </h5>
                    </div>
                    <Row>
                      {rates?.map((value, key) => (
                        <Col xs={12} sm={6} md={4} key={carrier + '-' + key}>
                          <div className="radio d-flex" style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                            <input
                              type="radio"
                              checked={selectedShippingMethod?.rate_id == value.rate_id}
                              onChange={(e) => onChangeShipping(value)}
                              id={value.rate_id}
                              name="ship_rate"
                              value="HTML"
                              style={{ marginRight: "5px" }}
                            />
                            <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                              {' '}
                              {convertServiceTypes(value.service)} : ${value.totalCharges}
                            </p>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                );
              }
            )}

          </Container>
        </div>)
        : (
          <div className="emptyaddressmsg">
            {selectedShippingAddress
              ? 'We can not ship to the selected address.'
              : 'Please ' +
              (addressList > 0 ? 'select' : 'add') +
              ' shipping address first.'}
          </div>
        )}
    </div>
  );
};

export default ShippingMethod;

import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useCalculateShippingRateMutation } from "../../redux-services/apis/cartApi";
import { getUserDetails } from "../../redux-services/slices/authSlice";
import { getCurrentCart } from "../../redux-services/slices/cartSlice";
import AddAddress from "./AddAddress";

const AddressList = ({ addressList, addressId, setAddressId, calculateShipping }) => {

    const [addNew, setAddNew] = useState(false);

    const currentCart = useSelector(getCurrentCart);

    const [newAdded, setNewAdded] = useState(false);

    const user = useSelector(getUserDetails);

    const [addressListData, setAddressListData] = useState([]);

    const [search, setSearch] = useState('');

    const handleClose = () => setAddNew(false);

    const [calculateShippingRates, _shippingResponse] = useCalculateShippingRateMutation();

    const addressChange = (address_id, addNew = false) => {
        setAddNew(addNew);
        setNewAdded(false);
        setAddressId(address_id);
        if (address_id != "" && calculateShipping) {
            calculateShippingRates({
                userId: user?._id,
                toAddress: address_id,
                currentCart
            })
        }
    }

    const changeSearch = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        if (search != '' && addressListData) {
            const Addresses = addressList.filter(address => address.address.toLowerCase().includes(search.toLowerCase()));
            console.log(Addresses);

            setAddressListData(Addresses);
        } else {
            setAddressListData(addressList);
        }
        if (newAdded) {
            setAddressId(addressList[0]?._id)
        }
    }, [search, addressList]);

    return <>
        <Row>
            <Col md="12">
                <div className="form-group searchaddressinput">
                    <input
                        type='text'
                        placeholder='Search Address'
                        className='form-control'
                        value={search}
                        onChange={(e) => changeSearch(e)}
                    />
                </div>
            </Col>
        </Row>

        <ul className={!addNew ? 'addressList address-fixed' : 'addressList '} >
            {addressListData?.map(addressData => <li key={addressData._id}>
                <input
                    type="radio"
                    id={addressData._id}
                    checked={newAdded ? (addressData?._id === addressListData[0]._id) : (addressData?._id == addressId)}
                    name="address"
                    value={addressData._id}
                    onChange={(e) => addressChange(addressData._id)}
                />
                <label htmlFor={addressData._id}>
                    <span className='address-contact'>{addressData.name} ({addressData.email} {addressData.ccode}{addressData.contact_no})  </span>
                    <address className='fulladdress'>{addressData.address}</address>
                </label>
            </li>)}



        </ul>
        <li key="new" className="add-new-address">
            <input type="radio" id="Address" checked={addNew} name="address" onChange={(e) => addressChange("", true)} />
            <label htmlFor="Address">
                <span className='fulladdress'>Add Address</span>
            </label>
        </li>
        {
            addNew &&

            <AddAddress setAddressId={addressChange} setAddNew={setAddNew} setNewAdded={setNewAdded} onClose={handleClose} />
        }
    </>
}

export default AddressList;
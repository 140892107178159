import React, { useCallback, useEffect, useState, createRef } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  FormText,
  Modal,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import Autocomplete from 'react-google-autocomplete';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import extractNumber from '../../utills/extractNumber';
import { getCountryShort } from '../../utills/countryCode';
import ShowError from '../Notifications/ShowError';
import { FaTiktok, FaFacebookF, FaSnapchat } from 'react-icons/fa';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { BsYoutube, BsTwitter, BsInstagram } from 'react-icons/bs';
import { useAddDealerDetailsMutation } from '../../redux-services/apis/dealerApi';
import { GOOGLE_MAPS_API_KEY, RECAPTCHA_SITE_KEY } from '../../config';
import LookupCountry from '../../components/CountryCode/LookupCountry';
import { getUserDetails } from '../../redux-services/slices/authSlice';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { showModel } from "../../redux-services/slices/siteDataSlice";
import ShowModel from '../Notifications/ShowModel.js';

const DealersRegistration = () => {
  const initialState = {
    first_name: '',
    last_name: '',
    email: '',
    business_name: '',
    business_email: '',
    ccode: '+1',
    contact_no: '',
    iute164_phone: '',
    website: '',
    business_license_no: '',
    business_model_description: '',
    annual_sales_volume: '',
    shaft_clubhead_component_vendors: '',
    password: '',
    confirm_password: '',
    address: '',
    latitute: 0,
    longitude: 0,
    address_line_1: '',
    address_line_2: '',
    selectedFile: '',
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: '',
    image: '',
    terms_and_policy_checked: false,
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    tiktok: '',
  };

  let navigate = useNavigate();
  let dispatch = useDispatch();
  const recaptchaRef = createRef();
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [addDealerDetails, addDealerDetailsResponse] =
    useAddDealerDetailsMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState('US');
  const [defaultCountryCode, setDefaultCountryCode] = useState('+1');
  const [formEvent, setFormEvent] = useState();
  const [message, setMessage] = useState('');
  // const [countryCode, setCountryCode] = useState();
  const authUser = useSelector(getUserDetails);
  const [autocompleteValue, setAutocompleteValue] = useState('');

  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);

  const [userType, setUserType] = useState('');

  const handleCloseTerms = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchDefaultCountryCode = async () => {
      try {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        const countryCode = data.country_code || '';

        // Use libphonenumber-js to format the country code
        const phoneNumber = getCountryCallingCode(countryCode);


        if (phoneNumber) {
          setDefaultCountry(countryCode);
          setFormData({ ...formData, ccode: `+${phoneNumber}` });
          setDefaultCountryCode(`+${phoneNumber}`);

        }
      } catch (error) {
        console.error('Error fetching default country code:', error);
      }
    };

    fetchDefaultCountryCode();
  }, []);

  const {
    first_name,
    last_name,
    email,
    business_name,
    business_email,
    ccode,
    iute164_phone,
    website,
    business_license_no,
    business_model_description,
    annual_sales_volume,
    shaft_clubhead_component_vendors,
    password,
    confirm_password,
    address,
    latitute,
    longitude,
    address_line_1,
    address_line_2,
    city,
    selectedFile,
    zipcode,
    country,
    state,
    image,
    terms_and_policy_checked,
    facebook,
    instagram,
    twitter,
    youtube,
    snapchat,
    tiktok,
  } = formData;



  const onChange = (e) => {
    e.preventDefault();

    if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);

      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
      });
      document.getElementById(`thumbnail`).value = '';
    } else {
      const newFormData = { ...formData, [e.target.name]: e.target.value };
      newFormData['address'] = `${newFormData.address_line_1} ${newFormData.address_line_2 ? newFormData.address_line_2 + ',' : ''
        } ${newFormData.city} ${newFormData.state} ${newFormData.zipcode} ${newFormData.country
        }`;
      setFormData(newFormData);
      setErrors({});
    }
  };

  const onPhoneChange = (number) => {
    const contact_no = extractNumber(number, defaultCountryCode);
    setFormData((form) => ({
      ...form,
      contact_no: contact_no,
      iute164_phone: number ?? '',
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      ccode: '+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)),
    }));
  };

  const handleCheckedChange = (e) => {
    setFormData({
      ...formData,
      terms_and_policy_checked: e.target.checked,
    });
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (e.currentTarget.getAttribute("data-id") == "password") {
      setPasswordShown(!passwordShown);
      setConfirmPassword(confirmPassword);
    }
    if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
      setPasswordShown(passwordShown);
      setConfirmPassword(!confirmPassword);
    }
  };


  const setGMapPlace = useCallback((location) => {
    const google_id = location.id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let latitute = location?.geometry?.location?.lat((e) => e.Scopes[0].e);
    let longitude = location?.geometry?.location?.lng((e) => e.Scopes[0].f);

    let city = '';
    let state = '';
    let address_line_1 = '';
    let address_line_2 = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? ' ' : '') + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? ' ' : '') + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case 'administrative_area_level_2':
          case 'postal_town':
            if (city == '') {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      });
    setFormData((formData) => ({
      ...formData,
      address,
      city,
      latitute,
      longitude,
      address_line_1,
      address_line_2,
      google_id,
      google_place_id,
      state,
      country,
      country_code,
      zipcode,
      // location: geometry,
    }));
    setAutocompleteValue('');
  });

  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  const handleClose = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (addDealerDetailsResponse?.isError) {
      if (addDealerDetailsResponse?.error?.data?.errors.length != undefined) {
        let errorsData = {};
        addDealerDetailsResponse?.error?.data?.errors?.forEach((element) => {
          errorsData[element.param] = element.msg;
        });
        setErrors(errorsData);
      }
      dispatch(showModel({ isOpen: true, modelType: "danger", modalClass: "dealer", body: addDealerDetailsResponse?.error?.data?.message }))
    }
    if (addDealerDetailsResponse?.isSuccess) {
      dispatch(showModel({ isOpen: true, modelType: "success", modalClass: "dealer", body: addDealerDetailsResponse?.data?.message }))
      setValidated(false);
      setFormData(initialState);
    }
  }, [addDealerDetailsResponse]);

  const onChangePlaceholder = () => {
    setHide(!hide);
  };

  const onReCAPTCHAChange = async (captchaCode) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      setMessage("Please Select the  Recaptcha")
      return false;
    } else {
      submitHandler(captchaCode)
    }
  }

  const generateCaptach = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setFormEvent(form);
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    recaptchaRef.current.execute();
  }

  const submitHandler = (token) => {

    if (formData.terms_and_policy_checked) {
      if (formEvent.checkValidity()) {
        setFormData({
          ...formData,
        });

        let paramDta = new FormData();

        paramDta.append('file', formData.selectedFile); //image file
        paramDta.append('first_name', formData.first_name);
        paramDta.append('last_name', formData.last_name);
        paramDta.append('email', formData.email);
        paramDta.append('business_name', formData.business_name);
        paramDta.append('business_email', formData.business_email);
        paramDta.append('ccode', defaultCountryCode);
        paramDta.append('contact_no', formData.contact_no);
        paramDta.append('iute164_phone', formData.iute164_phone);
        paramDta.append('website', formData.website);
        paramDta.append('business_license_no', formData.business_license_no);
        paramDta.append(
          'business_model_description',
          formData.business_model_description
        );
        paramDta.append('annual_sales_volume', formData.annual_sales_volume);
        paramDta.append(
          'shaft_clubhead_component_vendors',
          formData.shaft_clubhead_component_vendors
        );
        paramDta.append('password', formData.password);
        paramDta.append('confirm_password', formData.confirm_password);
        paramDta.append('image', formData.image);
        paramDta.append('dealer_type_id', formData.dealer_type_id);
        paramDta.append('address', formData.address);
        paramDta.append('address_line_1', formData.address_line_1);
        paramDta.append('address_line_2', formData.address_line_2);
        paramDta.append('city', formData.city);
        paramDta.append('zipcode', formData.zipcode);
        paramDta.append('country', formData.country);
        paramDta.append('state', formData.state);
        paramDta.append('google_id', formData.google_id ?? '');
        paramDta.append('latitute', formData.latitute ?? null);
        paramDta.append('longitude', formData.longitude ?? null);
        paramDta.append('google_place_id', formData.google_place_id);
        paramDta.append('country_code', formData.country_code);
        paramDta.append('facebook', formData.facebook);
        paramDta.append('instagram', formData.instagram);
        paramDta.append('twitter', formData.twitter);
        paramDta.append('youtube', formData.youtube);
        paramDta.append('snapchat', formData.snapchat);
        paramDta.append('tiktok', formData.tiktok);
        paramDta.append('thumbnail', formData.thumbnail);
        paramDta.append(
          'terms_and_policy_checked',
          formData.terms_and_policy_checked
        );
        paramDta.append('token', token);

        setErrors({});
        setIsOpen(true);
        addDealerDetails(paramDta);
      }
    }
    recaptchaRef.current.reset();
  };

  return (
    <>
      <section className="profileDatatop">
        <Container>
          <Row>
            <Col md="12">
              <div className="accountInnner pt-0 ps-0 pe-0">
                <h2>Dealer Registration</h2>

                <hr />
              </div>
            </Col>
            <ShowModel />
            <Col md="12">
              <div className="stepOne">
                <div className="infoheader">
                  <h3>Owner Information</h3>
                </div>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={generateCaptach}
                  className="infoform"
                >
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          value={first_name}
                          name="first_name"
                          onChange={onChange}
                          min="2"
                          maxLength="50"
                          placeholder="First Name*"
                          required
                          className="form-control"
                          autoComplete="first_name"
                          invalid={errors.first_name ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="first_name"
                          key="first_name"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          value={last_name}
                          name="last_name"
                          maxLength="25"
                          onChange={onChange}
                          autoComplete="last_name"
                          invalid={errors.last_name ? 'true' : 'false'}
                          placeholder="Last Name"
                          className="form-control"
                        />
                        <ShowError
                          current_key="last_name"
                          key="last_name"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="Email"
                          value={email}
                          name="email"
                          onChange={onChange}
                          autoComplete="email"
                          invalid={errors.email ? 'true' : 'false'}
                          placeholder="Email*"
                          required
                          className="form-control"
                        />
                        <ShowError
                          current_key="email"
                          key="email"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        {password == '' &&
                          <Tooltip anchorSelect=".my-anchor-element1" place="bottom">
                            Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                          </Tooltip>
                        }
                        <input
                          type={(passwordShown) ? "text" : "password"}
                          value={password}
                          name="password"
                          onChange={onChange}
                          autoComplete="password"
                          invalid={errors.password ? 'true' : 'false'}
                          placeholder="Password *"
                          required
                          className="form-control my-anchor-element1"
                        />
                        <span className='viewpass' onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                        {/* <ShowError
                            current_key="password"
                            key="password"
                            errors={errors}
                          /> */}
                      </div>
                      {errors && errors?.password !== undefined &&
                        <div id="message" style={{ marginBottom: '12px' }}>
                          <FormText style={{ fontSize: "10px", marginTop: "-10px" }} className='text-danger'> Password requirements:</FormText>
                          <FormText>
                            <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                            <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                          </FormText>
                        </div>
                      }
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        {confirm_password == '' &&
                          <Tooltip anchorSelect=".my-anchor-element2" place="bottom">
                            Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                          </Tooltip>
                        }
                        <input
                          type={(confirmPassword) ? "text" : "password"}
                          value={confirm_password}
                          name="confirm_password"
                          onChange={onChange}
                          autoComplete="confirm_password"
                          invalid={errors.confirm_password ? 'true' : 'false'}
                          placeholder="Confirm Password*"
                          required
                          className="form-control my-anchor-element2"
                        />
                        <span className='viewpass' onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                        <ShowError
                          current_key="confirm_password"
                          key="confirm_password"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <div className="infoheader">
                      <h3>Business Information</h3>
                    </div>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="business_name"
                          name="business_name"
                          placeholder="Business Name *"
                          className="form-control"
                          maxLength="100"
                          value={business_name}
                          required
                          onChange={onChange}
                          invalid={errors.business_name ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="business_name"
                          key="business_name"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="business_email"
                          name="business_email"
                          placeholder="Business Email *"
                          className="form-control"
                          maxLength="100"
                          value={business_email}
                          required
                          onChange={onChange}
                          invalid={errors.business_email ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="business_email"
                          key="business_email"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Autocomplete
                          apiKey={GOOGLE_MAPS_API_KEY}
                          onPlaceSelected={(place) => {
                            {
                              setGMapPlace(place);
                            }
                          }}
                          options={{
                            types: ['address'],
                          }}
                          value={autocompleteValue}
                          onChange={handleInputChange}
                          className="form-control"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="address_line_1"
                          name="address_line_1"
                          placeholder="Address Line 1 *"
                          maxLength="100"
                          value={address_line_1}
                          required
                          onChange={onChange}
                          invalid={errors.address_line_1 ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="address_line_1"
                          key="address_line_1"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="address_line_2"
                          name="address_line_2"
                          placeholder="Address Line 2"
                          maxLength="100"
                          value={address_line_2}
                          onChange={onChange}
                          invalid={errors.address_line_2 ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="address_line_2"
                          key="address_line_2"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Country *"
                          className="form-control"
                          name="country"
                          id="country"
                          onChange={onChange}
                          value={country}
                          required
                          invalid={errors.country ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="country"
                          key="country"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="State *"
                          className="form-control"
                          name="state"
                          id="state"
                          required
                          onChange={onChange}
                          value={state}
                          invalid={errors.state ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="state"
                          key="state"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          value={city}
                          required
                          invalid={errors.city ? 'true' : 'false'}
                          placeholder="City *"
                          className="form-control"
                          onChange={onChange}
                        />
                        <ShowError
                          current_key="city"
                          key="city"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onReCAPTCHAChange}
                    />
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="zipCode"
                          name="zipcode"
                          value={zipcode}
                          required
                          invalid={errors.zipcode ? 'true' : 'false'}
                          placeholder="ZipCode *"
                          className="form-control"
                          onChange={onChange}
                        />
                        <ShowError
                          current_key="zipcode"
                          key="zipcode"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <PhoneInput
                          className="form-control"
                          autoComplete="tel"
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          smartCaret={true}
                          international={true}
                          defaultCountry={defaultCountry}
                          value={iute164_phone}
                          name="contact_no"
                          addInternationalOption={false}
                          onChange={onPhoneChange}
                          onCountryChange={onCountryChange}
                        />
                        <ShowError
                          current_key="contact_no"
                          key="contact_no"
                          errors={errors}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Website"
                          className="form-control"
                          id="website"
                          name="website"
                          maxLength="100"
                          value={website}
                          onChange={onChange}
                          invalid={errors.website ? 'true' : 'false'}
                        />
                        <ShowError
                          current_key="website"
                          key="website"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="business_license_no"
                          name="business_license_no"
                          maxLength="100"
                          value={business_license_no}
                          required
                          onChange={onChange}
                          min={0}
                          invalid={
                            errors.business_license_no ? 'true' : 'false'
                          }
                          placeholder="Business License # or Tax I.D. Number *"
                          className="form-control"
                        />
                        <ShowError
                          current_key="business_license_no"
                          key="business_license_no"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          type="text"
                          id="business_model_description"
                          name="business_model_description"
                          rows="4"
                          onChange={onChange}
                          required
                          value={business_model_description}
                          invalid={
                            errors.business_model_description
                              ? 'true'
                              : 'false'
                          }
                          placeholder="Description of your business model/plan *"
                        />
                        <ShowError
                          current_key="business_model_description"
                          key="business_model_description"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <input
                          type="number"
                          id="annual_sales_volume"
                          name="annual_sales_volume"
                          min={0}
                          value={annual_sales_volume}
                          // required
                          onChange={onChange}
                          onMouseOver={onChangePlaceholder}
                          onMouseOut={onChangePlaceholder}
                          /*  invalid={
                             errors.annual_sales_volume ? 'true' : 'false'
                           } */
                          placeholder={
                            hide
                              ? 'Annual sales volume of metalwoods built or retrofitted with premium shafts?'
                              : 'Annual sales volume of metalwoods built or retrofitted with premium shafts?'
                          }
                          className="form-control"
                        />
                        {/*  <ShowError
                          current_key="annual_sales_volume"
                          key="annual_sales_volume"
                          errors={errors}
                        /> */}
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="shaft_clubhead_component_vendors"
                          name="shaft_clubhead_component_vendors"
                          maxLength="100"
                          value={shaft_clubhead_component_vendors}
                          // required
                          onChange={onChange}
                          /* invalid={
                            errors.shaft_clubhead_component_vendors
                              ? 'true'
                              : 'false'
                          } */
                          placeholder="Primary shaft and clubhead component suppliers/vendors"
                          className="form-control"
                        />
                        {/* <ShowError
                          current_key="shaft_clubhead_component_vendors"
                          key="shaft_clubhead_component_vendors"
                          errors={errors}
                        /> */}
                      </div>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <InputGroup size="lg">
                          <Form.Control
                            placeholder="Facebook Link"
                            id="facebook"
                            name="facebook"
                            value={facebook}
                            onChange={onChange}
                            invalid={errors.facebook ? 'true' : 'false'}
                          />

                          <InputGroup.Text>
                            <FaFacebookF />
                          </InputGroup.Text>
                        </InputGroup>
                        <ShowError
                          current_key="facebook"
                          key="facebook"
                          errors={errors}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <InputGroup size="lg">
                          <Form.Control
                            placeholder="Instagram Link"
                            id="instagram"
                            name="instagram"
                            value={instagram}
                            onChange={onChange}
                            invalid={errors.instagram ? 'true' : 'false'}
                          />

                          <InputGroup.Text>
                            <BsInstagram />
                          </InputGroup.Text>
                        </InputGroup>
                        <ShowError
                          current_key="instagram"
                          key="instagram"
                          errors={errors}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <InputGroup size="lg">
                          <Form.Control
                            placeholder="Twitter Link"
                            id="twitter"
                            name="twitter"
                            value={twitter}
                            onChange={onChange}
                            invalid={errors.twitter ? 'true' : 'false'}
                          />

                          <InputGroup.Text>
                            <BsTwitter />
                          </InputGroup.Text>
                        </InputGroup>
                        <ShowError
                          current_key="twitter"
                          key="twitter"
                          errors={errors}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <InputGroup size="lg">
                          <Form.Control
                            placeholder="YouTube Link"
                            id="youtube"
                            name="youtube"
                            value={youtube}
                            onChange={onChange}
                            invalid={errors.youtube ? 'true' : 'false'}
                          />

                          <InputGroup.Text>
                            <BsYoutube />
                          </InputGroup.Text>
                        </InputGroup>
                        <ShowError
                          current_key="youtube"
                          key="youtube"
                          errors={errors}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <InputGroup size="lg">
                          <Form.Control
                            placeholder="Snapchat Link"
                            id="snapchat"
                            name="snapchat"
                            value={snapchat}
                            onChange={onChange}
                            invalid={errors.snapchat ? 'true' : 'false'}
                          />

                          <InputGroup.Text>
                            <FaSnapchat />
                          </InputGroup.Text>
                        </InputGroup>
                        <ShowError
                          current_key="snapchat"
                          key="snapchat"
                          errors={errors}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <InputGroup size="lg">
                          <Form.Control
                            placeholder="Tiktok Link"
                            id="tiktok"
                            name="tiktok"
                            value={tiktok}
                            onChange={onChange}
                            invalid={errors.tiktok ? 'true' : 'false'}
                          />

                          <InputGroup.Text>
                            <FaTiktok />
                          </InputGroup.Text>
                        </InputGroup>
                        <ShowError
                          current_key="tiktok"
                          key="tiktok"
                          errors={errors}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <div className="form-group">
                        <label className='uploadimg'>Upload Logo</label>
                        <input
                          type="file"
                          accept="image/*"
                          id="thumbnail"
                          name="thumbnail"
                          onChange={onChange}
                          // multiple
                          invalid={errors.image ? 'true' : 'false'}
                          placeholder="Upload Logo"
                          className="form-control choose-file"
                        />
                        {image && (
                          <div>
                            <img src={image} className="preview-img" alt="dealer_image" />
                          </div>
                        )}
                        <ShowError
                          current_key="image"
                          key="image"
                          errors={errors}
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="checkouter m-0">
                        <span className="custom_check">
                          I agree to the{' '}
                          <Link
                            //  onClick={handleShow} 
                            to="/privacy"
                            target="_blank"
                          >
                            Privacy Policy
                          </Link>{' '}
                          and the  <Link to="/terms" target="_blank">Terms Of Use.</Link>
                          <input
                            type="checkbox"
                            id="terms_and_policy_checked"
                            name="terms_and_policy_checked"
                            checked={terms_and_policy_checked}
                            className="mr-2"
                            required
                            onChange={handleCheckedChange}
                            invalid={
                              errors.terms_and_policy_checked
                                ? 'true'
                                : 'false'
                            }
                          />
                          {validated && !terms_and_policy_checked && (
                            <FormText className="invalid-feedback-text">
                              Agree with terms & conditions before
                              registration
                            </FormText>
                          )}
                          <span className="check_indicator">&nbsp;</span>
                        </span>
                      </div>
                    </Col>
                    <Modal
                      className="termsmodal"
                      show={show}
                      onHide={handleCloseTerms}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Terms and Conditions</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>
                          {' '}
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>
                        <p>
                          Contrary to popular belief, Lorem Ipsum is not
                          simply random text. It has roots in a piece of
                          classical Latin literature from 45 BC, making it
                          over 2000 years old. Richard McClintock, a Latin
                          professor at Hampden-Sydney College in Virginia,
                          looked up one of the more obscure Latin words,
                          consectetur, from a Lorem Ipsum passage, and going
                          through the cites of the word in classical
                          literature, discovered the undoubtable source. Lorem
                          Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                          Finibus Bonorum et Malorum" (The Extremes of Good
                          and Evil) by Cicero, written in 45 BC. This book is
                          a treatise on the theory of ethics, very popular
                          during the Renaissance. The first line of Lorem
                          Ipsum, "Lorem ipsum dolor sit amet..", comes from a
                          line in section 1.10.32.
                        </p>
                        <p>
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form, by injected humour, or randomised
                          words which don't look even slightly believable. If
                          you are going to use a passage of Lorem Ipsum, you
                          need to be sure there isn't anything embarrassing
                          hidden in the middle of text. All the Lorem Ipsum
                          generators on the Internet tend to repeat predefined
                          chunks as necessary, making this the first true
                          generator on the Internet. It uses a dictionary of
                          over 200 Latin words, combined with a handful of
                          model sentence structures, to generate Lorem Ipsum
                          which looks reasonable. The generated Lorem Ipsum is
                          therefore always free from repetition, injected
                          humour, or non-characteristic words etc.
                        </p>
                      </Modal.Body>
                    </Modal>

                    <Col md="12" className="text-center mt-5">
                      <Button type="submit">Register</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DealersRegistration;

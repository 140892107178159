import { RESOURCE_TAG } from '../../constants';
import { ApiService } from './apiService';

const BASE_URL = '/api/front';
export const ResourceApi = ApiService.injectEndpoints({
    endpoints: (builder) => ({
        getAllResourceData: builder.query({
            query: (resourceData) => ({
                url: `${BASE_URL}/resources`,
                method: 'GET',
                body: {
                    ...resourceData,
                }
            }),
            providesTags: [RESOURCE_TAG],
        }),
        getDataByResourceSlug: builder.query({
            query: (query) => ({
                url: `${BASE_URL}/resources/slug`,
                method: 'GET',
                params: {
                    slug: query.slug,
                },
            }),
            providesTags: [RESOURCE_TAG],
        }),
    }),
});

export const { useGetAllResourceDataQuery, useGetDataByResourceSlugQuery } = ResourceApi;

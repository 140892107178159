import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import pdfPng from "../../img/pdf-logo-thumbnail.png";
import csvPng from "../../img/csv-logo.png";
import imagePng from "../../img/image.png";
import excelPng from "../../img/excel-rectangle-thumbnail.png";
import { BiArrowBack } from 'react-icons/bi';
import MetaData from '../../components/MetaData/MetaData'
import { Link, useParams } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import Spiner from '../../components/Layouts/Spiner';
import SharePopup from './SharePopup';
import { useGetDataByResourceSlugQuery } from '../../redux-services/apis/resourceApi';


const ResourceDetails = () => {

    const [techData, setTechData] = useState(null);
    const [cmsImages, setCMSImages] = useState([]);
    const [downloadableFiles, setDownloadableFiles] = useState([]);
    const [cmsVideo, setCMSVideo] = useState([]);
    const params = useParams();

    const { isLoading, data: getDataByResourceSlug } = useGetDataByResourceSlugQuery({
        slug: params.slug
    });

    useEffect(() => {
        if (getDataByResourceSlug?.status && getDataByResourceSlug.response) {
            setTechData(getDataByResourceSlug?.response[0])
        }
    }, [getDataByResourceSlug]);

    useEffect(() => {
        if (getDataByResourceSlug?.status && getDataByResourceSlug.response) {
            setTechData(getDataByResourceSlug?.response[0])
        }
        if (getDataByResourceSlug?.response[0]?.downloads?.length || getDataByResourceSlug?.response[0]?.videoUrl) {
            const images = [];
            const downloadables = [];
            const videoArray = [];
            const playImage = '/images/play.svg';
            const imageData = getDataByResourceSlug.response[0]?.downloads;
            imageData.map((image) => {
                downloadables.push({
                    original: image.original,
                    thumbnail: image.thumbnail,
                });
                if (!image.original.includes(".pdf") && !image.original.includes(".csv")) {
                    images.push({
                        original: image.original,
                        thumbnail: image.thumbnail,
                    });
                }
            })
            if (getDataByResourceSlug.response[0]?.videoUrl) {
                const thumbnailCode = getDataByResourceSlug?.response[0]?.videoUrl.substring(
                    getDataByResourceSlug?.response[0]?.videoUrl.lastIndexOf('/') + 1
                );
                const regex = /youtube.com/g;
                const found = getDataByResourceSlug?.response[0]?.videoUrl.match(regex);
                images.splice(0, 0, {
                    thumbnail: found
                        ? `https://i.ytimg.com/vi/${thumbnailCode}/hqdefault.jpg`
                        : playImage,
                    original: playImage,
                    embedUrl: getDataByResourceSlug?.response[0]?.videoUrl,
                    thumbnailClass: 'video-slide',
                    renderItem: renderVideo,
                });
                videoArray.splice(0, 0,
                    {
                        thumbnail: playImage,
                        original: playImage,
                        embedUrl: getDataByResourceSlug?.response[0]?.videoUrl,
                        thumbnailClass: 'video-slide',
                        renderItem: function (onClick, item) {
                            return <div className='image-gallery-image'>
                                <video controls width="450" height="300"
                                    className='custom-carousel-video'>
                                    <source src={getDataByResourceSlug?.response[0]?.videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        },
                    }
                );
            }
            setDownloadableFiles(downloadables);
            setCMSImages(images);
            setCMSVideo(videoArray);
        }
    }, [getDataByResourceSlug]);
    const renderVideo = useCallback((item) => {
        return (
            <div>
                {
                    <div className="video-wrapper">
                        <iframe
                            width="500"
                            height="400"
                            src={`${item.embedUrl}?autoplay=1&showinfo=0`}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                }
            </div>
        );
    }, []);

    return (
        isLoading ? (
            <Spiner />
        ) : (
            techData && techData?.status == 1 && techData != null ? (
                <>
                    <MetaData metaData={techData} />
                    <section className='facility-outer'>
                        <Container>
                            <Row className='align-items-center'>
                                <Col md="12">
                                    <div className='share-news mb-lg-5'>
                                        <Link to={`/resources`} className='btn btn-primary'><BiArrowBack /> Back to Resources</Link>
                                    </div>
                                </Col>
                                <Col md="12">
                                    <div className='technology-mat-content text-center mb-lg-5'>
                                        <h2>{techData.article_title}</h2>
                                        <p>{techData.article_subtitle}</p>
                                        <hr />
                                    </div>
                                </Col>

                                <Col md="12">
                                    <div className='technology-mat-content' style={{ marginBottom: "0px !important" }}>
                                        {techData?.videoUrl ?
                                            <ImageGallery items={cmsVideo} /> : null
                                        }
                                        <div
                                            style={{ wordWrap: 'break-word' }}
                                            dangerouslySetInnerHTML={{
                                                __html: (techData?.description),
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {techData?.downloads?.length >= 1 && downloadableFiles.length >= 1 ?
                                <>
                                    <hr />
                                    <Row><div><h3>Downloadable Files</h3></div>
                                        {techData?.downloads?.length >= 1 ? downloadableFiles.map((file) => {
                                            if (file?.original?.includes(".pdf")) {
                                                return (<Col md="2"><div style={{ position: 'relative', width: "200px", height: "150px", marginBottom: "10px", border: "1px solid #6d2a5f" }}><a target='_blank' href={`${file.original}`} download={`${file.original}`}><img src={pdfPng} alt="pdf" style={{ height: "100%", width: "100%" }} /><button className='btn btn-primary resource-download'>Download</button></a></div></Col>)
                                            }
                                            else if (file?.original?.includes(".csv")) {
                                                return (<Col md="2"><div style={{ position: 'relative', width: "200px", height: "150px", marginBottom: "10px", border: "1px solid #6d2a5f" }}><a target='_blank' href={`${file.original}`} download={`${file.original}`}><img src={csvPng} alt="csv" style={{ height: "100%", width: "100%" }} /><button className='btn btn-primary resource-download'>Download</button></a></div></Col>)
                                            }
                                            else if (file?.original?.includes(".xls") || file?.original?.includes(".xlsx") || file?.original?.includes(".ods")) {
                                                return (<Col md="2"><div style={{ position: 'relative', width: "200px", height: "150px", marginBottom: "10px", border: "1px solid #6d2a5f" }}><a target='_blank' href={`${file.original}`} download={`${file.original}`}><img src={excelPng} alt="excel" style={{ height: "100%", width: "100%" }} /><button className='btn btn-primary resource-download'>Download</button></a></div></Col>)
                                            }
                                            else {
                                                return (<Col md="2"><div style={{ position: 'relative', width: "200px", height: "150px", marginBottom: "10px", border: "1px solid #6d2a5f" }}><a target='_blank' href={`${file.original}`} download={`${file.original}`}><img src={imagePng} alt="image" style={{ height: "100%", width: "100%" }} /><button className='btn btn-primary resource-download'>Download</button></a></div></Col>)
                                            }
                                        })
                                            : null}</Row></>
                                : null}
                        </Container>
                    </section>
                </>
            ) : (
                <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
            )
        )
    );
}

export default ResourceDetails;

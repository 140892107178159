import { Button, Col, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

const ReturnToPreviousPage = ({
    previosPageLabel,
    forwardPageLabel,
    handleOnClick,
    previousPath
}) => {
    return <div className='Contact-informations'>
        <Row>
            <Col md="12">
                <div className='d-flex justify-content-between align-items-center mt-3 mb-3'>
                    <Link to={previousPath} className='returnshopping'> <IoIosArrowBack /> {previosPageLabel}</Link>
                    <Button onClick={handleOnClick}>{forwardPageLabel}</Button>
                </div>
            </Col>
        </Row>
    </div>
}

export default ReturnToPreviousPage;
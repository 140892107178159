import { createSlice } from "@reduxjs/toolkit";
import { AuthAPI } from "../apis/authApi";
import setAuthToken from "../apis/setAuthToken";

const userLogin = (state, { payload }) => {
    if (payload.status) {
        if (payload.response.otpVerified) {
            const { response: { token, user } } = payload;
            state.isAuthenticated = true;
            state.user = user;
            state.token = token;
        }
    }
}

const userRegister = (state, { payload }) => {
    if (payload.status) {
        const { response: { token, user } } = payload;
        state.isAuthenticated = true;
        state.user = user;
        state.token = token;
    }
}

const userSubLogin = (state, { payload }) => {
    if (payload.response.redirect) {
        const { response: { token, user } } = payload;
        state.isAuthenticated = true;
        state.user = user;
        state.token = token;
    }
}

const initialState = {
    token: null,
    user: null,
    isAuthenticated: false,
    previousLocation: null,
}
const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsAuthenticated: (state, { payload }) => {
            state.isAuthenticated = payload.status;
        },
        setPreviousLocation: (state, { payload }) => {
            state.previousLocation = payload
        },
        doLogout: (state, { payload }) => {
            localStorage.removeItem('userAccessToken');
            localStorage.removeItem('user');
            state.user = null;
            state.isAuthenticated = false;
            state.token = null;
            setAuthToken(null);

        },
        setUpdatedUserDetails: (state, {payload}) => {
            state.user = payload;
        }

    },
    extraReducers(builder) {
        builder.addMatcher(
            AuthAPI.endpoints.doLogin.matchFulfilled,
            userLogin
        ).addMatcher(
            AuthAPI.endpoints.doRegister.matchFulfilled,
            userRegister
        ).addMatcher(
            AuthAPI.endpoints.doSubLogin.matchFulfilled,
            userSubLogin
        ).addMatcher(
            AuthAPI.endpoints.getUser.matchFulfilled,
            (state, { payload }) => {
                state.isAuthenticated = true;
                state.user = payload.response;
            });

    },
});

const { reducer, actions } = AuthSlice;

export const { setIsAuthenticated, doLogout, setPreviousLocation, setUpdatedUserDetails } = actions;

export const getPreviousLocation = (state) => state.auth.previousLocation;
export const getUserDetails = (state) => state.auth.user;
export const getIsAuthenticated = (state) => state.auth.isAuthenticated;

export default reducer;
import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryList, setProductGraph } from '../../../redux-services/slices/siteDataSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useGetProductGraphMutation } from '../../../redux-services/apis/productDetailApi';

const DUMMY_IMAGE = '/images/productdummy.png';
const Graph = () => {
    const dispatch = useDispatch();
    const categoryList = useSelector(getCategoryList);
    const [categoriesData, setCategoriesData] = useState(null);
    const [cmsData, setCmsData] = useState();
    const navigate = useNavigate();
    const [category, setCategory] = useState('driver');
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [result, setResult] = useState(null);
    const [getProductGraph, getProductGraphResponse] = useGetProductGraphMutation();
    const handleClick = (e, slug) => {
        e.preventDefault();
        setCategory(slug);
    }
    useEffect(() => {
        getProductGraph(category);
    }, [category])

    useEffect(() => {
        if (categoryList !== undefined) {
            let data = categoryList?.filter(category => ['driver', 'hybrid', 'fairway']?.includes(category?.slug));
            setCategoriesData(data);
        }
    }, [categoryList])

    useEffect(() => {
        if (result !== undefined) {
            setCmsData(result?.cms[0])
        }
    }, [result]);

    useEffect(() => {
        setResult(getProductGraphResponse?.data?.response)
        dispatch(setProductGraph(null))
    }, [getProductGraphResponse])

    const handleClickProduct = (data, launch, spin) => {
        if (data !== undefined) {
            let item = [];
            item.push(data)
            let newData = [
                { data: item[0] },
                { launch: launch },
                { spin: spin },
            ]
            dispatch(setProductGraph(newData))
            navigate('/launch-spin-profiles-result')
        }
    }

    const handleModal = () => {
        setShow(false);
    }

    const filterUniqueFlex = (array) => {
        let newArray = [];
        array.map(item => newArray.push(item.product_variation))
        let uniqueFlexOptions = Array.from(new Set(newArray.flatMap(item => item.variation
            .filter(variation => variation.attribute_name === "Flex")
            .map(variation => variation.option_term)
        )));
        uniqueFlexOptions = uniqueFlexOptions.join(", ");
        return uniqueFlexOptions;
    }

    const showData = (data, launch, spin) => {
        return (
            <>
                <div className='show-mobile'><span>LAUNCH: {launch}</span>, <span>SPIN: {spin}</span></div>
                {data && data?.length > 0 &&
                    data.map((item) =>
                    (
                        <span onClick={() => handleClickProduct(item, launch, spin)} key={item[0]?.product?._id}>
                            {item[0]?.product?.product_title}
                            <div> ({filterUniqueFlex(item)})</div>
                        </span >
                    ))
                }
            </>
        );
    }

    return (
        <>
            {result !== undefined &&
                <section className='Driver-selecter-outer Select-productOuter facility-outer otherProducts graphpanel'>
                    <Container>
                        <Row className="align-items-center">
                            <Col md="12">
                                <div className="technology-mat-content text-center mb-5">
                                    <h2>{cmsData?.page_title}</h2>
                                    <h4>{cmsData?.page_subtitle}</h4>
                                    {/* <hr /> */}
                                </div>
                            </Col>
                            <Col md="12" lg="3">
                                <div className="sidebar-outer">
                                    <div className="sidebar">
                                        <h3>Club Type</h3>
                                        <ul className="categoryList">
                                            {categoriesData?.length &&
                                                categoriesData?.map((categoryItem) =>
                                                    categoryItem?.total_products > 0 ? (
                                                        <li className={category === categoryItem?.slug ? 'active' : ''} onClick={(e) => handleClick(e, categoryItem?.slug)} key={categoryItem?._id}>
                                                            <Link to=''>
                                                                <span className="productName">
                                                                    <img
                                                                        src={categoryItem?.image}
                                                                        onError={() =>
                                                                            (this.src = '/images/productdummy.png')
                                                                        }
                                                                        alt="#"
                                                                    />
                                                                    <h4>{categoryItem?.name}</h4>
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    ) : null
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md="12" lg="9">
                                <div className='graphOuter'>
                                    {/* <div className='kinetixx-title'>
                                        <span className={category === 'driver' ? 'active' : ''} onClick={handleClick} data-id={'driver'}>Driver</span>
                                        <span className={category === 'fairway' ? 'active' : ''} onClick={handleClick} data-id={'fairway'}>Fairway</span>
                                        <span className={category === 'hybrid' ? 'active' : ''} onClick={handleClick} data-id={'hybrid'}>Hybrid</span>
                                    </div> */}
                                    <div className='launch-heading'>
                                        <h3>Launch & Trajectory</h3>
                                    </div>
                                    <div className='H-label'>
                                        <label >High</label>
                                        <label>Mid-High</label>
                                        <label>Mid</label>
                                        <label>Low-mid</label>
                                        <label>Low</label>
                                        <label className='label-first'>Product Name</label>
                                    </div>
                                    <div className='strecher'>
                                        <div className='divaider-1'>
                                            <div className={result?.firstCol?.first.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {/* {result?.firstCol?.first.length > 2 &&
                                                    <span className='result-count' onMouseEnter={(e) => handleMouseHover(result?.firstCol?.first)}>+{result?.firstCol?.first.length - 2}</span>
                                                } */}
                                                {showData(result?.firstCol?.first, 'High', "Low")}
                                            </div>
                                            <div className={result?.firstCol?.second?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.firstCol?.second, 'High', "Low-Mid")}
                                            </div>
                                            <div className={result?.firstCol?.third?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.firstCol?.third, 'High', "Mid")}
                                            </div>
                                            <div className={result?.firstCol?.fourth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.firstCol?.fourth, 'High', "Mid-High")}
                                            </div>
                                            <div className={result?.firstCol?.fifth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.firstCol?.fifth, 'High', "High")}
                                            </div>
                                            <div className={result?.secondCol?.first?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.secondCol?.first, 'Mid-High', 'Low')}
                                            </div>
                                            <div className={result?.secondCol?.second?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.secondCol?.second, 'Mid-High', 'Low-Mid')}
                                            </div>
                                            <div className={result?.secondCol?.third?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.secondCol?.third, 'Mid-High', 'Mid')}
                                            </div>
                                            <div className={result?.secondCol?.fourth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.secondCol?.fourth, 'Mid-High', 'Mid-High')}
                                            </div>
                                            <div className={result?.secondCol?.fifth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.secondCol?.fifth, 'Mid-High', 'High')}
                                            </div>
                                            <div className={result?.thirdCol?.first?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.thirdCol?.first, 'Mid', 'Low')}
                                            </div>
                                            <div className={result?.thirdCol?.second?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.thirdCol?.second, 'Mid', 'Low-Mid')}
                                            </div>
                                            <div className={result?.thirdCol?.third?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.thirdCol?.third, 'Mid', 'Mid')}
                                            </div>
                                            <div className={result?.thirdCol?.fourth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.thirdCol?.fourth, 'Mid', 'Mid-High')}
                                            </div>
                                            <div className={result?.thirdCol?.fifth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.thirdCol?.fifth, 'Mid', 'High')}
                                            </div>
                                            <div className={result?.fourthCol?.first?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fourthCol?.first, 'Low-Mid', 'Low')}
                                            </div>
                                            <div className={result?.fourthCol?.second?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fourthCol?.second, 'Low-Mid', 'Low-Mid')}
                                            </div>
                                            <div className={result?.fourthCol?.third?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fourthCol?.third, 'Low-Mid', 'Mid')}
                                            </div>
                                            <div className={result?.fourthCol?.fourth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fourthCol?.fourth, 'Low-Mid', 'Mid-High')}
                                            </div>
                                            <div className={result?.fourthCol?.fifth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fourthCol?.fifth, 'Low-Mid', 'High')}
                                            </div>
                                            <div className={result?.fifthCol?.first?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fifthCol?.first, 'Low', 'Low')}
                                            </div>
                                            <div className={result?.fifthCol?.second?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fifthCol?.second, 'Low', 'Low-Mid')}
                                            </div>
                                            <div className={result?.fifthCol?.third?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fifthCol?.third, 'Low', 'Mid')}
                                            </div>
                                            <div className={result?.fifthCol?.fourth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fifthCol?.fourth, 'Low', 'Mid-High')}
                                            </div>
                                            <div className={result?.fifthCol?.fifth?.length == 0 ? 'divaider-2 one-center' : 'divaider-2'}>
                                                {showData(result?.fifthCol?.fifth, 'Low', 'High')}
                                            </div>
                                        </div>

                                    </div>
                                    <div className='V-label'>
                                        <label>Low</label>
                                        <label>Low-mid</label>
                                        <label>Mid</label>
                                        <label>Mid-High</label>
                                        <label >High</label>
                                    </div>
                                    <div className='spin-heading'>
                                        <h3>Spin</h3>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                    <Modal size="lg" show={show} onHide={handleModal} centered>
                        <Modal.Header className="border-bottom">
                            <Modal.Title style={{ fontSize: "larger" }}>Product Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-2" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                            <Row>
                                {modalData?.map((obj) =>
                                    <Col md={modalData.length > 1 ? '6' : '12'}>
                                        <Card className='product-chart-cart-list' onClick={() => handleClickProduct(obj)}>
                                            <Card.Img variant="top" src={obj[0]?.product?.thumbnail ? 'https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/' + obj[0]?.product?.thumbnail : DUMMY_IMAGE} />
                                            <Card.Body>
                                                <Card.Title onClick={() => handleClickProduct(obj)}>{obj[0]?.product?.product_title} ({obj[0]?.product_variation?.category_details?.name})</Card.Title>
                                                <Card.Text className="productdetails-right pt-0">
                                                    <p><span>Flex:</span>{obj[0]?.product_variation?.variation?.map(item => item.attribute_name === 'Flex' && item.option_term)}</p>
                                                    {
                                                        obj[0]?.product_variation?.category_details?.slug === "iron" &&
                                                        <p><span>Irons #:</span>
                                                            {obj[0]?.product_variation?.variation?.map(item => ['Iron', 'Iron #'].includes(item.attribute_name) && item.option_term)}
                                                        </p>
                                                    }
                                                    {
                                                        obj[0]?.product_variation?.category_details?.slug === "iron" &&
                                                        <p><span className="tip-sub">Tip:</span>{obj[0]?.product_variation?.variation?.map(item => ['Tip', 'Tip Diameter'].includes(item.attribute_name) && item.option_term)}
                                                        </p>
                                                    }
                                                    <p><span>Color:</span>{obj[0]?.product_variation?.variation?.map(item => item.attribute_name === 'Color' && item.option_term)}
                                                    </p>
                                                </Card.Text>
                                                {/* <Button variant="primary">Go somewhere</Button> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                variant="primary"
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={handleModal}
                            >
                                Close
                            </button>
                        </Modal.Footer>
                    </Modal>
                </section >
            }
        </>
    );
}

export default Graph;

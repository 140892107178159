import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import Layout from "../components/Layouts/Layouts";
import { IoIosArrowBack } from 'react-icons/io';

import { Link, useParams } from "react-router-dom";


function Compare1() {
    const [isActive, setIsActive] = useState(false);
    const handleToggle = () => {
        setIsActive(!isActive);
    };


    return (

        <>
            <section className='compareOuter'>
                <div className='headcenter'>
                    <img src="images/compare-banner.png" alt='#' />
                    <div>
                        <h2>For Guaranteed Longer, Straighter Play</h2>
                        <h4>Use this Comparison Tool</h4>
                    </div>
                </div>
                <div className='box-outer'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md="12">
                                <div className="showcompare">
                                    <Row>
                                        <Col md="4" className="text-start"><Link to=""><IoIosArrowBack /> Back</Link></Col>
                                        <Col md="8"><Link to="" onClick={handleToggle}>Click here to select products for comparison</Link></Col>
                                    </Row>

                                </div>
                                {
                                    isActive &&
                                    <div className="comparehideblock" >
                                        <div className='headmain'>
                                            <div className='headone'></div>
                                            <div className='headtwo'>
                                                <h5>Select Product One</h5>
                                                <div className='selectbrand-outer'>

                                                    <div className='conmpareproductimgOuter'>
                                                        <img src="images/shaft.png" alt='#' />
                                                    </div>
                                                    <div className='select-itembox'>
                                                        <div className='customSelect'>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Category Name</option>
                                                                <option value="1">IRON</option>
                                                            </Form.Select>
                                                        </div>
                                                        <div className='customSelect'>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Product Name</option>
                                                                <option value="1">BALLISTIC 80g</option>
                                                            </Form.Select>
                                                        </div>
                                                        <div className='customSelect'>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Flex Code</option>
                                                                <option value="1">I30</option>
                                                            </Form.Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='headtwo'>
                                                <h5>Select Product Two</h5>
                                                <div className='selectbrand-outer'>
                                                    <div className='conmpareproductimgOuter'>
                                                        <img src="images/shaft01.png" alt='#' />
                                                    </div>
                                                    <div className='select-itembox'>
                                                        <div className='customSelect '>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Category Name</option>
                                                                <option value="1">IRON</option>
                                                            </Form.Select>
                                                        </div>
                                                        <div className='customSelect'>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Product Name</option>
                                                                <option value="1">BALLISTIC 95g</option>
                                                            </Form.Select>
                                                        </div>
                                                        <div className='customSelect'>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Flex Code</option>
                                                                <option value="1">I40</option>
                                                            </Form.Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mt-3">
                                            <Button onClick={handleToggle}>compare Now</Button>
                                        </div>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
                {
                    !isActive &&
                    <Container >
                        <div className='box-outer'>
                            <div className='compare-product-list'>
                                <div className='compare-table'>
                                    <div className='compare-head'>
                                        <div className='compare-leftpart'>Basic Information</div>
                                        <div className='compare-center'>Velocity70 Green LD</div>
                                        <div className='compare-right'>Velocity</div>
                                    </div>
                                    <div className='compare-body compare-bodyone'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <div className='compareTd productImageOuterCompare'>
                                                        <h2>Product Image</h2>
                                                    </div>
                                                    <div className='compareTd'>
                                                        <h2>Material</h2>
                                                    </div>
                                                    <div className='compareTd'>
                                                        <h2>Construction Technology</h2>
                                                    </div>
                                                    <div className='compareTd'>
                                                        <h2>Weight</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd productImageOuterCompare'>
                                                    <img src="images/shaft.png" className="prdtImg img-fluid" alt="#" />
                                                </div>
                                                <div className='compareTd'>30T,5T Amorphous</div>
                                                <div className='compareTd'>123</div>
                                                <div className='compareTd'>64</div>

                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd productImageOuterCompare'><img src="images/shaft01.png" className="prdtImg img-fluid" alt="#" /></div>
                                                <div className='compareTd'>30T,5T Amorphous Nano Carbon</div>
                                                <div className='compareTd'>123</div>
                                                <div className='compareTd'>64</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-outer compare-bodyone'>
                            <div className='compare-product-list'>
                                <div className='compare-table'>
                                    <div className='compare-head'>
                                        <div className='compare-leftpart'>Fitting Information</div>
                                        <div className='compare-center'></div>
                                        <div className='compare-right'></div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>EI Profile</h2>
                                                </div>
                                                <div className='compareInner'>
                                                    <div className='compareTd'><h2>Butt</h2></div>
                                                    <div className='compareTd'><h2>Mid</h2></div>
                                                    <div className='compareTd'><h2>Tip</h2></div>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> Firm</div>
                                                <div className='compareTd'> Medium+</div>
                                                <div className='compareTd'> Medium+</div>

                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> Firm</div>
                                                <div className='compareTd'>Medium</div>
                                                <div className="compareTd"> Soft</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Balance Profile</h2>
                                                </div>
                                                <div className='compareInner'>
                                                    <div className='compareTd'><h2>Counterbalanced</h2></div>
                                                    <div className='compareTd'><h2>Neutral</h2></div>
                                                    <div className='compareTd'><h2>Lower</h2></div>
                                                    <div className='compareTd'><h2>Tip Balanced</h2></div>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> ✔</div>
                                                <div className='compareTd'> </div>
                                                <div className='compareTd'> </div>
                                                <div className='compareTd'> </div>

                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> ✔</div>
                                                <div className='compareTd'></div>
                                                <div className="compareTd"> </div>
                                                <div className="compareTd"> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Launch</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Low</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>High</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Spin</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Low</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>High</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Trajectory</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Low</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>High</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Tempo Fit</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Mid-Up Tempo</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Slow-Mid</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Angle Of Attack Fit</h2>
                                                </div>
                                                <div className='compareInner'>
                                                    <div className='compareTd'><h2>+ AoA</h2></div>
                                                    <div className='compareTd'><h2>Neutral AoA</h2></div>
                                                    <div className='compareTd'><h2>- AoA</h2></div>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> ✔</div>
                                                <div className='compareTd'> ✔</div>
                                                <div className='compareTd'> ✔</div>

                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> ✔</div>
                                                <div className='compareTd'>✔</div>
                                                <div className="compareTd"> ✔</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Recovery Mode</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Stable/Strong</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Active/Fast</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Flex-Fit Club Head Speed of Driver</h2>
                                                </div>
                                                <div className='compareInner'>
                                                    <div className='compareTd'><h2>Based off Playing Length Avg 45-46.00”</h2></div>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>125-155mph</div>

                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>70-80mph</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Installation</h2>
                                                </div>
                                                <div className='compareInner'>
                                                    <div className='compareTd'><h2>Butt Cut</h2></div>
                                                    <div className='compareTd'><h2>Tip Cut</h2></div>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> ✔</div>
                                                <div className='compareTd'> ✖</div>

                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'> ✔</div>
                                                <div className='compareTd'>✖</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='box-outer compare-bodyone'>
                            <div className='compare-product-list'>
                                <div className='compare-table'>
                                    <div className='compare-head'>
                                        <div className='compare-leftpart'>Technical Information</div>
                                        <div className='compare-center'></div>
                                        <div className='compare-right'></div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Torque ±0.5°</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>2.7°</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>6.0°</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Tip dia. ±0.002” (±0.05mm)</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>.335” (8.50mm)</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>.335” (8.50mm)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Tip/par ±0.5"</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>3.00"</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>3.00"</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Butt dia. ±0.005” (±0.12mm)</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>.626” (15.90mm)</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>.610” (15.20mm)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Butt par. ±0.5"</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>6.00"</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>7.50"</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Length</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>46.00"</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>46.00"</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Weight ±2g</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>75g</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>60g</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Frequency ±5cpm</h2>

                                                </div>
                                                <div className='compareInner'>
                                                    <h2>205g Tip weight</h2>

                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>300cpm</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>205cpm</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Balance point</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>54.00%</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>48.00%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='compare-body'>
                                        <div className='bodytr'>
                                            <div className='partleft'>
                                                <div className='compareInner'>
                                                    <h2>Kick point</h2>
                                                </div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Mid</div>
                                            </div>
                                            <div className='compareOuterpanel'>
                                                <div className='compareTd'>Low</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='compare-footer'>
                            <div className='compare-head'>
                                <div className='compare-leftpart border-0'></div>
                                <div className='compare-center text-center border-0'>
                                    <Button>Select Product</Button>
                                </div>
                                <div className='compare-right text-center border-0'>
                                    <Button>Select Product</Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                }
            </section>
        </>
    );
}

export default Compare1;

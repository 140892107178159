import axios from "axios";

// Base Url For Application 

export const axiosBaseQuery = () =>
    async ({ url, method, params, body }) => {
        try {
            const token = localStorage.userAccessToken;
            const result = await axios({
                url: url, method, data: body, params, headers: token ? {
                    'x-auth-token': token
                } : {}
            })
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError

            if (err.response?.status === 401) {
                localStorage.clear();
            }

            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

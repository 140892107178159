import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedShippingRate,
  getTaxData,
  setTotalPrice,
} from '../../redux-services/slices/cartSlice';

const OrderTotal = ({ cartData }) => {
  const dispatch = useDispatch();
  const shippingRate = useSelector(getSelectedShippingRate);
  const taxData = useSelector(getTaxData);

  const [taxTotal, setTaxTotal] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    let tax = 0.0;
    let totalAmount = 0.0;
    if (taxData?.applyTaxRate) {
      const taxRate = Number(taxData?.tax?.value ?? 0);
      tax = (Number(cartData?.totalCartAmount ?? 0) * taxRate) / 100;
      setTaxTotal(tax);
    }
    const shippingDiscount =
      cartData?.coupon?.applicable_for === 'shipping'
        ? cartData?.discounted_amount
        : 0;
    if (shippingRate?.rate_id) {
      totalAmount = (
        Number(shippingRate?.totalCharges) +
        tax +
        Number(cartData?.totalCartAmount) -
        Number(shippingDiscount)
      ).toFixed(2);
    } else {
      totalAmount = (Number(cartData?.totalCartAmount) + tax).toFixed(2);
    }
    setTaxTotal(tax);
    setTotalAmount(totalAmount);
    dispatch(setTotalPrice(totalAmount))
  }, [taxData, shippingRate, cartData]);

  return (
    <>
      <div className="paymentsummry">
        <p>
          Product(s) Total: <span>${cartData?.totalProduct}</span>
        </p>
        {cartData?.coupon && cartData?.coupon.applicable_for !== 'shipping' ? (
          <p>
            Discount: <span>-${cartData?.discounted_amount}</span>
          </p>
        ) : null}
        {cartData?.totalFitting && Number(cartData?.totalFitting) > 0 && (
          <p>
            {' '}
            Build Parameter(s) total: <span>${cartData?.totalFitting}</span>
          </p>
        )}
        {taxData?.applyTaxRate && taxTotal > 0 ? (
          <>
            <p className="grandtotal">
              Total Before Tax: <span>${cartData?.totalCartAmount}</span>
            </p>
            <p>
              Sales Tax: <span>${taxTotal.toFixed(2)}</span>
            </p>
          </>
        ) : null}
        {shippingRate?.rate_id && Number(shippingRate?.totalCharges) > 0 && (
          <p>
            {' '}
            Shipping: <span>${shippingRate?.totalCharges}</span>
          </p>
        )}
        {cartData?.coupon &&
          cartData?.coupon.applicable_for === 'shipping' &&
          shippingRate?.rate_id ? (
          <p>
            Shipping Discount: <span>-${cartData?.discounted_amount}</span>
          </p>
        ) : null}

        <p className="grandtotal">
          Grand Total: <span>${totalAmount}</span>
        </p>
      </div>
    </>
  );
};

export default OrderTotal;

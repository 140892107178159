import { useEffect, useState } from "react";
import { Col, Container, Row, Button, Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { Range } from 'react-range';
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { getUserDetails } from "../redux-services/slices/authSlice";
import { BsFillInfoCircleFill } from "react-icons/bs";
//state = { values: [50] };
const DriverSelectorNext = () => {
    const { product_category } = useParams();
    const productSelector = useSelector(getProductSelector);
    const authUser = useSelector(getUserDetails);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [rangeSlider, setRangeSlider] = useState({
        tempo: [2],
        trajectory: [3],
        clubHead: [2],
        shotConsistency: [2],
        attackAngle: [2],
        shaftWeight: [3],
        currWeight: [4],
    });
    const [tempoText, setTempoText] = useState("Low");
    const [attackAngle, setAttackAngle] = useState("Neutral AoA");
    const [shaftWeightText, setshaftWeightText] = useState("Low");
    const [currWeightText, setCurrWeightText] = useState("58-61g");
    const [toolTipText, setToolTipText] = useState(null);
    const [currentToolTipText, setCurrentToolTipText] = useState(null);
    const [showTooltipText, setShowTooltipText] = useState(false);

    useEffect(() => {
        let setProduct = {
            ...productSelector,
            userDetail: authUser,
            questions: { ...productSelector.questions, tempo: tempoText, shaft_weight: shaftWeightText, attack_angle: attackAngle, current_Weight: currWeightText },
        }
        dispatch(setProductSelector(setProduct))
    }, [product_category, tempoText, shaftWeightText, attackAngle, currWeightText])

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    useEffect(() => {
        let tempo1 = rangeSlider.tempo[0];
        switch (tempo1) {
            case 1:
                setTempoText("Slow");
                setToolTipText('A low tempo in golf shafts refers to a quicker and more aggressive swing rhythm, typically resulting in a faster clubhead speed and potentially benefiting from a golf shaft with a stiffer flex to optimize control and accuracy.')
                break;
            case 2:
                setTempoText("Mid");
                setToolTipText('A mid tempo in golf shafts refers to a balanced and smooth swing rhythm, allowing for consistent clubhead speed and potentially benefiting from a golf shaft with a flex that suits a wide range of players.')
                break;
            case 3:
                setTempoText("Up");
                setToolTipText('An up value of tempo in golf shafts refers to an intentionally faster tempo, indicating an aggressive swing rhythm that may benefit from a golf shaft with a more flexible flex to enhance timing and maximize distance.')
                break;
        }
    }, [rangeSlider.tempo[0]])

    useEffect(() => {
        let attackAngle = rangeSlider.attackAngle[0];
        switch (attackAngle) {
            case 1:
                setAttackAngle("- AoA");
                setToolTipText('A negative value of attack angle in golf shafts refers to a downward angle at which the clubhead strikes the ball, typically associated with hitting the ball with a descending blow, reducing launch and potentially improving control and spin.')
                break;
            case 2:
                setAttackAngle("Neutral AoA");
                setToolTipText('A neutral value of attack angle in golf shafts refers to a horizontal or level angle at which the clubhead strikes the ball, resulting in a balanced and versatile impact that suits a wide range of players and shot types.')
                break;
            case 3:
                setAttackAngle("+ AoA");
                setToolTipText('A positive value of attack angle in golf shafts refers to an upward angle at which the clubhead strikes the ball, typically associated with hitting the ball with an ascending blow, increasing launch and potentially maximizing distance.')
                break;
        }
    }, [rangeSlider.attackAngle[0]])

    useEffect(() => {
        let shaftWeight1 = rangeSlider.shaftWeight[0];
        switch (shaftWeight1) {
            case 1:
                setshaftWeightText("Ultra Light");
                setToolTipText('An ultra-light value of shaft weight in golf refers to a shaft that is significantly lighter than standard, allowing for increased swing speed and potential distance gains, especially for players with slower swing speeds.')
                break;
            case 2:
                setshaftWeightText("Light");
                setToolTipText('A light value of shaft weight in golf refers to a shaft that is lighter than standard, enabling players to generate increased swing speed and potentially achieve greater distance without sacrificing control.')
                break;
            case 3:
                setshaftWeightText("Light-Mid");
                setToolTipText('A light-mid value of shaft weight in golf refers to a shaft that falls between light and mid-weight categories, offering a balance of increased swing speed and control for players seeking a versatile option.')
                break;
            case 4:
                setshaftWeightText("Mid");
                setToolTipText('A mid value of shaft weight in golf refers to a shaft that is of standard weight, providing a balance between swing speed and control, suitable for a wide range of players and playing styles.')
                break;
            case 5:
                setshaftWeightText("Mid-Heavy");
                setToolTipText('A mid-heavy value of shaft weight in golf refers to a shaft that is slightly heavier than standard, providing added stability and potentially enhancing control for players who prefer a more solid feel during the swing.')
                break;
            case 6:
                setshaftWeightText("Heavy");
                setToolTipText('A heavy value of shaft weight in golf refers to a shaft that is significantly heavier than standard, offering increased stability and potentially promoting a smoother tempo and enhanced control for players who prefer a heavier feel during the swing.')
                break;
        }
    }, [rangeSlider.shaftWeight[0]])

    useEffect(() => {
        let shaftWeight1 = rangeSlider.currWeight[0];
        switch (shaftWeight1) {
            case 1:
                setCurrWeightText("<52g");
                break;
            case 2:
                setCurrWeightText("53g-57g");
                break;
            case 3:
                setCurrWeightText("58g-61g");
                break;
            case 4:
                setCurrWeightText("62g-67g");
                break;
            case 5:
                setCurrWeightText("68g-72g");
                break;
            case 6:
                setCurrWeightText("73g-78g");
                break;
            case 7:
                setCurrWeightText("79g+");
                break;
        }
    }, [rangeSlider.currWeight[0]])


    const handleModal = () => {
        setShowModal(!showModal);
    }

    const onMouseLeaveToolTip = () => {
        setShowTooltipText(false)
    }

    return <>
        <section className="Driver-selecter-outer facility-outer pb-0 pt-0">
            {/* <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="technology-mat-content text-center"><h2>Find the best driver for your game</h2><h4>Adjust the sliders to reflect your current game and goals. Once set, simply hit the recommend button.</h4><hr /></div>
                    </Col>
                </Row>
            </Container> */}
            <div className="application">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <div className="player-content">
                                <div className="landing-spot size-2 distance-200"></div>
                                <div className="paths">
                                    <div className="path high-200 selected"></div>

                                </div>

                                <div className="player-image" id="player"></div>
                            </div>
                            <div className="sliders" id="sliders">
                                <div className="border-box">

                                    <div className="slider desired-shaft" id="q7-slider">
                                        <div class="title">Desired Shaft weight<span className="slider-circle"><BsFillInfoCircleFill onClick={handleModal} /></span></div>
                                        {/* {showTooltipText && currentToolTipText === 'shaftWeight' &&
                                            <div className="tooltipSelector">
                                                {toolTipText}
                                            </div>
                                        } */}
                                        <div class="labels">
                                            <div class={`start-label extra`}>{shaftWeightText}</div>
                                        </div>
                                        <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                            <Range
                                                step={1}
                                                min={1}
                                                max={6}
                                                values={rangeSlider.shaftWeight}
                                                onChange={(values) => {
                                                    setRangeSlider({ ...rangeSlider, shaftWeight: values })
                                                    setShowTooltipText(true)
                                                    setCurrentToolTipText('shaftWeight')
                                                }}
                                                renderTrack={({ props, children }) => (
                                                    <div className={rangeSlider.shaftWeight[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div className="inner-label">
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                        </div>
                                                        {children}
                                                    </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                    <div className="thumb-dots"
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: '42px',
                                                            width: '52px',
                                                        }}
                                                    />
                                                )}
                                            />
                                            <div className="label-item">
                                                <span className="label-item-inner">Ultra Light</span>
                                                <span className="label-item-inner">Heavy</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="slider desired-shaft current-shaft" id="q7-slider">
                                        <div class="title">Current Shaft Weight</div>
                                        {/* {showTooltipText && currentToolTipText === 'currWeight' &&
                                            <div className="tooltipSelector">
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>Desired Shaft Weight</th>
                                                            <th>Current Shaft Weight</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{'<52g'}</td>
                                                            <td>{'Ultra Light'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'53g-57g'}</td>
                                                            <td>{'Ultra Light'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'58g-61g'}</td>
                                                            <td>{'Light'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'62g-67g'}</td>
                                                            <td>{'Light-Mid'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'68g-72g'}</td>
                                                            <td>{'Mid'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'73g-78g'}</td>
                                                            <td>{'Mid Heavy'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{'79g+'}</td>
                                                            <td>{'Heavy'}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        } */}
                                        <div class="labels">
                                            <div class={`start-label extra`}>{currWeightText}</div>
                                        </div>
                                        <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                            <Range
                                                step={1}
                                                min={1}
                                                max={7}
                                                values={rangeSlider.currWeight}
                                                onChange={(values) => {
                                                    setRangeSlider({ ...rangeSlider, currWeight: values })
                                                    setShowTooltipText(true)
                                                    setCurrentToolTipText('currWeight')
                                                }}
                                                renderTrack={({ props, children }) => (
                                                    <div className={rangeSlider.currWeight[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div className="inner-label">
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                        </div>
                                                        {children}
                                                    </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                    <div className="thumb-dots"
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: '42px',
                                                            width: '52px',
                                                        }}
                                                    />
                                                )}
                                            />
                                            <div className="label-item">
                                                <span className="label-item-inner">{'<'}52g</span>
                                                <span className="label-item-inner">79g+</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="slider tempo" id="q5-slider">
                                        <div class="title">Tempo</div>
                                        {/* {showTooltipText && currentToolTipText === 'tempo' &&
                                            <div className="tooltipSelector">
                                                {toolTipText}
                                            </div>
                                        } */}
                                        <div class="labels">
                                            <div class={`start-label extra`}>{tempoText}</div>
                                        </div>
                                        <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                            <Range
                                                step={1}
                                                min={1}
                                                max={3}
                                                values={rangeSlider.tempo}
                                                onChange={(values) => {
                                                    setRangeSlider({ ...rangeSlider, tempo: values })
                                                    setShowTooltipText(true)
                                                    setCurrentToolTipText('tempo')
                                                }}
                                                renderTrack={({ props, children }) => (
                                                    <div className={rangeSlider.tempo[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div className="inner-label">
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                        </div>
                                                        {children}
                                                    </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                    <div className="thumb-dots"
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: '42px',
                                                            width: '52px',
                                                        }}
                                                    />
                                                )}
                                            />
                                            <div className="label-item">
                                                <span className="label-item-inner">Slow</span>
                                                <span className="label-item-inner">Up</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="slider attackangle" id="q4-slider">
                                        <div className="title">Attack Angle</div>
                                        {/* {showTooltipText && currentToolTipText === 'attack' &&
                                            <div className="tooltipSelector">
                                                {toolTipText}
                                            </div>
                                        } */}
                                        <div className="labels">
                                            <div className={`start-label ${rangeSlider.attackAngle[0] === 1 ? "active" : ""}`}><AiOutlineArrowDown />(- &deg;)</div>
                                            <div className={`start-label ${rangeSlider.attackAngle[0] === 2 ? "active" : ""}`}>Flat (0 &deg;)</div>
                                            <div className={`start-label ${rangeSlider.attackAngle[0] === 3 ? "active" : ""}`}><AiOutlineArrowUp />(+ &deg;)</div>
                                            {/* <div className={`start-label ${rangeSlider.attackAngle[0] === 1 ? "active" : ""}`}>Up Arrow <span className="d-block">(+ degrees)</span></div>
                                            <div className={`start-label ${rangeSlider.attackAngle[0] === 2 ? "active" : ""}`}>Flat/Neutral line <span className="d-block">(0 degrees)</span></div>
                                            <div className={`start-label ${rangeSlider.attackAngle[0] === 3 ? "active" : ""}`}>Down arrow <span className="d-block">(- degrees)</span></div> */}
                                        </div>
                                        <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                            <Range
                                                step={1}
                                                min={1}
                                                max={3}
                                                values={rangeSlider.attackAngle}
                                                onChange={(values) => {
                                                    setRangeSlider({ ...rangeSlider, attackAngle: values })
                                                    setShowTooltipText(true)
                                                    setCurrentToolTipText('attack')
                                                }}
                                                renderTrack={({ props, children }) => (
                                                    <div className={rangeSlider.attackAngle[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div className="inner-label">
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                            <label className="first"></label>
                                                        </div>
                                                        {children}
                                                    </div>
                                                )}
                                                renderThumb={({ props }) => (
                                                    <div className="thumb-dots"
                                                        {...props}
                                                        style={{
                                                            ...props.style,
                                                            height: '42px',
                                                            width: '52px',
                                                        }}
                                                    />
                                                )}
                                            />

                                        </div>
                                    </div>
                                    {/* <div className="slider" id="q6-slider" onMouseEnter={() => setShowImage(true)} onMouseLeave={() => setShowImage(false)}>
                                        <div class="title">Preferred Clubhead Shape</div>
                                        <div class="labels">
                                            <div class={`start-label ${rangeSlider.clubHead[0] === 1 ? "active" : "" }`}>Not Sure</div>
                                            <div class={`start-label ${rangeSlider.clubHead[0] === 2 ? "active" : "" }`}>Modern</div>
                                            <div class={`start-label ${rangeSlider.clubHead[0] === 3 ? "active" : "" }`}>Traditional</div>
                                        </div>
                                        <div>
                                        <Range
                                            step={1}
                                            min={1}
                                            max={3}
                                            values={rangeSlider.clubHead}
                                            onChange={(values) => setRangeSlider({...rangeSlider, clubHead:values})}
                                            renderTrack={({ props, children }) => (
                                            <div className="progresh-bg"
                                                {...props}
                                                style={{
                                                ...props.style,
                                                width: '100%',
                                                }}
                                            >
                                                {children}
                                            </div>
                                            )}
                                            renderThumb={({ props }) => (
                                            <div className="thumb-dots"
                                                {...props}
                                                style={{
                                                ...props.style,
                                                height: '42px',
                                                width: '52px',
                                                }}
                                            />
                                            )}
                                        />
                                        
                                        </div>
                                    </div>
                                    <div className="slider" id="q7-slider">
                                        <div class="title">Shot Consistency</div>
                                        <div class="labels">
                                            <div class={`start-label ${rangeSlider.shotConsistency[0] === 1 ? "active" : "" }`}>Not very</div>
                                            <div class={`start-label ${rangeSlider.shotConsistency[0] === 2 ? "active" : "" }`}>Somewhat</div>
                                            <div class={`start-label ${rangeSlider.shotConsistency[0] === 3 ? "active" : "" }`}>very</div>
                                        </div>
                                        <div>
                                        <Range
                                            step={1}
                                            min={1}
                                            max={3}
                                            values={rangeSlider.shotConsistency}
                                            onChange={(values) => setRangeSlider({...rangeSlider, shotConsistency:values})}
                                            renderTrack={({ props, children }) => (
                                            <div className="progresh-bg"
                                                {...props}
                                                style={{
                                                ...props.style,
                                                width: '100%',
                                                }}
                                            >
                                                {children}
                                            </div>
                                            )}
                                            renderThumb={({ props }) => (
                                            <div className="thumb-dots"
                                                {...props}
                                                style={{
                                                ...props.style,
                                                height: '42px',
                                                width: '52px',
                                                }}
                                            />
                                            )}
                                        />
                                        
                                        </div>
                                    </div> */}
                                    <div className="text-center submit-outer back-next">
                                        <Link to='' onClick={() => navigate(-1)}>Go Back</Link>
                                        <Link to="/product-result">
                                            <Button>Recommend</Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Modal show={showModal} onHide={handleModal} centered>
                        <Modal.Header className="border-bottom">
                            <Modal.Title style={{ fontSize: "larger" }}>Shaft Weight Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-2" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                            The weight of a golf shaft can vary depending on various factors such as the material used, flex, and length of the shaft. Different golf shafts are designed to meet the needs and preferences of individual golfers.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={handleModal}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </div>
        </section>
    </>
}

export default DriverSelectorNext;
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useSubscribeNewsLetterMutation } from "../../redux-services/apis/newsApi";
import { useNavigate } from "react-router-dom";

const HomeAdvanceShaft = (props) => {
  const [email, setEmail] = useState('');
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [subscribeNewsLetter, subscribeNewsLetterResponse] = useSubscribeNewsLetterMutation();

  useEffect(() => {
    if (subscribeNewsLetterResponse?.isSuccess) {
      setShow(true);
      setEmail('');
    }
    if (subscribeNewsLetterResponse?.isError) {
      setShow(true);
    }
  }, [subscribeNewsLetterResponse])

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  const handleShow = () => {
    setShow(!show);
  }

  const handleClick = (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== '' && emailPattern.test(email)) {
      subscribeNewsLetter({ email: email });
    }
  }

  const handleNavigate = (e, link) => {
      e.preventDefault();
      let url = link.split('.com');
      if (url.length > 0) {
          navigate(url[1])
      }
  }

  return (
    props.homeData &&
    <section className="advancedShaft">
      <Container>
        <Row className="align-items-center">
          <Col md="6">
            <div className="shaftPlayer text-center">
              <img src={props.homeData?.section_6.images[0]?.original} alt="image_file" className="img-fluid" />
            </div>
          </Col>
          <Col md="6">
            <div className="shaftContent product-heading text-start">
              <h2>{props.homeData?.section_6.title}</h2>
              <h3><i>{props.homeData?.section_6.subtitle}</i></h3>
              <p>{props.homeData?.section_6.description}</p>
              {props.homeData?.section_6.button_Link && props.homeData?.section_6.button_text != '' &&
                <Button onClick={(e) => handleNavigate(e, props.homeData?.section_6.button_Link)} className="btn btn-light btn-primary dark">{props.homeData.section_6?.button_text}</Button>
              }
            </div>
          </Col>

        </Row>
      </Container>
      {/* <section className='newsletter'>
        <Container>
          {subscribeNewsLetterResponse?.isSuccess && show &&
            <Col md="12">
              <div className="productTopBar">
                <p>
                  {subscribeNewsLetterResponse?.data?.message}
                </p>
                <Button onClick={handleShow} className="btn btn btn-primary">
                  Close
                </Button>
              </div>
            </Col>
          }
          {subscribeNewsLetterResponse?.isError && show &&
            <Col md="12">
              <div className="productTopBar">
                <p>
                  {subscribeNewsLetterResponse?.error?.data?.message}
                </p>
                <Button onClick={handleShow} className="btn btn btn-primary">
                  Close
                </Button>
              </div>
            </Col>
          }
          <Row>
            <Col md="12">
              <div className='newsletter-main'>
                <div className='newsImg'>
                  <Image src="images/mobile-slider.png" />
                </div>
                <div className='newsletter-right product-heading text-start'>
                  <h2>Subscribe Newsletter</h2>
                  <p>Don't miss out on this opportunity to be a part of our thriving community! Subscribe now and open the door to a world of exciting possibilities.</p>
                  <Form className='emailnews form-group' onSubmit={handleClick}>
                    <input type="email" name="email" value={email} required className='form-control' placeholder='Enter your Email' onChange={handleChange} />
                    <button type='submit' className='btn-primary'>Subscribe</button>
                  </Form>
                  <p className='message'>We respect your privacy and promise not to inundate your inbox with unnecessary emails. You can rest assured that our newsletter will be sent at a reasonable frequency, and you can opt-out anytime with just a single click.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </section>
  );
}

export default HomeAdvanceShaft;
import { Col, Container, Row } from "react-bootstrap";

const HomeProduct = (props) => {

  return (
    props.homeData && props.homeData.section_1 &&
    <section className="ourProduct">
      <Container>
        <Row>
          <Col md="4">
            <div className="product-outer-main">
              <div className="product-outer product001">
                <a href={props.homeData.section_2?.card_1?.link} target="_blank">
                  <h3><img src="images/logo.svg" /> <span className="d-block">{props.homeData.section_2?.card_1?.subtitle}</span></h3>
                  <div className="product-image">
                    <div className="product-main-image">
                      <img src={props.homeData.section_2?.card_1?.images[0]?.original} className="img-fluid" alt="#" />
                    </div>
                  </div>
                  {/* <p>{props.homeData.section_2?.card_1?.description}</p> */}
                  <div
                    className="innerpages"
                    dangerouslySetInnerHTML={{
                      __html: (props.homeData.section_2?.card_1?.description),
                    }}
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="product-outer-main">
              <div className="product-outer">
                <a href={props.homeData.section_2?.card_2?.link} target="_blank">
                  <h3><img src="images/logo.svg" /> <span className="d-block">{props.homeData.section_2?.card_2?.subtitle}</span></h3>
                  <div className="product-image">
                    <div className="product-main-image">
                      <img src={props.homeData.section_2?.card_2?.images[0]?.original} className="img-fluid" alt="#" />
                    </div>
                  </div>
                  {/* <p>{props.homeData.section_2?.card_2?.description}</p> */}
                  <div
                    className="innerpages"
                    dangerouslySetInnerHTML={{
                      __html: (props.homeData.section_2?.card_2?.description),
                    }}
                  />
                </a>

              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="product-outer-main">
              <div className="product-outer">
                <a href={props.homeData.section_2?.card_3?.link} target="_blank">
                  <h3><img src="images/logo.svg" /> <span className="d-block">{props.homeData.section_2?.card_3?.subtitle}</span></h3>
                  <div className="product-image">
                    <div className="product-main-image">
                      <img src={props.homeData.section_2?.card_3?.images[0]?.original} className="img-fluid" alt="#" />
                    </div>
                  </div>
                  {/* <p>{props.homeData.section_2?.card_3?.description}</p> */}
                  <div
                    className="innerpages"
                    dangerouslySetInnerHTML={{
                      __html: (props.homeData.section_2?.card_3?.description),
                    }}
                  />
                </a>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  );
}

export default HomeProduct;
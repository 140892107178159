import { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useUpdateCartMutation } from '../../redux-services/apis/cartApi';
import { setCheckoutProcess } from '../../redux-services/slices/siteDataSlice';
import ShowError from '../Notifications/ShowError';
import CouponBox from './CouponBox';
import OrderTotal from './OrderTotal';

const CartSummery = ({ cartData, checkout, setCheckout }) => {
  const navigate = useNavigate();
  const [orderNotes, setOrederNotes] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptShipping, setAcceptShipping] = useState(false);
  const [updateCart, updateCartResponce] = useUpdateCartMutation();

  const { termCMS, shippingCMS } = useSelector(state => state.siteData);

  const [modalData, setModalData] = useState({
    title: '',
    msg: '',
  });
  const [showModal, setShowModal] = useState(false);

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const handleModalClick = (data) => {
    if (data === 'term' && termCMS?.length > 0) {
      setModalData({
        title: termCMS[0]?.page_title ?? '',
        msg: termCMS[0]?.page_description ?? '',
      })
    }
    if (data === 'shipping' && shippingCMS?.length > 0) {
      setModalData({
        title: shippingCMS[0]?.page_title ?? '',
        msg: shippingCMS[0]?.page_description ?? '',
      })
    }
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setModalData({
      title: '',
      msg: '',
    })
  }

  const changeOrderNotes = (e) => {
    setOrederNotes(e.target.value);
    setErrors({});
  };

  const acceptTermsChange = (e) => {
    setAcceptTerms(!acceptTerms);
    /* setErrors((err) => ({
      ...err,
      acceptTerms: '',
    })); */
  };

  const acceptShippingChange = (e) => {
    setAcceptShipping(!acceptShipping);
    /* setErrors((err) => ({
      ...err,
      acceptShipping: '',
    })); */
  }

  useEffect(() => {
    if (Object.keys(errors).length) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [errors]);

  const onCheckOut = () => {
    if (!acceptTerms) {
      setErrors((err) => ({
        ...err,
        acceptTerms: 'Agree with terms & conditions before checkout',
      }));
    }
    if (!acceptShipping) {
      setErrors((err) => ({
        ...err,
        acceptShipping: 'Agree with shipping & returns policy before checkout',
      }));
    }
    if (!acceptTerms && !acceptShipping) {
      setErrors((err) => ({
        ...err,
        acceptTerms: 'Agree with terms & conditions before checkout',
        acceptShipping: 'Agree with shipping & returns policy before checkout',
      }));
    }
    updateCart({ orderNotes });
  };

  useEffect(() => {
    if (checkout) {
      if (!acceptTerms) {
        setErrors((err) => ({
          ...err,
          acceptTerms: 'Agree with terms & conditions before checkout',
        }));
      }
      if (!acceptShipping) {
        setErrors((err) => ({
          ...err,
          acceptShipping: 'Agree with shipping & returns policy before checkout',
        }));
      }
      if (!acceptTerms && !acceptShipping) {
        setErrors((err) => ({
          ...err,
          acceptTerms: 'Agree with terms & conditions before checkout',
          acceptShipping: 'Agree with shipping & returns policy before checkout',
        }));
      }
      updateCart({ orderNotes });
      setCheckout(false);
    }
  }, [checkout])

  useEffect(() => {
    if (cartData) {
      setOrederNotes(orderNotes ? orderNotes : cartData.order_note ?? '');
    }
  }, [cartData]);

  useEffect(() => {
    if (updateCartResponce.isSuccess) {
      dispatch(setCheckoutProcess('cart'));
      if (!acceptTerms) {
        setErrors((err) => ({
          ...err,
          acceptTerms: 'Agree with terms & conditions before checkout',
        }));
      } else if (!acceptShipping) {
        setErrors((err) => ({
          ...err,
          acceptShipping: 'Agree with shipping & returns policy before checkout',
        }));
      } else if (!acceptTerms && !acceptShipping) {
        setErrors((err) => ({
          ...err,
          acceptTerms: 'Agree with terms & conditions before checkout',
          acceptShipping: 'Agree with shipping & returns policy before checkout',
        }));
      } else {
        navigate('/checkout');
      }
    }
  }, [updateCartResponce]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="checkoutRight">
        <div className="coupancodeouter">
          <h4>Order Summary</h4>
          <hr />
          {/* <p className="ordersummery">
            <strong>Order Restrictions</strong> – Cancellation and restocking
            fees may apply after the following business day of placed orders.
            Visit our order cancellation or modifications policy before checking
            out. <a href=""> View FAQ page</a>
          </p> */}



          <Modal className="termsmodal" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="ordersummery">
                <strong>Order Restrictions</strong> – Cancellation and restocking
                fees may apply after the following business day of placed orders.
                Visit our order cancellation or modifications policy before checking
                out. <a href=""> View FAQ page</a>
              </p>
              <p>
                {' '}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </p>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </p>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet. It uses a
                dictionary of over 200 Latin words, combined with a handful of
                model sentence structures, to generate Lorem Ipsum which looks
                reasonable. The generated Lorem Ipsum is therefore always free
                from repetition, injected humour, or non-characteristic words
                etc.
              </p>
              <div className='text-end'>
                <Button closeButton>I Agree </Button>
                <Button className='ms-3 btn-dark' closeButton>Cancel</Button>
              </div>
            </Modal.Body>
          </Modal>

          <div className="form-group">
            <label>Delivery Notes</label>
            <Form.Control
              as="textarea"
              maxLength="255"
              value={orderNotes}
              onChange={changeOrderNotes}
              rows={3}
            />
          </div>
        </div>

        <CouponBox cartData={cartData} />

        <div className="coupancodeouter">
          <hr />
          <OrderTotal cartData={cartData} />
          <div className="checkouter">
            <span className="custom_check">
              I agree with the{' '}
              <Link to="" onClick={() => handleModalClick('term')}>Terms and Conditions</Link>
              <input
                type="checkbox"
                checked={acceptTerms}
                onChange={acceptTermsChange}
              />
              <span className="check_indicator">&nbsp;</span>
              <ShowError
                current_key="acceptTerms"
                key="acceptTerms"
                errors={errors}
              />
            </span>
          </div>
          <div className="checkouter">
            <span className="custom_check">
              I agree with the{' '}
              <Link to="/shipping-policy" target="_blank">Shipping Policy</Link>{" & "}
              <Link to="/return-policy" target="_blank" >Return Policy</Link>
              <input
                type="checkbox"
                checked={acceptShipping}
                onChange={acceptShippingChange}
              />
              <span className="check_indicator">&nbsp;</span>
              <ShowError
                current_key="acceptShipping"
                key="acceptShipping"
                errors={errors}
              />
            </span>
          </div>
          <div className="mt-3 text-center">
            <Button onClick={onCheckOut} className="w-100">
              Proceed to Checkout
            </Button>
            <Link className="continuebtn" to="/shafts/category/all">
              Continue Shopping
            </Link>
          </div>
        </div>
      </div>

      <Modal className="termsmodal" show={showModal} onHide={handleCloseModal}>
        <ModalHeader closeButton>
          <Modal.Title >{modalData?.title}</Modal.Title>
        </ModalHeader>
        <ModalBody>
          <div
            className="ordersummery"
            dangerouslySetInnerHTML={{ __html: modalData?.msg }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="danger" onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CartSummery;

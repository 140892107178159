import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import {
  useApplyCouponMutation,
  useRemoveCouponMutation,
} from '../../redux-services/apis/cartApi';
import { getUserDetails } from '../../redux-services/slices/authSlice';
import { getSelectedShippingRate } from '../../redux-services/slices/cartSlice';
import ShowError from '../Notifications/ShowError';

const CouponBox = ({ cartData }) => {
  const [couponCode, setCouponCode] = useState('');
  const [errors, setErrors] = useState({});

  const user = useSelector(getUserDetails);
  const shippingPrice = useSelector(getSelectedShippingRate);
  const [applyCouponSubmit, couponResponse] = useApplyCouponMutation();
  const [removeCouponSubmit, _removeCouponResponse] = useRemoveCouponMutation();

  const removeCoupon = () => {
    removeCouponSubmit();
  };
  const applyCoupon = () => {
    if (couponCode == '') {
      setErrors({ coupon_code: 'Please enter coupon code' });
    }

    applyCouponSubmit({
      couponCode,
      cartTotal: cartData?.totalCartAmount,
      userId: user?._id,
      shippingPrice: shippingPrice?.totalCharges
        ? shippingPrice?.totalCharges
        : '',
    });
    console.log('COUPON RESPONSE', couponResponse);
    setCouponCode('');
  };
  useEffect(() => {
    if (couponResponse.isError) {
      setErrors({ coupon_code: couponResponse.error.data.message });
    }
  }, [couponResponse]);

  const couponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };

  return (
    <div
      className={
        !cartData?.coupon
          ? `giftcard ${errors?.coupon_code ? 'gift-error' : ''}`
          : ''
      }
    >
      {!cartData?.coupon ? (
        <>
          <input
            type="text"
            onChange={couponCodeChange}
            name="coupon_code"
            value={couponCode}
            placeholder="Coupon Code"
            className="form-control"
          />
          <ShowError
            current_key="coupon_code"
            key="coupon_code"
            errors={errors}
          />
        </>
      ) : null}
      {cartData?.coupon ? (
        <>
          <Button
            title="Remove coupon"
            className="applied-coupon"
            onClick={removeCoupon}
          >
            {cartData?.coupon?.coupon_code}
            <IoMdClose />
          </Button>{' '}
          <span>Applied Coupon</span>
        </>
      ) : (
        <Button onClick={applyCoupon}>Apply</Button>
      )}
    </div>
  );
};

export default CouponBox;

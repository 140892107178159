import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { FiSearch, FiChevronDown } from "react-icons/fi";
import { BsCurrencyDollar } from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";



function OrderNew() {

    return (
        <>
            <section className='checkoutpage'>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="accountInnner ps-0 pe-0">
                                <h2>Orders</h2>
                                <p>Stive Smith</p>
                                <hr />
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="cartmain">
                                <div className="cartheader">
                                    <div className="ordereditem">
                                        <p><span>2 orders</span> placed in</p>
                                        <Form.Select className="form-control" aria-label="Default select example">
                                            <option>Last 30 Days</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </Form.Select>
                                    </div>
                                    <div className="searchProduct">
                                        <input type="text" className="form-control" placeholder="Search all Order" />
                                        <Button><FiSearch /></Button>
                                    </div>
                                </div>
                                <div className="cartproductMain">
                                    <div className="cartlistheader">
                                        <div className="cartleftheader">
                                            <div className="orderplaced">
                                                <p>Order Placed <span className="d-block">30 November 2022</span></p>
                                            </div>
                                            <div className="orderplaced">
                                                <p>Total <span className="d-block"><BsCurrencyDollar />499.00</span></p>
                                            </div>
                                            <div className="orderplaced">
                                                <p>Ship To <span className="d-block"><Link to="">Stive Smith <FiChevronDown /></Link></span></p>
                                            </div>
                                        </div>
                                        <div className="cartlistheaderRight">
                                            <p>ORDER # 402-3724672-2049926</p>
                                        </div>
                                    </div>
                                    <div className="cartproductdetails">
                                        <div className="stpes">
                                            <div className="stpeouterbox">
                                                <ul className="list-unstyled multi-steps">
                                                    <li id="step-1" className="is-active"><Link to="">Placed</Link></li>
                                                    <li id="step-2"><AiOutlineRight /> Build Shop</li>
                                                    <li id="step-3"><AiOutlineRight /> Processing</li>
                                                    <li id="step-3"><AiOutlineRight /> Shipped</li>
                                                    <li id="step-4"><AiOutlineRight /> Delivered</li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="cartdetailsleft">
                                            <div className="cartproductdetailstab">
                                                <div className="cartproductimg">
                                                    <Link to=""><img src="images/noproduct.png" alt="#" className="img-fluid" /><span className="additems">3</span></Link>

                                                </div>
                                                <div className="cartproductcontentdetails">
                                                    <Link to=""><h6>Ballistic</h6></Link>
                                                    <p><span>Flex</span> D40</p>
                                                    <p><span>Color</span> Green</p>
                                                    <p className="customizedshaft">Raw Shaft</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cartdetailsright">
                                            <Link to="">Invoice</Link>
                                            <Link to="">Track Shipping</Link>
                                            <Link to="">Review Product</Link>
                                            <Link to="">Order Details</Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="cartproductMain">
                                    <div className="cartlistheader">
                                        <div className="cartleftheader">
                                            <div className="orderplaced">
                                                <p>Order Placed <span className="d-block">30 November 2022</span></p>
                                            </div>
                                            <div className="orderplaced">
                                                <p>Total <span className="d-block"><BsCurrencyDollar />499.00</span></p>
                                            </div>
                                            <div className="orderplaced">
                                                <p>Ship To <span className="d-block"><Link to="">Stive Smith <FiChevronDown /></Link></span></p>
                                            </div>
                                        </div>
                                        <div className="cartlistheaderRight">
                                            <p>ORDER # 402-3724672-2049926</p>
                                        </div>
                                    </div>
                                    <div className="cartproductdetails">
                                        <div className="stpes">
                                            <div className="stpeouterbox">
                                                <ul className="list-unstyled multi-steps">
                                                    <li id="step-1" className="is-active"><Link to="">Placed</Link></li>
                                                    <li id="step-2"><AiOutlineRight /> Build Shop</li>
                                                    <li id="step-3"><AiOutlineRight /> Processing</li>
                                                    <li id="step-3"><AiOutlineRight /> Shipped</li>
                                                    <li id="step-4"><AiOutlineRight /> Delivered</li>
                                                </ul>
                                            </div>

                                        </div>
                                        <div className="cartdetailsleft">
                                            <div className="cartproductdetailstab">
                                                <div className="cartproductimg">
                                                    <Link to=""><img src="images/noproduct.png" alt="#" className="img-fluid" /><span className="additems">3</span></Link>

                                                </div>
                                                <div className="cartproductcontentdetails">
                                                    <Link to=""><h6>Ballistic</h6></Link>
                                                    <p><span>Flex</span> D40</p>
                                                    <p><span>Color</span> Green</p>
                                                    <p className="customizedshaft">customized shaft</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cartdetailsright">
                                            <Link to="">Invoice</Link>
                                            <Link to="">Track Shipping</Link>
                                            <Link to="">Review Product</Link>
                                            <Link to="">Order Details</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    );
}

export default OrderNew;

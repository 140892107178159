import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setFittingData } from "../../../redux-services/slices/productDataSlice";

const ShowImage = ({ imageSrc }) => {

  return <Col md="7">
    <div className='adaptorImages modalimg'>
      <img src={imageSrc} className='img-fluid' alt='' />
    </div>
  </Col>
}

const InitialState = {
  adaptor: "",
  adaptor_option: "",
  grip: "",
  grip_option: "",
  grip_logo: "",
  extra_wrap: "",
  playing: "",
  acknowledgementChecked: false,
}

const FittingAttributes = ({ fittingAttributeList, setProductRates, message, product }) => {

  const dispatch = useDispatch();
  const params = useParams();


  const [fittingList, setFittingList] = useState(InitialState);
  const [check, setCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const getFittings = useCallback((title, isOption = false) => {
    const fitting = fittingAttributeList?.find(ftt => ftt.title.toLowerCase().includes(title))?.fittings?.filter(ft => ft.categoryIds.includes(product.categoryId._id));

    if (isOption && fitting) {
      if (fittingList[title]) {
        return fitting.find(ft => ft._id === fittingList[title])?.options;
      }
      return [];
    }
    return fitting ?? [];
  }, [fittingAttributeList, fittingList])

  const onFittingChange = useCallback((e) => {
    const id = e.currentTarget.getAttribute("data-fitting-id");
    const value = e.currentTarget.value;

    if (id === 'adaptor' || id === 'grip_option') {
      const fittingsG = getFittings(id === 'grip_option' ? 'grip' : id, id === 'grip_option').find(e => e._id === value);
      setProductRates(pr => ({ ...pr, [id]: fittingsG?.price ? fittingsG?.price : 0 }))
    }

    setFittingList(st => ({ ...st, [id]: value }));

    if ((id === 'adaptor' && id !== 'adaptor_option')) {
      setFittingList(st => ({ ...st, [id]: value }));
      setFittingList(st => ({ ...st, adaptor_option: "" }));
    }
    if ((id === 'grip' && id !== 'grip_option')) {
      setFittingList(st => ({ ...st, [id]: value }));
      setFittingList(st => ({ ...st, grip_option: "" }));
    }
    if (id === 'acknowledgementChecked') {
      setFittingList(st => ({ ...st, [id]: !check }));
      setCheck(!check);
    }
  }, [getFittings, setProductRates]);

  useEffect(() => {
    dispatch(setFittingData({ id: params.slug, fittingData: fittingList }))
  }, [dispatch, fittingList, params]);

  const getImage = useCallback((title) => {
    const fittings = fittingAttributeList?.find(ftt => ftt.title.toLowerCase().includes(title))?.fittings?.find(ft => ft._id === fittingList[title]);
    let image = '';
    if (title === 'grip') {
      image = fittings?.options?.find(ft => ft._id === fittingList['grip_option'])?.image;
    }
    else if (title === 'adaptor') {
      image = fittings?.image;
    }
    return image ? "https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/fitting-variation/" + image : "/images/productdummy.png";
  }, [fittingAttributeList, fittingList])


  const adaptorSelection = useMemo(() => {
    return <Col md="12">
      <div className='form-group'>
        <label>Select an adaptor for your clubhead make and model<span>*</span></label>
        <Form.Select aria-label="Default select example" name="adaptor" data-fitting-id="adaptor" onChange={onFittingChange}>
          <option value="">Select Model</option>
          {getFittings('adaptor')?.map(ftt => <option key={ftt._id} value={ftt._id} data-rate={ftt.price}>{ftt.title} / ${ftt.price}</option>)}
        </Form.Select>
        {(message !== "" && fittingList.adaptor === "") ? <p style={{ color: "red" }}>Please provide adaptor</p> : ""}
      </div>
    </Col>
  }, [fittingList.adaptor, getFittings, message, onFittingChange]);

  const adaptorOptionSelection = useMemo(() => {
    return <Col md="12"> <div className='form-group'>
      <label>Adaptor Settings<span>*</span></label>
      <Form.Select aria-label="Default select example" name="adaptor_option" data-fitting-id="adaptor_option" onChange={onFittingChange}>
        <option value="">Select Settings</option>
        {getFittings('adaptor', true)?.map(ftt => <option key={ftt._id} value={ftt._id}>{ftt.title}</option>)}
      </Form.Select>
      {(message !== "" && fittingList.adaptor_option === "") ? <p style={{ color: "red" }}>Please provide adaptor settings</p> : ""}
    </div>
    </Col>
  }, [fittingList.adaptor_option, getFittings, message, onFittingChange])


  const adaptorImage = useMemo(() => {
    const imageSrc = getImage("adaptor");

    return <ShowImage imageSrc={imageSrc} />
  }, [getImage])

  const handlePopUp = () => {
    setShowModal(!showModal);
  }

  const palyingLength = useMemo(() => {
    return <Col md="12">
      <Row>
        <Col md="5">
          <div className='form-group'>
            <label>Playing Length<span>*</span> <span style={{ textDecoration: "underline", cursor: "pointer", textTransform: "capitalize" }} onClick={handlePopUp}>(Whole Club Length in Inches)</span></label>
            <Form.Select aria-label="Default select example" data-fitting-id="playing" onChange={onFittingChange}>
              <option value="">Select Length</option>
              {getFittings('playing')?.map(ftt => <option key={ftt._id} value={ftt._id}>{(ftt.title == 0) ? "Uncut" : ftt.title}</option>)}
            </Form.Select>
            {(message !== "" && fittingList.playing === "") ? <p style={{ color: "red" }}>Please provide playing length</p> : ""}
          </div>
        </Col>
      </Row>
    </Col>
  }, [fittingList.playing, getFittings, message, onFittingChange]);

  const renderGrip = useMemo(() => {
    return <Col md="12">
      <div className='form-group'>
        <label>Grip: Select Brand<span>*</span></label>
        <Form.Select aria-label="Default select example" data-fitting-id="grip" onChange={onFittingChange}>
          <option value=""> Select Brand</option>
          {getFittings('grip')?.map(ftt => <option key={ftt._id} value={ftt._id}>{ftt.title}</option>)}
        </Form.Select>
        {(message !== "" && fittingList.grip === "") ? <p style={{ color: "red" }}>Please provide grip</p> : ""}
      </div>
    </Col>
  }, [fittingList.grip, getFittings, message, onFittingChange]);

  const renderGripOption = useMemo(() => {
    return <Col md="12">
      <div className='form-group'>
        <label>Grip: Select Model<span>*</span></label>
        <Form.Select aria-label="Default select example" data-fitting-id="grip_option" onChange={onFittingChange}>
          <option value="">Select Model</option>
          {getFittings('grip', true)?.map(ftt => <option key={ftt._id} value={ftt._id} data-rate={ftt.price}>{(ftt.title === "N/A") ? ftt.title : `${ftt.title} / $${ftt.price}`}</option>)}
        </Form.Select>
        {(message !== "" && fittingList.grip_option === "") ? <p style={{ color: "red" }}>Please provide grip option</p> : ""}
      </div>
    </Col>
  }, [fittingList.grip_option, getFittings, message, onFittingChange])

  const gripImage = useMemo(() => {
    const imageSrc = getImage("grip");
    return <ShowImage imageSrc={imageSrc} />
  }, [getImage]);

  const staticFormFields = useMemo(() => {
    return <>
      < Col md="5" >
        <Row>
          <Col md="12">
            <div className='form-group'>
              <label>Extra Wraps<span>*</span></label>
              <Form.Select data-fitting-id="extra_wrap" onChange={onFittingChange} aria-label="Default select example">
                <option value="">Select Wraps</option>
                <option value="1">None</option>
                <option value="2">1</option>
                <option value="3">2</option>
              </Form.Select>
              {(message !== "" && fittingList.extra_wrap === "") ? <p style={{ color: "red" }}>Please provide extra wrap</p> : ""}
            </div>
          </Col>
          <Col md="12">
            <div className='form-group'>
              <label>Grip Logo<span>*</span></label>
              <Form.Select aria-label="Default select example" data-fitting-id="grip_logo" onChange={onFittingChange}>
                <option value="" >Select Grip Logo</option>
                <option value="N/A" >N/A</option>
                <option value="up">Up</option>
                <option value="down">Down</option>
              </Form.Select>
              {(message !== "" && fittingList.grip_logo === "") ? <p style={{ color: "red" }}>Please provide grip logo</p> : ""}
            </div>
          </Col>
        </Row>
      </Col >
      <Col md="7">
        <div className='form-group'>
          <label>Product Customization Notes <span style={{ textTransform: "capitalize" }}>(optional)</span></label>
          <Form.Control style={{ border: '1px solid #ccc' }} maxLength="255" data-fitting-id="product_customization" onChange={onFittingChange} name="product_customization" as="textarea" rows={5} />
        </div>
      </Col>
      <Col md="12">
        <div className='checkouter'>
          <span className="custom_check">
            Order Turnaround Acknowledgment
            <span>
              I understand and agree the build time to shipping ready date can be up to five (5) business days calculated Monday-Friday. Counting starts the first business day after my original order was placed.
            </span>
            <input data-fitting-id="acknowledgementChecked" onChange={onFittingChange} type="checkbox" value={check} />
            <span className="check_indicator">
              &nbsp;
            </span>
          </span>
          {(message !== "" && fittingList.acknowledgementChecked === false) ? <p style={{ color: "red" }}>Please select the checkbox</p> : ""}
        </div>
      </Col>
    </>
  }, [fittingList.extra_wrap, fittingList.grip_logo, message, onFittingChange]);

  return fittingAttributeList && getFittings &&
    <div className='selectcolor'>
      <div className='selectproductcolor'>
        <h5 style={{ fontWeight: 600 }}>Custom build options</h5>
        <hr />
        <Row>
          <Col md="5">
            <Row>
              {adaptorSelection}
              {adaptorOptionSelection}
            </Row>
          </Col>
          {adaptorImage}
          {palyingLength}
          <Col md="5">
            <Row>
              {renderGrip}
              {renderGripOption}
            </Row>
          </Col>
          {gripImage}
          {staticFormFields}
        </Row>
      </div>
      <Modal className='modal-lg' show={showModal} onHide={handlePopUp} centered>
        <Modal.Header className="border-bottom">
          <Modal.Title style={{ fontSize: "larger" }}>Playing Length Description</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2" style={{ textAlign: "justify", textJustify: "inter-word" }}>
          The Term "Whole Club Length" typically refers to the total length of a golf club from the grip end to the clubhead. The length of golf clubs can vary depending on the type of club (driver, irons, putter, etc.) and the preferences of the golfer.
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="primary"
            type="button"
            className="btn btn-outline-primary"
            onClick={handlePopUp}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
};

export default FittingAttributes;
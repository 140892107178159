import { useSelector } from "react-redux";
import { getLoading } from "../../redux-services/slices/siteDataSlice";

const Spiner = ({ customLoading }) => {

    const loading = useSelector(getLoading);

    return (loading || customLoading) && <div className='loader-outer'>
        <div className="text-center">
            <div className="loader1">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
}

export default Spiner;
import { NEWS_TAG } from '../../constants';
import { ApiService } from './apiService';

const BASE_URL = '/api/front';
export const NewsApi = ApiService.injectEndpoints({
    endpoints: (builder) => ({
        getAllNewsData: builder.query({
            query: (newsData) => ({
                url: `${BASE_URL}/news`,
                method: 'GET',
                body: {
                    ...newsData,
                }
            }),
            providesTags: [NEWS_TAG],
        }),
        getDataByNewsSlug: builder.query({
            query: (query) => ({
                url: `${BASE_URL}/news/slug`,
                method: 'GET',
                params: {
                    slug: query.slug,
                },
            }),
            providesTags: [NEWS_TAG],
        }),
        subscribeNewsLetter: builder.mutation({
            query: (email) => {
                return {
                    url: `${BASE_URL}/news/newsletter`,
                    method: 'POST',
                    body: email,
                }
            },
            invalidatesTags: [NEWS_TAG],
        }),
        unSubscribeEmail: builder.mutation({
            query: (email) => {
                return {
                    url: `${BASE_URL}/news/newsletter/unsubscribe`,
                    method: 'POST',
                    body: email,
                }
            },
            invalidatesTags: [NEWS_TAG],
        }),
        findNewsLetterStatus: builder.query({
            query: (query) => ({
                url: `${BASE_URL}/news/newsletter/getNewsLetterStatus`,
                method: 'GET',
                params: {
                    email: query.email
                },
            }),
            providesTags: [NEWS_TAG],
        }),
    }),
});

export const { useGetAllNewsDataQuery, useGetDataByNewsSlugQuery, useSubscribeNewsLetterMutation, useUnSubscribeEmailMutation, useFindNewsLetterStatusQuery } = NewsApi;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getSelectedShippingAddress, getSelectedShippingRate } from "../../redux-services/slices/cartSlice";

const ShippingInfo = () => {

    const shippingAddress = useSelector(getSelectedShippingAddress)
    const shippingRate = useSelector(getSelectedShippingRate)

    //console.log(shippingAddress, "shippingAddress");
    const navigate = useNavigate();

    return <div className='infochanges'>
        <h5>Shipping</h5>
        <ul className='infolist'>
            <li>
                <h5>Ship to</h5>
                <p>{shippingAddress?.name} ({shippingAddress?.ccode + shippingAddress?.contact_no}) {shippingAddress?.address}</p>
                <Link to="/checkout">Change</Link>
            </li>
            <li>
                <h5>Method</h5>
                <p>{shippingRate?.carrier} ({shippingRate?.service}) · <strong>${shippingRate?.totalCharges}</strong></p>
                <Link to="/checkout">Change</Link>
            </li>
        </ul>
    </div>
}

export default ShippingInfo;
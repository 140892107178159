import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { FiSearch } from 'react-icons/fi';

import { Link, useParams, useNavigate } from "react-router-dom";
import Spiner from '../../components/Layouts/Spiner';
import ImageGallery from 'react-image-gallery';
import { useGetDataBySlugQuery } from '../../redux-services/apis/technologyApi';
import { useGetAllNewsDataQuery } from '../../redux-services/apis/newsApi';

const MediaNews = () => {

    const [techData, setTechData] = useState(null);
    const [newsData, setNewsData] = useState(null);
    const [newsImages, setNewsImages] = useState([]);
    const [techList, setTechList] = useState({
        query: '',
        list: []
    });
    const [search, setSearch] = useState('');
    const params = useParams();
    const navigate = useNavigate();

    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: params.page_slug,
    });

    const { data: getAllNewsData } = useGetAllNewsDataQuery();

    const getMonthName = (monthNumber) => {
        let mlist = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return mlist[monthNumber];
    }

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setTechData(getDataBySlug?.response[0])
        }
        if (getAllNewsData?.status && getAllNewsData.response) {
            setNewsData(getAllNewsData.response)
        }
        const videoArray = [];
        const playImage = '/images/play.svg';
        if (getAllNewsData && getAllNewsData.response[0]?.videoUrl) {
            let videoData = getAllNewsData?.response.map(items => items.videoUrl)
            for (let key of videoData) {
                let i = 0;
                // videoArray.push({
                //     original: playImage,
                //     embedUrl: key,
                //     thumbnailClass: 'video-slide',
                //     renderItem: renderVideo,
                // });
                videoArray.push({
                    thumbnail: playImage,
                    original: playImage,
                    embedUrl: key,
                    thumbnailClass: 'video-slide',
                    renderItem: function (onClick, item) {
                        return <div className='image-gallery-image'>
                            <iframe title='video' width='400' height='300' src={key} frameBorder='0' allowFullScreen></iframe>
                        </div>
                    },
                });
                i++;
            }
        }
        setNewsImages(videoArray);

    }, [getDataBySlug, getAllNewsData]);

    useEffect(() => {
        if (params.page_slug !== 'press-center') {
            navigate('/');
        }
    }, [params])

    const renderVideo = useCallback((item) => {
        return (
            <div>
                {
                    <div className="video-wrapper">
                        <iframe
                            width="500"
                            height="400"
                            src={`${item.embedUrl}?autoplay=1&showinfo=0`}
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                }
            </div>
        );
    }, []);

    const handleSearch = useCallback(
        (e) => {
            setSearch(e.target.value.trimStart());
            let delayTimer;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
                const results = newsData.filter(post => {
                    if (e.target.value.trimStart() === "") return newsData
                    return post.title.toLowerCase().includes(e.target.value.toLowerCase())
                })
                setTechList({
                    query: e.target.value,
                    list: results
                })
            }, 750);
        }, [search]);

    return (
        isLoading ? (
            <Spiner />
        ) : (
            <>
                {techData && techData?.status == 1 && techData != null ? (
                    <section className='InnerPage'>
                        <img src={techData.banner_image} alt='#' />
                        <div className='banner-contentInner'>
                            <h2>{techData?.page_title}</h2>
                            <h4>{techData?.page_subtitle}</h4>
                        </div>
                    </section>
                ) : (
                    <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                )}
                {newsData?.length > 0 ? (
                    <section className='facility-outer NewsMedia'>
                        <Container className="">
                            <Row>
                                <Col md="4" className='ms-auto'>
                                    <div className='form-group'>
                                        <div className='searchdealers'>
                                            <input
                                                type="text"
                                                placeholder='Search News'
                                                className='form-control'
                                                value={search}
                                                onChange={handleSearch}
                                            />
                                            <Button><FiSearch /></Button>
                                        </div>
                                    </div>
                                </Col>
                                {techList && newsData && (techList.query === '' ? newsData && newsData : techList.list).map((techItems, index) =>
                                (techItems.status == 1 && <Col md="12">
                                    <div className='mediaOuter'>
                                        <div className='mediapanel'>
                                            <Link to={`/pages/newsdetails/${techItems?.slug}`}>
                                                {techItems?.images[0]?.original &&
                                                    <img src={techItems?.images[0]?.original} className='img-fluid' />
                                                }
                                            </Link>
                                        </div>
                                        <div className='media-content'>
                                            <h4><Link to={`/pages/newsdetails/${techItems?.slug}`}>{techItems?.title}</Link></h4>
                                            <p>{techItems?.subtitle}</p>
                                            <span className='dateTime'>{new Date(techItems?.created_at).getDate() + '-' + getMonthName(new Date(techItems?.created_at).getMonth()) + '-' + new Date(techItems?.created_at).getFullYear()}</span>
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: (techItems?.description.slice(0, 250)),
                                                }}
                                            />
                                            <Link to={`/pages/newsdetails/${techItems?.slug}`} className='btn btn-primary'>Read More</Link>
                                        </div>
                                    </div>
                                </Col>)
                                )}
                                {(techList.query !== "" && techList.list.length == 0) && <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>}
                            </Row>
                        </Container>
                    </section>
                ) : (
                    <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                )}
            </>

        )
    );
}

export default MediaNews;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FiSearch, FiMapPin } from 'react-icons/fi';
import { AiOutlineMobile, AiOutlineMail } from 'react-icons/ai';
import { BsFillPinMapFill } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useGetDataBySlugQuery } from '../../../redux-services/apis/technologyApi';
import Spiner from '../../../components/Layouts/Spiner';
import { useGetDealerListQuery } from '../../../redux-services/apis/dealerApi';
import { parsePhoneNumber } from 'libphonenumber-js';

const InternationalFitting = () => {
    const [techData, setTechData] = useState(null);
    const params = useParams();
    const location = useLocation();
    console.log(location?.pathname.toString().split("/")[1])
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: location?.pathname.toString().split("/")[2]
    });

    const alphabet = [...Array(26).keys()].map((i) =>
        String.fromCharCode(i + 65)
    );

    const initialState = {
        searchText: '',
        filterOrder: '',
        latitute: '',
        longitude: '',
        radius: 0,
    };

    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const [alphabaticId, setAlphabaticId] = useState('');
    const [dealersList, setDealersList] = useState(initialState);
    const [dealerData, setDealerData] = useState();
    const { isSuccess, data: dealerListData } = useGetDealerListQuery({
        searchText: dealersList.searchText ?? search,
        filterOrder: dealersList.filterOrder ?? alphabaticId,
        latitute: dealersList.latitute ?? 0,
        longitude: dealersList.longitude ?? 0,
        radius: dealersList.radius ?? 0,
    });


    useEffect(() => {
        setDealerData(dealerListData?.response?.internationalsFittingStudioDealersList);
    }, [dealerListData])

    const [radius, setRadius] = useState('');
    const [lat, setlat] = useState();
    const [lng, setlng] = useState();

    const [myLocation, setMyLocation] = useState(false);
    const handleMyLocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setlat(position.coords.latitude);
            setlng(position.coords.longitude);
            let delayTimer;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
                setDealersList({ searchText: search, filterOrder: alphabaticId, radius: radius, latitute: position.coords.latitude, longitude: position.coords.longitude })
            }, 750);
        })
    }, [radius, lat, lng, search, alphabaticId]);

    useEffect(() => {
        if (lat != undefined) {
            setMyLocation(true);
        }
    }, [lat]);

    const handleRadius = useCallback((e) => {
        setRadius(e.target.value);
        let delayTimer;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            setDealersList({ searchText: search, filterOrder: "", radius: e.target.value, latitute: lat, longitude: lng })
            setAlphabaticId('');
        }, 750);
    }, [radius, lat, lng, search, alphabaticId]);

    const handleSearch = useCallback(
        (e) => {
            let delayTimer;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
                setDealersList({ searchText: e.target.value, filterOrder: "", radius: radius, latitute: lat, longitude: lng });
            }, 750);
            setSearch(e.target.value);
            setAlphabaticId('');
        },
        [radius, lat, lng, search, alphabaticId]
    );

    const handleClickOnAphabatic = useCallback(
        (e) => {
            e.preventDefault();

            // set the alphabeticId in dealerList
            const alphaOrderId = e.currentTarget?.getAttribute('data-id');
            let delayTimer;
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => {
                setDealersList({ filterOrder: alphaOrderId, radius: radius, latitute: lat, longitude: lng });
            }, 750);
            setAlphabaticId(alphaOrderId);
        },
        [radius, lat, lng, search, alphabaticId]
    );

    const handleNavigate = useCallback(() => {
        navigate('/dealer-registration');
    }, []);

    const AlphabaticUI = useMemo(() => {
        return (
            <Col md="12">
                <div className="selectfilters">
                    <Link
                        to=""
                        data-id={''}
                        onClick={handleClickOnAphabatic}
                        className={alphabaticId === '' ? 'active' : ''}
                    >
                        All
                    </Link>
                    {alphabet.map((alphaOrder) => {
                        return (
                            <div>
                                <Link
                                    to=""
                                    data-id={alphaOrder}
                                    onClick={handleClickOnAphabatic}
                                    className={alphabaticId === alphaOrder ? 'active' : ''}
                                >
                                    {alphaOrder}
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </Col>
        );
    }, [alphabaticId]);

    const dealerUI = useMemo(() => {
        const formatPhoneNumber = (phoneNumber, countryCode) => {
            const formattedPhoneNumber = parsePhoneNumber(countryCode + phoneNumber).formatInternational();
            return formattedPhoneNumber;
        }
        return (
            <div>
                {dealerData &&
                    dealerData.length != 0 ? (
                    <>
                        {dealerData.map((dealer) => (
                            <div key={dealer?.country}>
                                <div className="dealerslistouter">
                                    <h4>{dealer?.country}</h4>
                                </div>
                                <Row>
                                    {dealer.addresses.map((addressData) => (
                                        <Col md="4" sm="4" lg="4" xl="4" key={addressData._id}>
                                            <div className="listbody">
                                                <Link to="" className="companylogo">
                                                    {addressData?.avatar != "" ?
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_AWS_STORAGE_URL +
                                                                '/uploads/dealer-logo/' +
                                                                addressData.avatar
                                                            }
                                                            alt={addressData?.business_name}
                                                        /> :
                                                        <div style={{ paddingTop: "24px" }}>
                                                            <img src="" />
                                                        </div>
                                                    }
                                                </Link>
                                                <Link to="">
                                                    {addressData?.business_name} - {addressData?.city} <span className="d-block">({addressData.state}, {addressData.country})</span>
                                                </Link>
                                                <ul className="infolistdetails">
                                                    <li>
                                                        <FiMapPin />
                                                        {addressData.address_line_1}
                                                        {addressData.address_line_2 &&
                                                            addressData.address_line_2 != '' ? (
                                                            <span className="d-block">
                                                                {addressData.address_line_2}
                                                            </span>
                                                        ) : null}
                                                        <span className="d-block">
                                                            {addressData.city}, {addressData.state}, {addressData.zipcode}
                                                        </span>
                                                        {addressData.country}
                                                    </li>
                                                    <li>
                                                        <AiOutlineMobile />
                                                        <a href={`tel:${addressData.ccode + addressData.contact_no}`} style={{ textDecoration: 'none' }}>
                                                            {formatPhoneNumber(addressData.contact_no, addressData.ccode)}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <AiOutlineMail />
                                                        <a href={`mailto:?to=${addressData?.email}`} target="_blank" className="gmail">
                                                            {addressData.email}
                                                        </a>
                                                    </li>
                                                </ul>
                                                <span className="viewlocation">
                                                    <a
                                                        href={`https://www.google.com/maps/place/${addressData.address_line_1},${addressData.city},${addressData.state}`}
                                                        target="_blank"
                                                    >
                                                        <BsFillPinMapFill /> View on Map
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        )
                        )}
                    </>
                ) : (
                    <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                )}
            </div>
        );
    }, [dealerData]);

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setTechData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);
    console.log(techData)

    return (
        isLoading ? (
            <Spiner />
        ) : (
            techData && techData?.status == 1 && techData != null ? (
                <>
                    <div>
                        <section className='InnerPage'>
                            <img
                                src={techData?.banner_image}
                                alt='Banner image'
                            />
                            <div className='banner-contentInner'>
                                <h2>{techData.page_title}</h2>
                                <h4 style={{ margin: "0px 10px" }}>{techData.page_subtitle}</h4>
                            </div>
                        </section>
                    </div>
                    <section className="productlist-outer">
                        <Container>
                            <Row className="justify-content-between mb-4">
                                <Col md="12">
                                    <div className="uslinks">
                                        <Link
                                            to="/fitting-studios"
                                            className="Link"
                                        >
                                            US Fitting Studio
                                        </Link>
                                    </div>
                                </Col>
                                <Col md='12'>
                                    <div className="form-group text-end">
                                        <Button className="Dealerbtn" onClick={handleNavigate}>
                                            Become a Dealer
                                        </Button>
                                    </div>
                                </Col>
                                {AlphabaticUI}
                                <Col md="12">{dealerUI}</Col>
                            </Row>
                        </Container>
                    </section>
                </>
            ) : (
                <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
            )
        )
    );
}

export default InternationalFitting;

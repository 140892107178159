import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Button, Modal, Badge } from "react-bootstrap";
import { MdEdit, MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDeleteAddressMutation, useGetUserAddressQuery, useMarkAsPrimaryMutation } from "../../../redux-services/apis/userApi";
import { getUserDetails } from "../../../redux-services/slices/authSlice";
import AddAddress from '../../Shipping/AddAddress';

const Address = () => {

    const { isLoading: addressLoading, data: addressList } = useGetUserAddressQuery();

    const authUser = useSelector(getUserDetails);

    const [isLoading, setIsLoading] = useState(false);

    const [deleteAddressFun, deleteAddressResponse] = useDeleteAddressMutation();

    const [markAsPrimary, markAsPrimaryResponse] = useMarkAsPrimaryMutation();

    const [editAddress, setEditAddress] = useState(null);
    const [isOpen, setIsOpen] = useState(true);
    const [addressData, setAddressData] = useState(null);
    const [isOpenSuccess, setIsOpenSuccess] = useState(true);
    const [addAddressResponse, setAddAddressResponse] = useState();
    const [editAddressResponse, setEditAddressResponse] = useState();
    const [showRequestFittingStudio, setShowRequestFittingStudio] = useState(false);
    const [showRequestBusinessLocation, setShowRequestBusinessLocation] = useState(false);


    const deleteAddress = useCallback((e) => {
        const address_id = e.currentTarget.getAttribute("data-address-id");
        deleteAddressFun({ address_id });
    }, [deleteAddressFun])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = useCallback((e) => {
        const address_id = e.currentTarget.getAttribute("data-address-id");
        if (address_id) {
            setEditAddress(address_id)
        } else {
            setEditAddress(null)
        }
        setShow(true)
    }, [addressData]);

    useEffect(() => {
        setAddressData(addressList?.response);
    }, [addressList, authUser])

    const onClickRequestFittingStudio = () => {
        setShowRequestFittingStudio(true);
    }

    const onClickRequestBusinessLocation = () => {
        setShowRequestBusinessLocation(true);
    }

    const onCloseRequestFittingStudio = () => {
        setShowRequestFittingStudio(false);
    }

    const onCloseRequestBusinessLocation = () => {
        setShowRequestBusinessLocation(false);
    }

    const handleCloseModel = useCallback(() => {
        setIsOpen(false);
    }, [isOpen]);

    const handleCloseSuccess = useCallback(() => {
        setIsOpenSuccess(false);
    }, [isOpenSuccess]);

    const handlePrimary = useCallback((e) => {
        const address_id = e.currentTarget.getAttribute("data-address-id");
        if (address_id) {
            markAsPrimary({ dealer_id: authUser?._id, dealer_address_id: address_id })
        } else {
            markAsPrimary(null)
        }
    }, [addressData, authUser]);

    useEffect(() => {
        setIsLoading(addressLoading || deleteAddressResponse.isLoading);
        setIsOpen(true);
    }, [addressLoading, deleteAddressResponse.isLoading, addAddressResponse]);

    useEffect(() => {
        setIsOpenSuccess(true);
    }, [markAsPrimaryResponse, editAddressResponse])

    const addressUI = useMemo(() => <ul>
        {addressData && !isLoading && addressData?.map(address => <li key={address._id}>
            <fieldset className="fscontainer">
                {address.fitting_studio && address.fitting_studio == true ?
                    <label className="fslabel">Fitting Studio</label>
                    : ""}
                <div className="listaddress">
                    <div className="innerdetails">
                        <div className="backpage">
                            <h5>{address.name}</h5>
                            {address.is_primary ? <div><Badge bg pill className="badgeClass">Primary</Badge></div>
                                // : <div><Badge bg="secondary" pill data-address-id={address._id} onClick={handlePrimary} className="badgeClassPointer">Mark As Primary</Badge></div>
                                : ""
                            }
                        </div>
                        <p>{address.address_line_1}
                            {address.address_line_2 && <span className="d-block">
                                {address.address_line_2}
                            </span>}
                            <span className="d-block">
                                {address.city}, {address.state} - {address.zipcode}
                            </span>
                            {address.country}
                        </p>
                        <p>Mobile: {address.ccode} {address.contact_no}</p>
                        {authUser && authUser?.userType == 3 && !address?.fitting_studio ? <Link onClick={onClickRequestFittingStudio} style={{ color: "#6d2a5f" }}><p>Add this Address to the fitting studio</p></Link> : ""}
                        {authUser && authUser?.userType == 3 && address?.status !== 1 ? <Link onClick={onClickRequestBusinessLocation} style={{ color: "#6d2a5f" }}><p>Add this Address to the business locations</p></Link> : ""}
                    </div>
                    <div className="addressfooter">
                        <Button
                            variant='link'
                            data-address-id={address._id}
                            onClick={handleShow}
                        >
                            <MdEdit /> Edit
                        </Button>

                        <Button
                            variant='link'
                            data-address-id={address._id}
                            onClick={deleteAddress}
                        >
                            <MdDeleteOutline /> Remove
                        </Button>
                    </div>
                </div>
            </fieldset>
        </li>
        )}
        {addressData && !addressData?.length && <Col key="no-address">
            <span className="text-center d-block fw-bold text-bold mt-3">No Address Found</span>
        </Col>}
    </ul>, [addressData, isLoading, authUser])

    return (
        <section className='profileDatatop'>
            <Container>
                <Row>
                    <Col md="12">
                        <div className="accountInnner ps-0 pe-0">
                            <h2>Addresses</h2>
                            <div className="backpage">
                                <p>{authUser?.first_name} {authUser?.last_name}</p>
                                <div><Link to="/account">Back to Your Account</Link></div>
                            </div>
                            <hr />
                        </div>
                    </Col>
                    {deleteAddressResponse?.isError && deleteAddressResponse?.error?.status == 403 && isOpen ? (
                        <Col md="12">
                            <div className="productTopBar">
                                <p>
                                    {deleteAddressResponse?.error?.status ? deleteAddressResponse?.error?.data?.message : deleteAddressResponse?.error?.data?.message}
                                </p>
                                {deleteAddressResponse?.error?.status ? (
                                    <div
                                        className="btn btn btn-primary"
                                        data-id={isOpen}
                                        onClick={handleCloseModel}
                                    >
                                        Close
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    ) : null}
                    {markAsPrimaryResponse?.isSuccess && isOpenSuccess ? (
                        <Col md="12">
                            <div className="productTopBar">
                                <p>
                                    {markAsPrimaryResponse?.data?.status ? markAsPrimaryResponse?.data?.message : markAsPrimaryResponse?.data?.message}
                                </p>
                                {markAsPrimaryResponse?.data?.status ? (
                                    <div
                                        className="btn btn btn-primary"
                                        data-id={isOpenSuccess}
                                        onClick={handleCloseSuccess}
                                    >
                                        Close
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    ) : null}
                    {/* {addAddressResponse?.isSuccess && isOpen ? (
                        <Col md="12">
                            <div className="productTopBar">
                                <p>
                                    {addAddressResponse?.data?.status ? addAddressResponse?.data?.message : addAddressResponse?.data?.message}
                                </p>
                                {addAddressResponse?.data?.status ? (
                                    <div
                                        className="btn btn btn-primary"
                                        data-id={isOpen}
                                        onClick={handleCloseModel}
                                    >
                                        Close
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    ) : null}
                    {editAddressResponse?.isSuccess && isOpenSuccess ? (
                        <Col md="12">
                            <div className="productTopBar">
                                <p>
                                    {editAddressResponse?.data?.status ? editAddressResponse?.data?.message : editAddressResponse?.data?.message}
                                </p>
                                {editAddressResponse?.data?.status ? (
                                    <div
                                        className="btn btn btn-primary"
                                        data-id={isOpenSuccess}
                                        onClick={handleCloseSuccess}
                                    >
                                        Close
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    ) : null} */}
                    <Col md="12">
                        <div className='add-address d-flex justify-content-between' >
                            <Button onClick={handleShow}>Add Address</Button>
                            <Link className="btn btn-primary" to="/shafts/category/all">
                                Continue Shopping
                            </Link>
                        </div>
                        <div className="addressList">
                            {!isLoading && addressUI}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{editAddress ? "Edit" : "Add"} Address</Modal.Title>
                    <hr />
                </Modal.Header>
                <Modal.Body>
                    <AddAddress
                        isModel={true}
                        onClose={handleClose}
                        editAddress={editAddress}
                        addressList={addressData}
                        setAddAddressResponse={setAddAddressResponse}
                        setEditAddressResponse={setEditAddressResponse}
                    />
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showRequestFittingStudio} onHide={onCloseRequestFittingStudio}>
                <Modal.Header closeButton={showRequestFittingStudio} onHide={onCloseRequestFittingStudio}>
                </Modal.Header>
                <Modal.Body>
                    <div>Please contact Kinetixx Support at <a href="mailto:support@kinetixxgolf.com">support@kinetixxgolf.com</a> to add this address to fitting studios.</div>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showRequestBusinessLocation} onHide={onCloseRequestBusinessLocation}>
                <Modal.Header closeButton={showRequestBusinessLocation} onHide={onCloseRequestBusinessLocation}>
                </Modal.Header>
                <Modal.Body>
                    <div>Please contact Kinetixx support at <a href="mailto:support@kinetixxgolf.com">support@kinetixxgolf.com</a> to add this address to your business locations.</div>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default Address;

import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Accordion, Button, Input, Card } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { getUserDetails } from '../../../redux-services/slices/authSlice';
import { useDeleteCreditCardMutation } from '../../../redux-services/apis/paymentGetWay';
import ShowModel from '../../Notifications/ShowModel';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { CARDICON } from '../../../constants/CardConstant';



function SaveCard() {
    const [cardedSave, setCardedSave] = useState([]);
    const users = useSelector(getUserDetails);
    const [deleteCreditCard, deleteCreditCardResponse] = useDeleteCreditCardMutation();

    useEffect(() => {
        console.log(users, "usersusers")
        if (users !== null && users.user_info.length > 0) {
            setCardedSave(users.user_info)
        } else {
            setCardedSave([])
        }
    }, [users])

    const removeSaveCard = useCallback((id) => {
        deleteCreditCard({ id })
    })
    return (
        <>
            <section className='profileDatatop pb-5'>
                <Container>
                    <Row>
                        <ShowModel />
                        <Col md="12">
                            <div className="accountInnner pt-0 ps-0 pe-0">
                                <h2>Payment Methods</h2>
                                <div className="backpage">
                                    <p></p>
                                    <div><Link to="/account">Back to Your Account</Link></div>
                                </div>

                                <hr />
                            </div>
                        </Col>
                        {cardedSave && cardedSave.map((user) => (<Col key={user.vault_id} md="6">
                            <div className="savecardouter">
                                <div className="cardheader">
                                    <div className="cardleft-panel">
                                        <Link to="">
                                            <span><img src={CARDICON[user.brand]} /></span> Card ending in {user.last_digits}
                                        </Link>
                                    </div>
                                </div>
                                <div className="cardbody">
                                    <div className="cardbody-left">
                                        <h5>Cardholer Name</h5>
                                        <p>{user.cardholder_name}</p>
                                    </div>
                                    <div className="cardbody-right">
                                        <h5>Billing address</h5>
                                        <p><strong>{user.cardholder_name}</strong> {user.billing_address.address_line_1} {user.billing_address.address_line_2 && <span>{user.billing_address.address_line_2}</span>} {user.billing_address.city} {user.billing_address.state} {user.billing_address.zipcode} <span>{user.billing_address.country}</span>{user.billing_address.contact_no}</p>

                                    </div>
                                </div>
                                <div className="removeedit" onClick={(e) => removeSaveCard(user._id)}><button className='btn btn-outline-primary'>Remove</button></div>
                            </div>
                        </Col>))}
                        {cardedSave.length === 0 && <Col md="12">

                            <span className="text-center d-block fw-bold text-bold mt-3">{"No Payment Method Found."}</span>
                        </Col>
                        }
                    </Row>
                </Container>
            </section>


        </>
    );
}

export default SaveCard;

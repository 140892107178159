import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { SiteDataAPI } from '../redux-services/apis/siteDataApi';
import SiteDataReducer from '../redux-services/slices/siteDataSlice';
import CartDataReducer from '../redux-services/slices/cartSlice';
import ProductDataReducer from '../redux-services/slices/productDataSlice';
import AuthReducer from '../redux-services/slices/authSlice';
import { ProductDetailAPI } from '../redux-services/apis/productDetailApi';
import { ApiService } from '../redux-services/apis/apiService';
import { DealerAPI } from '../redux-services/apis/dealerApi';
import notifyEmailReducer from '../redux-services/slices/notifyEmailsSlice';
import { PaymentGetwayAPI } from '../redux-services/apis/paymentGetWay';
import paymentGetwayReducer from '../redux-services/slices/PaymentGetwaySlice';
import { HomeAPI } from '../redux-services/apis/homeApi';

export const store = configureStore({
  reducer: {
    siteData: SiteDataReducer,
    auth: AuthReducer,
    productData: ProductDataReducer,
    cartData: CartDataReducer,
    notifyEmail: notifyEmailReducer,
    paymentGetway: paymentGetwayReducer,
    [ApiService.reducerPath]: ApiService.reducer,
    [SiteDataAPI.reducerPath]: SiteDataAPI.reducer,
    [ProductDetailAPI.reducerPath]: ProductDetailAPI.reducer,
    [DealerAPI.reducerPath]: DealerAPI.reducer,
    [PaymentGetwayAPI.reducerPath]: PaymentGetwayAPI.reducer,
    [HomeAPI.reducerPath]: HomeAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(SiteDataAPI.middleware)
      .concat(ProductDetailAPI.middleware)
      .concat(ApiService.middleware)
      .concat(DealerAPI.middleware)
      .concat(PaymentGetwayAPI.middleware)
      .concat(HomeAPI.middleware),
});

setupListeners(store.dispatch);

import { useEffect, useState } from "react";

const CompareSpecification = ({ data, title, specs, flex1, flex2 }) => {

    let specDataCovered = [];

    const [dataToShow, setDataToShow] = useState(data);
    useEffect(() => {
        if (data) {
            const dataD = data.filter(dt => dt.data.filter(dtchild => (dtchild.productOne || dtchild.productTwo)).length)

            setDataToShow(dataD);
        }
    }, [data]);

    return <div className='box-outer compare-bodyone'>
        <div className='compare-product-list'>
            <div className='compare-table'>
                <div className='compare-head'>
                    <div className='compare-leftpart'>{title}</div>
                    <div className='compare-center'>{specs?.productOne?.product_title} ({
                      specs?.productOne?.flexData.find(
                        (flx) => flx.option_id === flex1
                      ).option_term
                    })</div>
                    <div className='compare-right'>{specs?.productTwo?.product_title} ({
                      specs?.productTwo?.flexData.find(
                        (flx) => flx.option_id === flex2
                      ).option_term
                    })</div>
                </div>


                {dataToShow?.map(specification => {
                    const HasChild = specification.label && specification.label != '';
                    const label = HasChild ? specification.label : specification.data[0].name;
                    const id = HasChild ? specification._id : specification.data[0]._id;
                    const specData = specification.data.filter(dt => (dt.productOne || dt.productTwo))
                    if (HasChild && !specDataCovered.includes(id)) {
                        specDataCovered.push(id)
                    } else if (HasChild && specDataCovered.includes(id)) {
                        return;
                    }

                    return specData.length && <div key={specification._id + label} className='compare-body'>
                        <div className={(title ==="Fitting Information" && HasChild === true)  ? 'bodytr multipal-options':'bodytr'}>
                            <div className='partleft'>
                                <div className='compareInner'>
                                    <h2>{label}</h2>
                                </div>
                                {HasChild &&
                                    <div className='compareInner'>
                                        {specData?.map((specificationHead, index) =>
                                            <div key={specificationHead.name + specificationHead._id} className="compareTd"><h2>{specificationHead.name}</h2></div>
                                        )}
                                    </div>
                                }

                            </div>


                            <div className='compareOuterpanel'>
                                {specData?.map((specificationHead, index) =>
                                    <div key={specificationHead.name + index + specificationHead.productOne} className="compareTd">{specificationHead.productOne ?? 'N/A'}</div>
                                )}
                            </div>
                            <div className='compareOuterpanel'>
                                {specData?.map((specificationHead, index) =>
                                    <div key={specificationHead.name + index + specificationHead.productTwo} className="compareTd">{specificationHead.productTwo ?? 'N/A'}</div>
                                )}
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default CompareSpecification;
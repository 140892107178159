import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineTwitter } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { RiWhatsappFill, RiShareForwardFill } from "react-icons/ri";
import { BsFillShareFill } from 'react-icons/bs';
import { FaFacebookF } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="copyText">
      <input type="text" value={copyText} readOnly />
      <button onClick={handleCopyClick}>
        <span>{isCopied ? "Copied!" : "Copy"}</span>
      </button>
    </div>
  );
}

function MyVerticallyCenteredModal(props) {
  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(window.location.href);
  });
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="example-modal-sizes-title-sm"
      centered
      className="shareblog"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Share News
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="share-socialMedia">
          <ul>
            <li>
              <a
                href={`https://api.whatsapp.com/send?text=${url}`}
                target="_blank"
                className="whatsapp"
              >
                <RiWhatsappFill />
              </a>
              <span>Whatsapp</span>
            </li>
            <li>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                target="_blank"
                className="facebook"
              >
                <FaFacebookF />
              </a>
              <span>FaceBook</span>
            </li>
            <li>
              <a href={`mailto:?subject=${url}`} target="_blank" className="gmail">
                <MdEmail />
              </a>
              <span>Email</span>
            </li>
            <li>
              <a
                href={`https://twitter.com/intent/tweet?url=${url}`}
                target="_blank"
                className="twitter"
              >
                <AiOutlineTwitter />
              </a>
              <span>Twitter</span>
            </li>
            <li>
              <a
                href={`https://www.linkedin.com/shareArticle?url=${url}`}
                target="_blank"
                className="Linkdin"
              >
                <ImLinkedin2 />
              </a>
              <span>Linkdin</span>
            </li>
          </ul>
        </div>
        <ClipboardCopy copyText={url} />
      </Modal.Body>
    </Modal>
  );
}

const SharePopup = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      <button onClick={() => setModalShow(true)} className="btn btn-primary">
        <BsFillShareFill />
      </button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default SharePopup;
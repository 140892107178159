const Country = [{
    "name": "Afghanistan",
    "cca2": "af",
    "code": 93
    }, {
    "name": "Albania",
    "cca2": "al",
    "code": 355
    }, {
    "name": "Algeria",
    "cca2": "dz",
    "code": 213
    }, {
    "name": "American Samoa",
    "cca2": "as",
    "code": 1684
    }, {
    "name": "Andorra",
    "cca2": "ad",
    "code": 376
    }, {
    "name": "Angola",
    "cca2": "ao",
    "code": 244
    }, {
    "name": "Anguilla",
    "cca2": "ai",
    "code": 1264
    }, {
    "name": "Antigua and Barbuda",
    "cca2": "ag",
    "code": 1268
    }, {
    "name": "Argentina",
    "cca2": "ar",
    "code": 54
    }, {
    "name": "Armenia",
    "cca2": "am",
    "code": 374
    }, {
    "name": "Aruba",
    "cca2": "aw",
    "code": 297
    }, {
    "name": "Australia",
    "cca2": "au",
    "code": 61
    }, {
    "name": "Austria",
    "cca2": "at",
    "code": 43
    }, {
    "name": "Azerbaijan",
    "cca2": "az",
    "code": 994
    }, {
    "name": "Bahamas",
    "cca2": "bs",
    "code": 1242
    }, {
    "name": "Bahrain",
    "cca2": "bh",
    "code": 973
    }, {
    "name": "Bangladesh",
    "cca2": "bd",
    "code": 880
    }, {
    "name": "Barbados",
    "cca2": "bb",
    "code": 1246
    }, {
    "name": "Belarus",
    "cca2": "by",
    "code": 375
    }, {
    "name": "Belgium",
    "cca2": "be",
    "code": 32
    }, {
    "name": "Belize",
    "cca2": "bz",
    "code": 501
    }, {
    "name": "Benin",
    "cca2": "bj",
    "code": 229
    }, {
    "name": "Bermuda",
    "cca2": "bm",
    "code": 1441
    }, {
    "name": "Bhutan",
    "cca2": "bt",
    "code": 975
    }, {
    "name": "Bolivia",
    "cca2": "bo",
    "code": 591
    }, {
    "name": "Bosnia and Herzegovina",
    "cca2": "ba",
    "code": 387
    }, {
    "name": "Botswana",
    "cca2": "bw",
    "code": 267
    }, {
    "name": "Brazil",
    "cca2": "br",
    "code": 55
    }, {
    "name": "Brunei Darussalam",
    "cca2": "bn",
    "code": 673
    }, {
    "name": "Bulgaria",
    "cca2": "bg",
    "code": 359
    }, {
    "name": "Burkina Faso",
    "cca2": "bf",
    "code": 226
    }, {
    "name": "Burundi",
    "cca2": "bi",
    "code": 257
    }, {
    "name": "Cambodia",
    "cca2": "kh",
    "code": 855
    }, {
    "name": "Cameroon",
    "cca2": "cm",
    "code": 237
    }, {
    "name": "Canada",
    "cca2": "ca",
    "code": 1
    }, {
    "name": "Cape Verde",
    "cca2": "cv",
    "code": 238
    }, {
    "name": "Cayman Islands",
    "cca2": "ky",
    "code": 1345
    }, {
    "name": "Central African Republic",
    "cca2": "cf",
    "code": 236
    }, {
    "name": "Chad",
    "cca2": "td",
    "code": 235
    }, {
    "name": "Chile",
    "cca2": "cl",
    "code": 56
    }, {
    "name": "China",
    "cca2": "cn",
    "code": 86
    }, {
    "name": "Colombia",
    "cca2": "co",
    "code": 57
    }, {
    "name": "Comoros",
    "cca2": "km",
    "code": 269
    }, {
    "name": "Congo (DRC)",
    "cca2": "cd",
    "code": 243
    }, {
    "name": "Congo (Republic)",
    "cca2": "cg",
    "code": 242
    }, {
    "name": "Cook Islands",
    "cca2": "ck",
    "code": 682
    }, {
    "name": "Costa Rica",
    "cca2": "cr",
    "code": 506
    }, {
    "name": "Ivory Coast",
    "cca2": "ci",
    "code": 225
    }, {
    "name": "Croatia",
    "cca2": "hr",
    "code": 385
    }, {
    "name": "Cuba",
    "cca2": "cu",
    "code": 53
    }, {
    "name": "Cyprus",
    "cca2": "cy",
    "code": 357
    }, {
    "name": "Czech Republic",
    "cca2": "cz",
    "code": 420
    }, {
    "name": "Denmark",
    "cca2": "dk",
    "code": 45
    }, {
    "name": "Djibouti",
    "cca2": "dj",
    "code": 253
    }, {
    "name": "Dominica",
    "cca2": "dm",
    "code": 1767
    }, {
    "name": "Dominican Republic",
    "cca2": "do",
    "code": 1809
    }, {
    "name": "Ecuador",
    "cca2": "ec",
    "code": 593
    }, {
    "name": "Egypt",
    "cca2": "eg",
    "code": 20
    }, {
    "name": "El Salvador",
    "cca2": "sv",
    "code": 503
    }, {
    "name": "Equatorial Guinea",
    "cca2": "gq",
    "code": 240
    }, {
    "name": "Eritrea",
    "cca2": "er",
    "code": 291
    }, {
    "name": "Estonia",
    "cca2": "ee",
    "code": 372
    }, {
    "name": "Ethiopia",
    "cca2": "et",
    "code": 251
    }, {
    "name": "Faroe Islands",
    "cca2": "fo",
    "code": 298
    }, {
    "name": "Fiji",
    "cca2": "fj",
    "code": 679
    }, {
    "name": "Finland",
    "cca2": "fi",
    "code": 358
    }, {
    "name": "France",
    "cca2": "fr",
    "code": 33
    }, {
    "name": "French Polynesia",
    "cca2": "pf",
    "code": 689
    }, {
    "name": "Gabon",
    "cca2": "ga",
    "code": 241
    }, {
    "name": "Gambia",
    "cca2": "gm",
    "code": 220
    }, {
    "name": "Georgia",
    "cca2": "ge",
    "code": 995
    }, {
    "name": "Germany",
    "cca2": "de",
    "code": 49
    }, {
    "name": "Ghana",
    "cca2": "gh",
    "code": 233
    }, {
    "name": "Gibraltar",
    "cca2": "gi",
    "code": 350
    }, {
    "name": "Greece",
    "cca2": "gr",
    "code": 30
    }, {
    "name": "Greenland",
    "cca2": "gl",
    "code": 299
    }, {
    "name": "Grenada",
    "cca2": "gd",
    "code": 1473
    }, {
    "name": "Guadeloupe",
    "cca2": "gp",
    "code": 590
    }, {
    "name": "Guam",
    "cca2": "gu",
    "code": 1671
    }, {
    "name": "Guatemala",
    "cca2": "gt",
    "code": 502
    }, {
    "name": "Guernsey",
    "cca2": "gg",
    "code": 44
    }, {
    "name": "Guinea",
    "cca2": "gn",
    "code": 224
    }, {
    "name": "Guinea-Bissau",
    "cca2": "gw",
    "code": 245
    }, {
    "name": "Guyana",
    "cca2": "gy",
    "code": 592
    }, {
    "name": "Haiti",
    "cca2": "ht",
    "code": 509
    }, {
    "name": "Honduras",
    "cca2": "hn",
    "code": 504
    }, {
    "name": "Hong Kong",
    "cca2": "hk",
    "code": 852
    }, {
    "name": "Hungary",
    "cca2": "hu",
    "code": 36
    }, {
    "name": "Iceland",
    "cca2": "is",
    "code": 354
    }, {
    "name": "India",
    "cca2": "in",
    "code": 91
    }, {
    "name": "Indonesia",
    "cca2": "id",
    "code": 62
    }, {
    "name": "Iran",
    "cca2": "ir",
    "code": 98
    }, {
    "name": "Iraq",
    "cca2": "iq",
    "code": 964
    }, {
    "name": "Ireland",
    "cca2": "ie",
    "code": 353
    }, {
    "name": "Isle of Man",
    "cca2": "im",
    "code": 44
    }, {
    "name": "Israel",
    "cca2": "il",
    "code": 972
    }, {
    "name": "Italy",
    "cca2": "it",
    "code": 39
    }, {
    "name": "Jamaica",
    "cca2": "jm",
    "code": 1876
    }, {
    "name": "Japan",
    "cca2": "jp",
    "code": 81
    }, {
    "name": "Jersey",
    "cca2": "je",
    "code": 44
    }, {
    "name": "Jordan",
    "cca2": "jo",
    "code": 962
    }, {
    "name": "Kazakhstan",
    "cca2": "kz",
    "code": 7
    }, {
    "name": "Kenya",
    "cca2": "ke",
    "code": 254
    }, {
    "name": "Kiribati",
    "cca2": "ki",
    "code": 686
    }, {
    "name": "Kuwait",
    "cca2": "kw",
    "code": 965
    }, {
    "name": "Kyrgyzstan",
    "cca2": "kg",
    "code": 996
    }, {
    "name": "Laos",
    "cca2": "la",
    "code": 856
    }, {
    "name": "Latvia",
    "cca2": "lv",
    "code": 371
    }, {
    "name": "Lebanon",
    "cca2": "lb",
    "code": 961
    }, {
    "name": "Lesotho",
    "cca2": "ls",
    "code": 266
    }, {
    "name": "Liberia",
    "cca2": "lr",
    "code": 231
    }, {
    "name": "Libya",
    "cca2": "ly",
    "code": 218
    }, {
    "name": "Liechtenstein",
    "cca2": "li",
    "code": 423
    }, {
    "name": "Lithuania",
    "cca2": "lt",
    "code": 370
    }, {
    "name": "Luxembourg",
    "cca2": "lu",
    "code": 352
    }, {
    "name": "Macao",
    "cca2": "mo",
    "code": 853
    }, {
    "name": "Macedonia",
    "cca2": "mk",
    "code": 389
    }, {
    "name": "Madagascar",
    "cca2": "mg",
    "code": 261
    }, {
    "name": "Malawi",
    "cca2": "mw",
    "code": 265
    }, {
    "name": "Malaysia",
    "cca2": "my",
    "code": 60
    }, {
    "name": "Maldives",
    "cca2": "mv",
    "code": 960
    }, {
    "name": "Mali",
    "cca2": "ml",
    "code": 223
    }, {
    "name": "Malta",
    "cca2": "mt",
    "code": 356
    }, {
    "name": "Marshall Islands",
    "cca2": "mh",
    "code": 692
    }, {
    "name": "Martinique",
    "cca2": "mq",
    "code": 596
    }, {
    "name": "Mauritania",
    "cca2": "mr",
    "code": 222
    }, {
    "name": "Mauritius",
    "cca2": "mu",
    "code": 230
    }, {
    "name": "Mexico",
    "cca2": "mx",
    "code": 52
    }, {
    "name": "Micronesia",
    "cca2": "fm",
    "code": 691
    }, {
    "name": "Moldova",
    "cca2": "md",
    "code": 373
    }, {
    "name": "Monaco",
    "cca2": "mc",
    "code": 377
    }, {
    "name": "Mongolia",
    "cca2": "mn",
    "code": 976
    }, {
    "name": "Montenegro",
    "cca2": "me",
    "code": 382
    }, {
    "name": "Montserrat",
    "cca2": "ms",
    "code": 1664
    }, {
    "name": "Morocco",
    "cca2": "ma",
    "code": 212
    }, {
    "name": "Mozambique",
    "cca2": "mz",
    "code": 258
    }, {
    "name": "Myanmar (Burma)",
    "cca2": "mm",
    "code": 95
    }, {
    "name": "Namibia",
    "cca2": "na",
    "code": 264
    }, {
    "name": "Nauru",
    "cca2": "nr",
    "code": 674
    }, {
    "name": "Nepal",
    "cca2": "np",
    "code": 977
    }, {
    "name": "Netherlands",
    "cca2": "nl",
    "code": 31
    }, {
    "name": "New Caledonia",
    "cca2": "nc",
    "code": 687
    }, {
    "name": "New Zealand",
    "cca2": "nz",
    "code": 64
    }, {
    "name": "Nicaragua",
    "cca2": "ni",
    "code": 505
    }, {
    "name": "Niger",
    "cca2": "ne",
    "code": 227
    }, {
    "name": "Nigeria",
    "cca2": "ng",
    "code": 234
    }, {
    "name": "North Korea",
    "cca2": "kp",
    "code": 850
    }, {
    "name": "Norway",
    "cca2": "no",
    "code": 47
    }, {
    "name": "Oman",
    "cca2": "om",
    "code": 968
    }, {
    "name": "Pakistan",
    "cca2": "pk",
    "code": 92
    }, {
    "name": "Palau",
    "cca2": "pw",
    "code": 680
    }, {
    "name": "Palestinian Territory",
    "cca2": "ps",
    "code": 970
    }, {
    "name": "Panama",
    "cca2": "pa",
    "code": 507
    }, {
    "name": "Papua New Guinea",
    "cca2": "pg",
    "code": 675
    }, {
    "name": "Paraguay",
    "cca2": "py",
    "code": 595
    }, {
    "name": "Peru",
    "cca2": "pe",
    "code": 51
    }, {
    "name": "Philippines",
    "cca2": "ph",
    "code": 63
    }, {
    "name": "Poland",
    "cca2": "pl",
    "code": 48
    }, {
    "name": "Portugal",
    "cca2": "pt",
    "code": 351
    }, {
    "name": "Puerto Rico",
    "cca2": "pr",
    "code": 1787
    }, {
    "name": "Qatar",
    "cca2": "qa",
    "code": 974
    }, {
    "name": "Reunion",
    "cca2": "re",
    "code": 262
    }, {
    "name": "Romania",
    "cca2": "ro",
    "code": 40
    }, {
    "name": "Russian Federation",
    "cca2": "ru",
    "code": 7
    }, {
    "name": "Rwanda",
    "cca2": "rw",
    "code": 250
    }, {
    "name": "Saint Kitts and Nevis",
    "cca2": "kn",
    "code": 1869
    }, {
    "name": "Saint Lucia",
    "cca2": "lc",
    "code": 1758
    }, {
    "name": "Saint Vincent and the Grenadines",
    "cca2": "vc",
    "code": 1784
    }, {
    "name": "Samoa",
    "cca2": "ws",
    "code": 685
    }, {
    "name": "San Marino",
    "cca2": "sm",
    "code": 378
    }, {
    "name": "Sao Tome and Principe",
    "cca2": "st",
    "code": 239
    }, {
    "name": "Saudi Arabia",
    "cca2": "sa",
    "code": 966
    }, {
    "name": "Senegal",
    "cca2": "sn",
    "code": 221
    }, {
    "name": "Serbia",
    "cca2": "rs",
    "code": 381
    }, {
    "name": "Seychelles",
    "cca2": "sc",
    "code": 248
    }, {
    "name": "Sierra Leone",
    "cca2": "sl",
    "code": 232
    }, {
    "name": "Singapore",
    "cca2": "sg",
    "code": 65
    }, {
    "name": "Slovakia",
    "cca2": "sk",
    "code": 421
    }, {
    "name": "Slovenia",
    "cca2": "si",
    "code": 386
    }, {
    "name": "Solomon Islands",
    "cca2": "sb",
    "code": 677
    }, {
    "name": "Somalia",
    "cca2": "so",
    "code": 252
    }, {
    "name": "South Africa",
    "cca2": "za",
    "code": 27
    }, {
    "name": "South Korea",
    "cca2": "kr",
    "code": 82
    }, {
    "name": "Spain",
    "cca2": "es",
    "code": 34
    }, {
    "name": "Sri Lanka",
    "cca2": "lk",
    "code": 94
    }, {
    "name": "Sudan",
    "cca2": "sd",
    "code": 249
    }, {
    "name": "Suriname",
    "cca2": "sr",
    "code": 597
    }, {
    "name": "Swaziland",
    "cca2": "sz",
    "code": 268
    }, {
    "name": "Sweden",
    "cca2": "se",
    "code": 46
    }, {
    "name": "Switzerland",
    "cca2": "ch",
    "code": 41
    }, {
    "name": "Syrian Arab Republic",
    "cca2": "sy",
    "code": 963
    }, {
    "name": "Taiwan, Province of China",
    "cca2": "tw",
    "code": 886
    }, {
    "name": "Tajikistan",
    "cca2": "tj",
    "code": 992
    }, {
    "name": "Tanzania",
    "cca2": "tz",
    "code": 255
    }, {
    "name": "Thailand",
    "cca2": "th",
    "code": 66
    }, {
    "name": "Timor-Leste",
    "cca2": "tl",
    "code": 670
    }, {
    "name": "Togo",
    "cca2": "tg",
    "code": 228
    }, {
    "name": "Tonga",
    "cca2": "to",
    "code": 676
    }, {
    "name": "Trinidad and Tobago",
    "cca2": "tt",
    "code": 1868
    }, {
    "name": "Tunisia",
    "cca2": "tn",
    "code": 216
    }, {
    "name": "Turkey",
    "cca2": "tr",
    "code": 90
    }, {
    "name": "Turkmenistan",
    "cca2": "tm",
    "code": 993
    }, {
    "name": "Turks and Caicos Islands",
    "cca2": "tc",
    "code": 1649
    }, {
    "name": "Tuvalu",
    "cca2": "tv",
    "code": 688
    }, {
    "name": "Uganda",
    "cca2": "ug",
    "code": 256
    }, {
    "name": "Ukraine",
    "cca2": "ua",
    "code": 380
    }, {
    "name": "United Arab Emirates",
    "cca2": "ae",
    "code": 971
    }, {
    "name": "United Kingdom",
    "cca2": "gb",
    "code": 44
    }, {
    "name": "United States",
    "cca2": "us",
    "code": 1
    }, {
    "name": "Uruguay",
    "cca2": "uy",
    "code": 598
    }, {
    "name": "Uzbekistan",
    "cca2": "uz",
    "code": 998
    }, {
    "name": "Vanuatu",
    "cca2": "vu",
    "code": 678
    }, {
    "name": "Vatican City",
    "cca2": "va",
    "code": 379
    }, {
    "name": "Venezuela",
    "cca2": "ve",
    "code": 58
    }, {
    "name": "Viet Nam",
    "cca2": "vn",
    "code": 84
    }, {
    "name": "Virgin Islands (British)",
    "cca2": "vg",
    "code": 1284
    }, {
    "name": "Virgin Islands (U.S.)",
    "cca2": "vi",
    "code": 1340
    }, {
    "name": "Western Sahara",
    "cca2": "eh",
    "code": 212
    }, {
    "name": "Yemen",
    "cca2": "ye",
    "code": 967
    }, {
    "name": "Zambia",
    "cca2": "zm",
    "code": 260
    }, {
    "name": "Zimbabwe",
    "cca2": "zw",
    "code": 263
    }];
  
    export function getCountryShort(code){
      let countryShort = "US";
      for(let item of Country){
        const ccode = "+"+item.code;
        if(code === ccode){
          countryShort = item.cca2.toUpperCase();
          break;
        }
      }
      return countryShort;
    }
    
    export default Country;
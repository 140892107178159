import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  createRef,
  useRef
} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useGetProductDetailQuery } from '../../../redux-services/apis/productDetailApi';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import SelectVariations from './SelectVariations';
import FittingAttributes from './FittingAttributes';
import { useDispatch, useSelector } from 'react-redux';
import { getExploreFitting, getFittingList } from '../../../redux-services/slices/siteDataSlice';
import {
  GlassMagnifier,
  SideBySideMagnifier
} from "@datobs/react-image-magnifiers";
import {
  useAddToCartMutation,
  useGetCartQuery,
  useNotifyMeMutation,
} from '../../../redux-services/apis/cartApi';
import ProductDetailTabs from './ProductDetailTabs';
import AddToCart from './AddToCart';
import { getUserDetails } from '../../../redux-services/slices/authSlice';
import { ProductType, USER_TYPE } from '../../../constants';
import { setFittingData } from '../../../redux-services/slices/productDataSlice';
import WishListMark from '../WishListMark';
import './product-detail.css';
import { AWS_STORAGE_URL, RECAPTCHA_SITE_KEY } from '../../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import MetaData from '../../../components/MetaData/MetaData';
import { getSelectedShippingRate } from '../../../redux-services/slices/cartSlice';
import { BsArrowBarLeft, BsArrowBarRight, BsArrowLeft, BsArrowRight, BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const AWS_PRODUCT_URL = AWS_STORAGE_URL + '/uploads/product/';

const ProductDetail = ({ type }) => {
  //################ State for customize shaft checkbox #####
  const [moreInfoChecked, setMoreInfoChecked] = useState(false);
  const [checkProductRes, setCheckProductRes] = useState(false);
  //################ Get params for slug #####
  const params = useParams();
  const recaptchaRef = createRef();
  const user = useSelector(getUserDetails);
  const exploreFitting = useSelector(getExploreFitting);
  const dispatch = useDispatch();

  //################ Set product images as per image Gallery need #####
  const [productImages, setProductImages] = useState([]);

  //################ State to manage product cart quentity (DEFAULT 1)#####
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  //################ State to store product instead of whole response #####
  const [product, setProduct] = useState(0);

  //################ Get product details by slug #####
  const { isSuccess, isError, data: productDetails } = useGetProductDetailQuery(
    params?.slug,
    { skip: !params?.slug }
  );

  const [openNotifyModal, setNotifyModal] = useState(false);
  const [openNotifiedModal, setNotifiedModal] = useState(false);
  const [notifiedMessage, setNotifiedMessage] = useState('');
  const [notifyEmail, setNotifyEmail] = useState(user?.email ?? '');
  const [validated, setValidated] = useState(false);
  const [stopCount, setStopCount] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  /* ################ Update Product var on get product from api server ##########
     ################ Update ImageGallery images array as productDetails updates ########## */
  const fittingAttributeList = useSelector(getFittingList);

  const { flexCode, ironNumber } = useSelector(state => state.siteData);

  const selectedFitting = useSelector(
    (state) => state.productData.productDetails[params?.slug]
  );

  const [message, setMessage] = useState('');
  const [checkAddToCart, setCheckAddToCart] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const selectedShippingRate = useSelector(getSelectedShippingRate);
  let { data: cartDetail } = useGetCartQuery(
    selectedShippingRate?.totalCharges
  );
  let IronData = ["Iron", "Iron #"];
  let IronNumbers = ['4', '5', '6', '7', '8', '9', 'P'];

  useEffect(() => {
    if (cartDetail) {
      let filterData = cartDetail?.response?.products?.find(item => item?.productVariationSku == product?.defaultVariation?.sku);

      const prodVariationMatch = filterData?.productVariationDetail.find(item => IronData.includes(item?.attribute_name) && IronNumbers.includes(item?.term_option));
      const checkVariationFor123 = filterData?.productVariationDetail.find(item => IronData.includes(item?.attribute_name) && item?.term_option === '1-2-3');
      const checkVariationForWedges = filterData?.productVariationDetail.find(item => IronData.includes(item?.attribute_name) && item?.term_option === "GW/SW/LW");

      if (user?.userType !== 2) {
        setCheckAddToCart(true);
      } else {
        if (prodVariationMatch == undefined) {
          setCheckAddToCart(true);
        } else {
          setCheckAddToCart(false);
        }
        if (checkVariationFor123 !== undefined && filterData?.quantity == 3) {
          setStopCount(true)
          setCheckAddToCart(false);
        }
        if (checkVariationForWedges !== undefined && filterData?.quantity == 4) {
          setStopCount(true)
          setCheckAddToCart(false);
        }
      }
    }
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let productData123 = product.defaultVariation?.variation.find(item => IronData.includes(item?.attribute_name) && item?.option_term === "1-2-3");
    let productDataWedges = product.defaultVariation?.variation.find(item => IronData.includes(item?.attribute_name) && item?.option_term === "GW/SW/LW");

    if (productData123?.option_term == "1-2-3" && selectedQuantity === 3) {
      setStopCount(true)
    }
    if (productData123?.option_term == "1-2-3" && selectedQuantity < 3) {
      setStopCount(false);
    }
    if (productDataWedges?.option_term == "GW/SW/LW" && selectedQuantity === 4) {
      setStopCount(true)
    }
    if (productDataWedges?.option_term == "GW/SW/LW" && selectedQuantity < 4) {
      setStopCount(false);
    }
  });



  const [addToCart, addToCartResult] = useAddToCartMutation();
  const [notifyMe, notifyMeResult] = useNotifyMeMutation();
  const initRates = {
    product: 0,
    adaptor: 0,
    grip_option: 0,
  };
  const [productRates, setProductRates] = useState(initRates);
  let previousSelectedQuantity = '';
  const [resetSelectedQuantity, setResetSelectedQuantity] = useState(
    previousSelectedQuantity
  );

  //################ Handle add to cart notifications #########
  const [cartUpdate, setCartUpdate] = useState(null);


  useEffect(() => {
    if (isError) {
      let url = location.pathname.split('/');
      if (url?.length > 1 && url[1] === 'gear') {
        navigate('/gear/category/all');
      }
      if (url?.length > 1 && url[1] === 'shafts') {
        navigate('/shafts/category/all');
      }
    }
  }, [isError]);

  useEffect(() => {
    if (checkProductRes === true && exploreFitting !== null && Object.values(exploreFitting)?.length > 0) {
      setMoreInfoChecked(!moreInfoChecked);
    } else {
      setMoreInfoChecked(false);
    }
  }, [exploreFitting, checkProductRes])
  // ################ EVENTS #############

  const updateQuantity = useCallback(
    (qty) => {
      if (moreInfoChecked) {
        setSelectedQuantity(1);
      }
      let newQty = 0;
      if (qty === -1) {
        newQty = selectedQuantity > 1 ? Number(selectedQuantity) - 1 : 1;
      }

      if (qty === 1) {
        newQty =
          selectedQuantity < product.defaultVariation?.stock_quantity
            ? Number(selectedQuantity) + 1
            : product.defaultVariation?.stock_quantity;
      }
      setSelectedQuantity(newQty);
    },
    [product, selectedQuantity, moreInfoChecked]
  );

  // const onInputQuantityChange = useCallback(
  //   (e) => {
  //     const quantity = parseInt(e.target.value);
  //     if (moreInfoChecked) {
  //       setSelectedQuantity(1);
  //       return;
  //     }
  //     if (product.defaultVariation?.stock_quantity >= quantity) {
  //       setSelectedQuantity(quantity > 0 ? quantity : 1);
  //     } else {
  //       setSelectedQuantity(product.defaultVariation?.stock_quantity);
  //     }
  //   },
  //   [product]
  // );

  // Reset selected quantity when variations are changed
  useEffect(() => {
    if (resetSelectedQuantity !== previousSelectedQuantity) {
      setSelectedQuantity(1);
      previousSelectedQuantity = resetSelectedQuantity;
    }
  }, [resetSelectedQuantity]);

  const getProductRate = useCallback(() => {
    let productRatesData =
      productRates['adaptor'] + productRates['grip_option'];
    if (!moreInfoChecked) {
      productRatesData = 0;
      return productRatesData;
    }
    if (moreInfoChecked && selectedFitting?.adaptor !== undefined) {
      return productRatesData;
    }
    if (moreInfoChecked && selectedFitting?.adaptor === undefined) {
      productRatesData = 0;
      return productRatesData;
    }
    // return productRates['adaptor'] + productRates['grip_option'];
  }, [productRates, moreInfoChecked]);

  const onClickAddProductToCart = useCallback(
    (e) => {
      if (product.defaultVariation?.stock_quantity < selectedQuantity) {
        return false;
      }
      const sVariations = product.defaultVariation.variation.reduce(
        (obj, item) => ({ ...obj, [item.attribute_id]: item.option_id }),
        {}
      );

      let newProduct = {
        product_id: product._id,
        product_slug: params?.slug,
        product_title: product?.product_title,
        product_variation: sVariations,
        quantity: selectedQuantity,
        fitting: moreInfoChecked ? selectedFitting : {},
        product_variation_sku: product.defaultVariation?.sku,
        product_variation_id: product.defaultVariation?._id,
        acknowledgementChecked:
          selectedFitting?.acknowledgementChecked === true,
        userId: user?._id,
      };

      if (checkAddToCart && selectedQuantity > 0) {
        if (moreInfoChecked) {
          if (
            selectedFitting.adaptor != '' &&
            selectedFitting.adaptor_option != '' &&
            selectedFitting.grip != '' &&
            selectedFitting.grip_option != '' &&
            selectedFitting.extra_wrap != '' &&
            selectedFitting.grip_logo != '' &&
            selectedFitting.playing != '' &&
            selectedFitting.acknowledgementChecked == true
          ) {
            setMessage('');
            addToCart({ productDetails: newProduct });
          } else if (
            newProduct.fitting.adaptor &&
            newProduct.fitting.adaptor_option == undefined
          ) {
            setMessage({ adaptor: 'please provide adaptor settings.' });
          } else if (
            newProduct.fitting.grip &&
            newProduct.fitting.grip_option == undefined
          ) {
            setMessage({ grip: 'please provide grip settings.' });
          } else if (selectedFitting.acknowledgementChecked == false) {
            setMessage({ checked: 'Please select the checkedBox' });
          } else {
            setMessage('Please select all fields');
          }
        } else {
          setMessage('');
          addToCart({ productDetails: newProduct });
        }
        setCheckAddToCart(false);
      }
      setSelectedQuantity(1);
    },
    [product, selectedQuantity, params, moreInfoChecked, selectedFitting, checkAddToCart, message]
  );

  const onReCAPTCHAChange = async (captchaCode) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      return false;
    } else {
      onClickNotifyMe(captchaCode);
    }
  };

  const generateCaptach = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    if (form.checkValidity()) {
      const tokenData = recaptchaRef.current.execute();
    }
  };

  const isEmail = (email) => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.match(regex)) return true;
    else return false;
  };
  const [showEmailError, setShowEmailError] = useState(false);

  const onClickNotifyMe = (token) => {
    setShowEmailError(false);
    const validEmail = isEmail(notifyEmail);
    if (!validEmail) {
      setShowEmailError(true);
      return;
    }
    notifyMe({
      sku: product?.defaultVariation?.sku,
      category: product.categoryId.name,
      product: product.product_title,
      email: notifyEmail,
      name: `${user?.first_name} ${user?.last_name}`,
    });
    setNotifyEmail(user?.email);
    setNotifyModal(false);
    recaptchaRef.current.reset();
  };

  const onShowNotifyModal = () => {
    setValidated(false);
    setNotifyModal(true);
  };
  const onCloseNotifyModal = () => {
    setNotifyModal(false);
    setNotifyEmail(user?.email);
    setShowEmailError(false);
  };

  const onCloseNotifiedModal = () => {
    setNotifiedModal(false);
  };

  const onChangeNotifyEmail = (e) =>
    setNotifyEmail(e.target.value.toLowerCase());
  const handleMoreInfoCheck = useCallback(
    (e) => {
      if (product.defaultVariation) {
        const cust = !moreInfoChecked;
        setMoreInfoChecked(cust);
        if (cust) {
          setSelectedQuantity(1);
        } else {
          dispatch(setFittingData({ id: params.slug, fittingData: {} }));
        }
      }
    },
    [product, moreInfoChecked, params]
  );

  //########### USE EFFECTS ##########

  useEffect(() => {
    const { isSuccess, isError } = addToCartResult;
    if (isSuccess || isError) {
      setCartUpdate(
        isSuccess ? addToCartResult.data : addToCartResult.error.data
      );
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [addToCartResult]);

  useEffect(() => {
    const { isSuccess, isError } = notifyMeResult;
    if (isSuccess) {
      setNotifiedMessage(notifyMeResult.data.message);
      setNotifiedModal(true);
    }
  }, [notifyMeResult]);

  useEffect(() => {
    if (productDetails?.status) {
      setCartUpdate(null);
      setProductRates(initRates);
      setCheckProductRes(!checkProductRes);

      let variationData;
      if (exploreFitting === null) {
        variationData = {
          ...(productDetails.response.variations?.find(
            (variation) => variation.isDefault
          ) ?? {}),
        };
      } else {
        variationData = {
          ...(productDetails.response.variations?.find(
            (variation) => variation?.sku == exploreFitting?.sku
          ) ?? {}),
        };
        if (Object.values(variationData).length === 0) {
          variationData = {
            ...(productDetails.response.variations?.find(
              (variation) => variation.isDefault
            ) ?? {}),
          };
        }
      }
      if (variationData.sale_price == 0) {
        delete variationData['sale_price'];
      }

      setProduct({
        ...productDetails.response,
        defaultVariation: variationData,
      });
      let varPrice = variationData.sale_price ?? variationData?.msrp_price ?? 0;
      if (
        user?.userType === USER_TYPE.DEALER &&
        variationData?.discounted_price
      ) {
        varPrice = variationData?.discounted_price;
      }

      setProductRates((rates) => ({ ...rates, product: varPrice }));
    }
    // if (productDetails?.response?.images?.length) {
    //   const images = [];
    //   const playImage = '/images/play.svg';
    //   productDetails.response?.images?.map(({ image }) => {
    //     images.push({
    //       original: AWS_PRODUCT_URL + image,
    //       thumbnail: AWS_PRODUCT_URL + image,
    //     });
    //   });
    //   if (images.length == 0) {
    //     images.push({
    //       original: '/images/productdummy.png',
    //       thumbnail: '/images/productdummy.png',
    //     });
    //   }

    //   if (productDetails.response?.videoLink) {
    //     const thumbnailCode = productDetails.response?.videoLink.substring(
    //       productDetails.response?.videoLink.lastIndexOf('/') + 1
    //     );
    //     const regex = /youtube.com/g;
    //     const found = productDetails.response?.videoLink.match(regex);
    //     images.push({
    //       thumbnail: found
    //         ? `https://i.ytimg.com/vi/${thumbnailCode}/hqdefault.jpg`
    //         : playImage,
    //       original: playImage,
    //       embedUrl: productDetails.response?.videoLink,
    //       thumbnailClass: 'video-slide',
    //       renderItem: renderVideo,
    //     });
    //   }
    //   setProductImages(images);
    // } else {
    //   setProductImages(
    //     [{
    //       original: '/images/productdummy.png',
    //       thumbnail: '/images/productdummy.png',
    //     }]
    //   )
    // }
    setSelectedQuantity(1);
    setStopCount(false);
  }, [productDetails]);

  // const CustomCarousel = ({ items }) => {
  //   const [currentIndex, setCurrentIndex] = useState(0);

  //   const goToNextSlide = () => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === items.length - 1 ? 0 : prevIndex + 1
  //     );
  //   };

  //   const goToPrevSlide = () => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === 0 ? items.length - 1 : prevIndex - 1
  //     );
  //   };

  //   return (
  //     <div className="custom_carousel">
  //       <div className="slide">
  //         <button className="arrow-btn" onClick={goToPrevSlide} style={{ display: "inline" }}>
  //           <BsChevronLeft style={{ fontSize: "30px" }} />
  //         </button>
  //         {items[currentIndex].type === 'image' ? (
  //           // <img src={items[currentIndex].original} alt={"product image"} />
  //           <SideBySideMagnifier
  //             imageSrc={items[currentIndex].original}
  //             imageAlt="product image"
  //           // magnifierSize="40%"
  //           // magnifierBorderSize="1"
  //           // magnifierBackgroundColor="rgba(225,225,225,.2)"
  //           // alwaysInPlace="false"
  //           // className='custom_carousel_image'
  //           />
  //         ) : items[currentIndex].type === 'video' ? (
  //           <iframe
  //             width="450"
  //             height="400"
  //             src={`${items[currentIndex].embedUrl}?autoplay=1&showinfo=0`}
  //             frameBorder="0"
  //             allowFullScreen
  //             className='custom-carousel-video'
  //           ></iframe>
  //         ) : null}
  //         <button className="arrow-btn" onClick={goToNextSlide} style={{ display: "inline" }}>
  //           <BsChevronRight style={{ fontSize: "30px" }} />
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  const CustomCarousel = ({ items }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
      setCurrentIndex(index);
    };

    const goToPrevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? items.length - 1 : prevIndex - 1));
    };

    const goToNextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex === items.length - 1 ? 0 : prevIndex + 1));
    };

    return (
      <div className="custom_carousel">
        <div className="slide">
          {items.length > 1 ?
            <button className="arrow-btn prev" onClick={goToPrevSlide}
            >
              <BsChevronLeft style={{
                fontSize: "20px",
                color: "#6d2a5f",
                transition: "color 0.3s ease",
              }} />
            </button> : null}

          {items[currentIndex].type === 'image' ? (
            <div className="image-wrapper">
              <SideBySideMagnifier
                imageSrc={items[currentIndex].original}
                imageAlt="product image"
                // fillAlignTop
                fillGapTop={20}
                fillGapRight={20}
                fillGapBottom={20}
                // magnifierSize="40%"
                // magnifierBorderSize="1"
                // magnifierBackgroundColor="rgba(225,225,225,.2)"
                // alwaysInPlace="false"
                className='custom_carousel_image'

              />
            </div>
          ) : items[currentIndex].type === 'video' ? (
            <div className='video-wrapper'>
              {/* <iframe
                width="450"
                height="300"
                src={`${items[currentIndex].embedUrl}?autoplay=1&showinfo=0`}
                frameBorder="0"
                allowFullScreen
                className='custom-carousel-video'
              ></iframe> */}
              <video controls width="450" height="300"
                className='custom-carousel-video'>
                <source src={`${items[currentIndex]?.embedUrl}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : null}

          {items.length > 1 ?
            <button className="arrow-btn next" onClick={goToNextSlide}>
              <BsChevronRight style={{
                fontSize: "20px",
                color: "#6d2a5f",
                transition: "color 0.3s ease",
              }} />
            </button> : null}

          {items?.length > 1 ?
            <div className="preview-images">
              {items.map((item, index) => (
                <img
                  key={index}
                  src={item.thumbnail}
                  alt={`Preview ${index + 1}`}
                  className={`preview-image ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => goToSlide(index)}
                />
              ))}
            </div> : null}
        </div>
      </div>
    );
  };


  useEffect(() => {
    if (product?.defaultVariation?.images?.length) {
      let images = [];
      const playImage = '/images/play.svg';

      if (productDetails.response?.videoLink) {
        const thumbnailCode = productDetails.response?.videoLink.substring(
          productDetails.response?.videoLink.lastIndexOf('/') + 1
        );
        const regex = /youtube.com/g;
        const found = productDetails.response?.videoLink.match(regex);
        images.push({
          thumbnail: playImage,
          original: playImage,
          embedUrl: productDetails.response?.videoLink,
          thumbnailClass: 'video-slide',
          renderItem: function (onClick, item) {
            return <div className='image-gallery-image'>
              <video controls width="450" height="300"
                className='custom-carousel-video'>
                <source src={productDetails.response?.videoLink} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          },
          type: "video"
        });
      }

      product?.defaultVariation?.images?.map((image) => {
        images.push({
          original: AWS_PRODUCT_URL + image,
          thumbnail: AWS_PRODUCT_URL + image,
          type: "image"
        });
      });
      if (images.length == 0) {
        images.push({
          original: '/images/productdummy.png',
          thumbnail: '/images/productdummy.png',
          type: "image"
        });
      }


      const videoIndex = images.findIndex(item => item.type === 'video');

      if (videoIndex !== -1 && videoIndex !== 0) {
        const videoItem = images.splice(videoIndex, 1)[0];
        images.unshift(videoItem);
      }
      setProductImages(images);
    } else if (product?.defaultVariation?.images?.length === 0 && productDetails.response?.videoLink) {
      let images = [];
      const playImage = '/images/play.svg';
      images.push({
        thumbnail: playImage,
        original: playImage,
        embedUrl: productDetails.response?.videoLink,
        thumbnailClass: 'video-slide',
        renderItem: function (onClick, item) {
          return <div className='image-gallery-image'>
            <video controls width="450" height="300"
              className='custom-carousel-video'>
              <source src={productDetails.response?.videoLink} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        },
        type: "video"
      });
      setProductImages(images);
    } else {
      setProductImages(
        [{
          original: '/images/productdummy.png',
          thumbnail: '/images/productdummy.png',
          type: "image"
        }]
      )
    }
  }, [product])

  const renderVideo = useCallback((item) => {
    return (
      <div>
        {
          <div className="video-wrapper">
            <iframe
              width="500"
              height="400"
              src={productDetails?.response?.videoLink}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        }
      </div>
    );
  }, []);

  useEffect(() => {
    if (!product.defaultVariation) {
      setProductRates(initRates);
      setMoreInfoChecked(false);
    }
    // setMoreInfoChecked(false);
    setMessage('');
  }, [product]);

  //########### Additional Component ###########
  const renderWishMark = useMemo(
    () => <WishListMark liked={true} productId={product?._id} />,
    [product]
  );

  return (
    <>
      {product && product.status != 2 && navigate('/')}
      {product.status == 2 &&
        (!isSuccess || !product ? null : (
          <>
            <section className="productDetails">
              {product?.product_seo?.length > 0 ? (
                <MetaData
                  metaData={
                    product?.product_seo?.length > 0 && product?.product_seo[0]
                  }
                />
              ) : (
                <MetaData
                  metaData={{ meta_title: 'Kinetixx', meta_description: '' }}
                />
              )}
              <Container>
                <Row>
                  {cartUpdate ? (
                    <Col md="12">
                      <div className="productTopBar">
                        <p>
                          {cartUpdate?.status
                            ? '"' +
                            product.product_title +
                            '" has been added to your cart.'
                            : cartUpdate.message}
                        </p>
                        {cartUpdate?.status ? (
                          <Link to="/cart" className="btn btn btn-primary">
                            View Cart
                          </Link>
                        ) : null}
                      </div>
                    </Col>
                  ) : null}
                  {
                    message !== '' ?
                      <Col md="12">
                        <div className="productTopBar">
                          <p>
                            Please fill in the below required fields.
                          </p>
                        </div>
                      </Col> : null
                  }
                  <Col md="6">
                    {/* <ImageGallery items={productImages} /> */}
                    {isMobile ? <ImageGallery items={productImages} /> : <CustomCarousel items={productImages} />}
                  </Col>
                  <Col md="6">
                    <div className="productdetails-right">
                      <h1>
                        <Link to="#" style={{ textTransform: 'capitalize' }}>
                          {' '}
                          {product.product_title}-{selectedQuantity > 1 ? product.categoryId?.name : product.categoryId?.slug}
                        </Link>
                      </h1>
                      <h4>{product.sub_title}</h4>
                      <p>{product.sub_heading}</p>
                      <div
                        className={
                          product.defaultVariation?.stock_quantity
                            ? 'paymentoption'
                            : ''
                        }
                      >
                        {product.defaultVariation?.stock_quantity > 0 &&
                          !product.defaultVariation?.is_external ? (
                          <>
                            {Number(
                              user?.userType === USER_TYPE.DEALER
                                ? product?.defaultVariation?.discounted_price
                                : product?.defaultVariation?.sale_price ??
                                product?.defaultVariation?.msrp_price
                            ) <
                              Number(product?.defaultVariation?.msrp_price) ? (
                              <p
                                style={{
                                  fontSize: '25px',
                                  textDecoration: 'line-through',
                                  marginRight: '10px',
                                  marginBottom: '0px',
                                }}
                              >
                                $
                                {(
                                  product?.defaultVariation?.msrp_price *
                                  selectedQuantity +
                                  getProductRate()
                                ).toFixed(2)}
                              </p>
                            ) : null}
                            <h5>
                              $
                              {(
                                (user?.userType === USER_TYPE.DEALER
                                  ? product?.defaultVariation?.discounted_price
                                  : product?.defaultVariation?.sale_price ??
                                  product?.defaultVariation?.msrp_price) *
                                selectedQuantity +
                                getProductRate()
                              ).toFixed(2)}
                            </h5>
                          </>
                        ) : null}

                        <AddToCart
                          onClickAddProductToCart={onClickAddProductToCart}
                          selectedQuantity={selectedQuantity}
                          updateQuantity={updateQuantity}
                          moreInfoChecked={moreInfoChecked}
                          product={product}
                          // onInputQuantityChange={onInputQuantityChange}
                          setSelectedQuantity={setSelectedQuantity}
                          onShowNotifyModal={onShowNotifyModal}
                          stopCount={stopCount}
                          user={user}
                        />
                      </div>
                      <div className="alltypes">
                        {product.categoryId ? (
                          <p>
                            <span>Category:</span>
                            {product.categoryId.name}
                          </p>
                        ) : null}
                        {product.defaultVariation ? (
                          <p>
                            <span>Model No:</span>
                            {product.defaultVariation.sku}
                          </p>
                        ) : null}
                        {product.meterial ? (
                          <p>
                            <span>Materials:</span>
                            {product.meterial}
                          </p>
                        ) : null}
                        {product.construction_technology ? (
                          <p>
                            <span>Construction:</span>
                            {product.construction_technology}
                          </p>
                        ) : null}
                      </div>
                      <div className="favrateicon">
                        {renderWishMark}
                        <hr />
                      </div>

                      <Row>
                        <SelectVariations
                          product={product}
                          setProduct={setProduct}
                          setProductImages={setProductImages}
                          setProductRates={setProductRates}
                          renderVideo={renderVideo}
                          setResetSelectedQuantity={setResetSelectedQuantity}
                          setStopCount={setStopCount}
                          flexCodeList={flexCode ?? []}
                          ironNumberList={ironNumber ?? []}
                        />

                        {product.sort_description ? (
                          <Col md="12" className="alltypes">
                            <label>Short Description:</label>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: product.sort_description,
                              }}
                            />
                          </Col>
                        ) : null}

                        {type !== ProductType &&
                          product.categoryId?.customization &&
                          product.defaultVariation?.stock_quantity ? (
                          <Col md="12">
                            <div className="checkouter">
                              <span className="custom_check">
                                Customize Shaft{' '}
                                <input
                                  type="checkbox"
                                  checked={moreInfoChecked}
                                  onChange={handleMoreInfoCheck}
                                />
                                <span className="check_indicator">&nbsp;</span>
                              </span>
                            </div>
                          </Col>
                        ) : (
                          ''
                        )}
                      </Row>
                    </div>
                  </Col>
                  {product.categoryId?.customization &&
                    moreInfoChecked &&
                    product.defaultVariation?.stock_quantity && (
                      <Col md="12" className="more-info">
                        <div className="productdetails-right">
                          <FittingAttributes
                            fittingAttributeList={fittingAttributeList}
                            product={product}
                            setProductRates={setProductRates}
                            message={message}
                          />
                          <div className="Quantitybox">
                            <h5>Quantity</h5>
                            <div>
                              <AddToCart
                                onClickAddProductToCart={
                                  onClickAddProductToCart
                                }
                                selectedQuantity={selectedQuantity}
                                updateQuantity={updateQuantity}
                                moreInfoChecked={moreInfoChecked}
                                product={product}
                                // onInputQuantityChange={onInputQuantityChange}
                                setSelectedQuantity={setSelectedQuantity}
                                second={true}
                                onShowNotifyModal={onShowNotifyModal}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    )}

                  <Col md="12"></Col>
                </Row>
              </Container>
            </section>
            <ProductDetailTabs product={product} type={type} />
          </>
        ))}
      <Modal show={openNotifyModal} onHide={onCloseNotifyModal}>
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title >Notify Email</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
          <Form noValidate validated={validated} onSubmit={generateCaptach}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={onReCAPTCHAChange}
            />
            <Form.Group className="mb-3 form-group" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                value={notifyEmail}
                onChange={onChangeNotifyEmail}
                autoFocus
              />
              {showEmailError ? (
                <p style={{ color: 'red', fontSize: '12px' }}>
                  Enter Valid Email Address.
                </p>
              ) : (
                ''
              )}
            </Form.Group>
            <Modal.Footer className="notify-modal-footer">
              <Button
                variant="primary"
                type="submit"
              >
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>

      </Modal>
      <Modal show={openNotifiedModal} onHide={onCloseNotifiedModal}>
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title>Product Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2">{notifiedMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="button"
            onClick={onCloseNotifiedModal}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductDetail;

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getRawSpecs } from "../../../redux-services/slices/siteDataSlice";
import { Table } from "react-bootstrap";

const Specs = ({ product, spec_key, raw_spec_key }) => {

  const [specifications, setSpecifications] = useState([]);
  const rawSpec = useSelector(getRawSpecs);
  const [isPhoneSize, setIsPhoneSize] = useState(false);

  useEffect(() => {
    if (product.defaultVariation && rawSpec[raw_spec_key]) {
      setSpecifications([]);

      let specData = product.defaultVariation[spec_key];
      let rawFiltered = rawSpec[raw_spec_key];


      specData = specData?.reduce((obj, element) => {
        const label = element.spec_label && element.spec_label != '' ? element.spec_label : 'default';
        obj[element.spec_id] = [...label in obj ? obj[label] : [], element] || [];
        return obj;
      }, {});

      const newSpecData = rawFiltered.map(data => {
        let specitem = { ...data.spec_data[0] };

        const newData = [];
        specitem.data.map(spec => {

          if (specData?.hasOwnProperty(spec?._id) && specData[spec?._id]) {
            newData.push({ ...spec, value: specData[spec._id][0].option_term });
          }
        });
        specitem.data = newData
        return specitem;
      })

      setSpecifications(newSpecData);
    }
  }, [product.defaultVariation, rawSpec]);

  useEffect(() => {
    const handleResize = () => {
      setIsPhoneSize(window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderSpec = useMemo(() => {
    const specCovered = [];
    return <>
      {!isPhoneSize ?
        <Table bordered className="w-100">
          <thead>
            <tr>
              {specifications?.map(specification => {
                if (specCovered.includes(specification._id)) {
                  return;
                }
                specCovered.push(specification._id);
                const HasChild = specification.label && specification.label != "";
                return specification?.data.length > 0 &&
                  <>
                    {HasChild && <><th colSpan={specification?.data.length > 1 && specification?.data.length}>{specification.label}</th>
                    </>}
                    {!HasChild &&
                      specification?.data.map((specificationHead, index) =>
                        <th rowSpan={specification?.data.length === 1 && '2'}>{specificationHead.name}</th>
                      )}
                  </>
              }
              )}
            </tr>
            <tr>
              {specifications?.map(specification => {
                return specification?.data.length > 0 &&
                  <>
                    {specification?.label !== '' &&
                      specification?.data.map((specificationHead, index) =>
                        <th>{specificationHead.name}</th>
                      )}
                  </>
              }
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              {specifications?.map(specification => {
                return specification?.data.length > 0 &&
                  specification?.data?.map((specificationHead, index) =>
                    <td>{specificationHead.value}</td>
                  )
              }
              )}
            </tr>
          </tbody>
        </Table>
        :
        <Table bordered className="w-100">
          <tbody>
            {specifications?.map(specification => {
              const HasChild = specification.label && specification.label != "";
              return specification?.data.length > 0 && <>
                {HasChild &&
                  <tr>
                    <th rowSpan={specification?.data.length > 0 && specification?.data.length + 1}>{specification.label}</th>
                  </tr>
                }
                {HasChild &&
                  specification?.data.map((specificationHead, index) =>
                    <tr>
                      <th>{specificationHead.name}</th>
                      <td>{specificationHead.value}</td>
                    </tr>
                  )}
                {!HasChild &&
                  specification?.data.map((specificationHead, index) =>
                    <tr>
                      <th colSpan={specification?.data.length === 1 && '2'}>{specificationHead.name}</th>
                      <td>{specificationHead.value}</td>
                    </tr>
                  )}
              </>
            })}
          </tbody>
        </Table>
      }
    </>
  }, [specifications, isPhoneSize]);

  // const renderSpec = useMemo(() => {
  //   const specCovered = [];
  //   return <>
  //   {specifications?.map(specification => {
  //     if (specCovered.includes(specification._id)) {
  //       return;
  //     }
  //     specCovered.push(specification._id);
  //     const HasChild = specification.label && specification.label != "";
  //     return specification?.data.length > 0 &&
  //       <div key={specification._id} className="td">
  //         <div className="spec-head">
  //           {HasChild && <><h2 key={'head_' + specification._id}>{specification.label}</h2><div className="wrap">
  //             {specification?.data.map((specificationHead, index) =>
  //               <div key={specificationHead.name + index} className="td"><h2>{specificationHead.name}</h2></div>
  //             )}
  //           </div></>}
  //           {!HasChild &&
  //             specification?.data.map((specificationHead, index) =>
  //               <h2 key={specificationHead.name + index}>{specificationHead.name}</h2>
  //             )}
  //         </div>
  //         <div className="wrap">
  //           {specification?.data?.map((specificationHead, index) =>
  //             <div
  //               key={specificationHead.value + index}
  //               className="td">{specificationHead.value}</div>
  //           )}
  //         </div>
  //       </div>
  //   }
  //   )}
  //   </>
  // }, [specifications])

  return <div key={spec_key} className='detailTBOuter'>
    <div>
      {renderSpec}
    </div>
  </div>
}

export default Specs;
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsCurrencyDollar } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { getProductsList, getWishList } from '../../redux-services/slices/siteDataSlice';
import { useAddRemoveWishItemMutation } from '../../redux-services/apis/productDetailApi';
import { getUserDetails } from '../../redux-services/slices/authSlice';

function Wishlist() {

    const [productList, setProductList] = useState([]);
    const products = useSelector(getProductsList);
    const wishList = useSelector(getWishList);
    const [addRemoveItem, _response] = useAddRemoveWishItemMutation();

    const authUser = useSelector(getUserDetails);

    const removeItem = useCallback((e) => {
        const productId = e.currentTarget.getAttribute("data-id");
        addRemoveItem({ productId, addRemove: false });
    }, [productList])

    useEffect(() => {
        if (wishList.length > 0 || _response.isSuccess) {
            const filteredWishList = products.filter(prod => wishList.includes(prod._id));
            setProductList(filteredWishList);
        }
    }, [products, wishList, _response.isSuccess]);

    return (
        <>

            <section className='profileDatatop'>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="accountInnner pt-0 ps-0 pe-0">
                                <h2>Wish List</h2>
                                <div className="backpage">
                                    <p>{authUser?.first_name} {authUser?.last_name}</p>
                                    <div><Link to="/account">Back to Your Account</Link></div>
                                </div>
                                <div style={{ cursor: "pointer" }}>
                                    <Link className="btn btn-primary" to="/shafts/category/all">
                                        Continue Shopping
                                    </Link>
                                </div>
                                <hr />
                            </div>
                        </Col>
                        {productList?.length === 0 &&
                            <Col>
                                <span className="text-center d-block fw-bold text-bold mt-3">
                                    No Product In Wish List.
                                </span>
                            </Col>
                        }
                        <Col md="12" className="wishlistmain">
                            {productList.map(product => <div key={product.slug} className="cartproductdetails">
                                <div className="cartdetailsleft">
                                    <div className="cartproductdetailstab">
                                        <div className="cartproductimg">
                                            <Link to={`/shafts/${product.slug}`}>
                                                <img
                                                    src={product.thumbnail ?? '/images/productdummy.png'}
                                                    alt={product.product_title}
                                                    className='img-fluid'
                                                    onError={() => this.src = '/images/productdummy.png'}
                                                />
                                            </Link>
                                        </div>
                                        <div className="cartproductcontentdetails">
                                            <Link to={`/shafts/${product.slug}`}><h6>{product.product_title} - {product.categoryName}</h6></Link>
                                            <h5>{product.sub_title}</h5>
                                            <p>{product.sub_heading}</p>
                                            <div className="productrate">
                                                From <span> <BsCurrencyDollar /> {product.discounted_price?.$numberDecimal
                                                    ? product.discounted_price?.$numberDecimal
                                                    : ((Math.min(
                                                        ...product?.sale_price?.filter(
                                                            (number) => number > 0
                                                        )
                                                    ) !== Infinity || 0) && Math.min(
                                                        ...product?.sale_price?.filter(
                                                            (number) => number > 0
                                                        )
                                                    ) < Math.min(
                                                        ...product?.msrp_price?.filter(
                                                            (number) => number > 0
                                                        )
                                                    ))
                                                        ? Math.min(
                                                            ...product?.sale_price?.filter(
                                                                (number) => number > 0
                                                            )
                                                        )
                                                        : Math.min(
                                                            ...product?.msrp_price?.filter(
                                                                (number) => number > 0
                                                            )
                                                        )}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cartdetailsright">
                                    <Link to={`/shafts/${product.slug}`}>Go to Product</Link>
                                    <Button variant="link" data-id={product._id} onClick={removeItem} className="removeitem" >Remove Item</Button>
                                </div>
                            </div>)}

                        </Col>
                    </Row>
                </Container>
            </section>







        </>
    );
}

export default Wishlist;

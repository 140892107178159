import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const AddToCart = ({
  onClickAddProductToCart,
  selectedQuantity,
  updateQuantity,
  moreInfoChecked,
  product,
  onInputQuantityChange,
  setSelectedQuantity,
  second = false,
  onShowNotifyModal,
  stopCount,
  user,
}) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };
  const AddToCartLabel = () => {
    return product.defaultVariation?.stock_quantity
      ? 'Add to Cart'
      : 'Notify Me';
  };

  const handleChange = useCallback((e) => {
    const qty = e.target.value;
    if (moreInfoChecked) {
      setSelectedQuantity(1);
    } else {
      if (product.defaultVariation?.stock_quantity >= qty) {
        // setSelectedQuantity(qty > 0 ? qty : 1);
        setSelectedQuantity(qty);
      } else {
        setSelectedQuantity(product.defaultVariation?.stock_quantity);
      }
    }
  }, [product, moreInfoChecked]);

  return (
    <>
      {product.defaultVariation?.stock_quantity &&
        !product.defaultVariation?.is_external ? (
        <div className="quantity-tab">
          <>
            <button
              disabled={selectedQuantity === 1 || moreInfoChecked}
              className="btn"
              onClick={() => updateQuantity(-1)}
            >
              <AiOutlineMinus />
            </button>
            {/* <span> */}
            <input
              type="number"
              name="quantity"
              className="form-control remove-control"
              value={selectedQuantity}
              // onChange={onInputQuantityChange}
              onChange={handleChange}
            />
            {/* </span> */}
            <div
              onMouseEnter={moreInfoChecked && handleMouseEnter}
              onMouseLeave={moreInfoChecked && handleMouseLeave}
            >
              <button
                className={`btn ${isTooltipVisible ? 'tooltip active' : ''}`}
                disabled={
                  product.defaultVariation?.stock_quantity <= selectedQuantity ||
                  moreInfoChecked || (stopCount && user?.userType == 2)
                }
                onClick={() => updateQuantity(1)}
              >
                <AiOutlinePlus />
                {isTooltipVisible && moreInfoChecked && <span className="tooltiptext">we allow only one shaft customized at a time</span>}
              </button>
            </div>
          </>
        </div>
      ) : null}
      {!product.defaultVariation?.is_external ? (
        product.defaultVariation?.stock_quantity ? (
          <div className={second ? 'notify ms-3' : 'addcart'}>
            <Button className="btn" onClick={onClickAddProductToCart}>
              Add To Cart
            </Button>
          </div>
        ) : (
          <div className={second ? 'notify ms-3' : 'addcart'}>
            <Button className="btn" onClick={onShowNotifyModal}>
              Notify Me
            </Button>
          </div>
        )
      ) : (
        <a href={`${product.defaultVariation?.external_link}`} target="_blank">
          <Button className="btn">Buy Now</Button>
        </a>
      )}
    </>
  );
};

export default AddToCart;

import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, Button, ProgressBar, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { Range } from 'react-range';
import convert from 'convert-units';
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { getUserDetails } from "../redux-services/slices/authSlice";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';

import { BiHelpCircle } from "react-icons/bi";
//state = { values: [50] };
const DriverSelectorIronNext = () => {
    const { product_category } = useParams();
    const productSelector = useSelector(getProductSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useSelector(getUserDetails);
    const [parameter, setParameter] = useState(false);
    const [priority, setPriority] = useState(productSelector?.questions?.swing_speed);
    const [rangeSlider, setRangeSlider] = useState({
        PreferredballFlight: [3]
    });
    const [showModal, setShowModal] = useState(false);
    const [trajectoryText, setTrajectoryText] = useState(null);
    const [convertText, setConvertText] = useState(null);
    const location = useLocation();
    const parts = location?.pathname.split("/");
    const endString = parts[parts.length - 1];
    const [cmsData, setCmsData] = useState();
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: endString,
    });

    useEffect(() => {
        if (productSelector?.questions?.metric) {
            setParameter(true);
        }
    }, [])

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    useEffect(() => {
        let setProduct = {
            ...productSelector,
            userDetail: authUser,
            questions: { ...productSelector.questions, check_swing_speed: trajectoryText, priority: priority, metricCarry: parameter },
        }
        dispatch(setProductSelector(setProduct))
    }, [trajectoryText, priority, parameter])

    useEffect(() => {
        let trajectory1 = rangeSlider.PreferredballFlight[0];
        if (parameter) {
            switch (trajectory1) {
                case 1:
                    setConvertText(Math.trunc(convert(100).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 2:
                    setConvertText(Math.trunc(convert(105).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 3:
                    setConvertText(Math.trunc(convert(110).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 4:
                    setConvertText(Math.trunc(convert(115).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 5:
                    setConvertText(Math.trunc(convert(120).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 6:
                    setConvertText(Math.trunc(convert(125).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 7:
                    setConvertText(Math.trunc(convert(130).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 8:
                    setConvertText(Math.trunc(convert(135).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 9:
                    setConvertText(Math.trunc(convert(140).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 10:
                    setConvertText(Math.trunc(convert(145).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 11:
                    setConvertText(Math.trunc(convert(150).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 12:
                    setConvertText(Math.trunc(convert(155).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 13:
                    setConvertText(Math.trunc(convert(160).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 14:
                    setConvertText(Math.trunc(convert(165).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 15:
                    setConvertText(Math.trunc(convert(170).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 16:
                    setConvertText(Math.trunc(convert(175).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 17:
                    setConvertText(Math.trunc(convert(180).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 18:
                    setConvertText(Math.trunc(convert(185).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 19:
                    setConvertText(Math.trunc(convert(190).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 20:
                    setConvertText(Math.trunc(convert(195).from('yd').to('m')) + ' ' + 'Meter');
                    break;
                case 21:
                    setConvertText(Math.trunc(convert(200).from('yd').to('m')) + ' ' + 'Meter');
                    break;
            }
        } else {
            switch (trajectory1) {
                case 1:
                    setTrajectoryText(`100 Yard`);
                    break;
                case 2:
                    setTrajectoryText(`105 Yard`);
                    break;
                case 3:
                    setTrajectoryText(`110 Yard`);
                    break;
                case 4:
                    setTrajectoryText(`115 Yard`);
                    break;
                case 5:
                    setTrajectoryText(`120 Yard`);
                    break;
                case 6:
                    setTrajectoryText(`125 Yard`);
                    break;
                case 7:
                    setTrajectoryText(`130 Yard`);
                    break;
                case 8:
                    setTrajectoryText(`135 Yard`);
                    break;
                case 9:
                    setTrajectoryText(`140 Yard`);
                    break;
                case 10:
                    setTrajectoryText(`145 Yard`);
                    break;
                case 11:
                    setTrajectoryText(`150 Yard`);
                    break;
                case 12:
                    setTrajectoryText(`155 Yard`);
                    break;
                case 13:
                    setTrajectoryText(`160 Yard`);
                    break;
                case 14:
                    setTrajectoryText(`165 Yard`);
                    break;
                case 15:
                    setTrajectoryText(`170 Yard`);
                    break;
                case 16:
                    setTrajectoryText(`175 Yard`);
                    break;
                case 17:
                    setTrajectoryText(`180 Yard`);
                    break;
                case 18:
                    setTrajectoryText(`185 Yard`);
                    break;
                case 19:
                    setTrajectoryText('190 Yard');
                    break;
                case 20:
                    setTrajectoryText(`195 Yard`);
                    break;
                case 21:
                    setTrajectoryText(`200 Yard`);
                    break;
            }
        }
    }, [rangeSlider.PreferredballFlight[0], parameter]);

    const onCloseModal = () => {
        setShowModal(false);
    }

    const onSubmit = useCallback(() => {
        let swing_speed = productSelector.questions.swing_speed;
        if ((swing_speed == "70-80 mph" && (parseInt(trajectoryText.match(/\d+/)[0], 10) >= 100 && parseInt(trajectoryText.match(/\d+/)[0], 10) < 120)) ||
            (swing_speed == "81-95 mph" && parseInt(trajectoryText.match(/\d+/)[0], 10) >= 120 && parseInt(trajectoryText.match(/\d+/)[0], 10) < 140) ||
            (swing_speed == "96-105 mph" && parseInt(trajectoryText.match(/\d+/)[0], 10) >= 140 && parseInt(trajectoryText.match(/\d+/)[0], 10) < 170) ||
            (swing_speed == "106-120 mph" && parseInt(trajectoryText.match(/\d+/)[0], 10) >= 170 && parseInt(trajectoryText.match(/\d+/)[0], 10) < 190) ||
            (swing_speed == "120 mph +" && parseInt(trajectoryText.match(/\d+/)[0], 10) >= 190) || showModal
        ) {
            setShowModal(false);
            navigate("/kinetixx-fit-iron-three/iron")
        } else {
            setShowModal(true);
        }
    }, [trajectoryText, showModal])

    const handleClickOnModal = (e) => {
        let data = e?.currentTarget?.getAttribute("value");
        setPriority(data);
    }

    const onChangeValue = () => {
        setParameter(!parameter);
    }

    const now = 42.84;

    return <>
        <section className="Driver-selecter-outer facility-outer irons-tabs pb-0">
            <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="technology-mat-content text-center"><h2>{cmsData?.page_title}</h2><h4>{cmsData?.page_subtitle}</h4><hr /></div>
                    </Col>
                    <Col md="12">
                        <div className="iron-content text-center">
                            <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                            <div className="metricChange">
                                <p className="questions">QUESTION 3/7</p>
                                <h4>How far do you hit/carry your 7 iron?</h4>
                                <span className="selectone">Please select one.</span>
                                <div className="changeMetirc">
                                    <span className="custom_check">
                                        Metric Scale
                                        <input
                                            type="checkbox"
                                            checked={parameter}
                                            onChange={onChangeValue}
                                        />
                                        <span className="check_indicator">&nbsp;</span>
                                    </span>
                                    {/* <Link
                                        value={parameter}
                                        onClick={onChangeValue}
                                    >
                                        <>{parameter ? "Imperial Scale" : "Metric Scale"} </>
                                    </Link> */}
                                </div>
                            </div>
                            <div className="labes-type">
                                <div className="sliders" id="sliders">
                                    <div className="border-box">
                                        <div className="slider average-dis" id="q1-slider">
                                            <div class="labels">
                                                <div class="start-label extra text-start">{parameter ? Math.trunc(convert(100).from('yd').to('m')) : 100} {parameter ? "meter" : "yard"}</div>
                                                <div className="start-label extra">{parameter ? convertText : trajectoryText}</div>
                                                <div class="start-label extra">{parameter ? Math.trunc(convert(200).from('yd').to('m')) : 200} {parameter ? "meter" : "yard"}</div>
                                            </div>
                                            <div className="range-outer nextrange">
                                                <Range
                                                    step={1}
                                                    min={1}
                                                    max={21}
                                                    values={rangeSlider.PreferredballFlight}
                                                    onChange={(values) => setRangeSlider({ ...rangeSlider, PreferredballFlight: values })}
                                                    renderTrack={({ props, children }) => (
                                                        <div className={rangeSlider.PreferredballFlight[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div className="inner-label">
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                            </div>
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div className="thumb-dots"
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                height: '42px',
                                                                width: '52px',
                                                            }}
                                                        />
                                                    )}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="back-next">
                                <Link to="/kinetixx-fit-iron-two/iron" >Go Back</Link>
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={onSubmit}
                                >
                                    Next Question
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal show={showModal} onHide={onCloseModal} backdrop="static" size="lg" centered>
                <Modal.Header className="border-bottom">
                    <Modal.Title>Alert <BiHelpCircle /></Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-2">
                    <p>Your driver speed does not correlate with your 7 iron carry distance.</p>
                    <Row className="question-list">
                        <p style={{ marginBottom: "2px" }}>Which value do you think is more accurate?</p>
                        <Col sm={6}>
                            <label>1. Swing Speed</label>
                            <Col sm={6}>
                                <input
                                    type="radio"
                                    id="swing_speed"
                                    name="fav_language"
                                    value={productSelector?.questions?.swing_speed}
                                    defaultChecked
                                    onChange={handleClickOnModal}
                                ></input>
                                <label className="swingspeed">{productSelector?.questions?.swing_speed}</label>
                            </Col>
                        </Col>
                        <Col sm={6}>
                            <label>2. Carry Distance</label>
                            <Col sm={6}>
                                <input
                                    type="radio"
                                    id="distance"
                                    name="fav_language"
                                    value={trajectoryText}
                                    onChange={handleClickOnModal}
                                ></input>
                                <label className="swingspeed">{trajectoryText}</label>
                            </Col>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Link className="text-gray" to="/kinetixx-fit-iron-two/iron">
                        Go Back
                    </Link>
                    <Link to="/kinetixx-fit-iron-three/iron">
                        <Button
                            variant="primary"
                            type="button"
                            onClick={onCloseModal}
                        >
                            Continue
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>
        </section>
    </>
}

export default DriverSelectorIronNext;
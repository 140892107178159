import { Container, Row, Col, Tabs, Tab, Form } from 'react-bootstrap';
import { AiOutlinePlus, AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { BsStarHalf } from 'react-icons/bs';
import CompareSelection from './Compare/CompareSelection';
import Specs from './Specs';
import { Rating } from 'react-simple-star-rating';
import { DATE_TIME_OPTIONS, ProductType } from '../../../constants';

const ProductDetailTabs = ({ product, type }) => {
  return (
    <section className="detailstabspanel">
      <Container fluid>
        <Row>
          <Col md="12">
            <div className="bottomtabs">
              <Tabs
                defaultActiveKey={'Description'}
                id="uncontrolled-tab-example"
                className={type === ProductType ? 'gears' : ''}
              >
                <Tab eventKey="Description" title="Description">
                  <div
                    className="detailTBOuter"
                    dangerouslySetInnerHTML={{
                      __html: product.tech_design,
                    }}
                  />
                </Tab>
                {type !== ProductType && (
                  <Tab eventKey="Specifications" title="Specifications">
                    <Specs
                      product={product}
                      raw_spec_key="technical_spec"
                      spec_key="technical_specs"
                    />
                  </Tab>
                )}
                {type !== ProductType && (
                  <Tab eventKey="Fitting" title="Fitting">
                    <Specs
                      product={product}
                      spec_key="fitting_specs"
                      raw_spec_key="fitting_spec"
                    />
                  </Tab>
                )}
                {type !== ProductType && (
                  <Tab eventKey="installation_guide" title="Installation Guide">
                    <div
                      className="detailTBOuter"
                      dangerouslySetInnerHTML={{
                        __html: product.installation_guidelines,
                      }}
                    />
                  </Tab>
                )}
                <Tab eventKey="Reviews" title="Reviews">
                  <div className="detailTBOuter">
                    {product.product_reviews.length > 0 && (
                      <ul className="reviewlist">
                        {product.product_reviews.map((item, index) => (
                          <li key={item.user_id}>
                            <div className="reviewouter">
                              <div className="reviewleft">
                                <span className="userimg">
                                  {item?.rated_by.charAt(0).toUpperCase()}
                                </span>
                                <div className="usercomment">
                                  <h5>
                                    {item?.rated_by}{' '}
                                    <span>Verified Reviewer</span>
                                  </h5>
                                  <div className="starrating">
                                    <Rating
                                      fillColor={'#6d2a5f'}
                                      size={20}
                                      readonly
                                      initialValue={item.rating}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="reviewdate">
                                {new Intl.DateTimeFormat('en-US', {
                                  ...DATE_TIME_OPTIONS,
                                  hour12: false,
                                }).format(new Date(item?.rated_on))}
                              </div>
                            </div>
                            <p>{item?.review}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                    {product.product_reviews.length === 0 && (
                      <p>{'No review found yet for this product.'}</p>
                    )}
                  </div>
                </Tab>
                {type !== ProductType && (
                  <Tab eventKey="Compare" title="Compare">
                    <div className="box-outer">
                      <Container>
                        <Row className="justify-content-center">
                          <Col md="12">
                            <CompareSelection
                              product={product?.slug}
                              productDetail={product}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProductDetailTabs;

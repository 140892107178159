import { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AiFillStar, AiOutlineMinus, AiOutlinePlus, AiOutlineStar } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { useAddToCartMutation, useRemoveCouponMutation, useUpdateCartQuantityMutation } from '../../redux-services/apis/cartApi';
import { useAddProductRatingMutation } from '../../redux-services/apis/productDetailApi';
import {
  showModel,
  getFittingList,
} from '../../redux-services/slices/siteDataSlice';
import ShowError from '../Notifications/ShowError';
import ShowModel from '../Notifications/ShowModel';
import { getUserDetails } from '../../redux-services/slices/authSlice';

const DUMMY_IMAGE = '/images/productdummy.png';

const AWS_STORAGE_URL = process.env.REACT_APP_AWS_STORAGE_URL;

const CartProduct = ({ product, cartId, isSideMenu, isOrder, orderStatus, productTotal, cartDetail }) => {
  const dispatch = useDispatch();
  const initialFormData = {
    productId: product.product_id,
    product_variation: product.product_variation,
    product_variation_sku: product.sku,
    rating: 0,
    review: '',
    isAlreadyRated: false,
    isApproved: false,
  };

  const inputRef = useRef(null);
  const [pModel, setPModel] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [showQuantity, setShowQuantity] = useState(false);
  const [productImage, setProductImage] = useState('');
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [showForm, setShowForm] = useState(true);
  const [addProductRating, productRatingRes] = useAddProductRatingMutation();
  const [count, setCount] = useState(0);
  const location = useLocation();
  const user = useSelector(getUserDetails);
  const [stopCount, setStopCount] = useState(false);

  const fittingList = useSelector(getFittingList);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  const handleRating = (rate) => {
    setFormData({ ...formData, rating: rate });
  };

  const onChange = (event) => {
    //if (event.target.value.length <= 250) {
    setCount(event.target.value.length);
    setFormData({ ...formData, review: event.target.value });
    // }
  };

  const setFocusOn = (fieldId) => {
    const fieldItem = document.querySelector('input[name=' + fieldId + ']');
    fieldItem?.focus();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowQuantity(!showQuantity); // Update the state when clicking outside the input field
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setShowQuantity(true); // Update the state when pressing the Escape key
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [showQuantity]);

  useEffect(() => {
    if (productRatingRes?.isSuccess && productRatingRes?.data?.message) {
      setShowForm(false);
      setErrors({});
      setFormData({ ...formData, isAlreadyRated: true });
      dispatch(
        showModel({
          isOpen: true,
          modelType: 'success',
          body: productRatingRes.data.message,
        })
      );
    }
    if (productRatingRes.isError && productRatingRes.error.data.errors) {
      const errorList = {};
      let firstError = '';
      dispatch(
        showModel({
          isOpen: true,
          modelType: 'danger',
          body: productRatingRes.error.data.message,
        })
      );
      productRatingRes?.error?.data?.errors?.forEach((error, index) => {
        if (index === 0) {
          firstError = error.param;
        }
        errorList[error.param] = error.msg;
      });

      setErrors(errorList);
      setFocusOn(firstError);
    }
  }, [productRatingRes]);

  const getImage = (title, productF) => {
    const fittings = fittingList
      ?.find((ftt) => ftt.title.toLowerCase() === title)
      ?.fittings?.find((ft) => ft._id === productF.option_id);
    let image = '';
    if (title === 'grips') {
      image = fittings?.options?.find(
        (ft) => ft._id === productF.sub_option_id
      )?.image;
    } else if (title === 'adaptors') {
      image = fittings?.image;
    }
    return image
      ? `${AWS_STORAGE_URL}/uploads/fitting-variation/${image}`
      : DUMMY_IMAGE;
  };

  useEffect(() => {
    if (product) {
      const newProduct = { ...product };
      if (newProduct?.rating && newProduct.rating.length > 0) {
        setShowForm(false);
        const ratingData = newProduct?.rating[0];
        setCount(ratingData?.review.length);
        setFormData({
          ...formData,
          rating: ratingData?.rating,
          review: ratingData?.review,
          isAlreadyRated: true,
          isApproved: ratingData?.status
        });
      }

      // SET THUMBNAIL
      newProduct.thumbnail = newProduct.thumbnail ?? DUMMY_IMAGE;
      if (cartId !== undefined) {
        newProduct['image'] = newProduct?.images[0]?.images[0]
      } else {
        // for (const iterator of newProduct?.product_attribute ?? []) {
        //   let branding = iterator.branding_attribute.find((item) => Object.values(newProduct.product_variation).includes(item.label));
        //   if (branding !== undefined) {
        //     newProduct['image'] = branding.images[0]
        //   }
        // }
        if (newProduct?.images?.images !== undefined) {
          newProduct['image'] = newProduct?.images?.images[0]
        }

      }

      let productVariationDetail = [];
      let productFitting = [];

      if (isOrder) {
        Object.keys(newProduct?.product_variation ?? []).map((vari) => {
          productVariationDetail.push({
            attribute_name: vari,
            term_option: newProduct.product_variation[vari],
          });
        });
        newProduct.productVariationSku = newProduct.sku;
        newProduct.price = newProduct.product_price?.$numberDecimal;
        productFitting = product?.fitting_options;
      } else {
        productFitting = product?.productFitting ?? [];
        productVariationDetail = [...newProduct.productVariationDetail];
      }
      newProduct.productVariationDetail = productVariationDetail.sort(
        (a, b) => {
          if (a?.attribute_name == ('Iron #')) {
            return a?.attribute_name === 'Iron #' ? -1 : (a?.attribute_name === 'Flex' ? -1 : 1);
          } else {
            return a.attribute_name === 'Flex' ? -1 : 1;
          }
        }
      );
      // setProductFittings
      const fittings = [];
      productFitting?.map((productF) => {
        if (
          productF.attribute_name.toLowerCase() == 'grips' ||
          productF.attribute_name.toLowerCase() == 'adaptors'
        ) {
          newProduct['has_customization'] = true;
          const ft = {
            ...productF,
            image: getImage(productF.attribute_name.toLowerCase(), productF),
          };
          newProduct[productF.attribute_name.toLowerCase()] = ft;
        } else {
          fittings.push(productF);
        }
      });

      newProduct.productFitting = fittings;

      let IronData = ["Iron", "Iron #"];
      let IronNumbers = ['4', '5', '6', '7', '8', '9', 'P'];
      const prodVariationMatch = newProduct?.productVariationDetail.find(item => IronData.includes(item?.attribute_name) && IronNumbers.includes(item?.term_option));
      const checkVariationFor123 = newProduct?.productVariationDetail.find(item => IronData.includes(item?.attribute_name) && item?.term_option.includes('1-2-3'));
      const checkVariationForWedges = newProduct?.productVariationDetail.find(item => IronData.includes(item?.attribute_name) && item?.term_option.includes("GW/SW/LW"));

      if (user?.userType === 2) {
        if (checkVariationFor123 !== undefined && newProduct?.quantity == 3) {
          setStopCount(true)
        } else {
          setStopCount(false)
        }
        if (checkVariationForWedges !== undefined && newProduct?.quantity == 4) {
          setStopCount(true)
        }
        if (prodVariationMatch !== undefined) {
          setStopCount(true)
        }
      }
      setPModel(newProduct);
    }
  }, [fittingList, product, stopCount]);

  // REMOVE PRODUCT
  const [removeFromCart, _cartResponse] = useAddToCartMutation();
  const [removeCoupon, _removeCouponResponse] = useRemoveCouponMutation();
  const [updateCartQuantity, updateCartQuantityResponse] = useUpdateCartQuantityMutation();


  useEffect(() => {
    if (updateCartQuantityResponse?.isSuccess) {
      setShowQuantity(false);
    }
  }, [updateCartQuantityResponse])

  const removeProduct = (productId) => {
    removeCoupon();
    removeFromCart({ productDetails: {}, is_delete: true, cartId, productId });
  };
  const submitRating = (event) => {
    event.preventDefault();
    addProductRating(formData);
  };

  // Decrease the product quantity
  const updateQuantity = (pmodel, dataOption) => {
    let cartProdId = pmodel?._id;
    let cartId = cartDetail?.cart_id;
    updateCartQuantity({
      productId: cartProdId,
      cartId: cartId,
      dataOption: dataOption,
    })
  }

  const handleSubmit = (pmodel, dataOption) => {
    let cartProdId = pmodel?._id;
    let cartId = cartDetail?.cart_id;
    if (quantity > 0) {
      updateCartQuantity({
        productId: cartProdId,
        cartId: cartId,
        dataOption: dataOption,
        count: quantity,
      })
    } else {
      removeProduct(cartProdId);
    }
  }

  const handleChange = (e, pModel) => {
    if (e.target.value <= pModel.stock_quantity && !stopCount && pModel?.productFitting?.length <= 0) {
      setQuantity(e.target.value);
    }
  }

  return (
    <>
      {[2, 4].includes(pModel?.status) &&
        <>
          {!isSideMenu ? (
            <>
              {productRatingRes?.isSuccess ? <ShowModel /> : null}
              <div
                className={
                  'checkoutproductdetails ' +
                  (isOrder ? 'check-out-details-panel' : '')
                }
              >
                <div className={isOrder ? 'check-out-panel-left' : ''}>
                  <div className="checkoutDouter">
                    <div className="checkoutleft">
                      <div className="checkimg">
                        <img
                          src={pModel?.image ? `https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/${pModel?.image}` : pModel?.thumbnail}
                          alt={pModel?.product_title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="checkoutDetail">
                        <Link to={'/shafts/' + pModel?.product_slug}>
                          <h5 style={{ textTransform: 'capitalize' }}>
                            {pModel?.product_title} - {pModel?.quantity > 1 ? pModel?.categoryName : pModel?.categorySlug}
                          </h5>
                        </Link>
                        <p key={pModel?.productVariationSku}>
                          <span>Model Number : </span>
                          {pModel?.productVariationSku}
                        </p>
                        {pModel?.productVariationDetail.map((variation) => (
                          <p key={variation.attribute_name}>
                            <span>{variation.attribute_name} : </span>
                            {variation.term_option}
                          </p>
                        ))}
                        {pModel?.productFitting.map((fitting) => (
                          <p key={fitting.attribute_name}>
                            <span>{fitting.attribute_name} : </span>
                            {fitting.option_name || fitting.attribute_value == '1'
                              ? 'None'
                              : fitting.attribute_value &&
                                fitting.attribute_value == '2'
                                ? '1'
                                : fitting.attribute_value &&
                                  fitting.attribute_value == '3'
                                  ? '2'
                                  : (fitting?.attribute_value == 0) ? "Uncut" : fitting?.attribute_value}
                          </p>
                        ))}
                        {location?.pathname.split("/")[1] === 'order' ?
                          (
                            <p>
                              <strong>
                                <span>Quantity : </span> {pModel?.quantity}
                              </strong>
                            </p>
                          )
                          :
                          (
                            <p className='cart-quantity'>
                              <span>Quantity : </span>
                              <div className="quantity-tab">
                                {showQuantity &&
                                  <span style={{ display: "flex" }} ref={inputRef}>
                                    <input
                                      type="number"
                                      min={0}
                                      name='quantity'
                                      id='quantity'
                                      value={quantity}
                                      onChange={(e) => handleChange(e, pModel)}
                                    />
                                    <button title='Add Quantity' className='btn btn-secondary update-btn' onClick={(e) => handleSubmit(pModel, 'update')} >Update</button>
                                  </span>
                                }
                                {!showQuantity &&
                                  <>
                                    <button
                                      disabled={pModel?.quantity === 1}
                                      className="btn"
                                      onClick={(e) => updateQuantity(pModel, 'decrease')}
                                    >
                                      <AiOutlineMinus />
                                    </button>
                                    {!showQuantity && <span className='product-quantity' onClick={() => { setShowQuantity(true); setQuantity(pModel?.quantity) }}>
                                      {pModel?.quantity}
                                    </span>
                                    }
                                    <div
                                      onMouseEnter={pModel?.productFitting.length > 0 && handleMouseEnter}
                                      onMouseLeave={pModel?.productFitting.length > 0 && handleMouseLeave}
                                    >
                                      <button
                                        className={`btn ${isTooltipVisible ? 'tooltip active' : ''}`}
                                        disabled={
                                          (stopCount || (pModel?.stock_quantity <= pModel?.quantity || pModel?.productFitting?.length > 0))
                                        }
                                        onClick={(e) => updateQuantity(pModel, 'increase')}
                                      >
                                        <AiOutlinePlus />
                                        {isTooltipVisible && pModel?.productFitting.length > 0 && <span className="tooltiptext">we allow only one shaft customized at a time</span>}
                                      </button>
                                    </div>
                                  </>
                                }
                              </div>
                            </p>
                          )
                        }
                      </div>
                    </div>

                    <div className="totals">
                      TOTAL <span>${isOrder ? (pModel?.price * pModel.quantity).toFixed(2) : (productTotal ? productTotal : pModel?.price)}</span>
                    </div>

                    {!isOrder && (
                      <a
                        className="removeproduct"
                        onClick={(e) => removeProduct(pModel?._id)}
                      >
                        <BiTrash /> Remove
                      </a>
                    )}
                  </div>

                  {pModel?.has_customization && (
                    <>
                      <hr />
                      <div className="additionalProduct">
                        <h4>Build Parameters</h4>
                        <div className="additinalOuter">
                          {pModel?.adaptors && (
                            <div className="additinalone">
                              <div className="addproductimg">
                                <img
                                  src={pModel?.adaptors?.image}
                                  alt={pModel?.adaptors?.attribute_name}
                                />
                              </div>
                              <div className="addcontent">
                                <p>{pModel?.adaptors.option_name}</p>
                                <p>
                                  Adaptor Model: {pModel?.adaptors?.sub_option_name}
                                </p>
                                <p className="totalpayment">
                                  ${pModel?.adaptors?.price?.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          )}
                          {(pModel?.grips && (pModel?.grips?.option_name).toLowerCase() !== "n/a") && (
                            <div className="additinalone">
                              <div className="addproductimg">
                                <img
                                  src={pModel?.grips.image}
                                  alt={pModel?.grips?.attribute_name}
                                />
                              </div>
                              <div className="addcontent">
                                <p>{pModel?.grips?.option_name}</p>
                                <p>Grip: {pModel?.grips?.sub_option_name}</p>
                                <p className="totalpayment">
                                  ${pModel?.grips?.price?.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isOrder && [8, 9, 10, 11, 12].includes(orderStatus) && (
                  <div className="checkout-review">
                    <div className="checkoutRight yourreviewright">
                      <div className="coupancodeouter">
                        <div className="review-panel-outer">
                          <h4>Your Review</h4>
                          {!showForm && !formData.isApproved && (
                            <Button
                              onClick={() => setShowForm(true)}
                              className=""
                              size="sm"
                            >
                              Edit Review
                            </Button>
                          )}
                        </div>
                        <hr />
                        <div className="readonly-review">
                          {!showForm && (
                            <>
                              <Rating
                                size={25}
                                fillColor={'#6d2a5f'}
                                readonly
                                initialValue={formData.rating}
                              />
                              <p style={{ width: "350px", overflowWrap: "break-word", overflow: "hidden" }}>{formData.review}</p>
                            </>
                          )}
                        </div>
                        {showForm && (
                          <Form onSubmit={submitRating}>
                            <div className="giverate">
                              <label className="mb-0">Rating </label>
                              <ul className="ratinglist">
                                <Rating
                                  size={25}
                                  fillColor={'#6d2a5f'}
                                  onClick={handleRating}
                                  initialValue={formData.rating}
                                />
                                <ShowError
                                  current_key="rating"
                                  key="rating"
                                  errors={errors}
                                />
                              </ul>
                            </div>
                            <div className="form-group">
                              <Form.Control
                                name="review"
                                value={formData.review}
                                onChange={onChange}
                                as="textarea"
                                rows={3}
                                placeholder="Message"
                              />
                              {/*  <small className='float-end'>{count}/{250}</small> */}
                              <ShowError
                                current_key="review"
                                key="review"
                                errors={errors}
                              />
                            </div>
                            <div className="form-group text-end">
                              <Button type="submit">Submit</Button>
                            </div>
                          </Form>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <li key={pModel?._id}>
              <div className="cart-partleft">
                <div className="cartproductImg">
                  <img
                    src={pModel?.image ? `https://kinetixx180422.s3.us-east-2.amazonaws.com/uploads/product/${pModel?.image}` : pModel?.thumbnail}
                    alt={pModel?.product_title}
                    className="img-fluid"
                  />
                  <span className="productcount">{pModel?.quantity}</span>
                </div>
                <div className="cartproduct-content">
                  <h5 style={{ textTransform: 'capitalize' }}>
                    {pModel?.product_title} - {pModel?.quantity > 1 ? pModel?.categoryName : pModel?.categorySlug}
                  </h5>
                  <span>{pModel?.productVariationSku}</span>
                </div>
              </div>
              <div className="cart-partright">
                ${pModel?.price}
                <a
                  className="cart-delete-icon"
                  onClick={(e) => removeProduct(pModel?._id)}
                >
                  <BiTrash />
                </a>
              </div>
              {pModel?.has_customization && (
                <ul className="cat-additional-pModel">
                  {pModel?.adaptors && (
                    <li key={pModel?.adaptors?._id}>
                      <div className="cart-partleft">
                        <div className="cartproductImg">
                          <img
                            src={pModel?.adaptors.image}
                            alt={pModel?.adaptors?.attribute_name}
                            className="img-fluid"
                          />
                        </div>
                        <div className="cartproduct-content">
                          <h5>{pModel?.adaptors?.attribute_name}</h5>
                          <span>{pModel?.adaptors?.option_name}</span>
                          <span>{pModel?.adaptors?.sub_option_name}</span>
                        </div>
                      </div>
                      <div className="cart-partright">
                        ${pModel?.adaptors?.price?.toFixed(2)}
                      </div>
                    </li>
                  )}
                  {(pModel?.grips && (pModel?.grips?.option_name).toLowerCase() !== "n/a") && (
                    <li key={pModel?.grips._id}>
                      <div className="cart-partleft">
                        <div className="cartproductImg">
                          <img
                            src={pModel?.grips?.image}
                            alt={pModel?.grips?.attribute_name}
                            className="img-fluid"
                          />
                        </div>
                        <div className="cartproduct-content">
                          <h5>{pModel?.grips?.attribute_name} </h5>
                          <span>{pModel?.grips?.option_name}</span>
                          <span>{pModel?.grips?.sub_option_name}</span>
                        </div>
                      </div>
                      <div className="cart-partright">
                        ${pModel?.grips.price?.toFixed(2)}
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </li>
          )}
        </>
      }
    </>
  );
};

export default CartProduct;

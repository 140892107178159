import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import { useSelector } from "react-redux";
import { getVideoList } from "../../redux-services/slices/siteDataSlice";

const HomeVideoOuter = (props) => {
  const responsive01 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1200, min: 991 },
      items: 2
    },
    minitablet: {
      breakpoint: { max: 991, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };

  const videoList = useSelector(getVideoList);
  const [dataId, setDataId] = useState(null);
  const [isVideoActive1, setIsVideoActive1] = useState(false);
  const handleVideo1 = (e) => {
    // 👇️ toggle isActive state on click
    setDataId(e?.currentTarget?.getAttribute('data-id'));
    setIsVideoActive1(true);
  };

  return (
    <section className="video-outer">
      {props.homeData &&
        <Container>
          <Row>
            <Col md="12">
              <div className="video-headings">
                <h2>
                  <div
                    className="innerpages"
                    dangerouslySetInnerHTML={{
                      __html: (props.homeData?.section_3?.title),
                    }}
                  />
                </h2>
                <h3>{props.homeData?.section_3?.subtitle}</h3>
                <p dangerouslySetInnerHTML={{
                  __html: (props.homeData?.section_3?.description),
                }} />
                {(props.homeData?.section_3?.column_1 && props.homeData?.section_3?.column_2) && <>
                  <hr />
                  <Row>
                    <Col md="6">
                      <p dangerouslySetInnerHTML={{
                        __html: (props.homeData?.section_3?.column_1),
                      }} />
                    </Col>
                    <Col md="6">
                      <p dangerouslySetInnerHTML={{
                        __html: (props.homeData?.section_3?.column_2),
                      }} />
                    </Col>
                  </Row>
                </>}
              </div>
            </Col>
            <Col md="12">
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive01}
                ssr={true}
                infinite={true}
                autoPlaySpeed={2000}
                keyBoardControl={true}
              >
                {videoList && videoList.map(video =>
                  <div>
                    <div className="videoTab">
                      <img src={video?.image} alt="#" className="img-fluid" />
                      <div className={(isVideoActive1 && dataId == video._id) ? 'classAdded videos-outer' : 'videos-outer'}>
                        <iframe width="774" height="358" src={video?.thumbnail} title={video?.title} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
                      <div className="video-content">
                        <h4>{video?.title}</h4>
                        <div className="playBtn" onClick={handleVideo1} data-id={video._id}>
                          <h5>Watch</h5>
                          <img src="/images/play.svg" alt="#" />
                          <h5>Video</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Carousel>
            </Col>

          </Row>
        </Container>
      }
    </section>

  );
}

export default HomeVideoOuter;
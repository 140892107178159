import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux"
import { getCategoryList } from "../../../../redux-services/slices/siteDataSlice"

const SelectProduct = ({
    SelectTitle,
    selectedProduct,
    setSelectedProduct,
    flex,
    setFlex,
    iron,
    setIron,
    tip,
    setTip,
    productId,
    category,
    setCategory,
    productDetail
}) => {

    const categories = useSelector(getCategoryList);
    const [ironData, setIronData] = useState([]);
    const [flexData, setFlexData] = useState([]);
    const [tipData, setTipData] = useState([]);

    useEffect(() => {

        if (productId && categories.length) {
            setCat(null, productId);
            if (category) {
                setProd(productId);
            }
        }
    }, [productId, categories])

    useEffect(() => {
        if (selectedProduct) {
            if (selectedProduct?.categoryName === "Iron") {
                    setIronData(selectedProduct?.ironData);
                    setFlexData([]);
            } else {
                    setFlexData(selectedProduct?.flexData);
                    setIronData([]);
            }
        }
    }, [selectedProduct])

    const setCat = (catId, prod = false) => {
        let cat = null;
        if (!catId && (prod != false)) {
            cat = categories?.find(categ => {
                return categ.products.find(product => product.slug === prod);
            });
        }
        if (catId) {
            cat = categories?.find(categ => categ._id == catId);
        }
        if (cat) {
            setCategory(cat);
        }
        if (prod) {
            const prodNew = cat?.products?.find(prodN => prodN.slug == prod);

            if (prodNew) {
                setSelectedProduct(prodNew);
            }
        }
    }
    const setProd = (prodId) => {
        const prod = category?.products?.find(prod => prod.slug == prodId);

        if (prod) {

            setSelectedProduct(prod);
        }
    }
    const onChangeCategory = (e) => {
        setCat(e.target.value)
        setProd('');
        setFlex('');
        setIron("");
        setTip("");
    }

    const onChangeProduct = (e) => {
        setProd(e.target.value);
        setFlex('');
        setIron("");
        setTip("");

    }

    const onChangeFlex = (e) => {
        if (category?.name !== "Iron") {
            setIron("");
            setTip("");
        }
        let irons = [];
        let tipDiameterData = [];

        for (const variation of selectedProduct.variations) {
            const flexCode = variation.variation.find(variationItem => variationItem.option_id === e.target.value);
            if (flexCode && flexCode !== undefined) {
                const iron = variation.variation.find(variationItem => variationItem.attribute_name === "Iron #" || variationItem.attribute_name === "Iron");
                const checkIron = irons.filter(ironItem => ironItem.option_id === iron.option_id)
                if (iron && iron !== undefined && checkIron.length < 1) {
                    irons.push({
                        option_id: iron.option_id,
                        option_term: iron.option_term
                    })
                }
                const tipDiameter = variation.variation.find(variationItem => variationItem.attribute_name === "Tip Diameter");
                const checkTipDiameter = tipDiameterData.filter(ironItem => ironItem.option_id === tipDiameter.option_id)
                if (tipDiameter && tipDiameter !== undefined && checkTipDiameter.length < 1) {
                    tipDiameterData.push({
                        option_id: tipDiameter.option_id,
                        option_term: tipDiameter.option_term
                    })
                }
            }
        }
        setFlex(e.target.value);
        setIronData(irons)
        setTipData(tipDiameterData);
    }

    const onChangeIron = (e) => {
        setFlex("");
        // setTip("");
        let flexs = [];
        let tipDiameterData = [];

        for (const variation of selectedProduct.variations) {
            const IronCode = variation.variation.find(variationItem => variationItem.option_id === e.target.value);
            if (IronCode && IronCode !== undefined) {
                const flex = variation.variation.find(variationItem => variationItem.attribute_name === "Flex");
                const checkIron = flexs.filter(ironItem => ironItem.option_id === flex.option_id)
                if (flex && flex !== undefined && checkIron.length < 1) {
                    flexs.push({
                        option_id: flex.option_id,
                        option_term: flex.option_term
                    })
                }
                const tipDiameter = variation.variation.find(variationItem => variationItem.attribute_name === "Tip Diameter");
                const checkTipDiameter = tipDiameterData.filter(ironItem => ironItem.option_id === tipDiameter.option_id)
                if (tipDiameter && tipDiameter !== undefined && checkTipDiameter.length < 1) {
                    tipDiameterData.push({
                        option_id: tipDiameter.option_id,
                        option_term: tipDiameter.option_term
                    })
                }
            }
        }
        setIron(e.target.value);
        setFlexData(flexs)
        setTipData(tipDiameterData);
    }

    const onChangeTip = (e) => {
        setTip(e.target.value);
    }

    const compareUI = useMemo(() => {
        return (
            <>
                {(category?.slug === 'iron')  ?
                    (<>
                        <div className='customSelect'>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={onChangeIron}
                                value={iron ?? ''}
                                required

                            >
                                <option key="iron" value="">Iron #</option>
                                {selectedProduct?.ironData?.map(iron => < option key={iron.option_id} value={iron.option_id}>{iron.option_term}</option>)}
                            </Form.Select>
                        </div>
                        <div className='customSelect'>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={onChangeFlex}
                                value={flex ?? ''}
                                required

                            >
                                <option key="flex" value="">Flex Code</option>
                                {flexData?.map(flex => < option key={flex.option_id} value={flex.option_id}>{flex.option_term}</option>)}
                            </Form.Select>
                        </div>
                        <div className='customSelect'>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={onChangeTip}
                                value={tip ?? ''}
                                required

                            >
                                <option key="Tip Diameter" value="">Tip Diameter</option>
                                {tipData?.map(tip => < option key={tip.option_id} value={tip.option_id}>{tip.option_term}</option>)}
                            </Form.Select>
                        </div>
                    </>)
                    :
                    (<>
                        <div className='customSelect'>
                            <Form.Select
                                aria-label="Default select example"
                                onChange={onChangeFlex}
                                value={flex ?? ''}
                                required

                            >
                                <option key="flex" value="">Flex Code</option>
                                {selectedProduct?.flexData?.map(flex => < option key={flex.option_id} value={flex.option_id}>{flex.option_term}</option>)}
                            </Form.Select>
                        </div>
                    </>)
                }
            </>
        )
    }, [categories, tipData, ironData, flexData, category, tip])

    return <div className='headtwo'>
        <h5>{SelectTitle}</h5>
        <div className='selectbrand-outer'>

            <div className='conmpareproductimgOuter'>
                <img src="/images/shaft.png" alt='#' />
            </div>
            <div className='select-itembox'>
                <div className='customSelect'>
                    <Form.Select
                        onChange={onChangeCategory}
                        aria-label="Default select example"
                        value={category?._id}
                        required
                    >
                        <option key="select_cat" value="">Club Types</option>
                        {categories.map(category => < option key={category._id} value={category._id}>{category.name}</option>)}
                    </Form.Select>
                </div>
                <div className='customSelect'>
                    <Form.Select
                        aria-label="Default select example"
                        onChange={onChangeProduct}
                        required
                        value={selectedProduct?.slug ?? ''}
                    >
                        <option key="prod" value="">Products</option>
                        {category?.products?.map(product => < option key={product._id} value={product.slug}>{product.product_title}</option>)}
                    </Form.Select>
                </div>
                {compareUI}
            </div>
        </div>
    </div >
}

export default SelectProduct;
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, ProgressBar, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Link, useLocation, useParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';

const DriverSelectorIron = () => {
    const { product_category } = useParams();
    const productSelector = useSelector(getProductSelector);
    const location = useLocation();
    const parts = location?.pathname.split("/");
    const endString = parts[parts.length - 1];
    const [cmsData, setCmsData] = useState();
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: endString,
    });

    const dispatch = useDispatch();
    const now = 14.28;
    const [showModal, setShowModal] = useState(false);
    const [check, setCheck] = useState(false);
    const [config, setConfig] = useState('3 - P');
    const [wedges, setWedges] = useState({
        wedges_1: "",
        wedges_2: "",
        wedges_3: "",
        wedges_4: "",
    });

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);
    
    useEffect(() => {
        let setProduct = {
            ...productSelector,
            category: product_category,
            questions: { ...productSelector.questions, config: config, one_length_player: check, wedges: wedges },
        }
        dispatch(setProductSelector(setProduct))
    }, [product_category, config, check, wedges])

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    const handleClick = useCallback((e) => {
        let data = e.currentTarget.getAttribute("data-id");
        setConfig(data);
    }, [config]);

    const handleClickWedges = useCallback((e) => {
        if (e.target.checked) {
            let data = e.currentTarget.getAttribute("data-id");
            setWedges({ ...wedges, [e.target.name]: data });
        } else {
            setWedges({ ...wedges, [e.target.name]: '' });
        }
    }, [wedges]);

    const handleCheck = useCallback(() => {
        setCheck(!check);
    }, [check]);

    const handleModal = () => {
        setShowModal(!showModal);
    }

    return <>
        <section className="Driver-selecter-outer facility-outer irons-tabs pb-0">
            <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="technology-mat-content text-center"><h2>{cmsData?.page_title}</h2><h4>{cmsData?.page_subtitle}</h4><hr /></div>
                    </Col>
                    <Col md="12">
                        <div className="iron-content text-center">
                            <ProgressBar now={now} label={`${now}%`} visuallyHidden />
                            <p className="questions">QUESTION 1/7</p>
                            <h4>What is your Set Configuration?</h4>
                            <span className="selectone">Please select one.</span>
                            <div className="labes-type">
                                <div className="lab-outer">
                                    <input type="radio" id="configuration6" name="Configuration" value="configuration6" onClick={handleClick} data-id="Utility Irons" />
                                    <label for="configuration6">Utility Irons (1-2-3)</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration1" name="Configuration" value="configuration1" onClick={handleClick} data-id="3 - P" defaultChecked />
                                    <label for="configuration1">3 – P</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration2" name="Configuration" value="configuration2" onClick={handleClick} data-id="4 - P" />
                                    <label for="configuration2">4 – P</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration3" name="Configuration" value="configuration3" onClick={handleClick} data-id="5 - P" />
                                    <label for="configuration3">5 – P</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="radio" id="configuration4" name="Configuration" value="configuration4" onClick={handleClick} data-id="6 - P" />
                                    <label for="configuration4">6 – P</label>
                                </div>
                                {/* <div className="lab-outer">
                                    <input type="radio" id="configuration5" name="Configuration" value="configuration5" onClick={handleClick} data-id="Wedges (AW/GW, SW, LW)" />
                                    <label for="configuration5">Wedges (AW/GW, SW, LW)</label>
                                </div> */}
                            </div>
                            <div style={{margin: "-20px 0px"}}>
                                <span className="selectone"> <span style={{fontSize: "30px", fontWeight: "700"}}>Wedges?</span> <span style={{fontSize: "13px", fontWeight: "500"}}>(You may select multiple)</span></span>
                            </div>
                            <div className="labes-type">
                                <div className="lab-outer">
                                    <input type="checkbox" id="configuration1" name="wedges_1" value="configuration1" onClick={handleClickWedges} data-id="AW" />
                                    <label for="configuration1">AW</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="checkbox" id="configuration2" name="wedges_2" value="configuration2" onClick={handleClickWedges} data-id="GW" />
                                    <label for="configuration2">GW</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="checkbox" id="configuration3" name="wedges_3" value="configuration3" onClick={handleClickWedges} data-id="SW" />
                                    <label for="configuration3">SW</label>
                                </div>
                                <div className="lab-outer">
                                    <input type="checkbox" id="configuration4" name="wedges_4" value="configuration4" onClick={handleClickWedges} data-id="LW" />
                                    <label for="configuration4">LW</label>
                                </div>
                            </div>
                            <div className='checkouter' style={{margin: "-10px 0px"}}>
                                <div>
                                    <span className="custom_check">
                                        I am a one-length iron player. <BsFillInfoCircleFill onClick={handleModal} />
                                        <input data-fitting-id="acknowledgementChecked" onClick={handleCheck} type="checkbox" value={check} />
                                        <span className="check_indicator">
                                            &nbsp;
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="back-next">
                                <Link to="/kinetixx-fit" >Go Back</Link>
                                <Link to="/kinetixx-fit-iron-two/iron" className="btn-primary">Next Question</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal show={showModal} onHide={handleModal} centered>
                <Modal.Header className="border-bottom">
                    <Modal.Title style={{ fontSize: "larger" }}>Description of One-length iron player</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-2" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                    A "one-length iron player" is a golfer who uses irons that are all the same length,
                    as opposed to the traditional set of irons with varying lengths.
                    The idea behind one-length irons is to simplify the swing mechanics by using a consistent setup, stance, and swing for every iron in the set.
                    This approach aims to provide more consistency in ball striking and potentially improve overall performance.
                </Modal.Body>
                <Modal.Footer>
                    <button
                        variant="primary"
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleModal}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </section>
    </>
}

export default DriverSelectorIron;
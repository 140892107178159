import { createSlice, current } from '@reduxjs/toolkit';
import { AuthAPI } from '../apis/authApi';
import { CartAPI } from '../apis/cartApi';
import { ProductDetailAPI } from '../apis/productDetailApi';
import { SiteDataAPI } from '../apis/siteDataApi';
const initialState = {
  categories: [],
  products: [],
  videoList: [],
  socialSetting: [],
  attributes: [],
  fittings: [],
  cartCount: 0,
  specs: [],
  checkoutProcess: [],
  loading: false,
  modelData: {},
  myWishList: [],
  requests: [],
  raw_spec: [],
  gearCategories: [],
  flexCode: [],
  ironNumber: [],
  termCMS: [],
  shippingCMS: [],
  productSelector: {
    shaft_type: 'normal_shaft',
    category: '',
    sale_closed: false,
    product_selector_result_id: '',
    questions: {
      swing_speed: "",
      check_swing_speed: '',
      priority: "",
      launch_angle: "",
      spin: "",
      attack_angle: "",
      tempo: "",
      trajectory: "",
      shaft_weight: "",
      current_Weight: "",
      iron_shaft_weight: "",
      config: "",
      ball_flight: "",
      one_length_player: false,
      wedges: {
        wedges_1: "",
        wedges_2: "",
        wedges_3: "",
        wedges_4: "",
      },
    },
  },
  productGraph: [],
  exploreFitting: null,
};

const setNewFittings = (fittingKey, key, oldFittings, variationData) => {
  const newSpecs = [];
  oldFittings.map((spec) => {
    const specObj = { ...spec };
    const data = [];
    spec.data.map((option) => {
      const opt = { ...option };
      const fittingData = variationData[fittingKey]?.find(
        (tech) => tech.spec_id === option._id
      );
      if (fittingData) {
        opt[key] = fittingData?.option_term;
      } else if (key in opt) {
        delete opt[key];
      }
      data.push(opt);
    });
    specObj.data = data;
    newSpecs.push(specObj);
  });
  return newSpecs;
};

const isPendingAction = (action) => {
  return action.type.endsWith('/pending');
};

const hadleRequestFinish = (state, action) => {
  let requests = [...state.requests];

  const { requestId } = action.meta;

  const reqIndex = requests.findIndex((re) => re === requestId);

  if (reqIndex >= 0) {
    requests = state.requests.splice(reqIndex, 1);
    state.requests = requests;
    if (requests.length) {
      state.loading = false;
    }
  }
};

const SiteDataSlices = createSlice({
  name: 'siteData',
  initialState,
  reducers: {
    setProductSelector: (state, { payload }) => {
      state.productSelector = payload;
    },
    setProductGraph: (state, { payload }) => {
      state.productGraph = payload;
    },
    setExploreFitting: (state, { payload }) => {
      state.exploreFitting = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
    },
    setFittingOnSpecs: (state, { payload }) => {
      const stateData = current(state);
      const { technical_spec, fitting_spec } = stateData.specs;
      const variationData = payload.product?.variations.find((variationObj) => {
        let dataExist = variationObj?.variation.find(
          (varData) => varData.option_id === payload.flex
        );

        if (payload.iron && payload.tip) {
          const iron = variationObj?.variation.find(
            (varData) => varData.option_id === payload.iron
          );
          const tip = variationObj?.variation.find(
            (varData) => varData.option_id === payload.tip
          );
          dataExist = iron && tip;
        }
        return dataExist ? true : false;
      });

      const newBasic = setNewFittings(
        'technical_specs',
        payload.key,
        technical_spec,
        variationData
      );
      const newFittings = setNewFittings(
        'fitting_specs',
        payload.key,
        fitting_spec,
        variationData
      );

      const prod = { ...payload.product, skuId: variationData.sku };

      state.specs = {
        ...stateData.specs,
        technical_spec: newBasic,
        fitting_spec: newFittings,
        [payload.key]: prod,
      };
    },
    setCheckoutProcess: (state, { payload }) => {
      if (!state.checkoutProcess.includes(payload))
        state.checkoutProcess.push(payload);
    },
    showModel: (state, { payload }) => {
      state.modelData = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        SiteDataAPI.endpoints.getSiteData.matchFulfilled,
        (state, { payload }) => {
          if (payload.status) {
            const wishList = [];
            const {
              categories,
              specs,
              products,
              videoList,
              socialSetting,
              attributes,
              fitting,
              cartCount,
              flexCode,
              ironNumber,
              termCMS,
              shippingCMS,
            } = payload.response;

            const allCategories = categories.map((cat) => {
              let productData = products
                ?.filter((product) => cat._id === product.categoryId)
                .sort((a, b) => {
                  const regex = /(\d+)g/;
                  const nameA = a.product_title.toLowerCase().replace(regex, '').trim();
                  const nameB = b.product_title.toLowerCase().replace(regex, '').trim();

                  if (nameA !== nameB) {
                    return nameA.localeCompare(nameB);
                  } else {
                    const numA = a.product_title.toLowerCase().match(regex) ? parseInt(a.product_title.toLowerCase().match(regex)[1]) : 0;
                    const numB = b.product_title.toLowerCase().match(regex) ? parseInt(b.product_title.toLowerCase().match(regex)[1]) : 0;
                    return numA - numB;
                  }
                });
              productData = productData?.map((prod) => {
                const flexData = [];
                const flexAttributes = [];
                const ironData = [];
                const ironAttributes = [];
                const tipData = [];
                const tipAttributes = [];

                if (prod.is_in_wishlist) wishList.push(prod._id);
                prod.variations.map((variation) => {
                  const data = variation.variation?.find(
                    (data) => data.attribute_name.toLowerCase() === 'flex'
                  );
                  if (data && !flexAttributes.includes(data.option_id)) {
                    flexAttributes.push(data.option_id);
                    flexData.push(data);
                  }

                  const ironDataNew = variation.variation?.find(
                    (data) => data.attribute_name.toLowerCase() === 'iron'
                  );
                  if (
                    ironDataNew &&
                    !ironAttributes.includes(ironDataNew.option_id)
                  ) {
                    ironAttributes.push(ironDataNew.option_id);
                    ironData.push(ironDataNew);
                  }
                  const tipDataNew = variation.variation?.find(
                    (data) =>
                      data.attribute_name.toLowerCase() === 'tip diameter'
                  );
                  if (
                    tipDataNew &&
                    !tipAttributes.includes(tipDataNew.option_id)
                  ) {
                    tipAttributes.push(tipDataNew.option_id);
                    tipData.push(tipDataNew);
                  }
                });

                const uniqueflexData = [
                  ...new Set(
                    flexData.map((item) => ({
                      option_id: item.option_id,
                      option_term: item.option_term,
                    }))
                  ),
                ];

                const uniqueIronData = [
                  ...new Set(
                    ironData.map((item) => ({
                      option_id: item.option_id,
                      option_term: item.option_term,
                    }))
                  ),
                ];
                const uniqueTipData = [
                  ...new Set(
                    tipData.map((item) => ({
                      option_id: item.option_id,
                      option_term: item.option_term,
                    }))
                  ),
                ];

                return {
                  ...prod,
                  flexData: uniqueflexData,
                  ironData: uniqueIronData,
                  tipData: uniqueTipData,
                };
              });
              return {
                ...cat,
                products: productData,
              };
            });

            state.categories = allCategories.filter(
              (cat) => cat.categoryType === 'SHAFTS'
            );
            state.gearCategories = allCategories.filter(
              (cat) => cat.categoryType === 'OTHER'
            );

            state.myWishList = wishList;
            state.products = products;
            state.videoList = videoList;
            state.socialSetting = socialSetting;
            state.attributes = attributes;
            state.cartCount = cartCount;

            // Save the Flex Code and Iron Number Nomenclature data
            state.flexCode = flexCode;
            state.ironNumber = ironNumber;

            // Save the Term CMS and Shipping & Return CMS Data
            state.termCMS = termCMS;
            state.shippingCMS = shippingCMS;

            state.specs = (specs ?? [])?.reduce((specifications, item) => {
              specifications[item.spec_type] =
                [
                  ...(specifications[item.spec_type] ?? []),
                  ...item.spec_data,
                ] || [];
              return specifications;
            }, {});

            state.raw_spec = (specs ?? [])?.reduce((specifications, item) => {
              specifications[item.spec_type] = [
                ...(specifications[item.spec_type] ?? []),
                item,
              ];

              return specifications;
            }, {});

            let filteredFitting = fitting.map((ftr) => ({
              ...ftr,
              fittings: ftr.fittings
                .map((ftmp) => ({
                  ...ftmp,
                  options: ftmp.options.filter(
                    (op) =>
                      op.status &&
                      (ftr.title.includes('grip') ? op.inventory >= 1 : true)
                  ),
                }))
                .filter(
                  (fttng) =>
                    fttng.status &&
                    (ftr.title.includes('adaptor')
                      ? fttng.inventory >= 1
                      : true)
                ),
            }));
            filteredFitting = filteredFitting.map((fitting) => {
              if (fitting.title == "Playing Length") {
                let fits = fitting?.fittings;

                let fitsNew = fits.sort((a, b) => parseFloat(a?.title) - parseFloat(b?.title));
                fitsNew = fitsNew.filter((fit) => !fit?.isDeleted);

                return { ...fitting, fittings: [...fitsNew] }
              } else {
                return { ...fitting };
              }
            })
            state.fittings = filteredFitting;
          }
        }
      )
      .addMatcher(
        CartAPI.endpoints.addToCart.matchFulfilled,
        (state, { payload }) => {
          if (payload.status) {
            state.cartCount = payload.response.count;
          }
        }
      )
      .addMatcher(
        AuthAPI.endpoints.doLogin.matchFulfilled,
        (state, { payload }) => {
          if (payload.status) {
            const { user } = payload.response;
            const { productMerge } = user;
            if (productMerge?.productMerge) {
              state.modelData = {
                isOpen: true,
                title: 'Cart Merged',
                // body: `We have merged your cart with ${productMerge?.message ? productMerge?.message : productMerge?.item} ${productMerge?.message ? 'quantity' : 'items'} which you have added previously.`,
                body: `We have merged your cart with products previously added by you.`,
              };
            }
            state.cartCount = user.cartCount;
          }
        }
      )
      .addMatcher(
        CartAPI.endpoints.getCart.matchFulfilled,
        (state, { payload }) => {
          if (payload.status) {
            const { count } = payload.response;
            state.cartCount = count;
          }
        }
      )
      .addMatcher(isPendingAction, (state, data) => {
        state.requests = [...state.requests, data.meta.requestId];
        //state.loading = true;
      })
      .addMatcher(
        // matcher can be defined inline as a type predicate function
        (action) => action.type.endsWith('/rejected'),
        //hadleRequestFinish
      )
      // matcher can just return boolean and the matcher can receive a generic argument
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled'),
        //hadleRequestFinish
      )
      .addMatcher(
        CartAPI.endpoints.createOrder.matchRejected,
        (state, { payload }) => {
          if (payload.status === 403) {
            state.modelData = {
              isOpen: true,
              modelType: 'danger',
              title: 'Error!',
              body: payload.data.message,
            };
          }
        }
      )
      .addMatcher(
        ProductDetailAPI.endpoints.addRemoveWishItem.matchFulfilled,
        (state, { meta, payload }) => {
          const { productId, addRemove } = meta.arg.originalArgs;
          const wishList = state.myWishList;
          if (addRemove && !wishList.includes(productId)) {
            wishList.push(productId);
            state.myWishList = wishList;
          } else if (!addRemove && wishList.includes(productId)) {
            wishList.splice(
              state.myWishList.findIndex((id) => id === productId),
              1
            );
            state.myWishList = wishList;
          }
        }
      );
  },
});

const { reducer, actions } = SiteDataSlices;

export const { setFittingOnSpecs, setCheckoutProcess, showModel, setLoading, setProductSelector, setExploreFitting, setProductGraph } =
  actions;

export const getCategoryList = (state) => state.siteData.categories;
export const getGearCategoryList = (state) => state.siteData.gearCategories;
export const getProductsList = (state) => state.siteData.products;
export const getVideoList = (state) => state.siteData.videoList;
export const getSocialSetting = (state) => state.siteData.socialSetting;
export const getAttributesList = (state) => state.siteData.attributes;
export const getFittingList = (state) => state.siteData.fittings;
export const getCartCount = (state) => state.siteData.cartCount;
export const getModelData = (state) => state.siteData.modelData;
export const getSpecs = (state) => state.siteData.specs;
export const getRawSpecs = (state) => state.siteData.raw_spec;
export const getLoading = (state) => state.siteData.loading;
export const getWishList = (state) => state.siteData.myWishList;
export const getProductSelector = (state) => state.siteData.productSelector;
export const getProductGraphDetail = (state) => state.siteData.productGraph;
export const getExploreFitting = (state) => state.siteData.exploreFitting;


export default reducer;

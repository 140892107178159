import { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { Range } from 'react-range';
import convert from 'convert-units';
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';
//state = { values: [50] };
const DriverSelector = () => {
    const { product_category } = useParams();
    const productSelector = useSelector(getProductSelector);
    const location = useLocation();
    const parts = location?.pathname.split("/");
    const endString = parts[parts.length - 1];
    const [cmsData, setCmsData] = useState();
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: endString,
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rangeSlider, setRangeSlider] = useState({
        averageDistance: [6],
        ballFlight: [3],
        trajectory: [3],
        desiredSpin: [3],
        clubHead: [2],
        shotConsistency: [2]
    });
    const [showImage, setShowImage] = useState(false);
    const [activeBar, setActiveBar] = useState('');
    const [parameter, setParameter] = useState(false);
    const [ballFlightText, setBallFlightText] = useState("70mph - 80mph");
    const [convertedText, setConvertedText] = useState('152 km/h')
    const [trajectoryText, setTrajectoryText] = useState("Low");
    const [addTrajectoryText, setAddAddTrajectoryText] = useState('Low');
    const [launchAngleText, setLaunchAngleText] = useState("Low");
    const [desiredSpinText, setDesiredSpinText] = useState("Low");
    const [toolTipText, setToolTipText] = useState(null);
    const [currentToolTipText, setCurrentToolTipText] = useState(null);
    const [showTooltipText, setShowTooltipText] = useState(false);

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    useEffect(() => {
        let setProduct = {
            ...productSelector,
            category: product_category,
            questions: { ...productSelector.questions, swing_speed: ballFlightText, trajectory: trajectoryText, launch_angle: launchAngleText, spin: desiredSpinText, metric: parameter },
        }
        dispatch(setProductSelector(setProduct))
    }, [product_category, ballFlightText, trajectoryText, desiredSpinText, launchAngleText, parameter])

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);
    useEffect(() => {
        let ballFlightValue = rangeSlider.averageDistance[0];
        if (parameter) {
            switch (ballFlightValue) {
                case 1:
                    setConvertedText(Math.trunc(convert(70).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 2:
                    setConvertedText(Math.trunc(convert(75).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 3:
                    setConvertedText(Math.trunc(convert(80).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 4:
                    setConvertedText(Math.trunc(convert(85).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 5:
                    setConvertedText(Math.trunc(convert(90).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 6:
                    setConvertedText(Math.trunc(convert(95).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 7:
                    setConvertedText(Math.trunc(convert(100).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 8:
                    setConvertedText(Math.trunc(convert(105).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 9:
                    setConvertedText(Math.trunc(convert(110).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 10:
                    setConvertedText(Math.trunc(convert(115).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
                case 11:
                    setConvertedText(Math.trunc(convert(120).from('m/h').to('km/h')) + ' ' + 'km/h');
                    break;
            }
        } else {
            switch (ballFlightValue) {
                case 1:
                    setBallFlightText("70mph");
                    setToolTipText(`A swing speed of 70mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 2:
                    setBallFlightText("75mph");
                    setToolTipText(`A swing speed of 75mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 3:
                    setBallFlightText("80mph");
                    setToolTipText(`A swing speed of ${ballFlightText} in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 4:
                    setBallFlightText("85mph");
                    setToolTipText(`A swing speed of 80mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 5:
                    setBallFlightText("90mph");
                    setToolTipText(`A swing speed of 90mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 6:
                    setBallFlightText("95mph");
                    setToolTipText(`A swing speed of 95mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 7:
                    setBallFlightText("100mph");
                    setToolTipText(`A swing speed of 100mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 8:
                    setBallFlightText("105mph");
                    setToolTipText(`A swing speed of 105mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 9:
                    setBallFlightText("110mph");
                    setToolTipText(`A swing speed of 110mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 10:
                    setBallFlightText("115mph");
                    setToolTipText(`A swing speed of 115mph in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
                case 11:
                    setBallFlightText("120mph+");
                    setToolTipText(`A swing speed of 120mph and greater than in golf refers to the velocity at which the clubhead strikes the ball.`)
                    break;
            }
        }
    }, [rangeSlider.averageDistance[0], parameter])

    useEffect(() => {
        setActiveBar("trajectory");
        let trajectory1 = rangeSlider.trajectory[0];
        switch (trajectory1) {
            case 1:
                setTrajectoryText("Low");
                setToolTipText('A low trajectory in golf shafts refers to a ball flight that stays relatively close to the ground, resulting in a flatter and more penetrating shot with potential for increased roll and distance.')
                break;
            case 2:
                setTrajectoryText("Low-Mid");
                setToolTipText('A low-mid trajectory in golf shafts refers to a ball flight that strikes a balance between a flatter and higher shot, offering a versatile trajectory for distance and control.')
                break;
            case 3:
                setTrajectoryText("Mid");
                setToolTipText('A mid trajectory in golf shafts refers to a balanced ball flight that offers a moderate height, providing a good compromise between distance and control.')
                break;
            case 4:
                setTrajectoryText("Mid-High");
                setToolTipText('A mid-high trajectory in golf shafts refers to a ball flight that reaches a higher peak and offers increased carry distance and potential for softer landings on the greens.')
                break;
            case 5:
                setTrajectoryText("High");
                setToolTipText('A high trajectory in golf shafts refers to a ball flight that reaches a significant height, providing increased carry distance and a steeper descent angle for better stopping power on the greens.')
                break;
        }
    }, [rangeSlider.trajectory[0]])

    useEffect(() => {
        if (launchAngleText === 'Low') {
            setAddAddTrajectoryText(`Low (< 11\u00B0)`)
        }
        if (launchAngleText === 'Low-Mid') {
            setAddAddTrajectoryText(`Low-Mid (12\u00B0-13\u00B0)`)
        }
        if (launchAngleText === 'Mid') {
            setAddAddTrajectoryText(`Mid (14\u00B0)`)
        }
        if (launchAngleText === 'Mid-High') {
            setAddAddTrajectoryText(`Mid-High (15\u00B0-16\u00B0)`)
        }
        if (launchAngleText === 'High') {
            setAddAddTrajectoryText(`High (> 17\u00B0)`)
        }
    }, [launchAngleText])

    useEffect(() => {
        setActiveBar("launch");
        let launchAngle = rangeSlider.ballFlight[0];
        switch (launchAngle) {
            case 1:
                setLaunchAngleText("Low");
                setToolTipText('A low launch angle in golf shafts refers to a smaller angle of ascent for the golf ball, resulting in a lower initial trajectory and potentially flatter ball flight.')
                break;
            case 2:
                setLaunchAngleText("Low-Mid");
                setToolTipText('A low-mid launch angle in golf shafts refers to a moderate angle of ascent for the golf ball, resulting in a balanced trajectory that is neither too high nor too low.')
                break;
            case 3:
                setLaunchAngleText("Mid")
                setToolTipText('A mid launch angle in golf shafts refers to a moderate angle of ascent for the golf ball, resulting in a balanced trajectory that offers a good combination of distance and control.')
                break;
            case 4:
                setLaunchAngleText("Mid-High");
                setToolTipText('A mid-high launch angle in golf shafts refers to a higher angle of ascent for the golf ball, resulting in a higher initial trajectory and potential for greater carry distance.')
                break;
            case 5:
                setLaunchAngleText("High");
                setToolTipText('A high launch angle in golf shafts refers to a significant angle of ascent for the golf ball, resulting in a higher initial trajectory and potentially maximizing carry distance while sacrificing some control.')
                break;
        }
    }, [rangeSlider.ballFlight[0]])

    useEffect(() => {
        let desiredSpinn = rangeSlider.desiredSpin[0];
        switch (desiredSpinn) {
            case 1:
                setDesiredSpinText("Low");
                setToolTipText('A low spin value in golf shafts refers to a reduced backspin on the golf ball, resulting in a flatter trajectory and potentially maximizing distance but sacrificing some stopping power on the greens.')
                break;
            case 2:
                setDesiredSpinText("Low-Mid");
                setToolTipText('A low-mid spin value in golf shafts refers to a moderate amount of backspin on the golf ball, striking a balance between distance and control for versatile shot-making.')
                break;
            case 3:
                setDesiredSpinText("Mid");
                setToolTipText('A mid spin value in golf shafts refers to a moderate amount of backspin on the golf ball, providing a balance between distance and control for well-rounded shot performance.')
                break;
            case 4:
                setDesiredSpinText("Mid-High");
                setToolTipText('A mid-high spin value in golf shafts refers to a relatively higher amount of backspin on the golf ball, offering increased control and stopping power but potentially sacrificing some distance.')
                break;
            case 5:
                setDesiredSpinText("High");
                setToolTipText('A high spin value in golf shafts refers to a significant amount of backspin on the golf ball, providing enhanced control and stopping power but potentially limiting distance due to increased spin.')
                break;
        }
    }, [rangeSlider.desiredSpin[0]])

    const onChangeValue = () => {
        setParameter(!parameter);
    }

    const onMouseLeaveToolTip = () => {
        setShowTooltipText(false)
    }

    return <>
        <section className="Driver-selecter-outer facility-outer p-0">
            {/* <Container>
                <Row className="justify-content-center">
                    <Col md="12">
                        <div className="technology-mat-content text-center">
                            <h2>Find the best {product_category} for your game</h2>
                            <h4>Adjust the sliders to reflect your current game and goals. Once set, simply hit the recommend button.</h4>
                            <hr />
                        </div>
                    </Col>
                </Row>
            </Container> */}
            <div className="application stepone">
                <div className="technology-mat-content text-center">
                    <h2>{cmsData?.page_title}</h2>
                    <h4>{cmsData?.page_subtitle}</h4>
                    <hr className="mb-0" />
                </div>
                <div className="player-content-outer">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="12">
                                <div className="player-content">
                                    <div className={`landing-spot low-${(activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0])} size-2 distance-${rangeSlider.averageDistance[0]}`}></div>
                                    <div className="paths">
                                        <div className={`path high-200 ${rangeSlider.averageDistance[0] === 1 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-210 ${rangeSlider.averageDistance[0] === 2 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-220 ${rangeSlider.averageDistance[0] === 3 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-230 ${rangeSlider.averageDistance[0] === 4 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-240 ${rangeSlider.averageDistance[0] === 5 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-250 ${rangeSlider.averageDistance[0] === 6 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-260 ${rangeSlider.averageDistance[0] === 7 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-270 ${rangeSlider.averageDistance[0] === 8 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-280 ${rangeSlider.averageDistance[0] === 9 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-290 ${rangeSlider.averageDistance[0] === 10 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>
                                        <div className={`path high-300 ${rangeSlider.averageDistance[0] === 11 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 5 ? "selected" : ""}`}></div>

                                        <div className={`path mid-high-200 ${rangeSlider.averageDistance[0] === 1 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-210 ${rangeSlider.averageDistance[0] === 2 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-220 ${rangeSlider.averageDistance[0] === 3 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-230 ${rangeSlider.averageDistance[0] === 4 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-240 ${rangeSlider.averageDistance[0] === 5 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-250 ${rangeSlider.averageDistance[0] === 6 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-260 ${rangeSlider.averageDistance[0] === 7 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-270 ${rangeSlider.averageDistance[0] === 8 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-280 ${rangeSlider.averageDistance[0] === 9 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-290 ${rangeSlider.averageDistance[0] === 10 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>
                                        <div className={`path mid-high-300 ${rangeSlider.averageDistance[0] === 11 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 4 ? "selected" : ""}`}></div>

                                        <div className={`path medium-200 ${rangeSlider.averageDistance[0] === 1 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-210 ${rangeSlider.averageDistance[0] === 2 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-220 ${rangeSlider.averageDistance[0] === 3 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-230 ${rangeSlider.averageDistance[0] === 4 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-240 ${rangeSlider.averageDistance[0] === 5 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-250 ${rangeSlider.averageDistance[0] === 6 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-260 ${rangeSlider.averageDistance[0] === 7 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-270 ${rangeSlider.averageDistance[0] === 8 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-280 ${rangeSlider.averageDistance[0] === 9 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-290 ${rangeSlider.averageDistance[0] === 10 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>
                                        <div className={`path medium-300 ${rangeSlider.averageDistance[0] === 11 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 3 ? "selected" : ""}`}></div>

                                        <div className={`path low-200 ${rangeSlider.averageDistance[0] === 1 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-210 ${rangeSlider.averageDistance[0] === 2 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-220 ${rangeSlider.averageDistance[0] === 3 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-230 ${rangeSlider.averageDistance[0] === 4 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-240 ${rangeSlider.averageDistance[0] === 5 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-250 ${rangeSlider.averageDistance[0] === 6 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-260 ${rangeSlider.averageDistance[0] === 7 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-270 ${rangeSlider.averageDistance[0] === 8 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-280 ${rangeSlider.averageDistance[0] === 9 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-290 ${rangeSlider.averageDistance[0] === 10 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>
                                        <div className={`path low-300 ${rangeSlider.averageDistance[0] === 11 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 1 ? "selected" : ""}`}></div>

                                        <div className={`path low-mid-200 ${rangeSlider.averageDistance[0] === 1 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-210 ${rangeSlider.averageDistance[0] === 2 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-220 ${rangeSlider.averageDistance[0] === 3 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-230 ${rangeSlider.averageDistance[0] === 4 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-240 ${rangeSlider.averageDistance[0] === 5 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-250 ${rangeSlider.averageDistance[0] === 6 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-260 ${rangeSlider.averageDistance[0] === 7 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-270 ${rangeSlider.averageDistance[0] === 8 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-280 ${rangeSlider.averageDistance[0] === 9 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-290 ${rangeSlider.averageDistance[0] === 10 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>
                                        <div className={`path low-mid-300 ${rangeSlider.averageDistance[0] === 11 && (activeBar === 'trajectory' ? rangeSlider.trajectory[0] : rangeSlider.ballFlight[0]) === 2 ? "selected" : ""}`}></div>

                                    </div>
                                    <div className={`clubheads ${showImage && rangeSlider.clubHead[0] !== 1 ? "selected" : ""}`} id="clubheads">
                                        <div className="circle"></div>
                                        <div className="line"></div>
                                        <div className="border-box">
                                            <div className="photo">
                                                <div className="clubhead" id="clubhead-1"></div>
                                                <div className={`clubheads ${rangeSlider.clubHead[0] === 2 ? "selected" : ""}`} id="clubhead-2">
                                                    <div className="title">Rounded Profile</div>
                                                    <div className="image"></div>
                                                </div>
                                                <div className={`clubheads ${rangeSlider.clubHead[0] === 3 ? "selected" : ""}`} id="clubhead-3">
                                                    <div className="title">Pear Profile</div>
                                                    <div className="image"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="player-image" id="player"></div>
                                </div>
                                <div className="sliders" id="sliders">
                                    <div className="border-box metricChange">
                                        <div className="changeMetirc">
                                            <span className="custom_check">
                                                {/* {parameter ? "Metric Scale" : "Imperial Scale"} */}
                                                Metric Scale
                                                <input
                                                    type="checkbox"
                                                    checked={parameter}
                                                    onChange={onChangeValue}
                                                />
                                                <span className="check_indicator">&nbsp;</span>
                                            </span>
                                            {/* <Link
                                                value={parameter}
                                                onClick={onChangeValue}
                                            >
                                                <>{parameter ? "Imperial Scale" : "Metric Scale"} </>
                                            </Link> */}
                                        </div>
                                        <div className="slider average-dis" id="q1-slider">
                                            <div className="title">Average Swing Speed</div>
                                            {/* {showTooltipText && currentToolTipText === 'swing' &&
                                                <div className="tooltipSelector">
                                                    {toolTipText}
                                                </div>
                                            } */}
                                            <div className="labels">
                                                <div className="start-label extra">{parameter ? convertedText : ballFlightText}</div>
                                            </div>
                                            <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                                <Range
                                                    step={1}
                                                    min={1}
                                                    max={11}
                                                    values={rangeSlider.averageDistance}
                                                    onChange={(values) => {
                                                        setRangeSlider({ ...rangeSlider, averageDistance: values })
                                                        setShowTooltipText(true)
                                                        setCurrentToolTipText('swing')
                                                    }}
                                                    renderTrack={({ props, children }) => (
                                                        <div className={rangeSlider.averageDistance[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div className="inner-label">
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                            </div>
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div className="thumb-dots"
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                height: '42px',
                                                                width: '52px',
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className="label-item">
                                                    <span className="label-item-inner">{parameter ? '112 km/h' : '70mph'}</span>
                                                    <span className="label-item-inner">{parameter ? '193 km/h' : '120mph+'}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="slider launch-angle" id="q2-slider" >
                                            <div className="title">Desired Launch Angle/Trajectory</div>
                                            {/* {showTooltipText && currentToolTipText === 'launch' &&
                                                <div className="tooltipSelector">
                                                    {toolTipText}
                                                </div>
                                            } */}
                                            {/* <div className="labels">
                                                <div className={`start-label extra`}>{launchAngleText}</div>
                                            </div> */}
                                            <div class="labels">
                                                <div class={`start-label extra`}>{addTrajectoryText}</div>
                                            </div>
                                            <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                                <Range
                                                    step={1}
                                                    min={1}
                                                    max={5}
                                                    values={rangeSlider.ballFlight}
                                                    onChange={(values) => {
                                                        setRangeSlider({ ...rangeSlider, ballFlight: values })
                                                        setShowTooltipText(true)
                                                        setCurrentToolTipText('launch')
                                                    }}
                                                    renderTrack={({ props, children }) => (
                                                        <div className={rangeSlider.ballFlight[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div className="inner-label">
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                            </div>
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div className="thumb-dots"
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                height: '42px',
                                                                width: '52px',
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className="label-item">
                                                    <span className="label-item-inner">Low ({'<'} 11°)</span>
                                                    <span className="label-item-inner">High ({'>'} 17°)</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="slider desirdSpin" id="q3-slider">
                                            <div className="title">Desired Spin</div>
                                            <div className="labels">
                                                <div className={`start-label extra`}>{desiredSpinText}</div>
                                            </div>
                                            <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                                <Range
                                                    step={1}
                                                    min={1}
                                                    max={5}
                                                    values={rangeSlider.desiredSpin}
                                                    onChange={(values) => {
                                                        setRangeSlider({ ...rangeSlider, desiredSpin: values })
                                                        setShowTooltipText(true)
                                                        setCurrentToolTipText('spin')
                                                    }}
                                                    renderTrack={({ props, children }) => (
                                                        <div className={rangeSlider.desiredSpin[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                width: '100%',
                                                            }}

                                                        >
                                                            <div className="inner-label">
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                            </div>
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div className="thumb-dots"
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                height: '42px',
                                                                width: '52px',
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className="label-item">
                                                    <span className="label-item-inner">Low</span>
                                                    <span className="label-item-inner">High</span>
                                                </div>

                                            </div>
                                        </div>

                                        {/* <div className="slider trajectory" id="q6-slider">
                                            <div class="title">Desired Trajectory</div>
                                            <div class="labels">
                                                <div class={`start-label extra`}>{addTrajectoryText}</div>
                                            </div>
                                            <div className="range-outer" onMouseLeave={onMouseLeaveToolTip}>
                                                <Range
                                                    step={1}
                                                    min={1}
                                                    max={5}
                                                    values={rangeSlider.trajectory}
                                                    onChange={(values) => {
                                                        setRangeSlider({ ...rangeSlider, trajectory: values })
                                                        setShowTooltipText(true)
                                                        setCurrentToolTipText('trajectory')
                                                    }}
                                                    renderTrack={({ props, children }) => (
                                                        <div className={rangeSlider.trajectory[0] === 1 ? "progresh-bg" : "progresh-bg range-color"}
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <div className="inner-label">
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                                <label className="first"></label>
                                                            </div>
                                                            {children}
                                                        </div>
                                                    )}
                                                    renderThumb={({ props }) => (
                                                        <div className="thumb-dots"
                                                            {...props}
                                                            style={{
                                                                ...props.style,
                                                                height: '42px',
                                                                width: '52px',
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div className="label-item">
                                                    <span className="label-item-inner">Low ({'<'} 11°)</span>
                                                    <span className="label-item-inner">High ({'>'} 17°)</span>
                                                </div>

                                            </div>
                                        </div> */}

                                        <div className="text-center submit-outer back-next">
                                            <Link to='/kinetixx-fit'>Go Back</Link>
                                            <Link to="/kinetixx-fit-next" className="btn btn-primary">Next</Link>
                                        </div>
                                    </div>
                                    {/* <div className="text-overlay"></div> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </section>
    </>
}

export default DriverSelector;
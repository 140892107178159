
module.exports = {
  PORT: process.env.REACT_APP_PORT,
  CHOKIDAR_USEPOLLING: process.env.REACT_APP_CHOKIDAR_USEPOLLING,
  APP_NAME: process.env.REACT_APP_APP_NAME,
  DISPLAY_DATE_FORMATE: process.env.REACT_APP_DISPLAY_DATE_FORMATE,
  DEFAULT_DATE_FORMAT: process.env.REACT_APP_DEFAULT_DATE_FORMAT,
  AWS_STORAGE_URL: process.env.REACT_APP_AWS_STORAGE_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  TAWK_TO_PRODUCT_ID: process.env.REACT_APP_TAWK_TO_PRODUCT_ID,
  TAWK_TO_WIDGET_ID: process.env.REACT_APP_TAWK_TO_WIDGET_ID,
};

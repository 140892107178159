import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ProductType } from '../../../constants';
import {
  getFilterList,
  setFilterData,
} from '../../../redux-services/slices/productDataSlice';
import {
  getCategoryList,
  getGearCategoryList,
} from '../../../redux-services/slices/siteDataSlice';
import { filter } from 'lodash';

function ProductCategoryList({ type }) {
  const filters = useSelector(getFilterList);
  const [total_count, set_total_count] = useState(0);
  const gearCategoryList = useSelector(getGearCategoryList);
  const categoryList = useSelector(getCategoryList);
  const categories = type === ProductType ? gearCategoryList : categoryList;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location) {
      const parts = location.pathname.split('/');
      const lastPart = parts[parts.length - 1];
      let filterCategory = lastPart === "all" ? "all" : lastPart.toLowerCase();
      if (lastPart !== "all") {
        const filteredCategory = categories.filter((cat) => cat.slug === filterCategory);
        filterCategory = filteredCategory[0]?._id
      }

      let filterData = {
        ...filters,
        category:
          lastPart === 'all'
            ? 'all'
            : filterCategory,
      };
      dispatch(setFilterData(filterData));
    }
  }, [location, categories]);
  const onCategoryChange = (id) => {
    if (id === "all") {
      const currentState = location.state;

      // Modify the state or create a new state object
      const updatedState = {
        ...currentState,  // Preserve existing properties
        categoryId: 'all'  // Add or update properties
      };

      // Create a new location object with the updated state
      const updatedLocation = {
        ...location,
        state: updatedState
      };

      // Use navigate to update the location with the new state
      navigate(location.pathname, { updatedLocation })
    }
    let filterData = {
      ...filters,
      category: filters.category === id ? '' : id,
    };
    dispatch(setFilterData(filterData));
  };

  useEffect(() => {
    if (categories !== (undefined || null)) {
      let count = 0;
      for (let data of categories) {
        if (data?.products?.length > 0) {
          count += data.products.length;
        }
      }
      set_total_count(count);
    }
  }, [categories])

  return (
    <Col md="4" lg="3">
      <div className="sidebar-outer">
        <div className="sidebar">
          <h3>{type === ProductType ? 'Gear' : 'Club Type'} </h3>
          <ul className="categoryList">
            <li className={
              filters.category === "all" || !filters.category ? 'active' : ''
            }
              key={'all'} >
              <Link
                onClick={(e) => {
                  // e.preventDefault();
                  onCategoryChange("all");
                }}
                state={{ categoryId: "all" }}
                to={`/${ProductType === type ? "gear" : "shafts"}/category/all`}
              >
                <span className="productName">
                  <img
                    src={'/images/all-product-img.png'}
                    alt="#"
                    style={{ mixBlendMode: "multiply" }}
                  />
                  <h4>{type === ProductType ? 'All Gear Products' : 'All Shafts'}</h4>
                </span>
                <span className="counter">{total_count}</span>
              </Link>
            </li>
            {categories.length &&
              categories.map((category) =>
                category.total_products > 0 ? (
                  <li
                    className={
                      filters.category === category._id ? 'active' : ''
                    }
                    key={'plist' + category._id}
                  >
                    <Link
                      onClick={(e) => {
                        // e.preventDefault();
                        onCategoryChange(category._id);
                      }}
                      to={`/${ProductType === type ? "gear" : "shafts"}/category/${category?.slug}`}
                    >
                      <span className="productName">
                        <img
                          src={category?.image}
                          onError={() =>
                            (this.src = '/images/productdummy.png')
                          }
                          alt="#"
                        />
                        <h4>{category?.name}</h4>
                      </span>
                      <span className="counter">{category.total_products}</span>
                    </Link>
                  </li>
                ) : null
              )}
          </ul>
        </div>
      </div>
    </Col>
  );
}

export default ProductCategoryList;

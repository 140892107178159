import React, { useCallback, useState, useMemo, useEffect, } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FiSearch, FiMapPin } from 'react-icons/fi';
import { AiOutlineMobile, AiOutlineMail } from 'react-icons/ai';
import { BsFillPinMapFill } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useGetDealerListQuery } from '../../redux-services/apis/dealerApi';
import { getUserDetails } from '../../redux-services/slices/authSlice';
import { useGetDataBySlugQuery } from '../../redux-services/apis/technologyApi';
import Spiner from '../../components/Layouts/Spiner';
import { parsePhoneNumber } from 'libphonenumber-js';

function Dealer() {
  const alphabet = [...Array(26).keys()].map((i) =>
    String.fromCharCode(i + 65)
  );

  const initialState = {
    searchText: '',
    filterOrder: '',
    latitute: '',
    longitude: '',
    radius: 0,
  };


  let navigate = useNavigate();
  const location = useLocation();
  const authUser = useSelector(getUserDetails);
  const [search, setSearch] = useState('');
  const [alphabaticId, setAlphabaticId] = useState('');
  const [dealersList, setDealersList] = useState(initialState);
  const [dealerData, setDealerData] = useState();
  const [metaDetails, setMetaDetails] = useState({});
  const { isSuccess, data: dealerListData } = useGetDealerListQuery({
    searchText: dealersList.searchText ?? search,
    filterOrder: dealersList.filterOrder ?? alphabaticId,
    latitute: dealersList.latitute ?? 0,
    longitude: dealersList.longitude ?? 0,
    radius: dealersList.radius ?? 0,
  });

  const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
    slug: location?.pathname.replace('/', '')
  });

  useEffect(() => {
    setMetaDetails(getDataBySlug?.response[0])
  })

  useEffect(() => {
    setDealerData(dealerListData?.response?.usDealersList);
  }, [dealerListData])

  const [radius, setRadius] = useState('');
  const [lat, setlat] = useState();
  const [lng, setlng] = useState();

  const [myLocation, setMyLocation] = useState(false);
  const handleMyLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setlat(position.coords.latitude);
      setlng(position.coords.longitude);
      let delayTimer;
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        setDealersList({ searchText: search, filterOrder: alphabaticId, radius: radius, latitute: position.coords.latitude, longitude: position.coords.longitude })
      }, 750);
    })
  }, [radius, lat, lng, search, alphabaticId]);

  useEffect(() => {
    if (lat != undefined) {
      setMyLocation(true);
    }
  }, [lat]);

  const handleNavigate = useCallback(() => {
    navigate('/dealer-registration');
  }, []);

  const handleRadius = useCallback((e) => {
    setRadius(e.target.value);
    let delayTimer;
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      setDealersList({ searchText: search, filterOrder: "", radius: e.target.value, latitute: lat, longitude: lng })
      setAlphabaticId('');
    }, 750);
  }, [radius, lat, lng, search, alphabaticId]);

  const handleSearch = useCallback(
    (e) => {
      let delayTimer;
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        setDealersList({ searchText: e.target.value, filterOrder: "", radius: radius, latitute: lat, longitude: lng });
      }, 750);
      setSearch(e.target.value);
      setAlphabaticId('');
    },
    [radius, lat, lng, search, alphabaticId]
  );

  const handleClickOnAphabatic = useCallback(
    (e) => {
      e.preventDefault();

      // set the alphabeticId in dealerList
      const alphaOrderId = e.currentTarget?.getAttribute('data-id');
      let delayTimer;
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        setDealersList({ filterOrder: alphaOrderId, radius: radius, latitute: lat, longitude: lng });
      }, 750);
      setAlphabaticId(alphaOrderId);
    },
    [radius, lat, lng, search, alphabaticId]
  );

  const AlphabaticUI = useMemo(() => {
    return (
      <Col md="12">
        <div className="selectfilters">
          <Link
            to=""
            data-id={''}
            onClick={handleClickOnAphabatic}
            className={alphabaticId === '' ? 'active' : ''}
          >
            All
          </Link>
          {alphabet.map((alphaOrder) => {
            return (
              <div>
                <Link
                  to=""
                  data-id={alphaOrder}
                  onClick={handleClickOnAphabatic}
                  className={alphabaticId === alphaOrder ? 'active' : ''}
                >
                  {alphaOrder}
                </Link>
              </div>
            );
          })}
        </div>
      </Col>
    );
  }, [alphabaticId]);

  const dealerUI = useMemo(() => {

    const formatPhoneNumber = (phoneNumber, countryCode) => {
      const formattedPhoneNumber = parsePhoneNumber(countryCode + phoneNumber).formatInternational();
      return formattedPhoneNumber;
    }
    return (dealerData &&
      dealerData.length != 0 ? (
      <>
        {dealerData.map((dealer) => (
          <>
            <div className="dealerslistouter" key={dealer?.state}>
              <h4>{dealer?.state}</h4>
            </div>
            <Row>
              {dealer.addresses.map((addressData) => (
                <Col md="6" sm="12" lg="4" xl="4" key={addressData._id}>
                  <div className="listbody">
                    <Link to="" className="companylogo">
                      {addressData?.avatar != "" ?
                        <img
                          src={
                            process.env.REACT_APP_AWS_STORAGE_URL +
                            '/uploads/dealer-logo/' +
                            addressData.avatar
                          }
                          alt={addressData?.business_name}
                        />
                        :
                        <div>
                          <img src="" />
                        </div>
                      }
                    </Link>
                    <Link to="">
                      {addressData?.business_name} - {addressData?.city} <span className="d-block">({addressData.state}, {addressData.country})</span>
                    </Link>
                    <ul className="infolistdetails">
                      <li>
                        <FiMapPin />
                        {addressData.address_line_1}
                        {addressData.address_line_2 &&
                          addressData.address_line_2 != '' ? (
                          <span className="d-block">
                            {addressData.address_line_2}
                          </span>
                        ) : null}
                        <span className="d-block">
                          {addressData.city}, {addressData.state}, {addressData.zipcode}
                        </span>
                        {addressData.country}
                      </li>
                      <li className='number'>
                        <AiOutlineMobile />
                        <a href={`tel:${addressData.ccode + addressData.contact_no}`} style={{ textDecoration: 'none' }}>
                          {formatPhoneNumber(addressData.contact_no, addressData.ccode)}
                        </a>
                      </li>
                      <li>
                        <AiOutlineMail />
                        <a href={`mailto:?to=${addressData?.email}`} target="_blank" className="gmail">
                          {addressData.email}
                        </a>
                      </li>
                      {addressData?.alternative_email_one ? <li>
                        <AiOutlineMail />
                        <a href={`mailto:?to=${addressData?.alternative_email_one}`} target="_blank" className="gmail">
                          {addressData.alternative_email_one}
                        </a>
                      </li> : null}
                      {addressData?.alternative_email_two ? <li>
                        <AiOutlineMail />
                        <a href={`mailto:?to=${addressData?.alternative_email_two}`} target="_blank" className="gmail">
                          {addressData.alternative_email_two}
                        </a>
                      </li> : null}
                    </ul>
                    <span className="viewlocation">
                      <a
                        href={addressData.google_map_link ? addressData.google_map_link : `https://www.google.com/maps/place/${addressData.address}`}
                        target="_blank"
                      >
                        <BsFillPinMapFill /> View on Map
                      </a>
                    </span>
                  </div>
                </Col>
              ))}
            </Row>
          </>
        ))}
      </>
    ) : (
      <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
    ));
  }, [dealerData]);

  return (
    isLoading ? (
      <Spiner />
    ) : (
      metaDetails && metaDetails?.status == 1 && metaDetails != null ? (
        <>
          <section className="pageheading">
            <img
              src={metaDetails?.banner_image}
              alt="#"
              className="w-100 Innerbanner"
            />
            <div className="mainsection">
              <div className="headingContent">
                <Container>
                  <h2>
                    {metaDetails?.page_title}
                  </h2>
                  <h4>{metaDetails?.page_subtitle}</h4>
                </Container>
              </div>
              <section className="productlist-outer">
                <Container>
                  <Row className="justify-content-between mb-4">
                    <Col md="12">
                      <div className="uslinks">
                        <Link
                          to="/international-dealer"
                          className="Link"
                        >
                          International Dealers
                        </Link>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="form-group">
                        <div className="searchdealers">
                          <input
                            type="text"
                            placeholder="Search dealers by name, city, state, zip... "
                            className="form-control"
                            value={search}
                            onChange={handleSearch}
                          />
                          <Button>
                            <FiSearch />
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="find-store">
                        <h5 onClick={handleMyLocation}>
                          <MdLocationPin /> Use My Location
                        </h5>
                        {myLocation && (
                          <div className="form-group">
                            <label>Radius</label>
                            <input
                              type="number"
                              className="form-control"
                              value={radius}
                              onChange={handleRadius}
                              min="0"
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                    {/* {authUser?.userType != 3 && ( */}
                    <Col md='4'>
                      <div className="form-group text-end">
                        <Button className="Dealerbtn" onClick={handleNavigate}>
                          Become a Dealer
                        </Button>
                      </div>
                    </Col>
                    {/* )} */}
                    {AlphabaticUI}
                    <Col md="12">{dealerUI}</Col>
                  </Row>
                </Container>
              </section>
            </div>
          </section>
        </>
      ) : (
        <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
      )
    )
  );
}

export default Dealer;

import { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AiFillStar, AiOutlineMinus, AiOutlinePlus, AiOutlineStar } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { useAddToCartMutation, useRemoveCouponMutation, useUpdateCartQuantityMutation } from '../../redux-services/apis/cartApi';
import { useAddProductRatingMutation } from '../../redux-services/apis/productDetailApi';
import {
    showModel,
    getFittingList,
} from '../../redux-services/slices/siteDataSlice';
import ShowError from '../Notifications/ShowError';
import ShowModel from '../Notifications/ShowModel';
import { getUserDetails } from '../../redux-services/slices/authSlice';

const DUMMY_IMAGE = '/images/productdummy.png';

const AWS_STORAGE_URL = process.env.REACT_APP_AWS_STORAGE_URL;

const ServiceCartProduct = ({ buildShopSupplyProduct, serviceProduct }) => {


    return (
        <>
            {buildShopSupplyProduct.map((item) => (<div key={item._id} className={'checkoutproductdetails check-out-details-panel'}>
                <div className={'check-out-panel-left'}>
                    <div className="checkoutDouter">
                        <div className="checkoutleft">
                            <div className="checkimg">
                                <img
                                    src={'/images/noproduct.png'}
                                    alt={"yes"}
                                    className="img-fluid"
                                />
                            </div>
                            <div className="checkoutDetail">
                                <Link to={'#'}>
                                    <h5 style={{ textTransform: 'capitalize' }}>
                                        {item?.product_title} - {"Build Shop Supply"}
                                    </h5>
                                </Link>
                                <p>
                                    <span>Model Number : </span>
                                    {item?.sku}
                                </p>
                                <p>
                                    <span>Quantity : </span>
                                    {item?.quantity}
                                </p>
                                {item?.description && <p>
                                    <span>Description : </span>
                                    <span dangerouslySetInnerHTML={{ __html: item?.description }} />
                                </p>}
                            </div>
                        </div>
                        <div className="totals">
                            TOTAL <span>${(item?.price * item.quantity).toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </div>))}
            {serviceProduct.map((item) => (<div key={item._id} className={'checkoutproductdetails check-out-details-panel'}>
                <div className={'check-out-panel-left'}>
                    <div className="checkoutDouter">
                        <div className="checkoutleft">
                            <div className="checkimg">
                                <img
                                    src={'/images/noproduct.png'}
                                    alt={"yes"}
                                    className="img-fluid"
                                />
                            </div>
                            <div className="checkoutDetail">
                                <Link to={'#'}>
                                    <h5 style={{ textTransform: 'capitalize' }}>
                                        {item?.product_title} - {"Service"}
                                    </h5>
                                </Link>
                                <p>
                                    <span>Model Number : </span>
                                    {item?.sku}
                                </p>
                                <p>
                                    <span>Quantity : </span>
                                    {item?.quantity}
                                </p>
                                {item?.description && <p>
                                    <span>Description : </span>
                                    <span dangerouslySetInnerHTML={{ __html: item?.description }} />
                                </p>}
                            </div>
                        </div>
                        <div className="totals">
                            TOTAL <span>${(item?.price * item.quantity).toFixed(2)}</span>
                        </div>
                    </div>
                </div>
            </div>))}
        </>
    );
};

export default ServiceCartProduct;

import { HOME_TAG } from '../../constants';
import { ApiService } from './apiService';

const BASE_URL = '/api/front';

export const HomeAPI = ApiService.injectEndpoints({
    endpoints: (builder) => ({
        getHomeList: builder.query({
            query: (homeData) => ({
                url: `${BASE_URL}/home`,
                method: 'GET',
                body: {
                    ...homeData,
                }
            }),
            providesTags: [HOME_TAG],
        }),
    }),
});

export const { useGetHomeListQuery } = HomeAPI;

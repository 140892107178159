import { useEffect, useState } from "react";
import { Col, Container, Row, Button, Image, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setSelectedShippingAddress, setSelectedShippingRate } from "../redux-services/slices/cartSlice";
import { getProductSelector, setProductSelector } from "../redux-services/slices/siteDataSlice";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';

const ProductSelectorMain = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [cmsData, setCmsData] = useState();
    const [isShow, setIsShow] = useState(false);
    const [check, setCheck] = useState('normal_shaft');
    const productSelector = useSelector(getProductSelector);
    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: location?.pathname?.replace('/', ''),
    });

    useEffect(() => {
        if (getDataBySlug?.status && getDataBySlug.response) {
            setCmsData(getDataBySlug?.response[0])
        }
    }, [getDataBySlug]);

    const initialState = {
        shaft_type: 'normal_shaft',
        category: '',
        sale_closed: false,
        product_selector_result_id: '',
        questions: {
            swing_speed: "",
            check_swing_speed: '',
            priority: "",
            launch_angle: "",
            spin: "",
            attack_angle: "",
            tempo: "",
            trajectory: "",
            shaft_weight: "",
            current_Weight: "",
            iron_shaft_weight: "",
            config: "",
            ball_flight: "",
            one_length_player: false,
            wedges: {
                wedges_1: "",
                wedges_2: "",
                wedges_3: "",
                wedges_4: "",
            },
        },
    };

    useEffect(() => {
        dispatch(setProductSelector(initialState))
    }, [])

    useEffect(() => {
        dispatch(setSelectedShippingAddress(null));
        dispatch(setSelectedShippingRate(null));
    }, []);

    const responsive01 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1200, min: 991 },
            items: 4
        },
        minitablet: {
            breakpoint: { max: 991, min: 768 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2
        }
    };

    const handleClick = (e) => {
        setCheck(e.target.name);
        let setProduct = {
            ...productSelector,
            shaft_type: e.target.name
        }
        dispatch(setProductSelector(setProduct));
    };

    const handleClose = () => {
        let setProduct = {
            ...productSelector,
            shaft_type: ''
        }
        setCheck('normal_shaft');
        dispatch(setProductSelector(setProduct));
        setIsShow(!isShow);
    };

    return <>

        <section className="Driver-selecter-outer Select-productOuter facility-outer otherProducts pt-0">
            <div className="mainDriver-selector technology-mat-content text-center mb-3">
                {/* <section className="InnerPage">
                    <img
                        src={cmsData?.banner_image}
                        alt='Banner image'
                    />
                    <div className="banner-contentInner">
                    <h2>{cmsData?.page_title}</h2>
                        <h4>{cmsData?.page_subtitle}</h4>
                    </div>
                </section> */}
                <div className="technology-mat-content text-center py-4 ">
                    <div className="container">
                        <h2>{cmsData?.page_title}</h2>
                        <h4>{cmsData?.page_subtitle}</h4>
                    </div>
                </div>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col md="3" sm="6">
                        <div className="product-outer-main">
                            <div className="product-outer">
                                <Link to="#" className="kinetixx-golf" onClick={(e) => setIsShow(!isShow)}>
                                    <div className="golf-Images">
                                        <Image src="images/Driver.png" alt="" className="img-fluid" />
                                    </div>
                                    <h4>Drivers</h4>
                                    <Button className="mt-3 mb-4">Get Started</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>

                    <Col md="3" sm="6">
                        <div className="product-outer-main">
                            <div className="product-outer">
                                <Link to="/kinetixx-fit/fairway" className="kinetixx-golf">
                                    <div className="golf-Images">
                                        <Image src="images/Hybrids.png" alt="" className="img-fluid" />
                                    </div>
                                    <h4>Fairways</h4>
                                    <Button className="mt-3 mb-4">Get Started</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col md="3" sm="6">
                        <div className="product-outer-main">
                            <div className="product-outer">
                                <Link to="/kinetixx-fit/hybrid" className="kinetixx-golf">
                                    <div className="golf-Images">
                                        <Image src="images/Hybrids.png" alt="" className="img-fluid" />
                                    </div>
                                    <h4>Hybrids</h4>
                                    <Button className="mt-3 mb-4">Get Started</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col md="3" sm="6">
                        <div className="product-outer-main">
                            <div className="product-outer">
                                <Link to="/kinetixx-fit-iron/iron" className="kinetixx-golf">
                                    <div className="golf-Images">
                                        <Image src="images/Iron.png" alt="" className="img-fluid" />
                                    </div>
                                    <h4>Irons</h4>
                                    <Button className="mt-3 mb-4">Get Started</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                    {/* <Col md="12">
                        <div className='kinetixx-slider-item'>
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive01}
                                ssr={true}
                                infinite={true}
                                autoPlaySpeed={2000}
                                keyBoardControl={true}
                            >
                                <div>
                                    
                                </div>
                                <div>
                                    
                                </div>
                                <div>
                                    
                                </div>
                                <div>
                                    
                                </div>
                                <div>
                                    <div className="product-outer-main">
                                        <div className="product-outer">
                                            <Link to="/kinetixx-fit/putter" className="kinetixx-golf">
                                                <div className="golf-Images">
                                                    <Image src="images/Putter.png" alt="" className="img-fluid" />
                                                </div>
                                                <h4>Putter</h4>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </Carousel>
                        </div>
                    </Col> */}
                </Row>
            </Container>

        </section>

        {isShow &&
            <Modal show={isShow} onHide={handleClose} centered>
                <Modal.Header closeButton className="border-bottom">
                    <Modal.Title>Shopping for</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-3">
                    <Form>
                        <Row>
                            <Col md='6'>
                                <Form.Check
                                    type="radio"
                                    name="normal_shaft"
                                    className="radio-driver"
                                    value="normal_shaft"
                                    label="Driver Shafts"
                                    checked={check === 'normal_shaft' ? true : false}
                                    onChange={handleClick}
                                />
                            </Col>
                            <Col md='6'>
                                <Form.Check
                                    type="radio"
                                    name="long_shaft"
                                    className="radio-driver"
                                    value="long_shaft"
                                    label="Long Drive Shafts"
                                    checked={check === 'long_shaft' ? true : false}
                                    onChange={handleClick}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-center submit-outer model-footer back-next">
                        <Link to='#' onClick={handleClose}>Go Back</Link>
                        <Link to="/kinetixx-fit/driver" className="btn btn-primary">Continue</Link>
                    </div>
                </Modal.Footer>
            </Modal>
        }
    </>
}

export default ProductSelectorMain;